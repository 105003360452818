// NavigTreeLoader.js
import React from 'react';
import { useSelector } from 'react-redux';
import { DotLoader } from '@mantine/core'; // Or whatever loader you use
import NavigTreeWrapper from './NavigTreeWrapper';

const NavigTreeLoader = (props) => {
  const objectsDefList = useSelector((state) => state.objects.objectsDefList);

  if (!objectsDefList?.length) {
    // Return loader instead of rejecting promise
    return <div>Loading</div>;
  }

  return <NavigTreeWrapper {...props} />;
};

export default NavigTreeLoader;
