import styled from 'styled-components';

export const MainDrawingPanelSVG = styled.svg({
  // Ensure the top-level <svg> itself has overflow visible
  overflow: 'visible !important',

  // Then ensure any nested <svg> inside it also has overflow visible
  '& svg': {
    overflow: 'visible !important',
  },
});
