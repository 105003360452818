import { rgb } from 'd3';
import { lighten } from './colorManipulator';
import { getBandsPosition } from './LayoutUtils';

const DURATION_MS = 300;
//------------------------------------------------------------------------------------------------------------
export function drawLinks(
  nodes,
  svg,
  colArrow,
  paramClassif,
  arrowtype,
  arrowopacity,
  arrowweight,
  bands_config
) {
  // nettoyage
  svg.select('.links-group').selectAll('*').remove();
  if (!nodes?.length) return;
  if (paramClassif !== 'default') {
    if (paramClassif === 'geo') {
      generateLinkToSpot(nodes, svg);
      generateCircleToSpot(nodes, svg);
      generateLineLink(nodes, svg);
    } else {
      switch (arrowtype) {
        case 'curved':
          generateClassicLink(
            nodes,
            svg,
            colArrow,
            arrowopacity,
            arrowweight,
            bands_config
          );
          break;
        case 'classic':
          generateStraightLink(
            nodes,
            svg,
            colArrow,
            arrowopacity,
            arrowweight,
            bands_config
          );
          break;
        case 'diagonal':
          generateDiagonalLink(
            nodes,
            svg,
            colArrow,
            arrowopacity,
            arrowweight,
            bands_config
          );
          break;
        default:
          generateFuzzyLink(
            nodes,
            svg,
            colArrow,
            arrowopacity,
            arrowweight,
            bands_config
          );
          break;
      }
    }
  } else {
    switch (arrowtype) {
      case 'curved':
        generateClassicLink(
          nodes,
          svg,
          colArrow,
          arrowopacity,
          arrowweight,
          bands_config
        );
        break;
      case 'classic':
        generateStraightLink(
          nodes,
          svg,
          colArrow,
          arrowopacity,
          arrowweight,
          bands_config
        );
        break;
      case 'diagonal':
        generateDiagonalLink(
          nodes,
          svg,
          colArrow,
          arrowopacity,
          arrowweight,
          bands_config
        );
        break;
      default:
        generateFuzzyLink(
          nodes,
          svg,
          colArrow,
          arrowopacity,
          arrowweight,
          bands_config
        );
        break;
    }
  }
}
//------------------------------------------------------------------------------------------------------------
export function generateLinkToSpot(nodes, svg) {
  const filteredNode = nodes.filter((a) => a.data.location !== undefined);
  svg
    .selectAll('.linkLineSpot')
    .data(filteredNode.slice(0))
    .enter()
    .append('path')
    .attr('class', 'linkLineSpot')
    .attr('style', 'fill:steelblue')
    .attr('stroke-width', '2')
    .attr('d', function (d) {
      return `M${d.x_loc},${d.y_loc} l ${50},${-35}`;
    });
}
//------------------------------------------------------------------------------------------------------------
export function generateCircleToSpot(nodes, svg) {
  const filteredNode = nodes.filter((a) => a.data.location !== undefined);
  svg
    .selectAll('.circleSpot')
    .data(filteredNode.slice(0))
    .enter()
    .append('circle')
    .attr('class', 'circleSpot')
    .attr('style', 'fill:steelblue')
    .attr('fill-opacity', '0.5')
    .attr('stroke-width', '1')
    .attr('cx', (d) => d.x_loc)
    .attr('cy', (d) => d.y_loc)
    .attr('r', '10')
    .attr('stroke', rgb('steelblue').darker());
}
//------------------------------------------------------------------------------------------------------------
export function generateLineLink(nodes, svg) {
  const filteredNode = nodes.filter(
    (a) => a.data.location !== undefined && a.parent.data.location !== undefined
  );
  svg
    .selectAll('.linkLine')
    .data(filteredNode.slice(0))
    .enter()
    .append('path')
    .attr('class', 'linkLine')
    .attr('fill-opacity', '1')
    .attr('stroke-width', function (d) {
      return 1;
    })
    .attr('style', 'fill:steelblue')
    .attr('d', function (d) {
      return d.depth < 2
        ? ''
        : `M${d.x_loc + 55},${-45 + d.y_loc} L ${d.parent.x_loc + 155},${
            -45 + d.parent.y_loc
          } l ${Math.min(40, 25 + 3 * d.data.nbChild)},${0} z `;
    });
}
//------------------------------------------------------------------------------------------------------------
export function generateClassicLink(
  nodes,
  svg,
  arrowColor,
  arrowOpacity = 0.5,
  arrowWeight = 1,
  bands_config
) {
  const group = svg.select('.links-group');

  const links = group.selectAll('*').data(nodes.slice(0), (d) => {
    return d.data.key;
  });

  const linksEnter = links
    .enter()
    .append('path')
    .attr('class', 'link')
    .attr('stroke', arrowColor)
    .attr('stroke-dasharray', '5,2')
    // .attr("filter","url(#ddglow)")
    .attr('stroke-opacity', function (d, i) {
      return arrowOpacity;
    })
    // .attr("stroke-opacity", function(d,i){return((1+(i%5))*0.1)})
    // .attr("stroke-width", function(d){return(1+0.1*d.data.nbChild)})
    .attr('stroke-width', 0.5 * arrowWeight);
  // .attr('d', function (d) {
  //   return d.depth < 2 ? '' : calculateCoordinateClassicLink(d, bands_config);
  // });

  linksEnter
    .merge(links)
    .transition()
    .duration(DURATION_MS) // Set the animation duration (in milliseconds)
    .attr('d', function (d) {
      return d.depth < 2 ? '' : calculateCoordinateClassicLink(d, bands_config);
    });
  // alert('exit:'+links.exit().size());
  links.exit().remove();
}
//------------------------------------------------------------------------------------------------------------
function calculateCoordinateClassicLink(d, bands_config) {
  // from childNode to parent
  // remember X and Y are inverted
  let bandEndCoordinate = getBandsPosition(d.depth - 1, bands_config).end;
  let bandStartCoordinate = getBandsPosition(d.depth - 2, bands_config).end;

  // on corrige les coordonnées du panel
  let destNodeMaxX = bandEndCoordinate - 86;
  let destNodeMinX = bandStartCoordinate - 87;

  // on vérifier que l'arrivée au node (destination) ne sorte pas pas panel
  let x0 = Math.min(d.x, destNodeMaxX);
  x0 = Math.max(x0, destNodeMinX);

  // Math.min(bandEndCoordinate-70, Math.max(d.y, d.parent.ymax));
  let y0 = d.y + 2 + d.ySize / 2;

  let x1 = -110 + d.parent.xmax;
  let y1 = d.y + 2 + d.ySize / 2;

  let x2 = -150 + d.parent.xmax;
  let y2 = d.y + 2 + d.ySize / 2;

  let x3 = -140 + d.parent.xmax;
  let y3 = d.parent.y + d.parent.ySize / 2;

  let x4 = d.parent.xmax - 200;
  let y4 = d.parent.y + d.parent.ySize / 2;

  let x5 = d.parent.x;
  let y5 = d.parent.y + d.parent.ySize / 2;

  return `M ${x0},${y0} L ${x1},${y1} C ${x2},${y2} ${x3},${y3}  ${x4},${y4} L${x5},${y5} `;
}
//------------------------------------------------------------------------------------------------------------
export function generateFuzzyLink(
  nodes,
  svg,
  arrowColor,
  arrowOpacity,
  arrowWeight,
  bands_config
) {
  try {
    if (arrowColor) arrowColor = lighten(arrowColor, 0.8);
  } catch (e) {
    console.log(e);
  }

  const group = svg.select('.links-group');

  const links = group.selectAll('*').data(nodes.slice(0), (d) => {
    return d.data.key;
  });

  const linksEnter = links
    .enter()
    .append('path')
    .attr('class', 'linkFuzzy')
    .attr('fill-opacity', function (d) {
      return arrowOpacity;
    })
    .attr('style', (d) =>
      d.data.plength === 1 ? 'fill:steelblue' : `fill:${arrowColor}`
    )
    // .attr("stroke-width", function(d){return(1+0.5*d.data.nbChild)})
    .attr('stroke', arrowColor)
    .attr('stroke-dasharray', '2,2')
    .attr('stroke-width', arrowWeight);

  linksEnter
    .merge(links)
    .transition()
    .duration(DURATION_MS) // Set the animation duration (in milliseconds)
    .attr('d', function (d) {
      return d.depth < 2 ? '' : calculateCoordinateFuzzy(d, bands_config);
    });

  links.exit().remove();
}
//------------------------------------------------------------------------------------------------------------
function calculateCoordinateFuzzy(d, bands_config) {
  // from childNode to parent
  // remember X and Y are inverted
  let bandEndCoordinate = getBandsPosition(d.depth - 1, bands_config).end;

  let x0 =
    -120 + Math.min(bandEndCoordinate - 60, Math.max(d.x, d.parent.xmax));
  let y0 = d.y + d.ySize / 2;
  let x1 = d.parent.x;
  let y1 = d.parent.y - 10 + d.parent.ySize / 2;
  let x2 = 0;
  let y2 = Math.min(45, 20 + 5 * d.data.nbChild);
  return `M${x0},${y0} L ${x1},${y1} l ${x2},${y2} z `;
}

//------------------------------------------------------------------------------------------------------------

export function generateStraightLink(
  nodes,
  svg,
  arrowColor,
  arrowOpacity,
  arrowWeight,
  bands_config
) {
  const group = svg.select('.links-group');

  const links = group
    .selectAll('.linkSL-group') // <-- IMPORTANT : ne pas mettre selectAll('*'),
    .data(nodes, (d) => d.data.key);

  const linksEnter = links
    .enter()
    .append('g') // Add a group for each link
    .attr('class', 'linkSL-group');

  // Sur ce groupe ENTER, on ajoute *deux* chemins (linkSL1 et linkSL2)
  linksEnter
    .append('path')
    .attr('class', 'linkSL1')
    .attr('stroke', arrowColor)
    .attr('stroke-opacity', arrowOpacity)
    .attr('stroke-width', arrowWeight)
    .attr('stroke-dasharray', '5,2');

  linksEnter
    .append('path')
    .attr('class', 'linkSL2')
    .attr('stroke', arrowColor)
    .attr('style', 'fill:none')
    .attr('stroke-opacity', arrowOpacity)
    .attr('stroke-width', arrowWeight);

  // GESTION de l’UPDATE + ENTER (merge)
  // (linksEnter.merge(links)) contient à la fois les groupes qui étaient déjà là (update) et
  // ceux qu’on vient de créer (enter). On peut alors cibler nos .linkSL1 et .linkSL2 pour leur
  // assigner la bonne position.
  const linksMerged = linksEnter.merge(links);

  // On met à jour le chemin 1 (linkSL1)
  linksMerged
    .select('.linkSL1')
    .transition()
    .duration(300)
    .attr('d', (d) => {
      return d.depth < 2
        ? ''
        : calculateCoordinateStraightLinkPart1(d, bands_config);
    });

  // On met à jour le chemin 2 (linkSL2)
  linksMerged
    .select('.linkSL2')
    .transition()
    .duration(300)
    .attr('d', (d) => {
      return d.depth < 2
        ? ''
        : calculateCoordinateStraightLinkPart2(d, bands_config);
    });

  // GESTION du EXIT
  links.exit().remove();
  links.exit().remove();
  // links2.exit().remove();
}
//------------------------------------------------------------------------------------------------------------
function calculateCoordinateStraightLinkPart1(d, bands_config) {
  // from childNode to parent
  // remember X and Y are inverted
  // from childNode to parent
  // remember X and Y are inverted
  let bandEndCoordinate = getBandsPosition(d.depth - 1, bands_config).end;
  let bandStartCoordinate = getBandsPosition(d.depth - 2, bands_config).end;

  // on corrige les coordonnées du panel
  let destNodeMaxX = bandEndCoordinate - 86;
  let destNodeMinX = bandStartCoordinate - 87;

  // on vérifier que l'arrivée au node (destination) ne sorte pas pas panel
  let x0 = Math.min(d.x, destNodeMaxX);
  x0 = Math.max(x0, destNodeMinX);
  let y0 = d.y + d.ySize / 2;
  let x1 = -140 + d.parent.xmax;
  let y1 = d.y + d.ySize / 2;

  return `M${x0},${y0} L ${x1},${y1} `;
}
//------------------------------------------------------------------------------------------------------------
function calculateCoordinateStraightLinkPart2(d, bands_config) {
  // from childNode to parent
  // remember X and Y are inverted

  let x0 = -140 + d.parent.xmax;
  let y0 = d.y + d.ySize / 2;
  let x1 = d.parent.xmax - 140;
  let y1 = d.parent.y + d.parent.ySize / 2;
  let x2 = d.parent.x;
  let y2 = d.parent.y + d.parent.ySize / 2;
  return `M${x0},${y0} L ${x1},${y1} L ${x2},${y2} `;
}
//------------------------------------------------------------------------------------------------------------
export function generateDiagonalLink(
  nodes,
  svg,
  arrowColor,
  arrowOpacity,
  arrowWeight,
  bands_config
) {
  const group = svg.append('g');
  group
    .selectAll('.link')
    .data(nodes.slice(0))
    .enter()
    .append('path')
    .attr('class', 'link')
    // .attr("stroke", d=>d.data.nodeColor)
    .attr('stroke', arrowColor)
    .attr('stroke-opacity', function (d, i) {
      return arrowOpacity;
    })
    .attr('stroke-width', arrowWeight)
    .attr('stroke-dasharray', '5,2')
    .attr('d', function (d) {
      return d.depth < 2
        ? ''
        : `M${-80 + d.x},${d.y + d.ySize / 2} L ${d.parent.x - 0},${
            d.parent.y + d.parent.ySize / 2
          }`;
    });
}
