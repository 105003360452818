import { Group, Input, MultiSelect, Space, Stepper, Text } from '@mantine/core';
import { useDebouncedValue, useEventListener } from '@mantine/hooks';
import isEmpty from 'lodash/isEmpty';
import React, { forwardRef, memo, useCallback, useMemo, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { style } from 'd3';
import { currentPaletteActiveSelector } from '../../reducers/themeReducers/themeSelector.js';

import { colors } from '../../constants/colors.js';
import { SearchIcon } from '../../constants/icon.js';
import { getFromDico, getObjectTypeLabelOrHome } from '../Utils/Utils.js';
import {
  ButtonStyled,
  DivBlocWrap,
  DivDivider,
  DivLine,
  DivSummary,
  DivTitleBloc,
  ObjectSelectorContainer,
} from './ObjectSelectorCss.js';
import { getStep } from './ObjectSelectorUtils.js';

class ObjectSelector extends React.Component {
  //------------------------------------------------------------------------------------------------
  constructor(props) {
    super(props);
    this.handleClickSelectTypeObject =
      this.handleClickSelectTypeObject.bind(this);
    this.handleClickSelectObject = this.handleClickSelectObject.bind(this);
    this.handleClickConfirmCreate = this.handleClickConfirmCreate.bind(this);
    this.handleClickSelectField = this.handleClickSelectField.bind(this);
    this.handleUndo = this.handleUndo.bind(this);

    if (props.typeSelectedObject) {
      props.setObjectSelectorState({
        ...props.objectSelectorState,
        typeSelectObject: props.typeSelectedObject,
        idSelectedObject: null,
      });
      props.getObjectsByTypeNoRestrictions(props.typeSelectedObject);
    }
  }

  //------------------------------------------------------------------------------------------------
  handleUndo(step) {
    const ocs = { ...this.props.objectSelectorState };
    if (step < 3) {
      ocs.idSelectedObject = null;
      if (step < 2) {
        ocs.typeSelectObject = null;
      }
    }
    this.props.setObjectSelectorState(ocs);
  }

  //------------------------------------------------------------------------------------------------
  handleClickConfirmCreate(e) {
    // this.props.openObjectDetails();
    this.props.closeSelectObject();
  }

  isLinkDrawingProperty(objectSelectorState) {
    // we are linking a text to an object property (context of drawing)
    return (
      objectSelectorState.action === 'link' &&
      objectSelectorState.mode === 'text'
    );
  }

  isLinkDrawingObject(objectSelectorState) {
    // we are linking an to an objId property (context of drawing)
    return (
      objectSelectorState.action === 'link' &&
      objectSelectorState.mode !== 'text'
    );
  }

  //------------------------------------------------------------------------------------------------
  handleClickSelectObject(e, objId) {
    const idSelectedObject = objId || e.currentTarget.getAttribute('data-id');
    if (this.props.onSelectValue) {
      this.props.onSelectValue(idSelectedObject);
      this.props.closeSelectObject();
    }
    if (this.isLinkDrawingObject(this.props.objectSelectorState)) {
      this.props.linkObject(idSelectedObject);
    }
    this.props.setObjectSelectorState({
      ...this.props.objectSelectorState,
      idSelectedObject,
    });
    if (this.isLinkDrawingObject(this.props.objectSelectorState)) {
      this.props.closeSelectObject();
    }
  }

  //------------------------------------------------------------------------------------------------
  handleClickSelectField(e, label) {
    const fieldName = label || e.currentTarget.getAttribute('data-id');
    if (this.isLinkDrawingProperty(this.props.objectSelectorState)) {
      this.props.linkField(fieldName);
    }
    this.props.setObjectSelectorState({
      ...this.props.objectSelectorState,
      fieldSelected: fieldName,
    });
    if (this.isLinkDrawingProperty(this.props.objectSelectorState)) {
      this.props.closeSelectObject();
    }
  }

  //------------------------------------------------------------------------------------------------
  handleClickSelectTypeObject(e, objId) {
    const typeSelectObject = objId || e.currentTarget.getAttribute('data-id');

    this.props.setObjectSelectorState({
      ...this.props.objectSelectorState,
      typeSelectObject,
    });

    this.props.getObjectsByTypeNoRestrictions(typeSelectObject); // ptet plutot dans le reducer ?
  }

  //------------------------------------------------------------------------------------------------
  render() {
    let step = 0;
    let recheck = true;
    const { t } = this.props;
    let mode = this.props.objectSelectorState.mode;
    let isDisplaySummary = this.props.isDisplaySummary ?? true;
    let isDisplayTitle = this.props.isDisplayTitle ?? true;
    let isDisplayConfirm = this.props.isDisplayConfirm ?? true;

    do {
      recheck = false;
      step = getStep(this.props.objectSelectorState);

      if (step === 0) {
        const potentialTypes = getAllowedTypes(
          this.props.availableTypes,
          this.props.objectSelectorState?.typeSelectObject
        );
        if (potentialTypes.length === 1) {
          // if only one choice we take it
          const typeSelectedObject = potentialTypes[0];
          this.props.setObjectSelectorState({
            ...this.props.objectSelectorState,
            typeSelectedObject,
            idSelectedObject: null,
          });
          this.props.getObjectsByTypeNoRestrictions(typeSelectedObject);
        }
      } else if (step === 1) {
        if (this.props.objectsByType.length === 1) {
          const idSelectedObject = this.props.objectsByType[0].key;
          this.props.setObjectSelectorState({
            ...this.props.objectSelectorState,
            idSelectedObject,
          });
        }
      }
    } while (recheck);

    return (
      <ObjectSelectorContainer
        height={this.props.height}
        id="object_selector_container"
      >
        {step >= 0 && isDisplaySummary && (
          <BlocSummary
            objectSelectorState={this.props.objectSelectorState}
            dicoNameRef={this.props.dicoNameRef}
            typeSelectObject={this.props.objectSelectorState.typeSelectObject}
            handleUndo={this.handleUndo}
            availableTypes={this.props.availableTypes}
          />
        )}
        {step === 0 && (
          <BlocTypeSelectObject
            suggestedType={this.props.suggestedType}
            availableTypes={this.props.availableTypes}
            typeSelectObject={this.props.objectSelectorState.typeSelectObject}
            handleClick={this.handleClickSelectTypeObject}
            mode={this.props.mini}
          />
        )}

        {step === 1 && (
          <BlocIdSelectObject
            objectsByType={this.props.objectsByType}
            isDisplayTitle={isDisplayTitle}
            typeParentLabel={getObjectTypeLabelOrHome(
              this.props.availableTypes,
              this.props.objectSelectorState.typeSelectObject
            )}
            onClick={this.handleClickSelectObject}
            isLinkDrawingProperty={this.isLinkDrawingProperty(
              this.props.objectSelectorState
            )}
          />
        )}

        {step === 2 && mode?.toLowerCase() === 'text' && (
          <BlocIdSelectField
            availableTypes={this.props.availableTypes}
            typeSelectedObject={this.props.objectSelectorState.typeSelectObject}
            onClick={this.handleClickSelectField}
          />
        )}
        {isDisplayConfirm && (
          <ButtonStyled
            disabled={step < 2}
            type="button"
            onClick={this.handleClickConfirmCreate}
          >
            {t('app.confirm')}
          </ButtonStyled>
        )}
      </ObjectSelectorContainer>
    );
  }
  //------------------------------------------------------------------------------------------------------------------
}
const GetIcon = (iconValue) => {
  let icon = iconValue;
  if (!icon || icon === 'null' || icon === '') {
    icon = 'nature';
  }
  return icon;
};
//------------------------------------------------------------------------------------------------

const getValueItemSearch = (item, type) => {
  switch (type) {
    case 'BlocTypeSelectObject':
      return item?.label?.toUpperCase();
    case 'BlocIdSelectObject':
      return getLabelItemBlocIdSelectObject(item).toUpperCase();
    case 'BlocIdSelectField':
      return item?.label?.toUpperCase();

    default:
      '';
  }
};

export const useFilterData = ({ availableData, type }) => {
  const [valueSearch, setValueSearch] = useState('');
  const [debouncedSearch] = useDebouncedValue(valueSearch, 200);
  const dataAvailableSearch = useMemo(() => {
    return availableData?.filter((item) => {
      const itemSearch = getValueItemSearch(item, type);
      return itemSearch
        ? itemSearch.indexOf(debouncedSearch?.toUpperCase()) >= 0
        : false;
    });
  }, [debouncedSearch, availableData]);

  return { dataAvailableSearch, valueSearch, setValueSearch };
};

export const HeaderBlocTypeSelectObject = ({
  valueSearch,
  setValueSearch,
  title,
  onEnter,
  hideBottomLine,
}) => {
  const currentPalette = useSelector(currentPaletteActiveSelector);
  const ref = useEventListener('keydown', (e) => {
    if (e?.key === 'Enter' && typeof onEnter === 'function') {
      onEnter();
    }
  });
  const { t } = useTranslation();
  return (
    <div>
      {!hideBottomLine && <Space h={6} />}

      {title && (
        <>
          <div>
            <DivTitleBloc color={currentPalette.text.primary1}>
              {title}
            </DivTitleBloc>
          </div>
          <Space h={6} />
        </>
      )}

      <Input
        autoFocus={true}
        ref={ref}
        styles={{
          icon: {
            padding: 6,
          },
          wrapper: {
            marginLeft: 6,
            marginRight: 6,
          },
        }}
        icon={<SearchIcon />}
        placeholder={t('objCreator.search_by_name')}
        value={valueSearch}
        onChange={(event) => setValueSearch(event.currentTarget.value)}
      />
      {!hideBottomLine && <DivDivider />}
    </div>
  );
};

const BlocTypeSelectObject = ({
  availableTypes = [],
  typeParent,
  typeNewObject,
  suggestedType,
  handleClick,
  mode,
}) => {
  const { t } = useTranslation();
  const currentPalette = useSelector(currentPaletteActiveSelector);
  const itemFilterSuggestedKey = [];
  const itemFilterSuggestedList = availableTypes.filter((item) => {
    if (item.key === suggestedType) {
      itemFilterSuggestedKey.push(item?.key);
    }
  });

  const newAvailableFindSuggestedType = availableTypes.findIndex((item) =>
    itemFilterSuggestedKey.includes(item?.key)
  );
  const AvailableData =
    newAvailableFindSuggestedType >= 0
      ? availableTypes
      : availableTypes.concat(itemFilterSuggestedList);
  const { dataAvailableSearch, setValueSearch, valueSearch } = useFilterData({
    availableData: AvailableData,
    type: 'BlocTypeSelectObject',
  });

  const handleOnEnter = () => {
    if (dataAvailableSearch?.length === 1) {
      handleClick(null, dataAvailableSearch[0]?.key);
    }
  };
  return (
    typeNewObject || (
      <>
        <HeaderBlocTypeSelectObject
          setValueSearch={setValueSearch}
          title={t('app.create')}
          valueSearch={valueSearch}
          onEnter={handleOnEnter}
        />

        <DivBlocWrap style={{ opacity: 1 }}>
          {Object.keys(dataAvailableSearch).map((ind) => {
            const currObj = dataAvailableSearch[ind];
            if (currObj.allowUnder.includes(typeParent) || !typeParent) {
              return (
                <DivLine
                  backgroundColorHover={
                    currentPalette.button.primaryButton.backgroundColorHover
                  }
                  backgroundColor={
                    currentPalette.button.primaryButton.backgroundColor
                  }
                  color={currentPalette.text.secondary}
                  selected={currObj.key === typeNewObject}
                  className="Item"
                  key={currObj.key}
                  data-id={currObj.key}
                  onClick={handleClick}
                >
                  <img
                    width="16px"
                    height="16px"
                    src={`/img/icons/${GetIcon(currObj.icon)}.svg`}
                  />
                  <Space w={6} />
                  <div>{mode !== 'mini' && currObj.label}</div>
                </DivLine>
              );
            }
          })}
        </DivBlocWrap>
      </>
    )
  );
};

const getAllowedTypes = (availableTypes, typeNewObject) => {
  if (typeNewObject && !isEmpty(availableTypes)) {
    return JSON?.parse(
      availableTypes.find((item) => item.key === typeNewObject)?.allowUnder
    );
  }
  return availableTypes;
};
//------------------------------------------------------------------------------------------------

const getLabelItemBlocIdSelectObject = (elem) => {
  return elem.crumbtrail?.length > 0
    ? elem.crumbtrail
    : elem.name?.length > 0
    ? elem.name
    : elem.key;
};
const BlocIdSelectObject = ({
  objectsByType,
  typeParentLabel,
  onClick,
  isDisplayTitle = true,
  isLinkDrawingProperty = false,
  isLinkDrawingObject = false,
}) => {
  const currentPalette = useSelector(currentPaletteActiveSelector);
  const { t } = useTranslation();

  const dataObjectsByTypeSort = objectsByType
    ?.slice()
    .sort((a, b) =>
      (a.crumbtrail ?? a.name ?? a.key).localeCompare(
        b.crumbtrail ?? b.name ?? b.key
      )
    );
  const { dataAvailableSearch, setValueSearch, valueSearch } = useFilterData({
    availableData: dataObjectsByTypeSort,
    type: 'BlocIdSelectObject',
  });
  const handleOnEnter = () => {
    if (dataAvailableSearch?.length === 1) {
      onClick(null, dataAvailableSearch[0]?.key);
    }
  };
  let title = isDisplayTitle
    ? t('object.select_with', { label: typeParentLabel })
    : '';
  return (
    <>
      <HeaderBlocTypeSelectObject
        setValueSearch={setValueSearch}
        title={title}
        valueSearch={valueSearch}
        onEnter={handleOnEnter}
      />
      <DivBlocWrap>
        {isLinkDrawingProperty && ( // en link drawing on permet de choisir self comme valeur, pour faire un template
          <DivLine
            onClick={onClick}
            key={'self'}
            data-id={'self'}
            backgroundColorHover={
              currentPalette.button.primaryButton.backgroundColorHover
            }
            backgroundColor={
              currentPalette.button.primaryButton.backgroundColor
            }
            color={currentPalette.text.secondary}
          >
            {'self'}
          </DivLine>
        )}
        {dataAvailableSearch.map((elem) => (
          <DivLine
            onClick={onClick}
            key={elem.key}
            data-id={elem.key}
            backgroundColorHover={
              currentPalette.button.primaryButton.backgroundColorHover
            }
            backgroundColor={
              currentPalette.button.primaryButton.backgroundColor
            }
            color={currentPalette.text.secondary}
          >
            {getLabelItemBlocIdSelectObject(elem)}
          </DivLine>
        ))}
      </DivBlocWrap>
    </>
  );
};
//------------------------------------------------------------------------------------------------
const BlocIdSelectField = ({ availableTypes, typeSelectedObject, onClick }) => {
  const currentPalette = useSelector(currentPaletteActiveSelector);
  const { t } = useTranslation();

  const trgType = availableTypes.find(
    (elem) => elem.label == typeSelectedObject || elem.key == typeSelectedObject
  );
  let fields = trgType.objFormData;

  const fieldsByName = fields?.slice().sort((a, b) => {
    return a.label.localeCompare(b.label);
  });
  const { dataAvailableSearch, setValueSearch, valueSearch } = useFilterData({
    availableData: fieldsByName,
    type: 'BlocIdSelectField',
  });

  return (
    <>
      <HeaderBlocTypeSelectObject
        setValueSearch={setValueSearch}
        title={t('object.select_with', { label: typeSelectedObject })}
        valueSearch={valueSearch}
      />
      <DivBlocWrap>
        {dataAvailableSearch.map((elem) => (
          <DivLine
            onClick={onClick}
            key={elem.attrName}
            data-id={elem.attrName}
            backgroundColorHover={
              currentPalette.button.primaryButton.backgroundColorHover
            }
            backgroundColor={
              currentPalette.button.primaryButton.backgroundColor
            }
            color={currentPalette.text.secondary}
          >
            {elem.label}
          </DivLine>
        ))}
      </DivBlocWrap>
    </>
  );
};
//------------------------------------------------------------------------------------------------
const BlocSummary = (props) => {
  const step = getStep(props.objectSelectorState);
  const currentPalette = useSelector(currentPaletteActiveSelector);

  const strTypeSelectObject = props.objectSelectorState.typeSelectObject
    ? `Create: ${getObjectTypeLabelOrHome(
        props.availableTypes,
        props.objectSelectorState.typeSelectObject
      )}`
    : 'What type?';

  const strIdSelectedObject = props.objectSelectorState.idSelectedObject
    ? `${getFromDico(
        props.dicoNameRef,
        props.objectSelectorState.idSelectedObject,
        null
      )}`
    : 'Object';

  const options = [
    { display: strTypeSelectObject, value: 1 },
    { display: strIdSelectedObject, value: 2 },
    { display: 'confirm', value: 3 },
  ];

  const handleNextStep = useCallback((step) => {
    props.handleUndo(step + 1);
  }, []);

  return (
    <>
      <DivSummary id="summary_container">
        <Stepper
          breakpoint="sm"
          styles={{
            root: {
              backgroundColor: currentPalette.objForm.backgroundColor,
            },
          }}
          size="sm"
          color="blue"
          active={step}
          onStepClick={handleNextStep}
        >
          {options.map((item, ind) => (
            <Stepper.Step description={item.display} key={item?.value || ind}>
              {item.display}
            </Stepper.Step>
          ))}
        </Stepper>
      </DivSummary>
    </>
  );
};

//------------------------------------------------------------------------------------------------
export default memo(withTranslation()(ObjectSelector));
