import React from 'react';
import {
  DataColumnHeaderStyle,
  DataColumnHeaderStyleContent,
  DataColumnsHeadersStyle,
} from './DataColumnsHeadersCSS';
import DataColumnResizer from '../DataColumns/DataColumResizer';

export const DataColumnsHeaders = (props) => {
  const { left, height, width, level } = props;
  return (
    <DataColumnsHeadersStyle
      left={left}
      height={height}
      width={width}
      level={level}
    >
      {props.children}
    </DataColumnsHeadersStyle>
  );
};

export const DataColumnHeader = (props) => {
  const { width, isLastColumn = false, panelNumber, colNumber } = props;
  return (
    <DataColumnHeaderStyle
      onClick={props.handleClick}
      // width={width}
    >
      <DataColumnHeaderStyleContent isSorted={props.isSorted}>
        {props.children}
      </DataColumnHeaderStyleContent>
      {!isLastColumn && (
        <DataColumnResizer panelNumber={panelNumber} colNumber={colNumber} />
      )}
    </DataColumnHeaderStyle>
  );
};
