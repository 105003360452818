import { NODE_NAVIG_WIDTH } from '../constants/layout';

export const calculateNodeLeftContainer = ({
  displayMode,
  level,
  leftVal,
  isMobile,
}) => {
  return displayMode[level] === 'grid' ? 0 : isMobile ? 20 : leftVal;
};
export const calculateLeftVal = ({ indBand, node, currBand }) => {
  return (
    (-0.95 * indBand + (node.offsetX + 2)) *
    ((currBand.width - NODE_NAVIG_WIDTH) / 100.0)
  );
};
