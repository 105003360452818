import React from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityLogIcon } from '../../../../constants/icon';
import { IconActionNavigNodeSvg } from '../../../node/NavigNode/NavigNodeCss';
import { ImageFromSVG } from '../../../Utils/Utils';
import { ObjectCreatorMiniContainer } from './ObjectCreatorMiniCSS';

//------------------------------------------------------------------------------------------------
const ObjectCreatorMini = ({
  canWrite,
  canDuplicateType,
  level,
  onClickActiveActivityLog,
  onHandleClickMenu,
  handleCreateBrother,
  handleClickVerifyCreateBrotherWithChilds,
  handleAddFieldRestriction,
  handleConfirmAction,
  handleShiftPerspectiveDown,
  handleShiftPerspectiveUp,
}) => {
  const { t } = useTranslation();
  return (
    <ObjectCreatorMiniContainer className="ObjectCreator">
      <div className="title">Actions:</div>
      <IconActionNavigNodeSvg onClick={onClickActiveActivityLog}>
        <ActivityLogIcon fill="black" />
      </IconActionNavigNodeSvg>
      <ImageFromSVG
        icon="plus.svg"
        onClick={onHandleClickMenu}
        tooltipText={t('miniNodeCommand.add_object')}
      />
      {canWrite && canDuplicateType && (
        <ImageFromSVG
          icon="group_work_24px.svg"
          onClick={handleCreateBrother}
          tooltipText={t('miniNodeCommand.duplicate')}
        />
      )}

      {canWrite && canDuplicateType && (
        <ImageFromSVG
          withBorders={true}
          icon="group_work_24px.svg"
          onClick={handleClickVerifyCreateBrotherWithChilds}
          tooltipText={t('miniNodeCommand.copy_struct')}
        />
      )}
      <ImageFromSVG
        icon="find_in_page_24px.svg"
        onClick={handleAddFieldRestriction}
        tooltipText={t('miniNodeCommand.focus')}
      />
      {canWrite && (
        <ImageFromSVG
          icon="delete2.svg"
          onClick={handleConfirmAction}
          tooltipText={t('miniNodeCommand.delete')}
        />
      )}
      {level == 0 && (
        <ImageFromSVG
          icon="navigate_next-black-18dp.svg"
          onClick={handleShiftPerspectiveDown}
          tooltipText={t('miniNodeCommand.shift_perspective_down')}
        />
      )}
      {level > 0 && (
        <ImageFromSVG
          icon="navigate_before-black-18dp.svg"
          onClick={handleShiftPerspectiveUp}
          tooltipText={t('miniNodeCommand.shift_perspective_up')}
        />
      )}
    </ObjectCreatorMiniContainer>
  );
};

//------------------------------------------------------------------------------------------------

export default ObjectCreatorMini;
