// layoutUtils.js
import * as NodeUtils from '../../Utils/NodeUtils';
import { updateVerticalNodePositions } from './nodeVerticalPositionUtils';

/**
 * Calcule les dimensions globales du graphe
 * @param {Object} treeRoot - Nœud racine de l'arbre
 * @param {number} zoom - Niveau de zoom actuel
 * @returns {Object} Dimensions {height, width} du graphe
 */
export const computeGraphSize = (treeNodes, zoom) => {
  let height =
    Math.max(0, ...treeNodes.map((node) => node.y + node.ySize)) + 500;
  let width = 50000;

  // Ajustement en fonction du scroll et du zoom
  height = Math.max(height, document.documentElement.scrollHeight) / zoom;
  width = Math.max(width, document.documentElement.scrollWidth) / zoom;
  return { height, width };
};

/**
 * Configure la disposition des nœuds
 * @param {Array} nodes - Nœuds à disposer
 * @param {Object} mapNodesSize - Map des tailles des nœuds
 * @param {Array} collapsedNodes - Nœuds repliés
 * @param {Array} arraySortByWithType - Configuration de tri
 * @returns {Object} Racine de l'arbre configuré
 */
export const setupNodesLayout = (
  nodes,
  mapNodesSize,
  collapsedNodes,
  arraySortByWithType,
  scrollTop,
  autoVertPos
) => {
  if (!nodes || nodes.length === 0) return [];
  console.log(mapNodesSize);
  // Retirer les nœuds repliés
  nodes = NodeUtils.removeCollapsed(nodes, collapsedNodes);

  // Trier les nœuds
  nodes = NodeUtils.sortArray(nodes, 0, arraySortByWithType);

  // Créer la racine
  const rootNode = {
    key: 'root',
    children: nodes,
    nbChild: nodes.length,
    nbRandom: 0,
  };
  return updateVerticalNodePositions(
    rootNode,
    scrollTop,
    mapNodesSize,
    autoVertPos
  );
};

/**
 * Configure le canvas SVG
 * @param {Object} dimensions - Dimensions du graphe
 * @returns {Object} Configuration du canvas
 */
export const configureSVGCanvas = ({ height, width }) => {
  let vOffset = height / 2;
  vOffset = 0;
  const margin = {
    top: vOffset,
    right: 160,
    bottom: 20,
    left: 120,
  };

  const canvasWidth = Math.max(1 * (1960 - margin.right - margin.left), width);
  const canvasHeight = Math.max(1 * (960 - margin.top - margin.bottom), height);

  return {
    width: canvasWidth + margin.right + margin.left,
    height: canvasHeight + margin.top + margin.bottom,
    margin,
  };
};
