import React from 'react';
import { useSelector } from 'react-redux';
import QuestSingleChoice from './QuestSingleChoice';
import QuestMultipleChoice from './QuestMultipleChoice';
import QuestConfirmation from './QuestConfirmation';
import QuestInput from './QuestInput';

const InteractionHandler = () => {
  const currentInteraction = useSelector(
    (state) => state.interaction.currentInteraction
  );

  if (!currentInteraction) return null;

  switch (currentInteraction.type) {
    case 'questSingleChoice':
      return <QuestSingleChoice />;
    case 'questMultChoice':
      return <QuestMultipleChoice />;
    case 'questConfirmation':
      return <QuestConfirmation />;
    case 'questInput':
      return <QuestInput />;
    default:
      return null;
  }
};

export default InteractionHandler;
