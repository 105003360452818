import { Center, Space } from '@mantine/core';
import { useResizeObserver } from '@mantine/hooks';
import React, { memo, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CloseCircle } from '../../constants/icon';
import {
  onTogglePreviewSvgSketch,
  openObjectDetails,
} from '../../reducers/layoutReducer/action';
import {
  isShowPreviewSvgSketchSelector,
  objDetailsOpenSelector,
  previewSvgSketchDataSelector,
} from '../../reducers/layoutReducer/layoutSelector';
import { currentPaletteActiveSelector } from '../../reducers/themeReducers/themeSelector';
import {
  ActivityLogsTitle,
  WrapButtonCloseView,
} from '../ActivityLog/ActivityLogCSS';
import ResizablePanel from '../Resizable';
import ErrorBoundary from '../UI/ErrorBoundary/ErrorBoundary';
import { IconButtonObjForm } from '../UI/Objects/ObjForm/ObjFormCSS';
import ObjectActionCreators from '../../actions';
import SVGDiagramRenderer from '../SVGDiagramRenderer';

function PreviewSvgSketch() {
  const currentPaletteActive = useSelector(currentPaletteActiveSelector);
  const isShowPreviewSvgSketch = useSelector(isShowPreviewSvgSketchSelector);
  const previewSvgSketchData = useSelector(previewSvgSketchDataSelector);
  const objDetailsOpen = useSelector(objDetailsOpenSelector);
  const [ref, rect] = useResizeObserver();
  const svgContainerRef = useRef(null);

  const dispatch = useDispatch();

  const handleCloseModalPreviewSketch = () => {
    dispatch(onTogglePreviewSvgSketch(false));
  };

  // Calculate scale based on available width
  const availableWidth = rect.width - 40;
  const width = 800;
  const height = 640;

  // Calculate scale that properly preserves aspect ratio
  const scale = Math.min(availableWidth / width, 1);

  const handleOnClickElement = (objId) => {
    if (objId) {
      dispatch(ObjectActionCreators.selectObjectAndStack(objId));
      onOpenObjectDetails();
    }
  };

  const onOpenObjectDetails = () => {
    if (objDetailsOpen) return;
    dispatch(openObjectDetails());
  };

  // Set a custom attribute to be used by the SVG utils to determine scale
  useEffect(() => {
    if (svgContainerRef.current) {
      svgContainerRef.current.setAttribute('data-scale-factor', scale);
    }
  }, [scale, svgContainerRef.current]);

  return (
    <ResizablePanel
      resizeType="activity_log_resize"
      width={850}
      id="activity_log_resize"
      isOpen={isShowPreviewSvgSketch}
      innerRef={ref}
    >
      <ErrorBoundary>
        <div
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          <div>
            <ActivityLogsTitle color={currentPaletteActive?.text?.highlight}>
              Preview sketch
            </ActivityLogsTitle>
            <WrapButtonCloseView>
              <IconButtonObjForm
                onClick={handleCloseModalPreviewSketch}
                onTouchEnd={handleCloseModalPreviewSketch}
              >
                <CloseCircle fill={currentPaletteActive?.text?.highlight} />
              </IconButtonObjForm>
            </WrapButtonCloseView>
          </div>
          <Space h={10} />
          <div
            style={{
              maxHeight: 'calc(100vh - 100px)',
              overflow: 'auto',
              paddingBottom: 10,
            }}
          >
            <Center style={{ paddingRight: 40, minHeight: height * scale }}>
              <div
                draggable={false}
                id="wrapSvgPadPreviewSketch"
                ref={svgContainerRef}
                style={{
                  width: width * scale,
                  height: height * scale,
                  backgroundColor: 'white',
                  border: '0.5px solid #94A3B8',
                  borderRadius: 4,
                  overflow: 'hidden',
                  transformOrigin: 'top left',
                }}
              >
                <svg
                  draggable={false}
                  width="100%"
                  height="100%"
                  viewBox={`0 0 ${width} ${height}`}
                  preserveAspectRatio="xMidYMid meet"
                  id="svgPreviewSketch"
                >
                  <SVGDiagramRenderer
                    elements={previewSvgSketchData || []}
                    mode="preview"
                    onElementClick={handleOnClickElement}
                  />
                </svg>
              </div>
            </Center>
          </div>
        </div>
      </ErrorBoundary>
    </ResizablePanel>
  );
}

export default memo(PreviewSvgSketch);
