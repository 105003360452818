import styled from 'styled-components';

export const DateField = styled.div({
  width: '100%',
  height: '22px',
  color: (props) => props.color,
  border: (props) =>
    props.readOnly ? '0px' : `1px solid ${props.borderColor}`,
  borderRadius: '2px',
  padding: '2px',
  margin: '0px',
  backgroundColor: 'transparent',
  fontWeight: 'normal',
  fontSize: '11px',
  cursor: (props) => (props.readOnly ? '' : 'pointer'),
});
