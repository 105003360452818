import { useSelector } from 'react-redux';
import { getHost } from '../../../helper/getHost';
import { lighten } from '../../Utils/colorManipulator';

export const Avatar = ({
  objId,
  ratio = 1,
  marginRight,
  marginTop,
  marginLeft,
}) => {
  const profiles = useSelector((state) => state.objects.profiles);
  if (!profiles) return null;
  const profile = profiles[objId];
  return (
    profile && (
      <div
        title={profile.name}
        style={{
          backgroundColor: lighten(profile.profilecolor ?? '#000000', 0.85),
          color: profile.profilecolor,
          borderRadius: '50%',
          padding: 'auto',
          display: 'inline-block',
          width: `${42 * ratio}px`,
          height: `${42 * ratio}px`,
          textAlign: 'center',
          fontSize: `${18 * ratio}px`,
          fontWeight: 'bold',
          marginLeft: marginLeft ? `${marginLeft}px` : '',
          marginRight: marginRight ?? `${15 * ratio}px`,
          marginTop: marginTop ? `${marginTop}px` : '',
          verticalAlign: 'middle',
          lineHeight: `${42 * ratio}px`,
          overflow: 'hidden',
        }}
      >
        {profile.photo ? (
          <img
            style={{
              width: '100%',
              height: '100%',
              borderRadius: '50%',
            }}
            alt=""
            src={`https://${getHost()}/guerillaPM3/DownloadFile/download/${
              profile.photo
            }/${profile.photoOrigName}`}
          />
        ) : (
          profile.initials
        )}
      </div>
    )
  );
};
