import { ActionIcon, Popover, useMantineTheme } from '@mantine/core';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import {
  ArrowBottomIcon,
  BidirectionalArrowIcon,
  CursorIcon,
  DeleteIcon,
  DuplicateIcon,
  EditIcon,
  GridsIcon100,
  GridsIcon20,
  GridsIcon50,
  GridsIcon80,
  LayoutDownIcon,
  LayoutUpIcon,
  LinkIcon,
  NextArrow,
  PolylineIcon,
  TextIcon,
  ZigzagIcon,
  ZoomInIcon,
  ZoomOutIcon,
  ConnectorIcon,
} from '../../../../constants/icon';
import AddTextSvgBox from './AddTextSvgBox';
import EditPolylineAndArrowLineSvgBox from './EditPolylineAndArrowLineSvgBox';
import {
  ActionListDrawingContainer,
  ActionListDrawingContent,
} from './ObjFieldDrawingPadCSS';
import EditConnectorBox from './EditConnectorBox';
import { svgDiagramSelector } from '../../../../reducers/svgDiagramReducer/selectors';
import {
  useDiagramView,
  useElementOperations,
} from '../../../../reducers/svgDiagramReducer/hooks';
import { onPushAndAutoRemoveNotification } from '../../../../reducers/notificationReducer/action';

export default function ActionDrawPanel({
  onChangeLineDrawing,
  lineDrawing,
  onChangeAction,
  onResizeSvg,
  onChangeTick,
  onLink,
  tick,
  onDuplicateItem,
  onChangeLayout,
  onUpdateItemPolylineOrArrowLine,
  color,
}) {
  const theme = useMantineTheme();
  const { gridSize, isGridVisible, resetView, toggleGrid } = useDiagramView();
  const { deleteSelected, elements, setElementParent, breakElementParent } =
    useElementOperations();

  const [showPopupSelectLine, setShowPopupSelectLine] = useState(false);
  const [showPopupSelectLineWidth, setShowPopupSelectLineWidth] =
    useState(false);

  const [showPopupSelectGrids, setShowPopupSelectGrids] = useState(false);
  const [isEditText, setIsEditText] = useState(false);
  const [openedAddText, setOpenedAddText] = useState(false);
  const [isOpenSvgArrowAndPolyline, setOpenSvgArrowAndPolyline] =
    useState(false);
  const [isOpenConnectorEdit, setOpenConnectorEdit] = useState(false);
  const { selectedElementKey, currentAction } = useSelector(svgDiagramSelector);
  const [parentElementKey, setParentElementKey] = useState(null);
  const dispatch = useDispatch();

  // Methods for connector editing modal
  const onShowModalEditConnector = useCallback(() => {
    onChangeAction('mouse', false);
    setOpenConnectorEdit(true);
  }, []);

  const onHideModalEditConnector = useCallback(() => {
    setOpenConnectorEdit(false);
  }, []);

  const handleCLickMouse = useCallback(() => {
    onChangeAction('mouse');
  }, []);

  const onShowModalAddText = useCallback(() => {
    onChangeAction('mouse', false);
    setOpenedAddText(true);
  }, []);

  const onShowModalEditPolylineAndArrowLine = useCallback(() => {
    onChangeAction('mouse', false);
    setOpenSvgArrowAndPolyline(true);
  }, []);
  const onHideModalEditPolylineAndArrowLine = useCallback(() => {
    setOpenSvgArrowAndPolyline(false);
  }, []);

  const onHideModalAddText = useCallback(() => {
    setOpenedAddText(false);
    setIsEditText(false);
  }, []);

  const onEditText = useCallback(() => {
    onShowModalAddText();
    setIsEditText(true);
  }, []);

  const handleClickConnectorAction = useCallback(() => {
    console.log('Connector tool selected');
    onChangeLineDrawing('connector');
    onChangeAction('drawing-connector', false); // false means don't reset selection
    setShowPopupSelectLine(false);
  }, [onChangeLineDrawing, onChangeAction]);

  const handleClickLineAction = useCallback(() => {
    onChangeLineDrawing('line');
    onChangeAction('drawing-line');
    setShowPopupSelectLine(false);
  }, []);

  const handleLinkElement = useCallback(() => {
    // Stocker l'élément sélectionné comme parent potentiel
    if (selectedElementKey) {
      // Stocker la clé dans une variable temporaire ou un état
      // pour utilisation ultérieure
      setParentElementKey(selectedElementKey);

      // Afficher un message à l'utilisateur
      dispatch(
        onPushAndAutoRemoveNotification({
          title: "Liaison d'éléments",
          description:
            "Parent sélectionné. Veuillez maintenant sélectionner l'élément enfant.",
          disallowClose: false,
          isLoading: false,
        })
      );
    }
  }, [selectedElementKey, dispatch]);

  const handleClickPolylineAction = useCallback(() => {
    onChangeLineDrawing('polyline');
    onChangeAction('drawing-polyline');

    setShowPopupSelectLine(false);
  }, []);

  const handleClickArrowLineAction = useCallback(() => {
    onChangeLineDrawing('arrow-line');
    onChangeAction('drawing-arrow-line');
    setShowPopupSelectLine(false);
  }, []);
  const handleClickBidirectionalLineAction = useCallback(() => {
    onChangeLineDrawing('bidirectional-line');
    onChangeAction('drawing-bidirectional-line');
    setShowPopupSelectLine(false);
  }, []);

  useEffect(() => {
    // Si un parent potentiel est stocké et qu'un nouvel élément est sélectionné
    if (
      parentElementKey &&
      selectedElementKey &&
      parentElementKey !== selectedElementKey
    ) {
      // Créer la relation parent-enfant
      setElementParent(selectedElementKey, parentElementKey);

      // Réinitialiser la variable temporaire
      setParentElementKey(null);

      // Notifier l'utilisateur
      dispatch(
        onPushAndAutoRemoveNotification({
          title: "Liaison d'éléments",
          description: 'Éléments liés avec succès.',
          disallowClose: false,
          isLoading: false,
        })
      );
    }
  }, [selectedElementKey, parentElementKey, dispatch]);

  const handleBreakLink = useCallback(() => {
    // Vérifier si l'élément sélectionné est un enfant
    if (selectedElementKey) {
      const selectedElement = elements.find(
        (el) => el.key === selectedElementKey
      );

      if (selectedElement && selectedElement.parentKey) {
        // Briser la relation parent-enfant
        breakElementParent(selectedElementKey);

        // Notifier l'utilisateur
        dispatch(
          onPushAndAutoRemoveNotification({
            title: "Liaison d'éléments",
            description: 'Lien parent-enfant supprimé.',
            disallowClose: false,
            isLoading: false,
          })
        );
      } else {
        // Notifier si l'élément n'a pas de parent
        dispatch(
          onPushAndAutoRemoveNotification({
            title: "Liaison d'éléments",
            description: "Cet élément n'a pas de parent.",
            disallowClose: false,
            isLoading: false,
          })
        );
      }
    }
  }, [selectedElementKey, elements, dispatch]);

  const renderCurrentLineAction = () => {
    switch (lineDrawing) {
      case 'line':
        return <ZigzagIcon />;
      case 'polyline':
        return <PolylineIcon />;
      case 'arrow-line':
        return <NextArrow />;
      case 'bidirectional-line':
        return <BidirectionalArrowIcon />;
      case 'connector':
        return <ConnectorIcon />;

      default:
        return null;
    }
  };

  const renderCurrentGridIcon = () => {
    switch (gridSize) {
      case 20:
        return <GridsIcon20 />;
      case 50:
        return <GridsIcon50 />;
      case 80:
        return <GridsIcon80 />;
      case 100:
        return <GridsIcon100 />;
      default:
        return null;
    }
  };

  const onZoomIn = () => {
    onResizeSvg('ZoomIn');
  };

  const onZoomOut = () => {
    onResizeSvg('ZoomOut');
  };

  const currentElementSelect = elements.find(
    (svg) => svg?.key === selectedElementKey
  );

  return (
    <ActionListDrawingContainer
      id="ActionListDrawingContainer"
      backgroundColor={'white'}
    >
      <ActionListDrawingContent>
        <WrapActionIcon
          onClick={handleCLickMouse}
          style={{
            ...styles.actionIconContainer,
            backgroundColor:
              currentAction === 'mouse' || currentAction === 'move'
                ? theme.colors.gray[4]
                : '',
          }}
        >
          <CursorIcon />
        </WrapActionIcon>
        <WrapActionIcon
          onClick={onShowModalAddText}
          style={styles.actionIconContainer}
        >
          <TextIcon />
        </WrapActionIcon>

        <Popover
          zIndex={210000000017}
          opened={showPopupSelectLine}
          onClose={() => setShowPopupSelectLine(false)}
          styles={{
            popover: {
              backgroundColor: theme.colors.gray[1],
            },
            body: {
              border: '1px solid #d0d0d0',
            },
            arrow: {
              backgroundColor: theme.colors.gray[1],
              border: '1px solid #d0d0d0',
            },
          }}
          target={
            <WrapActionIcon
              style={{
                ...styles.actionIconContainer,
                backgroundColor:
                  currentAction === 'drawing-line' ||
                  currentAction === 'drawing-polyline' ||
                  currentAction === 'drawing-arrow-line' ||
                  currentAction === 'drawing-connector'
                    ? theme.colors.gray[4]
                    : '',
              }}
              onClick={() => setShowPopupSelectLine((m) => !m)}
            >
              <LineActionContainer>
                {renderCurrentLineAction()}
                <ArrowBottomIcon />
              </LineActionContainer>
            </WrapActionIcon>
          }
          position="bottom"
          withArrow
        >
          <CreateLineContainer>
            <WrapActionIcon onClick={handleClickLineAction}>
              <ZigzagIcon />
            </WrapActionIcon>
            <WrapActionIcon onClick={handleClickPolylineAction}>
              <PolylineIcon />
            </WrapActionIcon>
            <WrapActionIcon onClick={handleClickArrowLineAction}>
              <NextArrow />
            </WrapActionIcon>
            <WrapActionIcon onClick={handleClickBidirectionalLineAction}>
              <BidirectionalArrowIcon />
            </WrapActionIcon>
            <WrapActionIcon onClick={handleClickConnectorAction}>
              <ConnectorIcon />
            </WrapActionIcon>
          </CreateLineContainer>
        </Popover>

        <Popover
          zIndex={210000000017}
          opened={showPopupSelectLineWidth}
          onClose={() => setShowPopupSelectLineWidth(false)}
          styles={{
            popover: {
              backgroundColor: theme.colors.gray[1],
            },
            body: {
              border: '1px solid #d0d0d0',
            },
            arrow: {
              backgroundColor: theme.colors.gray[1],
              border: '1px solid #d0d0d0',
            },
          }}
          target={
            <WrapActionIcon
              style={{
                ...styles.actionIconContainer,
              }}
              onClick={() => setShowPopupSelectLineWidth((m) => !m)}
            >
              <LineActionContainer>
                <div
                  style={{
                    backgroundColor: '#141E46',
                    width: 22,
                    height: tick,
                    borderRadius: 2,
                  }}
                />
                <ArrowBottomIcon />
              </LineActionContainer>
            </WrapActionIcon>
          }
          position="bottom"
          withArrow
        >
          <CreateLineContainer>
            {[2, 4, 8, 10, 12].map((tick) => {
              return (
                <WrapActionIcon
                  onClick={() => {
                    onChangeTick(tick);
                    setShowPopupSelectLineWidth(false);
                  }}
                >
                  <div
                    style={{
                      backgroundColor: '#141E46',
                      width: 26,
                      height: tick,
                      borderRadius: 2,
                    }}
                  />
                </WrapActionIcon>
              );
            })}
          </CreateLineContainer>
        </Popover>

        <Popover
          zIndex={210000000017}
          styles={{
            popover: {
              backgroundColor: theme.colors.gray[1],
            },
            body: {
              border: '1px solid #d0d0d0',
            },
            arrow: {
              backgroundColor: theme.colors.gray[1],
              border: '1px solid #d0d0d0',
            },
          }}
          opened={showPopupSelectGrids}
          target={
            <div
              style={{
                width: 44,
                backgroundColor: isGridVisible ? theme.colors.gray[4] : '',
                borderRadius: 4,
              }}
            >
              <FlexCenter>
                <WrapActionIcon
                  styles={{
                    root: {
                      ':hover': { backgroundColor: theme.colors.gray[2] },
                    },
                  }}
                  onClick={() => {
                    onChangeAction('mouse');
                    toggleGrid(!isGridVisible, gridSize);
                  }}
                >
                  {renderCurrentGridIcon()}
                </WrapActionIcon>
                <WrapIconGridSize
                  onClick={() => setShowPopupSelectGrids((m) => !m)}
                >
                  <ArrowBottomIcon />
                </WrapIconGridSize>
              </FlexCenter>
            </div>
          }
          position="bottom"
          withArrow
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <WrapActionIcon
              onClick={() => {
                toggleGrid(true, 20);
                setShowPopupSelectGrids(false);
              }}
            >
              <GridsIcon20 />
            </WrapActionIcon>
            <WrapActionIcon
              onClick={() => {
                toggleGrid(true, 50);
                setShowPopupSelectGrids(false);
              }}
            >
              <GridsIcon50 />
            </WrapActionIcon>
            <WrapActionIcon
              onClick={() => {
                toggleGrid(true, 80);
                setShowPopupSelectGrids(false);
              }}
            >
              <GridsIcon80 />
            </WrapActionIcon>
            <WrapActionIcon
              onClick={() => {
                toggleGrid(true, 100);
                setShowPopupSelectGrids(false);
              }}
            >
              <GridsIcon100 />
            </WrapActionIcon>
          </div>
        </Popover>
        {selectedElementKey && (
          <>
            <WrapActionIcon onClick={onLink} style={styles.actionIconContainer}>
              <LinkIcon fill="#141E46" />
            </WrapActionIcon>
            <WrapActionIcon
              onClick={onDuplicateItem}
              style={styles.actionIconContainer}
            >
              <DuplicateIcon fill="#141E46" />
            </WrapActionIcon>
            {/* up layout */}
            <WrapActionIcon
              onClick={() => {
                onChangeLayout('forward');
              }}
              style={styles.actionIconContainer}
            >
              <LayoutUpIcon fill="#141E46" />
            </WrapActionIcon>
            <WrapActionIcon
              onClick={() => {
                onChangeLayout('back');
              }}
              style={styles.actionIconContainer}
            >
              <LayoutDownIcon fill="#141E46" />
            </WrapActionIcon>
            {/* up layout */}
            {currentElementSelect?.type === 'text' && (
              <WrapActionIcon
                onClick={onEditText}
                style={styles.actionIconContainer}
              >
                <EditIcon fill="#141E46" />
              </WrapActionIcon>
            )}
            {['polyline', 'arrow-line', 'bidirectional-line'].includes(
              currentElementSelect?.type
            ) && (
              <WrapActionIcon
                onClick={onShowModalEditPolylineAndArrowLine}
                style={styles.actionIconContainer}
              >
                <EditIcon fill="#141E46" />
              </WrapActionIcon>
            )}

            {currentElementSelect?.type === 'connector' && (
              <WrapActionIcon
                onClick={onShowModalEditConnector}
                style={styles.actionIconContainer}
              >
                <EditIcon fill="#141E46" />
              </WrapActionIcon>
            )}
            <WrapActionIcon
              onClick={deleteSelected}
              style={styles.actionIconContainer}
            >
              <DeleteIcon />
            </WrapActionIcon>

            <WrapActionIcon
              onClick={handleLinkElement}
              style={styles.actionIconContainer}
              title="Lier à l'élément actuellement sélectionné"
            >
              <LinkIcon fill="#141E46" />
            </WrapActionIcon>

            <WrapActionIcon
              onClick={handleBreakLink}
              style={styles.actionIconContainer}
              title="Détacher du parent"
            >
              <LinkIcon fill="#241E46" />
            </WrapActionIcon>

            {(currentElementSelect?.type === 'text' ||
              currentElementSelect?.type === 'svg' ||
              currentElementSelect?.type === 'image') && (
              <>
                <WrapActionIcon
                  onClick={onZoomIn}
                  style={styles.actionIconContainer}
                >
                  <ZoomInIcon fill="#141E46" />
                </WrapActionIcon>
                <WrapActionIcon
                  onClick={onZoomOut}
                  style={styles.actionIconContainer}
                >
                  <ZoomOutIcon fill="#141E46" />
                </WrapActionIcon>
              </>
            )}
          </>
        )}
      </ActionListDrawingContent>
      <AddTextSvgBox
        visible={openedAddText}
        onHideModalAddText={onHideModalAddText}
        isEditText={isEditText}
        currentElementSelect={currentElementSelect}
        color={color}
      />
      <EditPolylineAndArrowLineSvgBox
        visible={isOpenSvgArrowAndPolyline}
        onHideModal={onHideModalEditPolylineAndArrowLine}
        currentElementSelect={currentElementSelect}
        onUpdatePolylineOrArrowLine={onUpdateItemPolylineOrArrowLine}
      />
      <EditConnectorBox
        visible={isOpenConnectorEdit}
        onHideModal={onHideModalEditConnector}
        currentElementSelect={currentElementSelect}
        allElements={elements}
        onUpdateConnector={onUpdateItemPolylineOrArrowLine}
      />
    </ActionListDrawingContainer>
  );
}

const WrapIconGridSize = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  ':hover': {
    cursor: 'pointer',
    backgroundColor: '#e9ecef',
  },
});

const LineActionContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
});

const CreateLineContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
});

const FlexCenter = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
});

const styles = {
  actionIconContainer: {
    width: 44,
    height: 25,
  },
};

const WrapActionIcon = ({ children, ...props }) => {
  const theme = useMantineTheme();

  return (
    <ActionIcon
      styles={{
        default: {
          backgroundColor: 'white',
        },
        root: {
          ':hover': { backgroundColor: theme.colors.gray[2] },
        },
      }}
      {...props}
    >
      {children}
    </ActionIcon>
  );
};
