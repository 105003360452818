import React from 'react';
import { NavigTreeResizePaneContainerDiv } from './NavigTreeResizePaneContainerCSS';

export default class NavigTreeResizePaneContainer extends React.Component {
  constructor(props) {
    super(props);
  }

  //-------------------------------------------------------------------------------
  render() {
    return (
      <NavigTreeResizePaneContainerDiv ref={this.ref}>
        {this.props.children}
      </NavigTreeResizePaneContainerDiv>
    );
  }
}
//-------------------------------------------------------------------------------
