import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import rootReducer from '../reducers/rootReducer';
import { websocketMiddleware } from '../api/websocketMiddleWare';
import abortMiddleware from '../api/abortMiddleware';
import { watchRequests } from './redux-saga/saga';
import { svgDiagramMiddleware } from '../reducers/svgDiagramReducer/svgDiagramMiddleWare';

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = composeWithDevTools({
  actionsBlacklist: [
    'GET_CHILDS_COUNT_BY_TYPE',
    'POST_GET_SOCIAL_CONTENT',
    'GET_LAYERS',
    'RECEIVED_CHILDS_COUNT_BY_TYPE',
    'SET_HOVER_NODE',
    'POST_GET_PERSPECTIVES',
    'RECEIVE_TREE',
    'REQUEST_TREE_BY_PATTERN',
    'GET_SOCIAL_CONTENT',
    'RECEIVED_SOCIAL',
    'GET_PERSPECTIVES',
    'SET_LOADING_PERSPECTIVE',
    'GET_FATHER',
    'GOT_FATHER',
    'SET_ACTIVE_BAND',
    'SET_MOUSE_POS',
    'SET_DRAGGED_OVER',
    'REQUEST_USERPRIVS',
    'RECEIVED_USERPRIVS',
    'SET_CLASS_BAND_BREAK_POINT_TABLET',
    'SET_POSITION_DRAG_TABLET',
    'SET_DRAG_TABLET',
    // 'POST_',
  ],
});
function configureStore() {
  return createStore(
    rootReducer,
    composeEnhancers(
      applyMiddleware(
        thunk,
        websocketMiddleware(),
        abortMiddleware,
        sagaMiddleware,
        svgDiagramMiddleware
      )
    )
  );
}
const store = configureStore();
sagaMiddleware.run(watchRequests);
store.subscribe(() => {
  const actionType = store.getState().lastAction.type;
  if (actionType.indexOf('POST_') != 0) {
    store.dispatch({ type: `POST_${actionType}` });
  }
});

export default store;
