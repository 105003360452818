// useTreeState.js
import { useMemo, useState, useCallback, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import { setupNodesLayout, computeGraphSize } from './treeLayoutUtils';
import { updateNodeHorizontalPositions } from './nodeHorizontalPositionUtils';
import { usePanelsConfig } from './usePanelsConfig';
import { useScales } from './useScale';

export const useTreeState = () => {
  const [scrollTop, setScrollTop] = useState(0);
  const nodes = useSelector((state) => state.objects.nodes);
  const zoom = useSelector((state) => state.objects.zoom || 1);
  const collapsedNodes = useSelector((state) => state.objects.collapsedNodes);
  const autoVertPos = useSelector((state) => state.objects.autoVertPos);
  const arraySortByWithType = useSelector(
    (state) => state.objects.arraySortByWithType
  );

  // Nouveaux hooks pour les panels et scales
  const { panelsConfig } = usePanelsConfig();
  const { scalesConfig } = useScales();

  const handleScroll = useCallback((newScrollTop) => {
    setScrollTop(newScrollTop);
  }, []);

  // Référence pour stocker la copie modifiable des nodes
  const nodesRef = useRef(null);

  // État pour les tailles des nodes
  const [nodesSize, setNodesSize] = useState({});

  // État pour les dimensions avec des valeurs initiales
  const [dimensions, setDimensions] = useState({
    graphHeight: 5000,
    graphWidth: 50000,
    // minNodeX: 6000, // Valeur initiale comme dans l'original
  });

  // Créer/recréer la copie des nodes quand props.nodes change
  useMemo(() => {
    nodesRef.current = cloneDeep(nodes);
  }, [nodes, collapsedNodes]);

  // Construction du treeRoot en utilisant la copie
  const treeNodes = useMemo(() => {
    if (!nodesRef.current) return null;
    const verticalNodes = setupNodesLayout(
      nodesRef.current, // Utilise la copie modifiable
      nodesSize,
      collapsedNodes,
      arraySortByWithType,
      scrollTop,
      autoVertPos
    );

    // Nouvelle étape : positionnement horizontal
    // Ne procède au positionnement horizontal que si les scales sont valides
    if (scalesConfig?.isValid) {
      return updateNodeHorizontalPositions(
        verticalNodes,
        scalesConfig,
        panelsConfig
      );
    }

    return verticalNodes;
  }, [
    nodesRef.current,
    nodesSize,
    collapsedNodes,
    arraySortByWithType,
    scrollTop,
    autoVertPos,
    scalesConfig,
    panelsConfig,
  ]);

  // Calcul des dimensions
  useEffect(() => {
    if (!treeNodes) return;
    console.log('dimensions');
    const { height, width } = computeGraphSize(treeNodes, zoom);
    // const minX = computeMinNodeX(treeNodes);

    // Première mise à jour : dimensions et passage à 6000 si nécessaire
    setDimensions((prev) => {
      const updates = {};

      // Mise à jour height/width si nécessaire
      if (
        Math.abs(prev.graphHeight - height) > 5 ||
        Math.abs(prev.graphWidth - width) > 5
      ) {
        updates.graphHeight = height;
        updates.graphWidth = width;
      }
      return Object.keys(updates).length > 0 ? { ...prev, ...updates } : prev;
    });
  }, [treeNodes, zoom]);

  // Callback pour mettre à jour la hauteur d'un node
  const updateNodeHeight = useCallback((nodeKey, newHeight) => {
    // Met à jour les tailles
    setNodesSize((prev) => {
      if (prev[nodeKey] === newHeight) {
        return prev;
      }
      return {
        ...prev,
        [nodeKey]: newHeight,
      };
    });
  }, []);

  // Helper pour obtenir les nodes d'un niveau spécifique
  const getNodesForLevel = useCallback(
    (level) => {
      return treeNodes.filter((node) => node.depth - 1 === level);
    },
    [treeNodes]
  );
  return {
    treeNodes, // Retourne les nodes modifiés
    updateNodeHeight,
    getNodesForLevel,
    dimensions,
    handleScroll,
  };
};
