import React from 'react';
import { ContainerDiv, BigMon } from './BigMonetaryCSS';

class BigMonetary extends React.Component {
  render() {
    return (
      <ContainerDiv>
        <BigMon color={this.props.nodeColor}>
          {this.props.value}
          {this.props.unit}
        </BigMon>
      </ContainerDiv>
    );
  }
}

export default BigMonetary;
