const PROFILE = (state = DEFAULT_STATE, action, root) => {
  let res;
  switch (action.type) {
    //--------------------------------------------------------------------------------------------------
    case 'RECEIVED_USERROLE':
      let role = action?.objects?.role;
      res = {
        ...state,
        isSysAdmin: role === 'sysadmin',
        isFuncAdmin: role === 'sysadmin' || role === 'funcadmin',
      };
      return res;
    //--------------------------------------------------------------------------------------------------
    case 'RECEIVED_USERDATA':
      let homepage = action?.objects?.homepage;
      res = {
        ...state,
        homepage: homepage,
      };
      return res;
    //--------------------------------------------------------------------------------------------------
    default:
      return state;
  }
};

//--------------------------------------------------------------------------------------------------
const DEFAULT_STATE = {
  isSysAdmin: false,
  isFuncAdmin: false,
  homepage: '',
};
//--------------------------------------------------------------------------------------------------
export default PROFILE;
