import { memo, useCallback, useMemo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import ObjectActionCreators from '../../../actions';
import {
  closeObjectDetails,
  draggedOnNode,
  openObjectDetails,
  setScrollPos,
} from '../../../reducers/layoutReducer/action';
import {
  isPerspectivesPanelOpenSelector,
  zoomSelector,
} from '../../../reducers/layoutReducer/layoutSelector';
import {
  openPerspectiveById,
  setArrayParamClassif,
  setLoadingReceiveTree,
  setNodesPosition,
} from '../../../reducers/objectReducers/action';
import {
  currentColumnSelector,
  isMobileSelector,
  isResizePaneSelector,
} from '../../../reducers/responsiveReducer/responsiveSelector';
import { onSetHorizontalContentNode } from '../../../reducers/responsiveReducer/action';
import NavigTree from './NavigTree';
import { useTreeState } from './useTreeState';
import { useNavigTreeHandlers } from './useNavigTreeHandlers';
import { useSVGCanvas } from './useSVGCanvas';
import { useScales } from './useScale';

const NavigTreeWrapper = (props) => {
  const dispatch = useDispatch();

  // Selectors
  const arrayClassifBy = useSelector((state) => state.objects.arrayClassifBy);
  const classifX = useSelector((state) => state.objects.classifX);
  const displayMode = useSelector((state) => state.objects.displayMode);
  const dragged_over = useSelector((state) => state.objects.dragged_over);
  const isPerspectivesPanelOpen = useSelector(isPerspectivesPanelOpenSelector);
  const perspectiveId = useSelector((state) => state.objects.perspectiveId);
  const zoom = useSelector((state) => zoomSelector(state) / 100.0);
  const isMobile = useSelector(isMobileSelector);
  const currentColumnMobile = useSelector(currentColumnSelector);
  const isLoadingTree = useSelector((state) => state.objects.isLoadingTree);
  const isResizePane = useSelector(isResizePaneSelector);
  const profiles = useSelector((state) => state.objects.profiles);
  const autoVertPosMS = useSelector((state) => state.objects.autoVertPosMS);
  const panels = useSelector((state) => state.objects.panels); // Lecture directe depuis Redux

  const { draggedItem, currentColumn, scrollTop, scrollRefY, handlers } =
    useNavigTreeHandlers(props);

  const {
    treeNodes,
    updateNodeHeight,
    getNodesForLevel,
    dimensions,
    handleScroll,
  } = useTreeState();

  const { scalesConfig } = useScales();

  const { svgRef, svg } = useSVGCanvas({
    dimensions: {
      height: dimensions.graphHeight,
      width: dimensions.graphWidth,
    },
    nodes: treeNodes,
    bands_config: panels,
  });

  const dispatchProps = {
    closeObjectDetails: useCallback(
      () => dispatch(closeObjectDetails()),
      [dispatch]
    ),

    createBrother: useCallback(
      (objId) => dispatch(ObjectActionCreators.createBrother(objId)),
      [dispatch]
    ),

    suggestModel: useCallback(
      (objId) => dispatch(ObjectActionCreators.suggestModel(objId)),
      [dispatch]
    ),

    suggestWorkflow: useCallback(
      (objId) => dispatch(ObjectActionCreators.suggestWorkflow(objId)),
      [dispatch]
    ),

    implementModel: useCallback(
      (objId) => dispatch(ObjectActionCreators.implementModel(objId)),
      [dispatch]
    ),

    executeCommand: useCallback(
      (command, objId) =>
        dispatch(ObjectActionCreators.executeCommand(command, objId)),
      [dispatch]
    ),

    draggedOnNode: useCallback(
      (idClick) => dispatch(draggedOnNode(idClick)),
      [dispatch]
    ),

    filter_set_level: useCallback(
      (level, value) =>
        dispatch(ObjectActionCreators.filter_set_level(level, value)),
      [dispatch]
    ),

    getFather: useCallback(
      (objId) => dispatch(ObjectActionCreators.getFather(objId)),
      [dispatch]
    ),

    openObjectDetails: useCallback(
      () => dispatch(openObjectDetails()),
      [dispatch]
    ),

    saveObjectFormData: useCallback(
      (objId, objFormData) =>
        dispatch(ObjectActionCreators.saveObjectFormData(objId, objFormData)),
      [dispatch]
    ),

    selectObject: useCallback(
      (objId) => dispatch(ObjectActionCreators.selectObject(objId)),
      [dispatch]
    ),

    setArrayParamClassif: useCallback(
      (arrayParamClassif, arrayParamClassifDetails) =>
        dispatch(
          setArrayParamClassif(arrayParamClassif, arrayParamClassifDetails)
        ),
      [dispatch]
    ),

    setNodesPosition: useCallback(
      (nodes) => dispatch(setNodesPosition(nodes)),
      [dispatch]
    ),
    setScrollPos: useCallback(
      (scrollX, scrollY) => dispatch(setScrollPos(scrollX, scrollY)),
      [dispatch]
    ),

    setSortBy: useCallback(
      (sortKey, level) =>
        dispatch(ObjectActionCreators.setSortBy(sortKey, level)),
      [dispatch]
    ),

    onSetHorizontalContentNode: useCallback(
      (status) => dispatch(onSetHorizontalContentNode(status)),
      [dispatch]
    ),

    addFieldRestriction: useCallback(
      (field, value, objectType, fieldType, comparator, level) =>
        dispatch(
          ObjectActionCreators.addFieldRestriction(
            field,
            value,
            objectType,
            fieldType,
            comparator,
            level
          )
        ),
      [dispatch]
    ),

    setLoadingReceiveTree: useCallback(
      (level, value) => dispatch(setLoadingReceiveTree(level, value)),
      [dispatch]
    ),

    openPerspectiveById: useCallback(
      (objId) => dispatch(openPerspectiveById(objId)),
      [dispatch]
    ),
  };

  useEffect(() => {
    const handleScroll = () => {
      dispatch(setScrollPos(window.scrollX, window.scrollY));
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [dispatch]);

  const stateProps = {
    arrayClassifBy,
    classifX,
    displayMode,
    dragged_over,
    isPerspectivesPanelOpen,
    perspectiveId,
    zoom,
    isMobile,
    currentColumnMobile,
    currentColumn,
    isLoadingTree,
    isResizePane,
    profiles,
    autoVertPosMS,
  };
  const combinedProps = {
    ...props,
    ...dispatchProps,
    ...stateProps,
    ...dimensions,
    handlers,
    treeNodes,
    getNodesForLevel,
    onUpdateNodeHeight: updateNodeHeight,
    handleScroll,
    svgRef,
    svg,
    scalesConfig,
    panels,
  };
  if (!scalesConfig.isValid) return <div>Loading</div>;
  return <NavigTree {...combinedProps} />;
};

export default memo(NavigTreeWrapper);
