import { useCallback } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import ObjectActionCreators from '../../../actions';
import { BANDS_CONFIG } from '../../../constants/layout';
import {
  scrollXSelector,
  isPerspectivesPanelOpenSelector,
  zoomSelector,
} from '../../../reducers/layoutReducer/layoutSelector';

export const usePanelsConfig = () => {
  const dispatch = useDispatch();
  // Sélectionne panels avec shallowEqual pour éviter les re-renders inutiles
  const panelsConfig = useSelector(
    (state) => state.objects.panels,
    shallowEqual
  );
  const zoom = useSelector(zoomSelector) / 100.0;
  const scrollX = useSelector(scrollXSelector);
  const isPerspectivesPanelOpen = useSelector(isPerspectivesPanelOpenSelector);

  const updatePanelConfig = useCallback(
    (indexParam, posX) => {
      let initX = (isPerspectivesPanelOpen ? 380 : 100) / zoom;
      const index = indexParam - 1;

      const adjustedPosX = posX / zoom;
      // Compute the cumulative width of previous panels
      const colsBeforeWidth = panelsConfig
        .slice(0, index)
        .reduce((sum, p) => sum + p.width, 0);

      // Compute new width for the current panel
      const newWidth = Math.max(
        BANDS_CONFIG[index].minWidth,
        adjustedPosX - initX - colsBeforeWidth + scrollX
      );

      // Ensure only the current panel and following ones are updated
      const updatedPanels = panelsConfig.map((item, indmap) => ({
        width: indmap === index ? newWidth : item.width,
      }));

      dispatch(ObjectActionCreators.setPanels(updatedPanels));
    },
    [panelsConfig, isPerspectivesPanelOpen, scrollX, dispatch]
  );

  return { panelsConfig, updatePanelConfig };
};
