const perspectivePanelWidth = 285;

export function getBandsIndiceFromPosX(posX, bands) {
  let sumWidth = 0;
  let ind = 0;
  while (sumWidth < posX && ind < bands.length) {
    sumWidth += bands[ind].width;
    ind++;
  }
  return ind;
}

export function getBandsPosition(depth, bands_config) {
  const res = bands_config
    ?.filter((_val, ind) => ind < depth + 1)
    ?.reduce(
      (acc, val) => {
        return {
          start: acc.end,
          end: acc.end + val.width,
          width: val.width,
          minWidth: val.width,
        };
      },
      { start: 0, end: 0, width: 0 }
    );
  if (depth === bands_config?.length - 1) {
    const calcWidth = window.innerWidth - (res.start + perspectivePanelWidth);

    if (calcWidth > res.width) {
      res.width = calcWidth;
    }
    res.end = res.start + res.width;
  }

  return res;
}
