import { useState, useRef, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { nodeFocusSelector } from '../../../reducers/responsiveReducer/responsiveSelector';
import { NUM_LEVEL } from '../../../constants/layout';
import {
  setLoadingReceiveTree,
  openPerspectiveById,
} from '../../../reducers/objectReducers/action';
import {
  draggedOnNode,
  setScrollPos,
} from '../../../reducers/layoutReducer/action';
import ObjectActionCreators from '../../../actions';
import { scrollYSelector } from '../../../reducers/layoutReducer/layoutSelector';

export const useNavigTreeHandlers = (props) => {
  const dispatch = useDispatch();
  const [draggedItem, setDraggedItem] = useState(null);
  const [currentColumn, setCurrentColumn] = useState(0);
  const [scrollTop, setScrollTop] = useState(0);

  const positionRef = useRef(0);
  const scrollTimeout = useRef(null);
  const nodeFocus = useSelector(nodeFocusSelector);
  const scrollListeners = useSelector((state) => state.objects.scrollListeners);
  const scrollY = useSelector(scrollYSelector);

  const setScrollPosAction = useCallback(
    (scrollX, scrollY) => dispatch(setScrollPos(scrollX, scrollY)),
    [dispatch]
  );

  const addFieldRestriction = useCallback(
    (field, value, objectType, fieldType, comparator, level) =>
      dispatch(
        ObjectActionCreators.addFieldRestriction(
          field,
          value,
          objectType,
          fieldType,
          comparator,
          level
        )
      ),
    [dispatch]
  );

  const setLoadingNode = useCallback(() => {
    Array.from({ length: 5 }, (_, i) =>
      dispatch(setLoadingReceiveTree(i, true))
    );
  }, [dispatch]);

  const onAddFieldRestriction = useCallback(
    (nodeData) => {
      addFieldRestriction(
        'key',
        nodeData.data.key,
        nodeData.data.type,
        'text',
        'text_is',
        nodeData.level
      );
    },
    [addFieldRestriction]
  );

  // Cleanup effect for scroll timeout
  useEffect(() => {
    return () => {
      if (scrollTimeout.current) {
        clearTimeout(scrollTimeout.current);
      }
    };
  }, []);

  const handlers = {
    handleDragStart: useCallback(
      (e, key) => {
        setTimeout(() => setDraggedItem(key), 100);
        props.handleStartDrag(key);
      },
      [props]
    ),

    handleDragEnd: useCallback(() => {
      setDraggedItem(null);
      props.handleEndDrag(null);
    }, [props]),

    handleMouseUp: useCallback(
      (e, key) => {
        if (!draggedItem) {
          props.handleClickLevel(key);
        }
        setDraggedItem(null);
      },
      [draggedItem, props]
    ),

    handleMouseOver: useCallback(
      (e, key) => {
        props.getFather(key);
      },
      [props]
    ),

    handleOnDrop: useCallback(
      (event) => {
        const idClick = event.currentTarget.getAttribute('data-id');
        if (idClick === draggedItem || !draggedItem) {
          setTimeout(() => setDraggedItem(null), 100);
          return;
        }
        if (idClick) {
          dispatch(draggedOnNode(idClick));
        }
      },
      [draggedItem, dispatch]
    ),

    handleTouchStart: useCallback(
      (e, key) => {
        setTimeout(() => setDraggedItem(key), 100);
        props.handleStartDrag(key);
      },
      [props]
    ),

    handleTouchEnd: useCallback(() => {
      setTimeout(() => setDraggedItem(null), 100);
    }, []),

    handleOnScroll: useCallback(
      (evt) => {
        evt.persist();
        // Call scroll listeners
        scrollListeners.forEach((listener) => listener(evt));

        // Throttle scroll position updates
        if (scrollTimeout.current) {
          clearTimeout(scrollTimeout.current);
        }

        scrollTimeout.current = setTimeout(() => {
          const x = evt.target.scrollLeft;
          if (x === positionRef.current) return;
          setScrollPosAction(x, scrollY);

          if (!nodeFocus) return;

          positionRef.current = x;
          const newColumn = x / evt.target.offsetWidth;

          // Handle column changes
          if (newColumn > currentColumn) {
            // Going right
            for (let i = 1; i < NUM_LEVEL; i++) {
              if (newColumn === i) {
                if (!nodeFocus[i - 1] || isEmpty(nodeFocus[i - 1])) return;
                setLoadingNode();
                onAddFieldRestriction(nodeFocus[i - 1]);
                setCurrentColumn(i);
                return;
              }
            }
          } else if (newColumn < currentColumn) {
            // Going left
            for (let i = NUM_LEVEL - 1; i >= 0; i--) {
              if (newColumn === i) {
                if (i > 0 && (!nodeFocus[i - 1] || isEmpty(nodeFocus[i - 1])))
                  return;
                setLoadingNode();
                dispatch(openPerspectiveById(nodeFocus?.perspective?.objId));
                if (i > 0) {
                  onAddFieldRestriction(nodeFocus[i - 1]);
                }
                setCurrentColumn(i);
                return;
              }
            }
          }
        }, 16); // ~60fps throttle
      },
      [
        scrollListeners,
        scrollY,
        currentColumn,
        nodeFocus,
        setScrollPosAction,
        setLoadingNode,
        onAddFieldRestriction,
        dispatch,
      ]
    ),
  };

  return {
    draggedItem,
    currentColumn,
    scrollTop,
    handlers,
  };
};
