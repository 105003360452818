export const SvgCoordinateTransform = {
  // Convert screen coordinates to SVG space
  screenToSvgCoords: (x, y, svgElement, panPosition, zoomLevel) => {
    const svgRect = svgElement.getBoundingClientRect();
    const relativeX = x - svgRect.left;
    const relativeY = y - svgRect.top;

    console.log(
      'converting to svg: [%d,%d]-> [%d,%d]',
      x,
      y,
      relativeX / zoomLevel - panPosition.x,
      relativeY / zoomLevel - panPosition.y
    );
    // Adjust for pan and zoom
    return {
      x: relativeX / zoomLevel - panPosition.x,
      y: relativeY / zoomLevel - panPosition.y,
    };
  },

  // Convert SVG coordinates to screen space
  svgToScreenCoords: (x, y, svgElement, panPosition, zoomLevel) => {
    const svgRect = svgElement.getBoundingClientRect();

    // Adjust for pan and zoom
    return {
      x: (x + panPosition.x) * zoomLevel + svgRect.left,
      y: (y + panPosition.y) * zoomLevel + svgRect.top,
    };
  },
};
