// svgDiagramReducer/types.js
export const SVG_DIAGRAM_ACTIONS = {
  SET_ATTR_NAME: 'svg_diagram/SET_ATTR_NAME',
  SET_ZOOM_LEVEL: 'svg_diagram/SET_ZOOM_LEVEL',
  SET_PAN_POSITION: 'svg_diagram/SET_PAN_POSITION',
  SET_PAN_MODE: 'svg_diagram/SET_PAN_MODE',
  SET_GRID_VISIBLE: 'svg_diagram/SET_GRID_VISIBLE',
  TOGGLE_DEBUG_MODE: 'svg_diagram/TOGGLE_DEBUG_MODE',

  SET_CURRENT_ACTION: 'svg_diagram/SET_CURRENT_ACTION',
  SET_SELECTED_ELEMENT: 'svg_diagram/SET_SELECTED_ELEMENT',
  SET_LINE_DRAWING_TYPE: 'svg_diagram/SET_LINE_DRAWING_TYPE',
  SET_ELEMENTS: 'svg_diagram/SET_ELEMENTS',
  ADD_ELEMENT: 'svg_diagram/ADD_ELEMENT',
  UPDATE_ELEMENT: 'svg_diagram/UPDATE_ELEMENT',
  DELETE_ELEMENT: 'svg_diagram/DELETE_ELEMENT',
  SET_LINE_THICKNESS: 'svg_diagram/SET_LINE_THICKNESS',
  SET_GRID_SIZE: 'svg_diagram/SET_GRID_SIZE',
  SET_CONNECTOR_MODE: 'svg_diagram/SET_CONNECTOR_MODE',
  SET_COLOR: 'svg_diagram/SET_COLOR',
  SET_DRAWING_MODE: 'svg_diagram/SET_DRAWING_MODE',
  DUPLICATE_ELEMENT: 'svg_diagram/DUPLICATE_ELEMENT',
  CHANGE_ELEMENT_LAYOUT: 'svg_diagram/CHANGE_ELEMENT_LAYOUT',
  SET_ELEMENT_PARENT: 'svg_diagram/SET_ELEMENT_PARENT',
  BREAK_ELEMENT_PARENT: 'svg_diagram/BREAK_ELEMENT_PARENT',
};
