import styled from 'styled-components';

const HEADER_TEXT_MAXSIZE = 12;

//----------------------------------------------------------------------
export const StyledStatsPadStyle = styled.div({
  border: '0px solid #CCCCCC44',
  color: '#1C7ED6',
  display: 'inline-block',
  fontSize: '12px',
  marginRight: 10,
  marginTop: 1,
  opacity: 1,
  padding: 2,
});

//----------------------------------------------------------------------
const getBackgroundColorHeaderSmallImg = (props) => {
  if (props.active) return '#E7F5FF';
  return props?.backgroundColor || '#FFFFFF';
};
export const StyledNavigHeaderSmallImg = styled.img({
  alignSelf: 'center',
  backgroundColor: (props) => getBackgroundColorHeaderSmallImg(props),
  border: (props) => (props.active ? '1px solid #6560A8' : '1px solid #CCCCCC'),
  borderRadius: '4px',
  color: (props) => (props.active ? 'black' : 'white'),
  cursor: 'pointer',
  float: 'right',
  fontSize: '11px',
  height: '30px',
  marginLeft: '2px',
  marginRight: '2px',
  padding: '3px',
  width: '30px',
  opacity: 0.8,
  ':hover': {
    opacity: '1',
  },
});

//----------------------------------------------------------------------
export const StyledHeaderStatDiv = styled.div({
  backgroundColor: (props) => props?.backgroundColor || 'white',
  borderBottom: (props) => `1px solid ${props?.borderBottomColor}`,
  color: (props) => props.colTxtHeaderOnLight,
  fontFamily: 'proxima-nova',
  fontSize: '16',
  height: '55px',
  marginLeft: '15px',
  paddingBottom: '10px',
  paddingLeft: '5px',
  paddingTop: '8px',
  // position: 'relative',
  width: 'calc(100% - 45px)',
  // zIndex: '10000000',
});
//------------------------------------------------------------------------------------------------
export const StyledDivHeader = styled.div({
  backgroundColor: (props) => props?.backgroundColor || 'white',
  borderRight: '1px dashed var(--ds-border-base)',
  height: '100%',
  left: (props) => `${props.x}px`,
  overflow: 'hidden',
  position: 'absolute',
  width: '100%',
  opacity: '1',
  ':hover': {
    opacity: '1',
  },
  '& .blocIcons img': {
    display: 'none',
  },
  ':hover .blocIcons': {
    display: 'inline-flex',
    border: '1px solid var(--ds-border-base)',
    borderLeft: '0px',
    borderRadius: '0px 3px 3px 0px',
    backgroundColor: (props) => '#ffffff' ?? 'var(--ds-bg-base)',
    // boxShadow: '1px -0px 0.5px 0.5px  rgba(0, 0, 0, 0.25)',
    zIndex: 99999999,
  },
  ':hover .blocIcons img': {
    display: 'inline',
    opacity: '0.5',
  },
  ':hover .blocIcons .btnMoreAction': {
    display: 'none',
  },
});
//------------------------------------------------------------------------------------------------
export const BlocIcons = styled.div({
  height: '36px',
  width: 'auto',
  whiteSpace: 'nowrap',
  position: 'relative',
  display: 'inline-flex',
  alignItems: 'center',
  paddingLeft: '3px',
  paddingRight: '3px',
  top: '0px',
  '& img': {
    // display: 'none',
  },

  ':hover': {
    backgroundColor: (props) => props.backgroundColorHover || '#DDD',
    // boxShadow: '1px -0px 0.5px 0.5px  rgba(0, 0, 0, 0.5)',
  },

  ':hover img': {
    display: 'inline',
  },
  ':hover .btnMoreAction': {
    display: 'none',
  },
});

export const StyleHeaderTopContainer = styled.div({});
//------------------------------------------------------------------------------------------------
export const StyledHeaderTop = styled.div({
  ':first-child': {},
  ':last-child': {
    borderTopRightRadius: '7px',
    borderBottomRightRadius: '7px',
  },
  ':only-child': {
    display: 'none',
    width: '100%',
    border: '0px solid red',
  },

  ':hover': {
    borderBottomRightRadius: '7px',
    borderTopRightRadius: '7px',
    height: 48,
    opacity: '1',
    overflow: 'visible',
  },

  backgroundColor: (props) =>
    props.hover ? 'var(--ds-bg-secondary)' : 'var(--ds-bg-base)',
  borderLeft: (props) => `3px solid ${props.color}`,
  borderRadius: '0px',
  borderTopLeftRadius: '7px',
  bottom: 0,
  boxShadow: (props) => '-1px 1px 1px 0px #cccccc88',
  color: 'white',
  fontSize: '12px',
  fontWeight: (props) => (props.hover ? '550' : '450'),
  height: (props) => '26px',
  left: (props) => `${10 + props.posX}px`, // +props.width,
  minWidth: (props) => (props.hover ? props.width : ''),
  opacity: '1',
  overflow: (props) => (props.hover ? 'visible' : 'hidden'),
  paddingLeft: 6,
  paddingRight: (props) => (props.hover ? '30' : ''),
  paddingTop: 0,
  position: 'absolute',
  textTransform: 'capitalize',
  transformOrigin: '0% 0%',
  transition: 'width 0.3s, left 0.3s, height 0.3s',
  width: '100%',
  zIndex: (props) => 60000 + props.posX + 1000 * props.level,
});
export const StatsPadContent = styled.div({
  backgroundColor: 'red',
  borderRadius: '5px',
  boxShadow: '1px 1px 2px #CCC',
  height: '50px',
  padding: '5px',
  width: '50px',
});

export const StyledDivHeaderContent = styled.div({
  display: 'flex',
  flexDirection: 'row',
  marginTop: '-2px',
  marginBottom: '10px',
  paddingTop: '4px',
  ':hover': {
    zIndex: 99999999,
    position: 'relative',
  },
});

export const StyledDivHeaderContentTooltip = styled.div({
  borderRadius: '3px',
  color: '#AAA',
  cursor: 'pointer',
  float: 'right',
  fontSize: '15px',
  fontWeight: '750',
  height: '35px',
  marginLeft: 10,
  marginRight: 10,
  paddingLeft: '5px',
  paddingRight: '5px',
  paddingTop: '9px',
});

export const StyledDivHeaderContentAddTooltip = styled.div({
  border: '0px solid #CCC',
  color: '#CCC',
  float: 'left',
  fontSize: '15px',
  fontWeight: '750',
  height: '30px',
  marginLeft: 5,
  marginRight: 5,
  paddingLeft: '5px',
  paddingRight: '5px',
  paddingTop: '0px',
  lineHeight: '25px',
});
export const StyledHeaderTopContent = styled.div({
  color: (props) => props.color,
  textOverflow: 'ellipsis',
  textTransform: 'capitalize',
  whiteSpace: 'nowrap',
  fontFamily: 'proxima-nova',
  fontSize: HEADER_TEXT_MAXSIZE,
});
