import React from 'react';
import { connect } from 'react-redux';
import { getProgressBars } from '../../../Utils/Utils';
import { ObjGraphsBarHtml2DCSSStyles } from './ObjGraphsBarHtml2DCSS';

//= ============================================================================
class ObjGraphsBarHtml2D extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  //----------------------------------------------------------------------------

  //----------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  render() {
    const formulaCat1 = this.props.formulaCat1;
    const formulaCat2 = this.props.formulaCat2;

    const sum = this.props.data.reduce(
      (sum, elem, arr) => sum + parseInt(elem.result),
      0
    );
    const data = this.props.data;

    let xses = data.map((item) => item[formulaCat1]);
    xses = [...new Set(xses)];
    let yses = data.map((item) => item[formulaCat2]);
    yses = [...new Set(yses)];
    yses = yses.sort((a, b) => a - b);

    const matrix = new Array(xses.length);
    for (let i = 0; i < xses.length; i++) {
      matrix[i] = new Array(yses.length);
      matrix[i].fill(0);
    }

    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      const xval = item[formulaCat1];
      const yval = item[formulaCat2];
      const resval = item[Object.keys(item)[0]];

      const matx = xses.indexOf(xval);
      const maty = yses.indexOf(yval);
      const existingValue = matrix[matx][maty];
      matrix[matx][maty] = (existingValue ?? 0) + Number(resval);
    }

    return (
      <div style={ObjGraphsBarHtml2DCSSStyles.container}>
        {xses.map((elem, ind) => {
          const values = matrix[ind];
          const label = elem;
          const colors = [
            '#228BE6',
            '#8a89a6',
            '#7b6888',
            '#6b486b',
            '#a05d56',
            '#d0743c',
            '#ff8c00',
          ];
          return getProgressBars(sum, values, label, colors, '', 12);
        })}
      </div>
    );
  }
}

//-----------------------------------------------------------------------------------------------------------

function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ObjGraphsBarHtml2D);
