import { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { select } from 'd3';
import { configureSVGCanvas } from './treeLayoutUtils';
import * as SVGMap from '../../Utils/SVGFunctionMap';
import { currentPaletteActiveSelector } from '../../../reducers/themeReducers/themeSelector';

export const useSVGCanvas = ({ dimensions, nodes, bands_config }) => {
  const svgRef = useRef(null);
  const svgInstance = useRef(null);

  // Selectors liés au SVG
  const arrowcolor = useSelector((state) => state.objects.arrowcolor);
  const arrowopacity = useSelector((state) => state.objects.arrowopacity);
  const arrowtype = useSelector((state) => state.objects.arrowtype);
  const arrowweight = useSelector((state) => state.objects.arrowweight);
  const pal = useSelector(currentPaletteActiveSelector);

  useEffect(() => {
    if (!svgRef.current) return;

    const config = configureSVGCanvas(dimensions);

    if (!svgInstance.current) {
      svgInstance.current = select(svgRef.current)
        .attr('width', config.width)
        .attr('height', config.height);

      svgInstance.current
        .append('g')
        .attr(
          'transform',
          `translate(${config.margin.left},${config.margin.top})`
        )
        .attr('class', 'links-group');
    } else {
      svgInstance.current
        .attr('width', config.width)
        .attr('height', config.height);

      svgInstance.current
        .select('.links-group')
        .attr(
          'transform',
          `translate(${config.margin.left},${config.margin.top})`
        );
    }

    if (svgInstance.current) {
      SVGMap.drawLinks(
        nodes,
        svgInstance.current,
        arrowcolor || pal.colArrow,
        'default',
        arrowtype,
        +arrowopacity || 0.5,
        +arrowweight || 1,
        bands_config
      );
    }
  }, [
    dimensions,
    nodes,
    arrowcolor,
    arrowtype,
    arrowopacity,
    arrowweight,
    bands_config,
    pal,
  ]);

  return {
    svgRef,
    svg: svgInstance.current,
  };
};