import { useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ObjectActionCreators from '../../../actions';

const DataColumnResizer = ({ panelNumber, colNumber }) => {
  const resizerRef = useRef(null);
  const dispatch = useDispatch();

  const handleMouseDown = (e) => {
    e.stopPropagation();
    e.preventDefault();
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = (e) => {
    // Check if the left mouse button is pressed (buttons === 1 indicates the left button)
    if (e.buttons !== 1) {
      handleMouseUp(); // If the left button is not pressed, simulate mouse up
      return;
    }

    e.stopPropagation();
    const newWidth =
      e.clientX - resizerRef.current.parentElement.getBoundingClientRect().left;
    dispatch(
      ObjectActionCreators.updateDataColumSize(panelNumber, colNumber, newWidth)
    );
  };

  const handleMouseUp = () => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  // Clean up any listeners on component unmount
  useEffect(() => {
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, []);

  return (
    <div
      ref={resizerRef}
      className="resizer"
      onMouseDown={handleMouseDown}
      draggable="false"
      style={{
        width: '7px',
        backgroundColor: 'var(--ds-border-base)',
        border: '3px solid var(--ds-bg-base)',
        height: '100%',
        margin: '0px',
        cursor: 'col-resize',
      }}
    ></div>
  );
};

export default DataColumnResizer;
