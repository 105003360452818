import styled from 'styled-components';

export const DivCellText = styled.div({
  border: `0px solid var(--ds-border-base)`,
  color: (props) =>
    props.isName ? 'var(--ds-text-title)' : 'var(--ds-text-base)',
  fontFamily: 'proxima-nova',
  fontSize: 12,
  fontWeight: (props) => (props.isName ? 'bold' : 'normal'),
  overflow: 'hidden',
  paddingLeft: 5,
  textOverflow: 'ellipsis',
  width: '100%',
  height: '100%',
});

export const DataCellYesNoContainer = styled.div({
  width: '20px',
  height: '20px',
});

export const DataCellNumberContainer = styled.div({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  borderLeft: '0px solid #DDDDDD22',
});

export const DataCellNumberValue = styled.div({
  color: 'var(--ds-text-base)',
  fontFamily: 'proxima-nova',
  fontSize: 12,
  fontWeight: 'normal',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: 'calc(100% - 20px)',
  paddingLeft: '5px',
});

export const DataCellCrumbtrailContainer = styled.div({
  height: 'auto',
  marginTop: '2px',
  paddingLeft: '4px',
  overflow: 'hidden',
  display: 'flex',
  flexWrap: 'wrap',
});

export const DataCellCrumbtrailItem = styled.div({
  fontSize: '11px',
  fontWeight: 600,
  color: 'var(--ds-text-title)',
  backgroundColor: 'var(--ds-bg-secondary)',
  border: '0px solid #ADBFD966',
  padding: '1px 3px 1px 3px',
  margin: '2px 2px 0px 2px',
  borderRadius: '3px',
  height: '22px',
});

export const DataCellCrumbtrailSeparator = styled.div({
  fontWeight: 600,
  fontSize: '9px',
  color: 'var(--ds-text-title)',
  lineHeight: '22px',
});
