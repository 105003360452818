import React from 'react';

import { Modal, Tooltip } from '@mantine/core';

import throttle from 'lodash/throttle';
import { debounce } from 'lodash';
import APortalRestrict from '../../APortalRestrict';
import { getNextType } from '../../Utils/NodeUtils';
import {
  getDateTicks,
  getDateTicksModeFromOptions,
  getLabel,
  getObjectDef,
  getOptionsForField,
} from '../../Utils/Utils.js';
import {
  BlocIcons,
  StatsPadContent,
  StyledDivHeader,
  StyledDivHeaderContent,
  StyledDivHeaderContentAddTooltip,
  StyledDivHeaderContentTooltip,
  StyledHeaderStatDiv,
  StyledHeaderTop,
  StyledHeaderTopContent,
  StyledNavigHeaderSmallImg,
  StyledStatsPadStyle,
  StyleHeaderTopContainer,
} from './NavigHeaderCss.js';
import { modalStyle } from '../../../constants/commonStyles.js';
import { NavigHorizontalSlide } from './NavigHorizontalSlider.js';
import { Avatar } from '../../UI/Avatar/Avatar.js';

const QueryBuilder = React.lazy(() =>
  import('../../query-builder/QueryBuilder.js')
);

const ObjFilterItem = React.lazy(() =>
  import('../../UI/Objects/ObjFilterItem/ObjFilterItem.js')
);

const StatsPad = ({ subMap, color }) => {
  let res = [];
  if (subMap?.keys()) {
    for (const key of subMap.keys()) {
      const val = subMap.get(key);
      if (!isNaN(val)) {
        res.push(
          <StyledStatsPadStyle>
            {key}: <span style={{ color }}>{val}</span>
          </StyledStatsPadStyle>
        );
      }
    }
    res = <StatsPadContent>{res}</StatsPadContent>;
  }
  return res;
};
export class NavigHeader extends React.Component {
  //----------------------------------------------------------------------
  constructor(props) {
    super(props);
    this.handleDragEnter = this.handleDragEnter.bind(this);
    this.handleDropLocal = this.handleDropLocal.bind(this);
    this.handleClickFilter = this.handleClickFilter.bind(this);
    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.showFilter = this.showFilter.bind(this);
    this.hideFilter = throttle(this.hideFilter.bind(this), 2500);
    this.handleToggleQB = this.handleToggleQB.bind(this);
    this.handleChangeQuery = this.handleChangeQuery.bind(this);
    this.handleClickCard = this.handleClickCard.bind(this);
    this.aref = React.createRef();
    this.handleClickAdd = this.handleClickAdd.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.handleClickSort = this.handleClickSort.bind(this);
    this.handleClickCollapse = this.handleClickCollapse.bind(this);
    this.handleClickExpand = this.handleClickExpand.bind(this);
    this.handleToggleMode = this.handleToggleMode.bind(this);
    this.handleChangeQuery = debounce(this.handleChangeQuery.bind(this), 1000);
    this.handleToggleMandatoryLevel =
      this.handleToggleMandatoryLevel.bind(this);
    this.state = {
      scrollX: 0,
      dragInto: '',
      fixed: true,
      showFilter: -1,
      dateTicks: [],
    };
  }

  //----------------------------------------------------------------------
  componentDidUpdate(prevProps) {
    if (
      this.props.fieldType === 'date' &&
      this.props.fieldType !== prevProps.fieldType
    ) {
      this.setState({
        dateTicks: getDateTicks(
          this.props.width,
          80,
          this.props?.scalerXBand,

          getDateTicksModeFromOptions(this.props.options)
        ),
      });
    }
  }

  //------------------------------------------------------------------------------------------------
  onDragOver(event, key) {
    event.preventDefault();
    this.setState({ dragInto: key });
  }

  //------------------------------------------------------------------------------------------------
  handleDragEnter(key) {
    this.setState({ dragInto: key });
  }

  //------------------------------------------------------------------------------------------------
  handleDropLocal(key) {
    this.setState({ dragInto: '' });

    this.props.onDrop(key);
  }

  handleMenuClick(e) {
    this.props.setActivePanel(this.props.level);
    this.props.remoteControllerShowToggle(e.clientX, e.clientY);
  }

  //------------------------------------------------------------------------------------------------
  hideFilter(e) {
    this.setState({ showFilter: -1 });
  }

  //------------------------------------------------------------------------------------------------
  showFilter(e) {
    this.setState({ showFilter: e.currentTarget.id });
  }

  //------------------------------------------------------------------------------------------------
  handleToggleQB() {
    this.setState({ openQB: !this.state.openQB });
  }

  //------------------------------------------------------------------------------------------------
  handleChangeQuery(queryJSON, queryString, queryRaw) {
    this.props.setQBRestrictionByLevel(
      this.props.level,
      queryJSON,
      queryString,
      queryRaw
    );
  }

  //------------------------------------------------------------------------------------------------

  //------------------------------------------------------------------------------------------------
  removeDuplicate(arrayClassif) {
    if (arrayClassif)
      arrayClassif = arrayClassif.filter(function (item, pos, self) {
        return self.indexOf(item) === pos;
      });
    return arrayClassif;
  }

  //------------------------------------------------------------------------------------------------
  getBandTicks(arrayClassif, fieldType) {
    if (fieldType === 'date') {
      const res = getDateTicks(
        this.props.width,
        80,
        this.props?.scalerXBand,
        getDateTicksModeFromOptions(this.props.options)
      );

      return res;
    }
    return arrayClassif;
  }

  //------------------------------------------------------------------------------------------------
  calcBandWidth(arrayClassif, fieldType) {
    let bandW = 140;

    if (arrayClassif != null && arrayClassif.length > 1) {
      bandW =
        this.props?.scalerXBand(arrayClassif[1]) -
        this.props?.scalerXBand(arrayClassif[0]);
    }

    try {
      const getBandW = () => {
        if (this.props?.scalerXBand === undefined) return 1;
        return this.props.fieldType === 'date'
          ? bandW
          : this.props?.scalerXBand.bandwidth();
      };

      bandW = getBandW();
    } catch (error) {}

    return bandW;
  }

  handleClickCard() {
    this.props.setStatBy(
      getNextType(
        this.props.objectsDefList,
        this.props.objectType,
        this.props.fieldName
      ),
      this.props.level
    );
  }

  handleClickAdd(e) {
    e.stopPropagation();
    this.props.selectObject(null);
    this.props.openObjectMenu('addObject');
    this.props.setObjectToCreate(this.props.objectType);
  }

  handleToggleMandatoryLevel() {
    this.props.toggleMandatoryLevel(this.props.level);
  }

  handleToggleMode() {
    this.props.toggleDisplayMode(this.props.level);
  }

  handleClickFilter(e) {
    e.stopPropagation();
    this.handleToggleQB();
  }

  handleClickSort() {
    this.props.setSortBy(this.props.fieldName, this.props.level);
  }

  handleClickCollapse() {
    this.props.collapseNodes(this.props.level);
  }

  handleClickExpand() {
    this.props.expandNodes(this.props.level);
  }

  //------------------------------------------------------------------------------------------------
  render() {
    const {
      arrayClassif,
      fieldType,
      fieldName,
      t,
      x,
      qb_restrictions,
      level,
      displayMode,
      objectsDefList,
      objectType,
      type,
      colorBand,
      width,
      dicoNameRef,
      statsMap,
      dragged_over,
      activeBand,
      addFieldRestriction,
      scalerXBand,
      nbMandatoryLevel,
      options,
    } = this.props;
    let arrayClassifNoDup = this.removeDuplicate(arrayClassif);
    arrayClassifNoDup = this.getBandTicks(arrayClassifNoDup, fieldType);
    const bandW = this.calcBandWidth(arrayClassifNoDup, fieldType);
    let arrayClassifDate = null;

    if (fieldType === 'date') {
      let lastMonth = null;
      let lastYear = null;
      let displayMonthName = false;
      const MONTH_NAME_SHORT = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ];

      arrayClassifDate = arrayClassifNoDup?.map((item) => {
        const date = new Date(parseInt(item));
        const valDate = date.getDate();
        const valMonth = displayMonthName
          ? MONTH_NAME_SHORT[date.getMonth()]
          : `/${date.getMonth() + 1}`;
        const valYear =
          (displayMonthName ? '' : '/') + (date.getFullYear() % 100);
        let res = '';
        if (options !== 'monthselector') {
          res += valDate;
        }
        // if (valMonth !== lastMonth || valYear !== lastYear || true) {
        res += `${valMonth}`;
        if (valYear !== lastYear) {
          res += `${valYear}`;
        }
        // }
        lastMonth = valMonth;
        lastYear = valYear;

        return res;
      });
    }

    const IsRestricted = qb_restrictions?.[level]?.str?.length > 2;
    const IsLevelMandatory = nbMandatoryLevel >= level;
    return (
      <StyledDivHeader
        x={x}
        backgroundColor={'var(--ds-bg-base)'}
        id="StyledDivHeader"
      >
        <StyledHeaderStatDiv
          backgroundColor={'var(--ds-bg-base)'}
          borderBottomColor={'var(--ds-border-base)'}
        >
          {level > 0 && (
            <div
              onClick={this.handleToggleMandatoryLevel}
              style={{
                cursor: 'pointer',
                position: 'absolute',
                top: '18px',
                left: '-1px',
                width: '21px',
                padding: '2px',
                paddingLeft: '1px',
                height: '24px',
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke={IsLevelMandatory ? '#416ab5' : 'gray'}
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="icon icon-tabler icons-tabler-outline icon-tabler-circles-relation"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M9.183 6.117a6 6 0 1 0 4.511 3.986" />
                <path d="M14.813 17.883a6 6 0 1 0 -4.496 -3.954" />
              </svg>
            </div>
          )}

          <StyledDivHeaderContent id={`flt${level}`}>
            <React.Suspense fallback={null}>
              <ObjFilterItem level={level} />
            </React.Suspense>
            <BlocIcons
              id="BlocIcons"
              className="blocIcons"
              backgroundColorHover={'var(--ds-bg-base)'}
            >
              {objectType && getObjectDef(objectsDefList, objectType) && (
                <>
                  {this.props.displayMode === 'card' && (
                    <Tooltip
                      label={t('header.arrangement')}
                      gutter={5}
                      withArrow
                    >
                      <StyledDivHeaderContentTooltip
                        onClick={this.handleClickCard}
                        onTouchEnd={this.handleClickCard}
                      >
                        {getLabel(objectsDefList, objectType, type) ??
                          fieldName}
                      </StyledDivHeaderContentTooltip>
                    </Tooltip>
                  )}

                  <Tooltip
                    label={t('app.add')}
                    gutter={5}
                    withArrow
                    zIndex="20000000000000000"
                  >
                    <StyledDivHeaderContentAddTooltip className="btnMoreAction">
                      ...
                    </StyledDivHeaderContentAddTooltip>
                  </Tooltip>

                  <Tooltip label={t('app.add')} gutter={5} withArrow>
                    <StyledNavigHeaderSmallImg
                      backgroundColor={'var(--ds-bg-base)'}
                      important
                      src="/img/icons/add.svg"
                      onClick={this.handleClickAdd}
                      onTouchEnd={this.handleClickAdd}
                    />
                  </Tooltip>
                  <Tooltip label={t('header.filter')} gutter={5} withArrow>
                    <StyledNavigHeaderSmallImg
                      backgroundColor={'var(--ds-bg-base)'}
                      active={IsRestricted}
                      src="/img/icons/filter.svg"
                      id="trgEE"
                      onClick={this.handleClickFilter}
                      onTouchEnd={this.handleClickFilter}
                    />
                  </Tooltip>
                  <Tooltip label={t('header.transpose')} gutter={5} withArrow>
                    <StyledNavigHeaderSmallImg
                      backgroundColor={'var(--ds-bg-base)'}
                      src="/img/icons/transpose.svg"
                      onClick={this.handleToggleMode}
                      onTouchEnd={this.handleToggleMode}
                    />
                  </Tooltip>
                  <Tooltip label={t('header.view')} gutter={5} withArrow>
                    <StyledNavigHeaderSmallImg
                      backgroundColor={'var(--ds-bg-base)'}
                      src="/img/icons/menu-black.svg"
                      onClick={this.handleMenuClick}
                      onTouchEnd={this.handleMenuClick}
                    />
                  </Tooltip>
                  <Tooltip label={t('header.sort')} gutter={5} withArrow>
                    <StyledNavigHeaderSmallImg
                      backgroundColor={'var(--ds-bg-base)'}
                      src="/img/icons/arrowVert.svg"
                      onClick={this.handleClickSort}
                      onTouchEnd={this.handleClickSort}
                    />
                  </Tooltip>

                  <Tooltip label={t('header.collapse')} gutter={5} withArrow>
                    <StyledNavigHeaderSmallImg
                      backgroundColor={'var(--ds-bg-base)'}
                      src="/img/icons/collapse.svg"
                      onClick={this.handleClickCollapse}
                      onTouchEnd={this.handleClickCollapse}
                    />
                  </Tooltip>
                  <Tooltip label={t('header.expand')} gutter={5} withArrow>
                    <StyledNavigHeaderSmallImg
                      backgroundColor={'var(--ds-bg-base)'}
                      src="/img/icons/expand.svg"
                      onClick={this.handleClickExpand}
                      onTouchEnd={this.handleClickExpand}
                    />
                  </Tooltip>
                </>
              )}
              <Modal
                id="define_filter"
                styles={modalStyle}
                title={`${t('header.define_filter')} [${level}]`}
                size="1000px"
                opened={this.state.openQB}
                onClose={this.handleToggleQB}
              >
                <React.Suspense fallback={null}>
                  <QueryBuilder
                    stringExp={qb_restrictions?.[level]?.strraw ?? ''}
                    type={objectType}
                    handleChangeQuery={this.handleChangeQuery}
                  />
                </React.Suspense>
              </Modal>
            </BlocIcons>
          </StyledDivHeaderContent>
        </StyledHeaderStatDiv>

        <StyleHeaderTopContainer>
          {displayMode === 'card' &&
            arrayClassifNoDup &&
            arrayClassifNoDup.map((item, ind, arr) => {
              let valToDisp = item === undefined ? '' : `${item}`;

              if (dicoNameRef[valToDisp] !== undefined)
                valToDisp = dicoNameRef[valToDisp];
              valToDisp = valToDisp?.substring(0, 15);

              if (fieldType === 'date') {
                valToDisp = arrayClassifDate[ind];
              }

              const id = `inDiv${level}${ind}`;
              const isHover = dragged_over === item || activeBand === item;
              const posX = Math.floor((scalerXBand(item) / 100) * width);
              return (
                <StyledHeaderTop
                  key={ind}
                  className="navigHeader"
                  ref={this.aref}
                  color={colorBand === undefined ? 'white' : colorBand(item)}
                  posX={posX}
                  level={level}
                  width={(bandW / 100) * width}
                  hover={isHover}
                  noneHover={!(dragged_over || activeBand)}
                  id={id}
                  onMouseOver={this.showFilter}
                  onMouseLeave={this.hideFilter}
                >
                  {this.state.showFilter === id && (
                    <APortalRestrict
                      idParent={id}
                      aref={this.aref}
                      addFieldRestriction={addFieldRestriction}
                      level={level}
                      fieldType={fieldType}
                      type={type}
                      item={item}
                      objectType={objectType}
                    >
                      <StatsPad
                        subMap={statsMap.get(item)}
                        color={'var(--ds-text-secondary)'}
                      ></StatsPad>
                    </APortalRestrict>
                  )}

                  <StyledHeaderTopContent color={colorBand(item)}>
                    {typeof item === 'string' && item?.includes('emplo') && (
                      <Avatar
                        objId={item}
                        ratio={0.6}
                        marginTop={0}
                        marginLeft={-2}
                      />
                    )}
                    {`${valToDisp}`.toLowerCase()}
                  </StyledHeaderTopContent>
                </StyledHeaderTop>
              );
            })}
          {fieldType === 'date' && (
            <NavigHorizontalSlide width={width} level={level} />
          )}
        </StyleHeaderTopContainer>
      </StyledDivHeader>
    );
  }
}

export default NavigHeader;
