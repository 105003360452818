import { Group } from '@mantine/core';
import { Calendar } from '@mantine/dates';

function DayCalendar({ handleDateChanged, attrName }) {
  const internalHandleDateChanged = (selectedDate) => {
    handleDateChanged(null, attrName, selectedDate);
  };

  return (
    <Group position="center">
      <Calendar
        firstDayOfWeek="monday"
        withCellSpacing={false}
        onChange={internalHandleDateChanged}
      />
    </Group>
  );
}
export default DayCalendar;
