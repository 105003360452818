import React, { useState, useCallback } from 'react';
import WeekCalendar from '../../Calendar/WeekCalendar';
import MonthCalendar from '../../Calendar/MonthCalendar';
import DayCalendar from '../../Calendar/DayCalendar';
import { DateField } from './ObjFieldDateCSS';

const ObjFieldDate = ({
  readOnly,
  isEditing = false,
  options,
  attrName,
  keyParam,
  value,
  handleChangeFieldValue,

  pal,
}) => {
  const [showCal, setShowCal] = useState(isEditing);

  const toggleShowCal = useCallback(() => {
    setShowCal((prevShowCal) => !prevShowCal);
  }, []);

  const handleChangeDateField = useCallback(
    (event, attrName, aDate) => {
      if (aDate != null && aDate instanceof Date) {
        handleChangeFieldValue(keyParam + attrName, `${aDate.getTime()}`);
        setShowCal(false);
      }
    },
    [handleChangeFieldValue, keyParam]
  );

  const getISOWeekOfYear = (date) => {
    const target = new Date(date.valueOf());
    const dayNumber = (date.getDay() + 6) % 7; // Adjust so Monday is 0
    target.setDate(target.getDate() - dayNumber + 3); // Ensure Thursday is in the same week
    const firstThursday = new Date(target.getFullYear(), 0, 4); // Find the first Thursday of the year
    const daysDifference = (target - firstThursday) / 86400000; // Days between target date and first Thursday
    return 1 + Math.floor(daysDifference / 7);
  };

  const formatDateToMonth = (date) => {
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${month}-${year}`;
  };

  const renderCalendar = () => {
    if (!showCal) return null;

    const commonProps = {
      attrName,
      handleDateChanged: handleChangeDateField,
    };

    switch (options) {
      case 'weekselector':
        return <WeekCalendar {...commonProps} />;
      case 'monthselector':
        return <MonthCalendar {...commonProps} />;
      default:
        return <DayCalendar {...commonProps} handleClose={toggleShowCal} />;
    }
  };

  const renderDate = () => {
    let aDate = new Date(parseInt(value));
    if (isNaN(aDate)) {
      return '';
    }

    switch (options) {
      case 'weekselector':
        return `${aDate.toLocaleDateString()} - W${getISOWeekOfYear(aDate)}`;
      case 'monthselector':
        return formatDateToMonth(aDate);
      default:
        return aDate.toLocaleDateString();
    }
  };

  return (
    <>
      {!readOnly && renderCalendar()}
      {!showCal && (
        <DateField
          color="var(--ds-text-base)"
          borderColor="var(--ds-border-base)"
          readOnly={readOnly}
          onClick={readOnly ? null : toggleShowCal}
          key={`ds${keyParam}${attrName}3`}
          id={`ds${keyParam}${attrName}3`}
        >
          {renderDate()}
        </DateField>
      )}
    </>
  );
};

export default ObjFieldDate;
