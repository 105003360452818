export const svgDiagramSelector = (state) => {
  return state.svgDiagram;
};

export const zoomLevelSelector = (state) => state.svgDiagram.zoomLevel;
export const panPositionSelector = (state) => state.svgDiagram.panPosition;
export const isGridVisibleSelector = (state) => state.svgDiagram.isGridVisible;
export const gridSizeSelector = (state) => state.svgDiagram.gridSize;
export const isDebugModeSelector = (state) => state.svgDiagram.isDebugMode;
export const isPanModeSelector = (state) => state.svgDiagram.isPanMode;

// Sélecteurs pour l'état d'édition
export const currentActionSelector = (state) => state.svgDiagram.currentAction;
export const selectedElementKeySelector = (state) =>
  state.svgDiagram.selectedElementKey;
export const lineDrawingTypeSelector = (state) =>
  state.svgDiagram.lineDrawingType;
export const elementsSelector = (state) => state.svgDiagram.elements;
export const currentLineDrawingIdSelector = (state) =>
  state.svgDiagram.currentLineDrawingId;
export const lineThicknessSelector = (state) => state.svgDiagram.lineThickness;
export const colorSelector = (state) => state.svgDiagram.color;
export const isDrawingSelector = (state) => state.svgDiagram.isDrawing;
export const connectorModeSelector = (state) => state.svgDiagram.connectorMode;

// Sélecteurs dérivés
export const selectedElementSelector = (state) => {
  const selectedKey = state.svgDiagram.selectedElementKey;
  if (!selectedKey) return null;

  return (
    state.svgDiagram.elements.find((element) => element.key === selectedKey) ||
    null
  );
};
