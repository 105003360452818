export const BANDS_CONFIG = [
  { width: 500, minWidth: 300 },
  { width: 800, minWidth: 300 },
  { width: 500, minWidth: 300 },
  { width: 500, minWidth: 300 },
  { width: 1000, minWidth: 500 },
];

export const RESIZE_PANE_BAR_WIDTH = 15; // NavigTreeResizePaneCSS

export const HEADER_HEIGHT = 150;
export const NUM_LEVEL = 5; // navigTree.js
export const NODE_OFFSET_X = 60;
export const NODE_OFFSET_Y = 30 + HEADER_HEIGHT;
export const NODE_OFFSET_Y_DIV = 70;

export const NODE_NAVIG_WIDTH = 260; // navigHeaderBar.js

export const HEADER_LEVEL_OFFSET = 33; // navigHeaderBar.js - x={band?.start + HEADER_LEVEL_OFFSET * (index - 1)}
export const DATA_COLUMNS_HEADER_OFFSET = 15; // navigHeaderBar.js - left={band.start + DATA_COLUMNS_HEADER_OFFSET * index}
export const DATA_COLUMNS_ICON_OFFSET = 24;
export const DATA_COLUMNS_COLOR_BAR_OFFSET = 8;
