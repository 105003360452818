import React, { memo, useCallback, useEffect, useRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { color } from 'd3';
import ObjectActionCreators from '../../../actions.js';
import { closeObjectDetails } from '../../../reducers/layoutReducer/action.js';
import {
  isPerspectivesPanelOpenSelector,
  zoomSelector,
} from '../../../reducers/layoutReducer/layoutSelector.js';
import { setNodeDraggedOver } from '../../../reducers/objectReducers/action.js';
import {
  onSetClassifTablet,
  onSetCurrentBandTablet,
  onSetLevelNodeNadBandTablet,
} from '../../../reducers/responsiveReducer/action.js';
import {
  arrayClassifTabletSelector,
  isDragTabletSelector,
  positionNodeDragSelector,
} from '../../../reducers/responsiveReducer/responsiveSelector.js';
import { currentPaletteActiveSelector } from '../../../reducers/themeReducers/themeSelector.js';
import { isTablet } from '../../../utils/detectDevices.js';
import {
  getDateTicks,
  getDateTicksModeFromOptions,
} from '../../Utils/Utils.js';
import { NavigBandContainer, StyledHeader } from './NavigBandCss.js';

//= ===================================================================================================
class NavigBand extends React.Component {
  constructor(props) {
    super(props);
    this.handleDragEnter = this.handleDragEnter.bind(this);
    this.handleDropLocal = this.handleDropLocal.bind(this);
    this.handleOnClick = this.handleOnClick.bind(this);
    this.handleOver = this.handleOver.bind(this);
    this.handleOverBand = this.handleOverBand.bind(this);
    this.handleDragLeave = this.handleDragLeave.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.state = {
      dragInto: '',
    };
  }

  onDragOver(event, key) {
    event?.preventDefault();
    if (
      key !== this.state.dragInto &&
      this.props.level === this.props.dragged_node_origin_panel
    ) {
      this.setState({ dragInto: key });
      this.props.setNodeDraggedOver(key);
    }
  }

  //------------------------------------------------------------------------------------------------
  handleDragEnter(key) {
    if (
      key !== this.state.dragInto &&
      this.props.level === this.props.dragged_node_origin_panel
    ) {
      this.setState({ dragInto: key });
      this.props.setNodeDraggedOver(key);
    }
  }

  //------------------------------------------------------------------------------------------------
  handleDragLeave(key) {
    if (this.state.dragInto !== '') {
      this.setState({ dragInto: '' });
    }
  }

  //------------------------------------------------------------------------------------------------
  handleDropLocal(key) {
    this.setState({ dragInto: '' });
    this.props.setNodeDraggedOver(null);
    if (this.props.level === this.props.dragged_node_origin_panel) {
      this.props.onDrop(key);
    }
  }

  //------------------------------------------------------------------------------------------------
  handleOnClick(key) {
    this.props.selectObject(null);
    this.props.closeObjectDetails();
  }

  //------------------------------------------------------------------------------------------------
  handleOver(e) {
    if (this.props.activePanel !== this.props.level) {
      this.props.setActivePanel(this.props.level);
    }
  }

  //------------------------------------------------------------------------------------------------
  handleOverBand(key) {
    if (
      this.props.activeBand !== key &&
      this.props.level === this.props.dragged_node_origin_panel
    ) {
      this.props.setActiveBand(this.props.level, key);
    }
  }

  getHexColor(color) {
    let trgColor = color;
    try {
      if (trgColor?.includes('rgb')) {
        trgColor = color(trgColor).formatHex();
      }
    } catch (e) {}
    return color;
  }

  removeDuplicates(array) {
    return array.filter(function (item, pos, self) {
      return self.indexOf(item) === pos;
    }); // let's remove duplicates
  }

  //------------------------------------------------------------------------------------------------
  render() {
    let arrayClassifTrg = null;
    let bandWidth = 100;

    const {
      fieldType,
      scalerXBand,
      pal,
      level,
      bandValues,
      availableSpace,
      zoom,
      colorBandFct, // fct
      arrayClassif,
      options,
    } = this.props;

    const { dragInto } = this.state;

    if (fieldType === 'date') {
      let ticksMode = getDateTicksModeFromOptions(options);

      arrayClassifTrg = getDateTicks(
        availableSpace,
        80,
        scalerXBand,
        ticksMode
      );
      if (arrayClassifTrg.length > 1)
        bandWidth = availableSpace / arrayClassifTrg.length;
    } else {
      arrayClassifTrg = arrayClassif;
      if (arrayClassifTrg && scalerXBand) {
        arrayClassifTrg = this.removeDuplicates(arrayClassifTrg);
        bandWidth = (scalerXBand.step() / 100) * availableSpace;
      }
    }
    return (
      <NavigBandContainer className="step5" onMouseOver={this.handleOver}>
        {arrayClassifTrg?.length > 1 &&
          arrayClassifTrg.map((item, ind) => {
            let trgColor = this.getHexColor(colorBandFct(item));
            return (
              <RenderStyledHeader
                showBorder={zoom >= 80}
                colorBand={trgColor}
                width={bandWidth}
                dragInto={
                  dragInto === item && item !== undefined && item !== ''
                }
                pal={pal}
                posX={(scalerXBand(item) / 100) * availableSpace}
                key={`headerBand${ind}`}
                zIndexPos={ind}
                onMouseOver={this.handleOverBand}
                onMouseLeave={this.handleOverBand}
                onDragEnter={this.handleDragEnter}
                onDragLeave={this.handleDragLeave}
                onDrop={this.handleDropLocal}
                onDragOver={this.onDragOver}
                onClick={this.handleOnClick}
                item={item}
                level={level}
                bandValues={bandValues}
              />
            );
          })}
      </NavigBandContainer>
    );
  }
}
//------------------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------------------------
function mapStateToProps(state, ownProps) {
  return {
    activePanel: state.objects.activePanel,
    dragged_node_origin_panel: state.objects.dragged_node_origin_panel,
    pal: currentPaletteActiveSelector(state),
    childsCountByType: state.objects.childsCountByType[ownProps.level],
    zoom: zoomSelector(state),
  };
}
//--------------------------------------------------------------------------------------------------------
const mapDispatchToProps = (dispatch) => ({
  setSelectedObjFormData: (objFormData) =>
    dispatch(ObjectActionCreators.setSelectedObjFormData(objFormData)),
  setActiveBand: (level, band) =>
    dispatch(ObjectActionCreators.setActiveBand(level, band)),
  selectObject: (objId) => dispatch(ObjectActionCreators.selectObject(objId)),
  closeObjectDetails: (objId) => dispatch(closeObjectDetails(objId)),
  setNodeDraggedOver: (objId) => dispatch(setNodeDraggedOver(objId)),
  setActivePanel: (level) =>
    dispatch(ObjectActionCreators.setActivePanel(level)),
  filter_set_level: (level, value) =>
    dispatch(ObjectActionCreators.filter_set_level(level, value)),
});
//------------------------------------------------------------------------------------------------

const RenderStyledHeader = memo(
  ({
    showBorder,
    colorBand,
    width,
    dragInto,
    pal,
    posX,
    key,
    zIndexPos,
    onMouseOver,
    onMouseLeave,
    onDragEnter,
    onDragLeave,
    onDrop,
    onDragOver,
    onClick,
    item,
    level,
    bandValues,
  }) => {
    const dispatch = useDispatch();
    const buildIndex = () => {
      return zIndexPos + 10 * level;
    };
    const isPerspectivesPanelOpen = useSelector(
      isPerspectivesPanelOpenSelector
    );

    const arrayClassifTablet = useSelector(arrayClassifTabletSelector);
    const positionNodeDrag = useSelector(positionNodeDragSelector);
    const isDragTablet = useSelector(isDragTabletSelector);
    const currentPositionBand = arrayClassifTablet[buildIndex()];
    // const arrayClassif = bandValues[level];
    const arrayClassifWithLevel = bandValues[level];
    var arrayClassif = [...new Set(arrayClassifWithLevel)];

    const positionActive = useRef({ start: 0, end: 0 });
    const isSetBandLevel = useRef(false);

    const isShowColorHighlightClassifTablet =
      positionNodeDrag?.x >= currentPositionBand?.x &&
      positionNodeDrag?.x < currentPositionBand?.x + currentPositionBand?.width;

    positionActive.current.start = arrayClassifTablet[0]?.x;
    positionActive.current.end =
      arrayClassifTablet[arrayClassif?.length - 1]?.x +
      arrayClassifTablet[arrayClassif?.length - 1]?.width;

    const handleOnMouseOver = useCallback(() => {
      onMouseOver(item);
    }, [item]);

    const handleOnMouseLeave = useCallback(() => {
      onMouseLeave('');
    }, []);

    const handleOnDragEnter = useCallback(() => {
      onDragEnter(item);
    }, [item]);

    const handleOnDragLeave = useCallback(() => {
      console.log('handleOnDragLeave');
      onDragLeave(item);
    }, [item]);

    const handleOnDrop = useCallback(() => {
      console.log('handleOnDrop>88', item);
      onDrop(item);
    }, [item]);

    const handleOnDragOver = useCallback(
      (evt) => {
        onDragOver(evt, item);
      },
      [item]
    );

    const boundingEffect = document
      .getElementById(`headerBand_level${level}_${zIndexPos}`)
      ?.getBoundingClientRect();

    useEffect(() => {
      if (!isTablet) return;
      if (isShowColorHighlightClassifTablet) {
        return dispatch(onSetCurrentBandTablet(arrayClassif[zIndexPos]));
      }
    }, [isShowColorHighlightClassifTablet, item]);

    useEffect(() => {
      if (isShowColorHighlightClassifTablet) {
        dispatch(onSetLevelNodeNadBandTablet({ bandLevel: level }));
        isSetBandLevel.current = true;
      }
      if (isSetBandLevel.current && !isShowColorHighlightClassifTablet) {
        dispatch(onSetLevelNodeNadBandTablet({ bandLevel: null }));
        isSetBandLevel.current = false;
      }
    }, [level, isShowColorHighlightClassifTablet, isSetBandLevel.current]);

    useEffect(() => {
      if (boundingEffect?.x) {
        dispatch(onSetClassifTablet(buildIndex(), boundingEffect));
      }
    }, [boundingEffect?.x, zIndexPos, level, isPerspectivesPanelOpen]);

    return (
      <StyledHeader
        id={`headerBand_level${level}_${zIndexPos}`}
        showBorder={showBorder}
        colorBand={colorBand}
        width={width}
        dragInto={
          isTablet && isDragTablet
            ? isShowColorHighlightClassifTablet
            : dragInto
        }
        pal={pal}
        posX={posX}
        key={key}
        zIndexPos={zIndexPos}
        onMouseOver={handleOnMouseOver}
        onMouseLeave={handleOnMouseLeave}
        onDragEnter={handleOnDragEnter}
        // drag in to not valid area
        onDragLeave={handleOnDragLeave}
        // drag in to valid area
        onDrop={handleOnDrop}
        // drag
        onDragOver={handleOnDragOver}
        onClick={onClick}
      />
    );
  }
);
export default memo(connect(mapStateToProps, mapDispatchToProps)(NavigBand));
//------------------------------------------------------------------------------------------------
