import styled from 'styled-components';
import { devices } from '../../../constants/devices';
import { NODE_NAVIG_WIDTH } from '../../../constants/layout';
import { isTablet } from '../../../utils/detectDevices';
//------------------------------------------------------------------------------

export const ImgCollapse = styled.div({
  align: 'center',
  backgroundColor: (props) => '#F5F5F5',
  border: '1px solid #CCC',
  borderBottomLeftRadius: (props) => (props.up ? '50%' : '0%'),
  borderBottomRightRadius: (props) => (props.up ? '0%' : '50%'),
  borderTopLeftRadius: (props) => (props.up ? '50%' : '0%'),
  borderTopRightRadius: (props) => (props.up ? '0%' : '50%'),
  color: (props) => '#413D7A',
  cursor: 'pointer',
  draggable: 'false',
  fontSize: '16px',
  fontWeight: 'bolder',
  height: '20px',
  left: (props) => `${props.left}px`,
  lineHeight: '16px',
  position: 'absolute',
  textAlign: 'center',
  top: (props) => `${props.top - 5}px`,
  userSelect: 'none',
  width: '20px',
  zIndex: -1,
});
//------------------------------------------------------------------------------
export const SVGPanel = styled.svg({
  marginTop: 5,
  pointerEvents: 'none',
  position: 'absolute',
  top: '1px',
  minHeight: (props) => props.height,
});
//------------------------------------------------------------------------------
export const DivNavigPanel = styled.div({
  backgroundColor: 'var(--ds-bg-secondary)',
  height: (props) => `calc(${1 / props.zoom} * (100vh - 50px))`,
  marginLeft: '60px',
  minHeight: 'calc (100% - 40px)',
  overflowX: 'scroll',
  overflowY: 'scroll',
  position: 'relative',
  transform: (props) => `scale(${props.zoom},${props.zoom})`,
  transformOrigin: '0% 0%',
  transition: 'transform 0.3s',
  width: (props) => `calc(${(1 / props.zoom) * 100}% - 30px)`,
  zIndex: 10,
  [devices.tablet]: {
    marginLeft: '25px',
    width: 'calc(100% - 35px)',
    transform: 'none',
    touchAction: 'none',
    height: 'calc(100vh - 40px)',
    overflowX: 'hidden',
    overflowY: 'scroll',
  },
  touchAction: (props) => (props.isResizePane ? 'none' : 'auto'),
  overflow: (props) => (props.isResizePane ? 'hidden' : 'auto'),
});
//------------------------------------------------------------------------------
export const DivNavigPanelContent = styled.div({
  height: (props) => `${props.height}px`,
  minHeight: '100vh',
  opacity: 1,
  overflow: 'visible',
  position: 'absolute',
  width: isTablet ? '10000px' : '100%',
  zIndex: 51,
  [devices.tablet]: {},
});
//------------------------------------------------------------------------------
export const DivTitle = styled.div({
  color: '#545196',
  fontFamily: 'proxima-nova',
  fontSize: '24px',
  fontWeight: 'bold',
  height: '50px',
  lineHeight: '30px',
  padding: '10px',
  paddingLeft: '20px',
});

export const GetDataColumnsContent = styled.div({
  display: 'grid',
  gridTemplate: (props) => `var(--grid-template-${props.level})`,
  backgroundColor: 'var(--ds-bg-base)',
  borderLeft: 'var(--ds-border-intense)',
  height: '100%',
  left: `${NODE_NAVIG_WIDTH - 33}px`,
  opacity: '1',
  overflow: 'hidden',
  padding: '0px',
  paddingLeft: '22px',
  position: 'absolute',
  top: '0px',
  width: (props) => `${props.width}px`,
});

export const ToBadgeContent = styled.div({
  backgroundColor: (props) => props.backgroundColor,
  border: (props) => props.border,
  borderRadius: '8px',
  color: (props) => props.color,
  display: 'inlineBlock',
  fontSize: '11px',
  fontWeight: '600',
  paddingLeft: '6px',
  paddingRight: '6px',
  textAlign: 'center',
});
export const WrapButtonExport = styled.div({
  width: (props) => props.width || '140px',
});

export const WrapIconExport = styled.div({
  padding: 2,
  width: 24,
  height: 24,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const WrapSelectNodes = styled.div({
  backgroundColor: 'var(--ds-bg-base)',
  boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 6,
  padding: '10px 40px',
});

export const SelectedListContainer = styled.div({
  width: 'calc(100% - 300px)',
  height: '100%',
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
});

export const SelectedListContent = styled.div({
  position: 'absolute',
  zIndex: 20,
  bottom: 100,
});

export const WrapItemEmpty = styled.div({
  padding: 20,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 40,
});
export const ColumnsNodeContainer = styled.div({
  width: '100vw',
  display: 'flex',
  flexDirection: 'column',
  // alignItems: 'center',
  rowGap: 20,
  paddingTop: 100,
  paddingBottom: 100,
  minHeight: '100vh',
  paddingLeft: 20,
});

export const NodeContainerMobile = styled.div({
  width: '100%',
  flex: 1,
  height: 15000,
  flexDirection: 'row',
  display: 'flex',
  backgroundColor: 'var(--ds-bg-secondary)',
  scrollSnapType: 'x mandatory',
  overflowX: 'scroll',
  // touchAction: 'auto',
  overflowY: 'hidden',
  scrollBehavior: 'smooth',
});

export const DisableScrollMobileView = styled.div({
  backgroundColor: 'transparent',
  position: 'absolute',
  top: 0,
  opacity: 0.2,
  width: '100%',
  height: '100%',
  zIndex: 100000000,
  touchAction: (props) =>
    `pan-y ${props.isNextColumnNotHaveContent ? 'pan-x' : ''}`,
});
