import * as d3 from 'd3';
import React from 'react';
import { isInteger } from 'lodash';
import store from '../../store/configureStore';
import {
  ProgressBarContainer,
  ProgressBarLabel,
  ProgressBarsContainer,
  ProgressBarsLabel,
  ProgressBarsViewContainer,
  ProgressBarsViewValue,
  ProgressBarsViewValuePerc,
  ProgressViewContainer,
  ProgressViewValue,
  ProgressViewValueInPerc,
  StyledImg,
  StyledImgContainer,
} from './UtilsCSS.js';
import { abbreviateName } from './TextUtils';
import { parseJson } from '../../utils/parseJson.js';

export const IMAGE_FORMAT = ['jpg', 'jpeg', 'png', 'bmp', 'svg', 'webp'];
export const BASIC_FIELD = [
  'area',
  'text',
  'number',
  'date',
  'formula',
  'file',
  'location',
  'address',
  'list',
];

//-----------------------------------------------------------------------------------------
export function isFieldBasic(type) {
  return BASIC_FIELD.includes(type);
}

export const getProgressBar = (
  valueInPerc,
  label,
  colorOrig,
  unit = '',
  fontSize = 11
) => {
  let color = '#EEEEEE';
  try {
    color = colorOrig;
  } catch (e) {}

  return (
    <>
      <ProgressBarContainer>
        {label && (
          <ProgressBarLabel
            fontSize={fontSize}
            color={'var(--ds-text-base)'}
            title={label}
          >
            {label}
          </ProgressBarLabel>
        )}
        <ProgressViewContainer minWidth={label ? '40%' : '80%'}>
          <ProgressViewValue
            color={color}
            fontSize={fontSize}
            backgroundColor={color}
            width={valueInPerc}
          />
        </ProgressViewContainer>
        <ProgressViewValueInPerc
          color={'var(--ds-text-base)'}
          fontSize={fontSize}
        >
          {valueInPerc}
          {unit}
        </ProgressViewValueInPerc>
      </ProgressBarContainer>
    </>
  );
};

//-----------------------------------------------------------------------------------------
export const getProgressBars = (
  sum,
  values,
  label,
  arrayColors,
  unit = '',
  fontSize = 11
) => {
  const sumLine = values.reduce((acc, item) => acc + item, 0);
  const sumLinePerc =
    sum > 0 ? `${Math.round((sumLine / sum) * 100)}%` : `${0}%`;
  return (
    <>
      <ProgressBarsContainer>
        <ProgressBarsLabel color={'var(--ds-text-base)'} fontSize={fontSize}>
          {abbreviateName(label || '...')}
        </ProgressBarsLabel>
        <ProgressBarsViewContainer>
          {values.map((elem, ind) => (
            <ProgressBarsViewValue
              color={arrayColors[ind]}
              fontSize={fontSize}
              backgroundColor={arrayColors[ind]}
              width={`${(elem / sum) * 100}%`}
              key={arrayColors[ind]}
            />
          ))}
        </ProgressBarsViewContainer>
        <ProgressBarsViewValuePerc
          fontSize={fontSize}
          color={'var(--ds-text-base)'}
        >
          {sumLinePerc}
          {unit}
        </ProgressBarsViewValuePerc>
      </ProgressBarsContainer>
    </>
  );
};

//-----------------------------------------------------------------------------------------
export function rgbStringToHex(rgb) {
  if (!rgb || !rgb.startsWith('rgb')) return rgb;
  const values = rgb.split('(')[1].split(')')[0]; // contenu de la parenthèse rgb(r,g,b) => "r,g,b"
  const valuesTbl = values.split(','); // tableau de string "r,g,b" => r, g, b
  const valuesAsInt = valuesTbl.map((comp) => parseInt(comp)); // parse to number
  return rgbToHex(...valuesAsInt); // spread table as parameters
}
//-----------------------------------------------------------------------------------------
export function rgbToHex(r, g, b) {
  const res = `#${((1 << 24) + (r << 16) + (g << 8) + b)
    .toString(16)
    .slice(1)}`;
  return res;
}
//-----------------------------------------------------------------------------------------
export function confirmAction(message, callback, args) {
  if (window.confirm(message)) {
    if (args == null) {
      callback();
    } else {
      callback(args);
    }
  }
}
//-----------------------------------------------------------------------------------------
export function getTechNameFromLabel(label) {
  let res = '';
  if (label) {
    res = label.toLowerCase();
    res = res.replace(/[^0-9a-z]/gi, '');
  }
  return res;
}
//-----------------------------------------------------------------------------------------
export function getName(objFormData) {
  let res = '';
  if (objFormData) {
    const nameField = objFormData.find((a) => a.label === 'name');
    if (nameField) res = nameField.defaultValue;
  }
  return res;
}
//-----------------------------------------------------------------------------------------
export function getDateTicksModeFromOptions(options) {
  let ticks = 'auto';
  if (options?.includes('week')) {
    ticks = 'week';
  } else if (options?.includes('month')) {
    ticks = 'month';
  }
  return ticks;
}
//------------
//-----------------------------------------------------------------------------------------
export function getDateTicks(bandW, tickW, scalerXBand, mode = 'week') {
  const nbrTick = bandW / tickW;
  let tickMode;
  let format = '%d/%m';
  switch (mode) {
    case 'week':
      tickMode = d3.timeMonday;

      break;
    case 'month':
      tickMode = d3.timeMonth;
      break;
    case 'auto':
      tickMode = nbrTick;
      break;
    default:
      tickMode = nbrTick;
      break;
  }

  const xAxis = d3
    ?.axisBottom(scalerXBand)
    // ?.ticks(nbrTick)
    ?.ticks(tickMode)
    ?.tickFormat(d3.timeFormat(format));
  try {
    return (
      xAxis
        ?.scale()
        // ?.ticks(nbrTick)
        ?.ticks(tickMode)
        ?.map((item) => new Date(item).getTime())
    );
  } catch (error) {
    console.log(':getDateTicks error', error);
    return [];
  }
}

/**
 * Calcule une plage de temps basée sur des jours, des semaines, des mois ou des années.
 *
 * @param {number} totalUnit - Nombre total d'unités à couvrir (moitié avant, moitié après).
 * @param {number} centralDate - Date centrale avant shift.
 * @param {number} shift - Décalage en unités (jours, semaines, mois ou années).
 * @param {'day' | 'week' | 'month' | 'year'} unit - L'unité de calcul : 'day', 'week', 'month', 'year'.
 * @returns {[Date, Date]} - Une plage de dates contenant la date de début et de fin.
 */
function getTimeRange(totalUnit, centralDate, shift = 0, unit = 'week') {
  // Déterminer la fonction de gestion des unités
  let timeUnit;
  switch (unit) {
    case 'day':
      timeUnit = d3.timeDay; // Gestion des jours
      break;
    case 'week':
      timeUnit = d3.timeMonday; // Gestion des semaines
      break;
    case 'month':
      timeUnit = d3.timeMonth; // Gestion des mois
      break;
    case 'year':
      timeUnit = d3.timeYear; // Gestion des années
      break;
    default:
      throw new Error(`Unité inconnue : ${unit}`); // Gestion d'erreur pour les unités non reconnues
  }

  // Obtenez le début de l'unité courante
  const currentUnitStart = timeUnit(centralDate);

  // Décalez de `shift` unités
  const shiftedUnitStart = timeUnit.offset(currentUnitStart, shift);

  // Divisez la plage totale en deux
  const halfRange = Math.floor(totalUnit / 2);

  // Calculez les dates de début et de fin
  const rangeStart = timeUnit.offset(shiftedUnitStart, -halfRange);
  const rangeEnd = timeUnit.offset(shiftedUnitStart, halfRange + 1); // Inclure la dernière unité complète

  // Retournez la plage
  return [rangeStart, d3.timeDay.offset(rangeEnd, -1)]; // Fin du dernier jour inclus
}

//-----------------------------------------------------------------------------------------
export const getTimeRangeFromValues = (rawArray, mode = 'week', shift) => {
  let array = parseAndOrderNumberArray(rawArray);
  let closestDate = getClosestOrToday(array);
  if (mode === 'month' || mode === 'week') {
    return getTimeRange(12, closestDate, shift, mode);
  }

  let timeDomainStart = new Date();
  let timeDomainEnd = new Date();
  if (array.length) {
    const minVal = parseInt(array[0]);
    const maxVal = parseInt(array[array.length - 1]);
    const spread = maxVal - minVal;
    const margin = spread * 0.2 * 0;
    let shiftInMs = 0;

    timeDomainStart = d3.timeDay.offset(
      new Date(minVal + shiftInMs - margin),
      -0
    );
    timeDomainEnd = d3.timeDay.offset(new Date(maxVal + shiftInMs + margin), 0);
    console.log(`tds:${timeDomainStart}-tde:${timeDomainEnd}`);
  }
  return [timeDomainStart, timeDomainEnd];
};

// Utility: Parse and sanitize array values
const parseAndOrderNumberArray = (rawArray) => {
  return rawArray
    .filter((item) => {
      const num = parseInt(item, 10);
      return !isNaN(num); // Keep only valid numbers
    })
    .map((item) => parseInt(item, 10)) // Convert to integers
    .sort((a, b) => a - b); // Sort in ascending order
};

/**
 * Trouve la date la plus proche du jour actuel dans un tableau de timestamps.
 * Si aucun timestamp n'est présent, renvoie la date actuelle.
 *
 * @param {number[]} timestamps - Tableau de timestamps en millisecondes.
 * @returns {Date} - La date la plus proche ou la date actuelle.
 */
const getClosestOrToday = (timestamps) => {
  if (!timestamps || timestamps.length === 0) {
    return new Date(); // Si le tableau est vide, renvoie la date actuelle
  }

  const now = Date.now(); // Timestamp actuel
  let closestTimestamp = timestamps[0]; // Initialise avec le premier timestamp
  let minDiff = Math.abs(now - closestTimestamp); // Différence initiale avec "now"

  for (const timestamp of timestamps) {
    const diff = Math.abs(now - timestamp); // Calcul de la différence avec "now"
    if (diff < minDiff) {
      minDiff = diff;
      closestTimestamp = timestamp;
    }
  }

  return new Date(closestTimestamp); // Convertit le timestamp le plus proche en Date
};

//-----------------------------------------------------------------------------------------
export function isImageFormat(filename) {
  let res = false;
  if (filename) {
    res = IMAGE_FORMAT.some((format) =>
      filename.toLowerCase().endsWith(format)
    );
  }
  return res;
}
//-----------------------------------------------------------------------------------------
export function isPDF(filename) {
  let res = false;
  if (filename) {
    res = filename.toLowerCase().endsWith('pdf');
  }
  return res;
}

//-----------------------------------------------------------------------------------------
export function getFromDico(dicoNameRef, val, maxsize) {
  let res = val;
  if (dicoNameRef?.[val]) {
    let resTmp = dicoNameRef[val];
    if (maxsize) {
      resTmp = resTmp.substring(0, maxsize);
    }
    if (resTmp && resTmp.length > 0) {
      res = resTmp;
    }
  }
  return res;
}
//-----------------------------------------------------------------------------------------
export function getIconForModule(modKey) {
  const state = store.getState();
  const objectsDefList = state.objects.objectsDefList;
  let res = ``;
  if (objectsDefList && objectsDefList.length > 0) {
    const options = getOptionsForField(objectsDefList, `persp`, `module`);
    res = options.find((elem) => elem.value === modKey)?.icon;
  }
  return res;
}
//-----------------------------------------------------------------------------------------
export function getObjectDefTrg(objectsDefList = [], objTypeName, fldName) {
  let defField = getObjectDefField(objectsDefList, objTypeName, fldName);
  // si c'est un champ indirect on retoure la definition correspondante au cahamp visé
  if (
    defField &&
    defField.type === 'formula' &&
    defField.formulaType === 'parentValue'
  ) {
    defField = getObjectDefField(
      objectsDefList,
      defField.formulaObject,
      defField.formulaField
    );
  }

  return defField;
}

//-----------------------------------------------------------------------------------------
export function getObjectDef(objectsDefList = [], key) {
  return objectsDefList?.find((odf) => odf.key.startsWith(key));
}
//-----------------------------------------------------------------------------------------
export function getObjectTypeId(objectsDefList = [], key) {
  return getObjectDef(objectsDefList, key)?.key;
}
//-----------------------------------------------------------------------------------------
export function getObjectTypeLabel(objectsDefList, objTypeName) {
  return getObjectDef(objectsDefList, objTypeName)?.label;
}
//-------------------------------------------------------------------------------------
export function getObjectTypeLabelOrHome(objectsDefList, type) {
  let res = getObjectTypeLabel(objectsDefList, type);
  if (type.startsWith('ROOT')) res = 'HOME';
  return res;
}
//-----------------------------------------------------------------------------------------
export function getObjectDefField(objectsDefList = [], objTypeName, fldName) {
  return getObjectDef(objectsDefList, objTypeName)?.objFormData?.find(
    (fld) => fld.attrName === fldName
  );
}
export function formatNumber(n) {
  if (isNaN(n)) return n;

  const absN = Math.abs(n);
  const sign = n < 0 ? '-' : '';

  const formatValue = (value, suffix = '') => {
    const num = Math.abs(Number(value)); // Utiliser la valeur absolue ici aussi
    const formatted = num.toFixed(1);
    return (
      sign +
      (formatted.endsWith('.0')
        ? `${formatted.slice(0, -2)}${suffix}`
        : `${formatted}${suffix}`)
    );
  };

  if (absN < 1000) return formatValue(n);
  if (absN < 1000000) return formatValue(n / 1000, 'K');
  return formatValue(n / 1000000, 'M');
}

export function formatMonetary(n) {
  return `${formatNumber(n)}`;
}
//-----------------------------------------------------------------------------------------
export const canCreate = (objectDefinition, userPriv) => {
  let result = false;
  let noRestriction =
    (!objectDefinition.canReadGroups ||
      !objectDefinition.canReadGroups === '') &&
    (!objectDefinition.canWriteGroups ||
      !objectDefinition.canWriteGroups === '') &&
    (!objectDefinition.canCreateGroups ||
      !objectDefinition.canCreateGroups === '');
  if (noRestriction) return true;

  let canCreateGroups = objectDefinition.canCreateGroups.split(',');
  const intersection = canCreateGroups.find((element) =>
    Object.keys(userPriv).includes(element)
  );
  console.log(
    `intersection for ${objectDefinition.key}=${JSON.stringify(intersection)}`
  );
  return intersection !== undefined && intersection !== null;
};
//-----------------------------------------------------------------------------------------
export function getTypeOfField(objectsDefList = [], objTypeName, fldName) {
  return getObjectDefField(objectsDefList, objTypeName, fldName)?.type;
}
//-----------------------------------------------------------------------------------------
export function isLinkedType(objectsDefList, type) {
  return objectsDefList.some((item) => item.key === type);
}
//-----------------------------------------------------------------------------------------
export function isLinkedField(objectsDefList = [], objTypeName, fldName) {
  let typeOfField = getTypeOfField(objectsDefList, objTypeName, fldName);
  return isLinkedType(objectsDefList, typeOfField);
}
//-----------------------------------------------------------------------------------------
export function getRefValuOfField(objectsDefList = [], objTypeName, fldName) {
  return getObjectDefField(objectsDefList, objTypeName, fldName)?.refValue;
}
//-----------------------------------------------------------------------------------------
export function getUnitOfField(objectsDefList = [], objTypeName, fldName) {
  return getObjectDefField(objectsDefList, objTypeName, fldName)?.unit;
}
//-----------------------------------------------------------------------------------------
export function getTabOfField(objectsDefList = [], objTypeName, fldName) {
  return getObjectDefField(objectsDefList, objTypeName, fldName)?.tabName;
}
//-----------------------------------------------------------------------------------------
export function getOptionsForField(objectsDefList = [], objTypeName, fldName) {
  let options = getObjectDefField(
    objectsDefList,
    objTypeName,
    fldName
  )?.options;
  if (options) options = parseJson(options) ?? options;
  return options;
}
//-----------------------------------------------------------------------------------------
export function getListValueForField(
  objectsDefList = [],
  objTypeName,
  fldName
) {
  let options = getObjectDefField(
    objectsDefList,
    objTypeName,
    fldName
  )?.options;
  try {
    if (options) options = parseJson(options)?.map((a) => a.value);
    return options;
  } catch (error) {}
}
//-----------------------------------------------------------------------------------------
export function isNumericFormula(objectsDefList, objTypeName, fldName) {
  let isNumeric = false;
  const fldObj = getObjectDefField(objectsDefList, objTypeName, fldName);
  if (fldObj) {
    isNumeric = fldObj.formulaCat1 == null || fldObj.formulaCat1 === '';
  }
  return isNumeric;
}
//-----------------------------------------------------------------------------------------
export function getLabel(objectsDefList, objTypeName, fldName) {
  return getObjectDefField(objectsDefList, objTypeName, fldName)?.label;
}

//-----------------------------------------------------------------------------------------
export const ImageFromSVG = ({
  onClick,
  icon,
  withBorders = false,
  tooltipText = '',
}) => (
  <StyledImgContainer>
    <div
      className="xtooltip"
      style={{
        color: 'black',
        backgroundColor: 'white',
        position: 'absolute',
        left: '60px',
        top: '3px',
        opacity: 0,
        display: 'none',
      }}
    >
      {tooltipText}
    </div>
    <StyledImg
      withBorders={withBorders}
      src={`/img/icons/${icon}`}
      onClick={onClick}
    ></StyledImg>
  </StyledImgContainer>
);

//-----------------------------------------------------------------------------------------
// The two list are compared to find, which element was added or removed
export const getChangedElem = (arrayA, arrayB) => {
  let bigArray = arrayA;
  let smallArray = arrayB;
  if (arrayA.length < arrayB.length) {
    bigArray = arrayB;
    smallArray = arrayA;
  }
  const res = bigArray.filter((elem) => !smallArray.includes(elem))[0];

  return res;
};

//--------------------------------------------------------------------------------------------
export const removeTagHtml = (value) => {
  const doc = new DOMParser().parseFromString(value, 'text/html');
  const valueCleaned = doc.body.textContent || '';
  return valueCleaned;
};

//--------------------------------------------------------------------------------------------
export const isJSON = (data) => {
  try {
    const testIfJson = JSON.parse(data);
    return typeof testIfJson === 'object';
  } catch {
    return false;
  }
};

export const formatJsonString = (jsonString) => {
  try {
    // Parse la chaîne JSON en objet
    const jsonObject = JSON.parse(jsonString);

    // Reformate l'objet avec une indentation de 2 espaces
    return JSON.stringify(jsonObject, null, 2);
  } catch (error) {
    // En cas d'erreur (par exemple, si ce n'est pas une chaîne JSON valide), on retourne la chaîne originale
    return jsonString;
  }
};
