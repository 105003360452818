import React from 'react';
import { useSelector } from 'react-redux';
import { currentPaletteActiveSelector } from '../../../reducers/themeReducers/themeSelector';
import { formatNumber } from '../../Utils/Utils';
import {
  DataCellCrumbtrailContainer,
  DataCellCrumbtrailItem,
  DataCellCrumbtrailSeparator,
  DataCellNumberContainer,
  DataCellYesNoContainer,
  DataCellNumberValue,
} from './DataCellsCSS';

export const DataCellCrumbtrail = ({ value }) => {
  const valueTrimed = value?.trim();
  const cleanedChain = valueTrimed.endsWith('>')
    ? valueTrimed.slice(0, -1)
    : valueTrimed;
  const crumbElems = cleanedChain?.split('>');

  return (
    <DataCellCrumbtrailContainer>
      {crumbElems?.map((text, index) => (
        <React.Fragment key={index}>
          <DataCellCrumbtrailItem>
            {text?.length > 15 ? `${text.substr(0, 15)}...` : text}
          </DataCellCrumbtrailItem>
          {index < crumbElems.length - 1 && (
            <DataCellCrumbtrailSeparator>&gt;</DataCellCrumbtrailSeparator>
          )}
        </React.Fragment>
      ))}
    </DataCellCrumbtrailContainer>
  );
};

export const DataCellYesNo = ({ color }) => {
  return (
    <DataCellYesNoContainer>
      <svg opacity="1">
        <circle
          cx="10"
          cy="27"
          r="14"
          fill={`var(--ds-bg-secondary)`}
          stroke="#222"
          fillOpacity="0.3"
          strokeOpacity="0.3"
        />
        <circle
          cx="10"
          cy="27"
          r="8"
          fill={color}
          stroke="#333"
          fillOpacity="1"
        />
      </svg>
    </DataCellYesNoContainer>
  );
};

export const DataCellNumber = ({ value, onClick, refValue, unit = '' }) => {
  return (
    <DataCellNumberContainer
      onMouseUp={(e) => {
        e.stopPropagation();
      }}
      onClick={onClick}
    >
      <DataCellNumberValue>
        {formatNumber(value) || ''}
        {unit}
      </DataCellNumberValue>
      <div style={{ width: '20px', height: '20px' }}>
        <svg opacity="1" height="20" width="20">
          <circle
            cx="10"
            cy="10"
            r="10"
            fill={`var(--ds-bg-secondary)`}
            strokeOpacity="0"
            fillOpacity="1"
          />
          <circle
            cx="10"
            cy="10"
            r={Math.min(
              10,
              10 * (parseFloat(value) / parseFloat(refValue)) ** 0.7
            )}
            fill={
              parseFloat(value) <= parseFloat(refValue)
                ? `var(--ds-base)`
                : `red`
            }
            fillOpacity="1"
          />
        </svg>
      </div>
    </DataCellNumberContainer>
  );
};
