import styled from 'styled-components';

export const NavigBandContainer = styled.div({
  border: '0px solid red',
  display: 'flex',
  flexDirection: 'row',
  height: '100%',
  top: '0px',
  width: '100%',
  zIndex: '10000000000',
});

export const StyledHeader = styled.div({
  ':hover': {
    borderLeft: (props) => `0px solid ${props.colorBand}`,
  },
  borderRight: (props) => (props.showBorder ? '1px solid #66666627' : ''),
  backgroundColor: (props) =>
    props.dragInto ? `${props.colorBand ?? '#333333'}11` : 'transparent',
  height: '100%',
  left: (props) => `${12 + props.posX}px`,
  opacity: (props) => (props.dragInto ? 0.6 : 0.5),
  overflow: 'hidden',
  padding: '0px',
  paddingRight: '0px',
  position: 'absolute',
  top: '0px',
  width: (props) => `${props.width}px`,
  zIndex: (props) => (props.dragInto ? 800 : 50),
});
