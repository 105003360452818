import React, { useRef, useEffect } from 'react';
import { LoadingOverlay, Title } from '@mantine/core';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import VerticalButtonBar from '../VerticalButtonBar';
import NavigHeaderBar from '../NavigHeaderBar/NavigHeaderBar';
import NavigTreeResizePaneContainer from '../NavigTreeResizePaneContainer/NavigTreeResizePaneContainer.js';
import { NUM_LEVEL } from '../../../constants/layout.js';
import NavigPanelContent from './NavigPanelContent';
import {
  ColumnsNodeContainer,
  DisableScrollMobileView,
  DivNavigPanel,
  NodeContainerMobile,
  SVGPanel,
  WrapItemEmpty,
} from './NavigTreeCSS';
import SelectedList from './SelectedList';
import NavigTreeColumns from './NavigTreeColums.js';
import { buildNodes } from './nodeBuilderUtils.js';
import { initializeStatsMap } from './statsUtils';

const NavigTree = ({
  graphHeight,
  isMobile,
  isResizePane,
  handlers,
  treeNodes,
  svgRef,
  scalesConfig,
  displayMode,
  handleDrop,
  zoom,
  isLoadingTree,
  autoVertPosMS,
  onUpdateNodeHeight,
  perspectiveId,
  panels,
  handleScroll,
}) => {
  const { t } = useTranslation();
  const scrollRefY = useRef(null);
  const dataMap = initializeStatsMap();

  // Scroll handling
  useEffect(() => {
    const scrollContainer = document.getElementById('snap_scroll_container');
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handlers.handleScroll);
    }
    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', handlers.handleScroll);
      }
    };
  }, [handlers]);

  useEffect(() => {
    let timeoutId;
    const handleScrollY = () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        if (scrollRefY.current) {
          handleScroll(scrollRefY.current.scrollTop);
        }
      }, +autoVertPosMS);
    };

    if (scrollRefY.current) {
      scrollRefY.current.addEventListener('scroll', handleScrollY);
    }
    return () => {
      if (scrollRefY.current) {
        scrollRefY.current.removeEventListener('scroll', handleScrollY);
      }
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [handleScroll, autoVertPosMS]);

  // Scroll to top when perspective changes
  useEffect(() => {
    if (scrollRefY.current) {
      scrollRefY.current.scrollTop = 0;
    }
  }, [perspectiveId]);

  const update = (nodes) => {
    return buildNodes({
      nodes,
      dataMap,
      myNodeColorScale: scalesConfig.colorScales,
      displayMode,
      isMobile,
      bands_config: panels,
      arrayClassifBy: scalesConfig.arrayClassifBy,
      eventHandlers: handlers,
      updateNodeHeight: onUpdateNodeHeight,
      handleDrop,
    });
  };

  const width =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  const nodesByLevel = Array.from({ length: NUM_LEVEL }, (_, index) =>
    treeNodes.filter((a) => a.depth - 1 === index)
  );

  if (isMobile) {
    const height =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;

    return (
      <div
        id="test"
        style={{ overflowY: 'scroll', touchAction: 'pan-y', height }}
      >
        <LoadingOverlay
          visible={isLoadingTree[0]} // assuming first level loading state
          overlayBlur={2}
          transitionDuration={500}
          overlayOpacity={0.3}
          overlayColor="#c5c5c5"
          zIndex={6000}
        />
        <NodeContainerMobile id="snap_scroll_container">
          {nodesByLevel.map((nodes, index) => {
            const isNextColumnEmpty = isEmpty(nodes);
            return (
              <div
                key={index}
                style={{
                  scrollSnapAlign: 'start',
                  zIndex: 10,
                  touchAction: 'pan-y pan-x',
                  position: 'relative',
                }}
              >
                <DisableScrollMobileView
                  id="disable_scroll_mobile"
                  isNextColumnNotHaveContent={isNextColumnEmpty}
                />
                <ColumnsNodeContainer>
                  {isNextColumnEmpty && (
                    <WrapItemEmpty>
                      <Title
                        style={{
                          color: 'var(--ds-text-secondary)',
                          textAlign: 'center',
                        }}
                        order={6}
                      >
                        {t('display.no_data')}
                      </Title>
                    </WrapItemEmpty>
                  )}
                  {update(nodes, 'default', index)}
                </ColumnsNodeContainer>
              </div>
            );
          })}
        </NodeContainerMobile>
      </div>
    );
  }

  return (
    <>
      <SelectedList />
      <VerticalButtonBar />
      <DivNavigPanel
        onScroll={handlers.handleOnScroll}
        zoom={zoom}
        id="divNavigPanel_handle_scroll_y"
        ref={scrollRefY}
        widthScreen={width}
        isResizePane={isResizePane}
      >
        <NavigHeaderBar
          scalesConfig={scalesConfig}
          handleDrop={handleDrop}
          dataMap={dataMap}
          nodes={treeNodes}
        />
        <NavigPanelContent height={Math.max(graphHeight, window.innerHeight)}>
          <NavigTreeResizePaneContainer>
            <NavigTreeColumns
              scalesConfig={scalesConfig}
              nodes={treeNodes}
              update={update}
              handleDrop={handleDrop}
            />
          </NavigTreeResizePaneContainer>
          <SVGPanel id="svgPanel" width="500%" height={graphHeight}>
            <svg ref={svgRef} />
          </SVGPanel>
        </NavigPanelContent>
      </DivNavigPanel>
    </>
  );
};

export default React.memo(NavigTree);
