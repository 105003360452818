import React from 'react';
import ReactDOM from 'react-dom';
import {
  APortalRestrictContainer,
  APortalRestrictContent,
  APortalRestrictList,
  SVGStyledAPortalRestrict,
} from './APortalRestrictCSS';

const ICONSIZE = 12;

class APortalRestrict extends React.PureComponent {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
    this.el.style.width = 'auto';

    this.el.style.position = 'absolute';

    this.el.style.top = 0;
    this.el.style.left = 0;
    this.el.style.height = 'auto';
    this.el.style.zIndex = 20000000000000000;
    this.handleAddFieldRestriction = this.handleAddFieldRestriction.bind(this);
    this.onHandleAddFieldRestrictionLte =
      this.onHandleAddFieldRestrictionLte.bind(this);
    this.onHandleAddFieldRestrictionIs =
      this.onHandleAddFieldRestrictionIs.bind(this);
    this.onHandleAddFieldRestrictionGte =
      this.onHandleAddFieldRestrictionGte.bind(this);
  }

  componentDidMount() {
    document.body.appendChild(this.el);
    const id = this.props.idParent;

    const elemParent = document.getElementById(id);
    const dimensions = elemParent.getBoundingClientRect();
    this.el.style.left = `${dimensions.left - ICONSIZE / 2}px`;
    this.el.style.top = `${dimensions.bottom - 10}px`;
    this.el.style.width = dimensions.width + ICONSIZE;
    this.el.style.height = '30px';
  }

  componentWillUnmount() {
    try {
      document.body.removeChild(this.el);
    } catch (e) {
      console.log(e);
    }
  }

  handleAddFieldRestriction(operatorParam) {
    const operator = `${this.props.fieldType}_${operatorParam}`; // e.g. text_gte ou number_is ou number_lte
    this.props.addFieldRestriction(
      this.props.type,
      this.props.item,
      this.props.objectType,
      this.props.fieldType,
      operator,
      this.props.level
    );
  }

  onHandleAddFieldRestrictionLte() {
    this.handleAddFieldRestriction('lte');
  }

  onHandleAddFieldRestrictionIs() {
    this.handleAddFieldRestriction('is');
  }

  onHandleAddFieldRestrictionGte() {
    this.handleAddFieldRestriction('gte');
  }

  render() {
    const icons = (
      <APortalRestrictContainer>
        <APortalRestrictContent>
          <SVGStyledAPortalRestrict
            onClick={this.onHandleAddFieldRestrictionLte}
          >
            <polygon points="6,0 6,6 0,3" />
          </SVGStyledAPortalRestrict>
          <SVGStyledAPortalRestrict
            onClick={this.onHandleAddFieldRestrictionIs}
          >
            <polygon points="0,6 6,6 3,0" />
          </SVGStyledAPortalRestrict>
          <SVGStyledAPortalRestrict
            onClick={this.onHandleAddFieldRestrictionGte}
          >
            <polygon points="0,0 0,6 6,3" />
          </SVGStyledAPortalRestrict>
        </APortalRestrictContent>
        <APortalRestrictList>{this.props.children}</APortalRestrictList>
      </APortalRestrictContainer>
    );

    return ReactDOM.createPortal(icons, this.el);
  }
}

export default APortalRestrict;
