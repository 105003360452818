import * as d3 from 'd3';
import { cloneDeep, isArray } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { svgDiagramSelector } from '../../reducers/svgDiagramReducer/selectors';
import { makeDraggable } from '../../utils/DragDropTouchTablet';
import { buildCursorSvgPadWithAction } from '../../utils/buildCursorSvgPadWithAction';
import {
  calculatePositionSnapClosedPolyline,
  generatePointsPolylineWhenMovePoint,
  generatePolyline,
  getPolylineBackground,
  getPositionDragSnapSvg,
} from '../../utils/generatePolyline';
import { withSvgTransform } from './withSvgTransform';
import { DRAW_POINT_COLOR, DRAW_POINT_TICK } from '../../constants/drawing';
import {
  useDiagramView,
  useElementOperations,
} from '../../reducers/svgDiagramReducer/hooks';

const SVGPolylineRenderer = ({
  lineData,
  idLineDrawing,
  isDisableAction,
  setSvgFocus,
  prefix_key,
  setActionDragSvg,
  onUpdatePointsOnMove,
  transformClientToSVG,
  svgZoomLevel,
}) => {
  const { selectedElementKey, currentAction } = useSelector(svgDiagramSelector);
  const { gridSize, isGridVisible, resetView, toggleGrid } = useDiagramView();
  const { deleteSelected, updateElement } = useElementOperations();
  const svgRef = useRef(null);
  const svgVertexRef = useRef(null);
  const isDragging = useRef(false);

  const [positionSvg, setPositionSvg] = useState({ x: 0, y: 0 });

  const [dataPoint, setDataPoint] = useState([]);
  useEffect(() => {
    setDataPoint(lineData?.points);
  }, [lineData?.points]);

  const startPosition = useRef({ x: 0, y: 0 });

  const handleSelectSvg = () => {
    if (isDisableAction) return;
    if (currentAction === 'delete') {
      return deleteSelected(lineData?.key);
    }
    if (typeof setSvgFocus === 'function') setSvgFocus(lineData?.key);
  };

  const extractStartCoordinates = () => {
    const svg = d3.select(svgRef.current);

    const polyline = svg.select('polyline');

    // Get the points attribute as a string and split it into an array
    const pointsString = polyline.attr('points');
    return findStartPositionPolyline(pointsString);
  };

  const idSvg = prefix_key
    ? `${prefix_key + lineData?.key}`
    : `${lineData?.key}`;
  const clientRect = document.getElementById(idSvg)?.getBoundingClientRect();
  const dataType = getTypeDataStructPolyline(dataPoint);
  useEffect(() => {
    const svg = d3.select(svgRef.current);
    svg.selectAll('*').remove();
    d3.select(svgVertexRef.current).selectAll('*').remove();

    // Create the polyline

    const pointsStr = generatePolylineByData(dataType, dataPoint);
    svg
      .append('polyline')
      .attr('points', pointsStr)
      .attr('stroke', lineData?.color)
      .attr('fill', getPolylineBackground(dataPoint, lineData?.backgroundColor))
      .attr('stroke-width', lineData.tick)
      .on('click', handleSelectSvg);

    // Create vertices as circles
    if (idLineDrawing === lineData?.key) {
      showVertexPoints();
    }
  }, [lineData, idLineDrawing, currentAction, dataPoint, dataType]);

  useEffect(() => {
    if (
      (!selectedElementKey && !idLineDrawing) ||
      selectedElementKey !== lineData?.key
    ) {
      d3.select(svgRef.current).selectAll('.vertex').remove();
      d3.select(svgVertexRef.current).selectAll('*').remove();
    }
  }, [selectedElementKey, idLineDrawing, lineData?.key]);

  const showVertexPoints = (newDataPoints) => {
    if (lineData.type === 'line') return;
    d3.select(svgVertexRef.current)
      .selectAll('.vertex')
      .data(newDataPoints || dataPoint)
      .enter()
      .append('circle')
      .attr('cx', (d) => d.x)
      .attr('cy', (d) => d.y)
      .attr('r', DRAW_POINT_TICK)
      .attr('fill', DRAW_POINT_COLOR)
      .attr('class', 'vertex');
  };

  // HANDLE  MOVE POINT
  useEffect(() => {
    const svg = d3.select(svgVertexRef.current);
    if (lineData?.key === selectedElementKey) {
      showVertexPoints();

      // handle move point
      var dragHandler = d3
        .drag()
        .on('start', function () {
          startPosition.current = { x: d3.event.x, y: d3.event.y };
        })
        .on('drag', function (e) {
          const newDataPoints = generatePointsPolylineWhenMovePoint({
            dataPoint,
            isGridVisible,
            gridSize,
          });

          if (!isGridVisible) {
            calculatePositionSnapClosedPolyline(dataPoint, newDataPoints);
          }

          showVertexPoints(newDataPoints);
          setPositionSvg(extractStartCoordinates());
          setDataPoint(newDataPoints);
        })
        .on('end', function () {
          const newDataPoints = generatePointsPolylineWhenMovePoint({
            dataPoint,
            isGridVisible,
            gridSize,
          });
          if (!isGridVisible) {
            calculatePositionSnapClosedPolyline(dataPoint, newDataPoints);
          }
          onUpdatePointsOnMove(lineData?.key, newDataPoints);
        });

      dragHandler(svg.selectAll('.vertex'));
    }
  });

  useEffect(() => {
    if (selectedElementKey === lineData?.key) {
      setPositionSvg(extractStartCoordinates());
    }
  }, [selectedElementKey, lineData?.key]);

  const onMakeDraggable = (id) => {
    makeDraggable(
      document.getElementById(id),
      ({ x, y }) => {
        startPosition.current = transformClientToSVG(x, y);
        isDragging.current = true;
        if (currentAction === 'delete') return;
        setActionDragSvg('move');
      },
      (position) => {
        // drag end
        if (!isDragging.current) return;
        const transformedPosition = transformClientToSVG(
          position.x,
          position.y
        );
        const newDataPoints = calculateDataDragSvg(
          transformedPosition,
          startPosition,
          dataPoint,
          gridSize,
          isGridVisible
        );

        updateElement(lineData?.key, {
          points: newDataPoints,
        });
        setTimeout(() => {
          isDragging.current = false;
          setDataPoint(newDataPoints); // Mise à jour de l'état local après les mises à jour Redux
        }, 0);
      },
      (position) => {
        // drag move
        if (!isDragging.current) return;
        const transformedPosition = transformClientToSVG(
          position.x,
          position.y
        );
        const svg = d3.select(svgRef.current);
        const polyline = svg.select('polyline');
        d3.select(svgVertexRef.current).selectAll('*').remove();

        const newDataPoints = calculateDataDragSvg(
          transformedPosition,
          startPosition,
          dataPoint,
          gridSize,
          isGridVisible
        );
        showVertexPoints(newDataPoints);

        const pointsStr = generatePolylineByData(dataType, newDataPoints);

        polyline.attr('points', pointsStr);

        svg.selectAll('circle').remove();

        setPositionSvg(extractStartCoordinates());
        updateElement(lineData?.key, {
          points: newDataPoints,
        });
      },
      (e) => {},
      30
    );
  };
  useEffect(() => {
    if (lineData?.key !== selectedElementKey) return;
    onMakeDraggable(`wrap-${idSvg}`);
  });

  return (
    <>
      <svg
        style={{
          cursor: buildCursorSvgPadWithAction(
            isDisableAction,
            currentAction,
            lineData?.link
          ),
        }}
        ref={svgRef}
        id={idSvg}
      ></svg>
      {lineData?.key === selectedElementKey && (
        <>
          <rect
            // data-html2canvas-ignore="true"
            className="data-html-to-image-ignore"
            style={{ cursor: 'pointer' }}
            x={positionSvg?.x - 10}
            y={positionSvg?.y - 10}
            width={clientRect?.width / svgZoomLevel + 20}
            height={clientRect?.height / svgZoomLevel + 20}
            fill="transparent"
            stroke="#D8D8D8"
            strokeWidth="1"
            strokeDasharray="8 4"
          />

          <rect
            // data-html2canvas-ignore="true"
            className="data-html-to-image-ignore"
            style={{ cursor: 'pointer' }}
            id={`wrap-${idSvg}`}
            x={positionSvg?.x}
            y={positionSvg?.y}
            width={clientRect?.width / svgZoomLevel}
            height={clientRect?.height / svgZoomLevel}
            fill="transparent"
            stroke="transparent"
            strokeWidth="2"
            strokeDasharray="8 4"
          />
        </>
      )}
      <svg ref={svgVertexRef} />
    </>
  );
};

export default withSvgTransform(SVGPolylineRenderer);

const calculateDataDragSvg = (
  position,
  startPosition,
  points,
  gridSize,
  isGridVisible
) => {
  const positionMove = { x: 0, y: 0 };
  positionMove.x = position.x - startPosition.current.x;
  positionMove.y = position.y - startPosition.current.y;
  const positionSvg = isGridVisible
    ? getPositionDragSnapSvg(positionMove, gridSize)
    : positionMove;
  const startPosSvgView = getStartPositionSvgPolyline(points);

  let newData = [];
  if (typeof points[0] === 'object') {
    // new data structure [{x:100, y:100},{x:200, y:200}]
    newData = cloneDeep(points);
    newData.map((point) => {
      if (typeof point === 'object') {
        point.x += positionSvg.x;
        point.y += positionSvg.y;
        point.id = point?.id;
      }
    });

    return isGridVisible
      ? prepareDataWithSnapPosition(newData, gridSize, startPosSvgView)
      : newData;
  }
  // old data structure [100, 100, 200, 200]

  let ind = 0;
  while (ind < points.length) {
    const x = points[ind++] + positionSvg.x;
    const y = points[ind++] + positionSvg.y;
    newData.push(x, y);
  }
  return newData;
};

const generatePolylineByData = (type, data) => {
  if (type === 'new') {
    return data.map((d) => [d?.x, d?.y].join(',')).join(' ');
  }
  return generatePolyline(data);
};

const getTypeDataStructPolyline = (data) => {
  if (typeof data[0] === 'object') return 'new';
  return 'old';
};
const findStartPositionPolyline = (pointsString) => {
  const pointsArray = pointsString.split(' ');

  let [minX, minY] = pointsArray[0].split(',').map(parseFloat);

  pointsArray.map((position) => {
    const [positionX, positionY] = position.split(',').map(parseFloat);
    if (minX > positionX) {
      minX = positionX;
    }
    if (minY > positionY) {
      minY = positionY;
    }
  });
  return { x: minX, y: minY };
};

const prepareDataWithSnapPosition = (position, gridSize, startPos) => {
  const newPosition = cloneDeep(position);

  const positionSvgSnap = getPositionDragSnapSvg(startPos, gridSize);
  const roundX = positionSvgSnap.x - startPos.x;

  const roundY = positionSvgSnap.y - startPos.y;

  newPosition.map((point) => {
    point.x += roundX;
    point.y += roundY;
  });

  return newPosition;
};

export const getStartPositionSvgPolyline = (points = []) => {
  if (!isArray(points) || points?.length === 0) return;
  let { x, y } = points[0];

  points.map((point) => {
    const { x: posX, y: posY } = point;
    if (x > posX) {
      x = posX;
    }
    if (y > posY) {
      y = posY;
    }
  });
  return { x, y };
};
