import styled from 'styled-components';

export const SliderContainer = styled.div({
  position: 'relative',
  left: (props) => `${props.width + 160}px`,
  width: '100px',
  top: '10px',
  zIndex: '70000',
  fontSize: '12px',
  fontWeight: 'normal',
  color: 'var(--ds-text-secondary)',
  border: '1px solid var(--ds-border-base)',
  borderRadius: '5px',
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center', // alignement vertical des éléments du container
});

export const SliderArrow = styled.div({
  borderRight: '1px solid var(--ds-border-base)',
  display: 'flex',
  height: '25px',
  width: '30px', // largeur définie pour contrôler l'espace
  justifyContent: 'center', // centre horizontalement le texte
  alignItems: 'center', // centre verticalement le texte
  '&:hover': {
    cursor: 'pointer',
    color: 'var(--ds-icon-active)',
    fontWeight: 'bolder',
  },
  '&:last-child': {
    borderRight: 'none',
  },
});
