import styled from 'styled-components';

//--------------------------------------------------------------------------------------------------------
export const ContainerDiv = styled.div({
  alignContent: 'center',
  padding: '0px',
  textAlign: 'center',
  width: '100%',
});

//--------------------------------------------------------------------------------------------------------
export const BigNum = styled.div({
  color: (props) => props.color ?? '#7A57BF',
  fontSize: '17px',
  fontWeight: '600',
  lineHeight: '23px',
  height: '100%',
});
