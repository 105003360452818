import { EditorContent } from '@tiptap/react';
import styled, { createGlobalStyle } from 'styled-components';

export const StyledEditorContainer = styled.div({
  position: (props) => (props.isFullScreen ? 'fixed' : ''),
  top: (props) => (props.isFullScreen ? '0px' : ''),
  left: (props) => (props.isFullScreen ? '0px' : ''),
  width: (props) => (props.isFullScreen ? '100vw' : ''),
  height: (props) => (props.isFullScreen ? '100vh' : ''),
  backgroundColor: (props) =>
    props.isFullScreen ? 'var(--ds-bg-secondary)' : '',
  zIndex: (props) => (props.isFullScreen ? '9999' : ''),
  display: (props) => (props.isFullScreen ? 'flex' : ''),
  flexDirection: (props) => (props.isFullScreen ? 'column' : ''),
  overflow: (props) => (props.isFullScreen ? 'auto' : ''),
  alignItems: 'center',
  flex: 1, // Ajoutez cette ligne
  minHeight: (props) => (props.isFullScreen ? '100vh' : '220px'),
});

export const StyledEditorContent = styled(EditorContent)({
  maxWidth: '1024px',
  width: 'calc(100% - 10px)',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  backgroundColor: 'var(--ds-bg-base)',
  bottom: (props) => (props.isFullScreen ? 0 : ''),
  paddingLeft: '8px',
  paddingRight: '8px',
  border: (props) =>
    props.isFullScreen ? '1px solid var(--ds-border-base)' : '',
  '& .ProseMirror': {
    marginTop: '10px',
    minHeight: (props) => (props.isFullScreen ? 'calc(100% - 100px)' : '200px'),
    height: '100%', // Ajoutez cette ligne
    padding: '10px',
    border: '1px solid var(--ds-border-base)',
    borderRadius: '4px',
  },
});
