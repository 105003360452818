import {
  Button,
  ColorInput,
  Modal,
  Select,
  Space,
  Slider,
  Textarea,
  NumberInput,
} from '@mantine/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { colorsSvgDrawing } from '../../../../constants/colors';
import { modalStyle } from '../../../../constants/commonStyles';

export default function EditConnectorBox({
  visible,
  onHideModal,
  currentElementSelect,
  allElements,
  onUpdateConnector,
}) {
  const { t } = useTranslation();
  const [connectorInfo, setConnectorInfo] = useState(null);
  const [availableElements, setAvailableElements] = useState([]);
  const [curveAmount, setCurveAmount] = useState(0);
  const [connectorText, setConnectorText] = useState('');

  useEffect(() => {
    if (currentElementSelect) {
      setConnectorInfo(currentElementSelect);
      setConnectorText(currentElementSelect.text || '');

      // Set initial curve amount based on current connector path
      setCurveAmount(currentElementSelect.curveAmount || 20);

      // Filter out the current connector itself from available elements
      const filteredElements = allElements
        .filter(
          (elem) =>
            elem.key !== currentElementSelect.key &&
            [
              'text',
              'svg',
              'image',
              'polyline',
              'line',
              'arrow-line',
              'bidirectional-line',
            ].includes(elem.type)
        )
        .map((elem) => ({
          value: elem.key,
          label: getElementLabel(elem),
        }));

      setAvailableElements(filteredElements);
    }
  }, [currentElementSelect, allElements]);

  const getElementLabel = (elem) => {
    if (elem.type === 'text') {
      return `Text: "${elem.points.substring(0, 20)}${
        elem.points.length > 20 ? '...' : ''
      }"`;
    }
    return `${elem.type} (${elem.key})`;
  };

  const onUpdateValue = (key, value) => {
    setConnectorInfo({ ...connectorInfo, [key]: value });
  };

  const handleSubmit = useCallback(() => {
    onUpdateConnector({
      ...connectorInfo,
      curveAmount,
      text: connectorText,
    });
    onHideModal();
  }, [connectorInfo, curveAmount, connectorText]);

  if (!connectorInfo) return null;

  return (
    <Modal
      styles={modalStyle}
      opened={visible}
      onClose={onHideModal}
      title={t('Edit Connector')}
      centered
      overlayOpacity={0.55}
      overlayBlur={3}
      size={'md'}
    >
      <Select
        label="Source Element"
        placeholder="Select source element"
        data={availableElements}
        value={connectorInfo.sourceId}
        onChange={(value) => onUpdateValue('sourceId', value)}
        searchable
        maxDropdownHeight={300}
      />

      <Space h="md" />

      <Select
        label="Target Element"
        placeholder="Select target element"
        data={availableElements}
        value={connectorInfo.targetId}
        onChange={(value) => onUpdateValue('targetId', value)}
        searchable
        maxDropdownHeight={300}
      />

      <Space h="md" />

      <ColorInput
        value={connectorInfo.color}
        placeholder="Pick color"
        label="Line color"
        disallowInput
        withPicker={false}
        swatches={colorsSvgDrawing}
        onChange={(color) => {
          onUpdateValue('color', color);
        }}
      />

      <Space h="md" />

      <Slider
        label={(value) => `Curve amount: ${value}`}
        value={curveAmount}
        onChange={setCurveAmount}
        min={0}
        max={100}

      />

      <Space h="md" />

      <Space h="xl" />

      <Textarea
        label="Connector Text"
        placeholder="Enter text for connector"
        value={connectorText}
        onChange={(event) => setConnectorText(event.currentTarget.value)}
      />
      <Space h="md" />

      <ColorInput
        value={connectorInfo?.textColor || connectorInfo?.color}
        placeholder="Pick text color"
        label="Text color"
        disallowInput
        withPicker={false}
        swatches={colorsSvgDrawing}
        onChange={(color) => {
          onUpdateValue('textColor', color);
        }}
      />

      <Space h="md" />

      <NumberInput
        label="Text Size"
        min={10}
        max={36}
        value={connectorInfo?.textSize || 14}
        onChange={(value) => onUpdateValue('textSize', value)}
      />
      <Button fullWidth onClick={handleSubmit}>
        {t('update')}
      </Button>
    </Modal>
  );
}
