import { Modal, useMantineTheme } from '@mantine/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
// import { dataLibraryIcon } from '../../constants/fakeDataLibraryIcons';
import { produce } from 'immer';
import { cloneDeep } from 'lodash';
import ObjectApi from '../../api/ObjectApi';
import {
  changeModeLibrary,
  changeStatusShowModalIconsLibrary,
  getDataIcons,
  setCallbackFuncIconsLibrary,
  setCurrentIconSelect,
} from '../../reducers/iconsLibraryReducer/action';
import {
  actionAddResourceLibrarySelector,
  currentIconSelectLibrarySelector,
  iconsLibrarySelector,
  sessionModalLibrarySelector,
  statusShowModalLibrarySelector,
} from '../../reducers/iconsLibraryReducer/iconsLibrarySelector';
import { isDarkModeSelector } from '../../reducers/themeReducers/themeSelector';
import { heightScreen } from '../../utils/windowSize';
import CategoriesLibraryIcons from './CategoriesLibraryIcons';
import CurrentIconSelect from './CurrentIconSelect';
import ListIcons from './ListIcons';
import { ModalLibraryIconContainer } from './ModalLIbraryIconCss';
import { MODE_LIBRARY_ICON } from '../../reducers/iconsLibraryReducer/type';
import { modalStyle } from '../../constants/commonStyles';

const numberItemPerPage = 42;
export default function ModalLIbraryIcon() {
  const theme = useMantineTheme();
  const sliceIcon = useRef(0);
  const refList = useRef();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const statusShowModalLibrary = useSelector(statusShowModalLibrarySelector);

  const dataSessionLibraryIcons = useSelector(sessionModalLibrarySelector);

  const isDarkMode = useSelector(isDarkModeSelector);

  const iconsLibrary = useSelector(iconsLibrarySelector);

  const actionAddResource = useSelector(actionAddResourceLibrarySelector);

  const currentIconSelectLibrary = useSelector(
    currentIconSelectLibrarySelector
  );

  const [dataRenderIcon, setDataRenderIcon] = useState({});
  const [hasMore, setHasMore] = useState(true);
  const [currentSession, setCurrentSession] = useState(null);
  const [currentCategory, setCurrentCategory] = useState(null);

  useEffect(() => {
    setCurrentSession(dataSessionLibraryIcons[0].value);
  }, [dataSessionLibraryIcons]);

  useEffect(() => {
    const savedCategory = sessionStorage.getItem('currentCategory');
    if (savedCategory) {
      setCurrentCategory(JSON.parse(savedCategory));
    }
  }, []);

  const onHideModal = useCallback(() => {
    dispatch(changeStatusShowModalIconsLibrary(false));
  }, []);

  const onChangeCategory = (category) => {
    if (category?.id === currentCategory?.id) return;
    sliceIcon.current = 0;
    setCurrentCategory(category);
    setHasMore(true);
    dispatch(setCurrentIconSelect(null));
    sessionStorage.setItem('currentCategory', JSON.stringify(category));
  };

  const data = currentCategory?.icons;

  const fetchMoreData = useCallback(
    (isReset, _category) => {
      setTimeout(() => {
        const category = _category || currentCategory;

        if (!category) {
          setDataRenderIcon({});
          return;
        }

        const sliced = Object.keys(category?.icons)
          .slice(sliceIcon.current, sliceIcon.current + numberItemPerPage)
          .reduce((result, key) => {
            result[key] = category?.icons[key];
            return result;
          }, {});

        if (isReset) {
          setDataRenderIcon({
            ...sliced,
          });
          refList.current?.scrollIntoView({ behavior: 'smooth' });
        } else {
          setDataRenderIcon({
            ...dataRenderIcon,
            ...sliced,
          });
        }

        sliceIcon.current += numberItemPerPage;
        if (sliceIcon.current >= Object.keys(data).length) {
          setHasMore(false);
        }
      }, 350);
    },
    [dataRenderIcon, sliceIcon?.current, data]
  );

  useEffect(() => {
    fetchMoreData(true, currentCategory);
  }, [currentCategory]);

  useEffect(() => {
    dispatch(getDataIcons());
  }, []);

  const heightContainerIcons = (heightScreen / 100) * 70;

  const updateItemIcons = (icon, type) => {
    if (type === 'delete') {
      const { [icon.key]: _itemDelete, ...iconsAvailable } = dataRenderIcon;
      return setDataRenderIcon(iconsAvailable);
    }
    setDataRenderIcon({ ...dataRenderIcon, ...icon });
  };
  const updateIconSvgDrawing = async (newLine, filepath) => {
    const dataNewIconsRender = produce(dataRenderIcon, (draffState) => {
      draffState[currentIconSelectLibrary.key].elements = newLine;
      draffState[currentIconSelectLibrary.key].icon = filepath;
      draffState[currentIconSelectLibrary.key].file = filepath;
    });

    const newDataUpdate = {
      ...currentIconSelectLibrary,
      icon: filepath,
      file: filepath,
      elements: newLine,
    };
    dispatch(setCurrentIconSelect(newDataUpdate));
    try {
      const { currentSession, currentCategory, ...rest } = newDataUpdate;
      const newData = cloneDeep(iconsLibrary);
      newData[currentSession].categories[currentCategory.parentId].icons[
        currentIconSelectLibrary.key
      ] = {
        ...rest,
        icon: filepath,
        file: filepath,
        elements: newLine,
      };

      await ObjectApi.saveLibrary(JSON.stringify(newData));
    } catch (error) {}

    setDataRenderIcon(dataNewIconsRender);
  };

  return (
    <>
      <Modal
        zIndex={20000000005}
        title={t('assets_library')}
        size="auto"
        centered
        opened={statusShowModalLibrary}
        onClose={() => {
          onHideModal();
          dispatch(setCurrentIconSelect(null));
          setCurrentSession(dataSessionLibraryIcons[0].value);
          dispatch(changeModeLibrary(MODE_LIBRARY_ICON.NONE));
          dispatch(setCallbackFuncIconsLibrary(null));
        }}
        overlayColor={'var(--ds-bg-overlay)'}
        overlayOpacity={0.55}
        overlayBlur={3}
        styles={modalStyle}
      >
        <ModalLibraryIconContainer height={`${heightContainerIcons}px`}>
          <CategoriesLibraryIcons
            currentSession={currentSession}
            setCurrentSession={setCurrentSession}
            currentCategory={currentCategory}
            onChangeCategory={onChangeCategory}
          />
          <ListIcons
            currentCategory={currentCategory}
            currentSession={currentSession}
            data={dataRenderIcon}
            fetchMoreData={fetchMoreData}
            hasMore={hasMore}
            refList={refList}
            updateItemIcons={updateItemIcons}
          />
          {actionAddResource && (
            <CurrentIconSelect
              updateItemIcons={updateItemIcons}
              currentSession={currentSession}
              updateIconSvgDrawing={updateIconSvgDrawing}
            />
          )}
        </ModalLibraryIconContainer>
      </Modal>
    </>
  );
}
