// svgDebugUtils.js
// Utilitaires pour le débogage des diagrammes SVG

/**
 * Crée une grille de débogage SVG pour visualiser les coordonnées
 * @param {number} gridSize - Taille des cellules de la grille
 * @param {number} gridRange - Étendue de la grille (-gridRange à +gridRange)
 * @returns {SVGElement} Élément SVG contenant la grille
 */
export const createDebugGrid = (gridSize = 100, gridRange = 10) => {
  const debugGrid = document.createElementNS('http://www.w3.org/2000/svg', 'g');
  debugGrid.setAttribute('id', 'debug-grid');

  // Lignes horizontales et verticales principales
  const xAxis = document.createElementNS('http://www.w3.org/2000/svg', 'line');
  xAxis.setAttribute('x1', -gridRange * gridSize);
  xAxis.setAttribute('y1', 0);
  xAxis.setAttribute('x2', gridRange * gridSize);
  xAxis.setAttribute('y2', 0);
  xAxis.setAttribute('stroke', 'red');
  xAxis.setAttribute('stroke-width', 2);
  debugGrid.appendChild(xAxis);

  const yAxis = document.createElementNS('http://www.w3.org/2000/svg', 'line');
  yAxis.setAttribute('x1', 0);
  yAxis.setAttribute('y1', -gridRange * gridSize);
  yAxis.setAttribute('x2', 0);
  yAxis.setAttribute('y2', gridRange * gridSize);
  yAxis.setAttribute('stroke', 'red');
  yAxis.setAttribute('stroke-width', 2);
  debugGrid.appendChild(yAxis);

  // Créer les lignes de la grille
  for (let i = -gridRange; i <= gridRange; i++) {
    // Ignorer l'axe central qui est déjà dessiné
    if (i === 0) continue;

    // Ligne horizontale
    const hLine = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'line'
    );
    hLine.setAttribute('x1', -gridRange * gridSize);
    hLine.setAttribute('y1', i * gridSize);
    hLine.setAttribute('x2', gridRange * gridSize);
    hLine.setAttribute('y2', i * gridSize);
    hLine.setAttribute('stroke', '#ccc');
    hLine.setAttribute('stroke-width', 1);
    debugGrid.appendChild(hLine);

    // Ligne verticale
    const vLine = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'line'
    );
    vLine.setAttribute('x1', i * gridSize);
    vLine.setAttribute('y1', -gridRange * gridSize);
    vLine.setAttribute('x2', i * gridSize);
    vLine.setAttribute('y2', gridRange * gridSize);
    vLine.setAttribute('stroke', '#ccc');
    vLine.setAttribute('stroke-width', 1);
    debugGrid.appendChild(vLine);

    // Texte pour les coordonnées X
    const textX = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'text'
    );
    textX.setAttribute('x', i * gridSize);
    textX.setAttribute('y', 20);
    textX.setAttribute('fill', 'blue');
    textX.setAttribute('text-anchor', 'middle');
    textX.setAttribute('font-size', '12px');
    textX.textContent = i * gridSize;
    debugGrid.appendChild(textX);

    // Texte pour les coordonnées Y
    const textY = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'text'
    );
    textY.setAttribute('x', 20);
    textY.setAttribute('y', i * gridSize);
    textY.setAttribute('fill', 'blue');
    textY.setAttribute('text-anchor', 'middle');
    textY.setAttribute('font-size', '12px');
    textY.textContent = i * gridSize;
    debugGrid.appendChild(textY);
  }

  // Ajouter une indication des coordonnées du centre du viewport
  const viewportCenter = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'circle'
  );
  viewportCenter.setAttribute('cx', 0);
  viewportCenter.setAttribute('cy', 0);
  viewportCenter.setAttribute('r', 5);
  viewportCenter.setAttribute('fill', 'purple');
  debugGrid.appendChild(viewportCenter);

  const viewportCenterLabel = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'text'
  );
  viewportCenterLabel.setAttribute('x', 0);
  viewportCenterLabel.setAttribute('y', -10);
  viewportCenterLabel.setAttribute('fill', 'purple');
  viewportCenterLabel.setAttribute('text-anchor', 'middle');
  viewportCenterLabel.setAttribute('font-size', '12px');
  viewportCenterLabel.textContent = 'Viewport Center';
  debugGrid.appendChild(viewportCenterLabel);

  return debugGrid;
};

/**
 * Ajoute une marque pour le centre du contenu et un rectangle autour des limites
 * @param {SVGElement} debugGrid - Élément de grille de débogage
 * @param {Object} contentBounds - Les limites du contenu {minX, minY, maxX, maxY}
 * @param {Object} contentCenter - Centre du contenu {x, y}
 * @param {number} contentWidth - Largeur du contenu
 * @param {number} contentHeight - Hauteur du contenu
 */
export const markContentBounds = (
  debugGrid,
  contentBounds,
  contentCenter,
  contentWidth,
  contentHeight
) => {
  // Marquer le centre du contenu sur la grille
  const contentCenterMark = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'circle'
  );
  contentCenterMark.setAttribute('cx', contentCenter.x);
  contentCenterMark.setAttribute('cy', contentCenter.y);
  contentCenterMark.setAttribute('r', 8);
  contentCenterMark.setAttribute('fill', 'green');
  debugGrid.appendChild(contentCenterMark);

  const contentCenterLabel = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'text'
  );
  contentCenterLabel.setAttribute('x', contentCenter.x);
  contentCenterLabel.setAttribute('y', contentCenter.y - 15);
  contentCenterLabel.setAttribute('fill', 'green');
  contentCenterLabel.setAttribute('text-anchor', 'middle');
  contentCenterLabel.setAttribute('font-size', '14px');
  contentCenterLabel.textContent = `Content Center (${Math.round(
    contentCenter.x
  )},${Math.round(contentCenter.y)})`;
  debugGrid.appendChild(contentCenterLabel);

  // Marquer les limites du contenu
  const boundsRect = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'rect'
  );
  boundsRect.setAttribute('x', contentBounds.minX);
  boundsRect.setAttribute('y', contentBounds.minY);
  boundsRect.setAttribute('width', contentWidth);
  boundsRect.setAttribute('height', contentHeight);
  boundsRect.setAttribute('fill', 'none');
  boundsRect.setAttribute('stroke', 'orange');
  boundsRect.setAttribute('stroke-width', 2);
  boundsRect.setAttribute('stroke-dasharray', '5,5');
  debugGrid.appendChild(boundsRect);
};

/**
 * Ajoute un bloc de débogage qui affiche diverses informations utiles
 * @param {Object} options - Options pour le bloc de débogage
 * @param {Object} options.viewportSize - Dimensions du viewport {width, height}
 * @param {Object} options.contentBounds - Limites du contenu {minX, minY, maxX, maxY}
 * @param {Object} options.contentCenter - Centre du contenu {x, y}
 * @param {Object} options.contentSize - Dimensions du contenu {width, height}
 * @param {Object} options.transform - Informations de transformation {currentTransform, currentZoom, currentPan}
 * @param {Object} options.newTransform - Nouvelles informations de transformation {newZoom, newPan}
 */
export const createDebugInfoBlock = (options) => {
  // Supprimer le bloc existant s'il existe
  const existingBlock = document.getElementById('autofit-debug-block');
  if (existingBlock) {
    document.body.removeChild(existingBlock);
  }

  // Créer le nouveau bloc
  const debugBlock = document.createElement('div');
  debugBlock.id = 'autofit-debug-block';
  debugBlock.style.position = 'absolute';
  debugBlock.style.top = '10px';
  debugBlock.style.right = '10px';
  debugBlock.style.backgroundColor = 'rgba(0, 0, 0, 0.7)';
  debugBlock.style.color = 'white';
  debugBlock.style.padding = '10px';
  debugBlock.style.borderRadius = '5px';
  debugBlock.style.zIndex = '10000';
  debugBlock.style.fontFamily = 'monospace';
  debugBlock.style.fontSize = '12px';
  debugBlock.style.maxWidth = '350px';
  debugBlock.style.maxHeight = '400px';
  debugBlock.style.overflow = 'auto';
  debugBlock.style.boxShadow = '0 0 10px rgba(0,0,0,0.5)';

  // Remplir le bloc avec les informations
  debugBlock.innerHTML = `
      <h3 style="margin:0 0 10px 0;border-bottom:1px solid #999;">SVG Diagram Debug Info</h3>
      <div><strong>Viewport:</strong> ${options.viewportSize.width} × ${
    options.viewportSize.height
  }</div>
      <div><strong>Content:</strong> ${Math.round(
        options.contentSize.width
      )} × ${Math.round(options.contentSize.height)}</div>
      <div><strong>Content Center:</strong> (${Math.round(
        options.contentCenter.x
      )}, ${Math.round(options.contentCenter.y)})</div>
      <div><strong>Content Bounds:</strong> (${Math.round(
        options.contentBounds.minX
      )},${Math.round(options.contentBounds.minY)}) to (${Math.round(
    options.contentBounds.maxX
  )},${Math.round(options.contentBounds.maxY)})</div>
      
      <div style="margin-top:10px;"><strong>Current Transform:</strong> ${
        options.transform.currentTransform || 'none'
      }</div>
      <div><strong>Current Zoom:</strong> ${options.transform.currentZoom.toFixed(
        2
      )}</div>
      <div><strong>Current Pan:</strong> (${Math.round(
        options.transform.currentPan.x
      )}, ${Math.round(options.transform.currentPan.y)})</div>
      
      <div style="margin-top:10px;border-top:1px dashed #999;padding-top:10px;">
        <strong>New Zoom:</strong> ${options.newTransform.newZoom.toFixed(2)}
      </div>
      <div><strong>New Pan:</strong> (${Math.round(
        options.newTransform.newPan.x
      )}, ${Math.round(options.newTransform.newPan.y)})</div>
      
      <div style="margin-top:10px;border-top:1px dashed #999;padding-top:10px;">
        <strong>Expected Transform:</strong>
      </div>
      <div style="font-size:11px;word-break:break-all">translate(${Math.round(
        options.newTransform.newPan.x
      )}, ${Math.round(
    options.newTransform.newPan.y
  )}) scale(${options.newTransform.newZoom.toFixed(2)})</div>
    `;

  // Ajouter le bloc à la page
  document.body.appendChild(debugBlock);

  return debugBlock;
};

/**
 * Supprime la grille de débogage et le bloc d'informations
 */
export const removeDebugElements = () => {
  // Supprimer la grille
  const debugGrid = document.getElementById('debug-grid');
  if (debugGrid) {
    debugGrid.parentNode.removeChild(debugGrid);
  }

  // Supprimer le bloc d'infos
  const debugBlock = document.getElementById('autofit-debug-block');
  if (debugBlock) {
    debugBlock.parentNode.removeChild(debugBlock);
  }
};
