import styled from 'styled-components';

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

export const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  width: 90%;
  text-align: center;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
`;

export const TitleMarkdown = styled.div`
  font-size: 1em; /* Plus petit pour permettre plus de contenu */
  color: #555;
  margin-bottom: 20px;
  text-align: left; /* Alignement pour Markdown */
  line-height: 1.5;

  /* Ajout de styles spécifiques au Markdown */
  p {
    margin: 0 0 10px;
  }
  strong {
    font-weight: bold;
  }
  em {
    font-style: italic;
  }
  ul,
  ol {
    margin-left: 20px;
  }
`;

export const Description = styled.div`
  font-size: 1em; /* Plus petit pour permettre plus de contenu */
  color: #333;
  margin-bottom: 20px;
  text-align: left; /* Alignement pour Markdown */
  line-height: 1.5;

  /* Ajout de styles spécifiques au Markdown */
  p {
    margin: 0 0 10px;
  }
  strong {
    font-weight: normal;
  }
  em {
    font-style: normal;
  }
  ul,
  ol {
    margin-left: 20px;
  }
`;

export const OptionsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 0 20px;
`;

export const OptionItem = styled.li`
  margin: 10px 0;
`;

export const OptionButton = styled.button`
  padding: 10px 20px;
  font-size: 1em;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

export const CloseButton = styled.button`
  padding: 10px 15px;
  font-size: 0.9em;
  color: #333;
  background-color: #f1f1f1;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #e0e0e0;
  }
`;
