import { line, max, scaleBand, scaleLinear, scaleOrdinal, select } from 'd3';
import React from 'react';
import ReactFauxDOM from 'react-faux-dom';
import { connect } from 'react-redux';
import { ObjGraphsRadarContainer } from './ObjGraphsRadarCSS';
//= ============================================================================
class ObjGraphsRadar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  //----------------------------------------------------------------------------

  //----------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  render() {
    const isDark = this.props.pal.isDark;
    const width = this.props.mini ? 130 : 180;
    const widthGfx = width * 0.7;
    const height = this.props.mini ? 35 : 60;
    const svg = select(ReactFauxDOM.createElement('g'));
    // set x scale
    const x = scaleBand()
      .rangeRound([0, widthGfx])
      .paddingInner(0.5)
      .align(0.1);

    // set y scale
    const y = scaleLinear().rangeRound([height, 0]);

    // set the colors
    const z = scaleOrdinal().range([
      '#98abc5',
      '#8a89a6',
      '#7b6888',
      '#6b486b',
      '#a05d56',
      '#d0743c',
      '#ff8c00',
    ]);

    let data = this.props.data;

    const myArray = [];
    const keys = [];
    const cats = [];
    for (const rowind in data) {
      const row = data[rowind];
      const key = Object.keys(row)[1];
      const cat = Object.keys(row)[2];
      if (cats.indexOf(row[cat]) === -1) {
        cats.push(row[cat]);
      }
      if (keys.indexOf(row[key]) === -1) {
        keys.push(row[key]);
      }
      let obj = myArray.filter((item) => item[key] === row[key])[0];
      if (obj === null || obj === undefined) {
        obj = { [key]: row[key] };
        myArray.push(obj);
      }
      obj[row[Object.keys(row)[2]]] = row[Object.keys(row)[0]];
    }
    data = myArray;

    x.domain(keys);
    y.domain([
      0,
      max(data, (d) => {
        let total = 0;
        for (const indcat in cats) {
          const cat = cats[indcat];
          if (d[cat] !== null && d[cat] !== undefined) {
            total += +d[cat];
          }
        }
        return total;
      }),
    ]).nice();

    const maxInd = 10.0;
    const summ = data.map((obj) =>
      Number(
        Object.keys(obj).reduce(
          (a, c) => (isNaN(obj[c]) ? a : a + Number(obj[c])),
          0
        )
      )
    );

    const ratio = maxInd / summ.reduce((max, curr) => Math.max(max, curr), 0);
    const rotate = 360.0 / data.length;
    const starFunction = line()
      .y(function (d, ind, arr) {
        return 0;
      })
      .x(function (d, ind, arr) {
        return 0;
      })
      .y(function (d, ind, arr) {
        return (
          Math.cos((rotate / 2) * ind * (Math.PI / 180)) *
          ratio *
          Number(
            Object.keys(d).reduce(
              (a, c) => (isNaN(d[c]) ? a : a + Number(d[c])),
              0
            ) *
              5 +
              0
          )
        );
      })
      .x(function (d, ind, arr) {
        return (
          Math.sin((rotate / 2) * ind * (Math.PI / 180)) *
          ratio *
          Number(
            Object.keys(d).reduce(
              (a, c) => (isNaN(d[c]) ? a : a + Number(d[c])),
              0
            ) *
              5 +
              0
          )
        );
      });

    const lineFunction = line()
      .y(function (d, ind, arr) {
        return (
          Math.cos(rotate * (ind + 1) * (Math.PI / 180)) *
          ratio *
          Number(
            Object.keys(d).reduce(
              (a, c) => (isNaN(d[c]) ? a : a + Number(d[c])),
              0
            ) *
              5 +
              0
          )
        );
      })
      .x(function (d, ind, arr) {
        return (
          Math.sin(rotate * (ind + 1) * (Math.PI / 180)) *
          ratio *
          Number(
            Object.keys(d).reduce(
              (a, c) => (isNaN(d[c]) ? a : a + Number(d[c])),
              0
            ) *
              5 +
              0
          )
        );
      });

    z.domain(cats);
    const g = svg.append('g');
    g.attr(
      'transform',
      this.props.mini ? 'translate(35,5)' : 'translate(60,20)'
    );
    g.selectAll('g');

    const filter = g.append('defs').append('filter').attr('id', 'dropShadow');

    filter
      .append('feGaussianBlur')
      .attr('in', 'SourceAlpha')
      .attr('stdDeviation', 0.6)
      .attr('class', 'blur');
    filter.append('feOffset').attr('dx', 0.3).attr('dy', 0.3);
    const fm = filter.append('feMerge');
    fm.append('feMergeNode').attr('in', 'SourceGraphic');
    filter
      .append('feComponentTransfer')
      .append('feFuncA')
      .attr('type', 'linear')
      .attr('slope', 0.3);

    let valRatio = 2.0 / ratio;
    let contour = [
      { a: valRatio },
      { b: valRatio },
      { c: valRatio },
      { d: valRatio },
      { e: valRatio },
      { a: valRatio },
      { b: valRatio },
      { c: valRatio },
      { d: valRatio },
      { e: valRatio },
    ];

    const radar = svg.append('g').attr('transform', () => {
      return this.props.mini
        ? 'scale(1.0,1.0) translate(100,55)'
        : 'scale(1.2,1.2) translate(80,55)';
    });

    const opacityLine = 1;

    radar
      .append('path')
      .attr('transform', () => {
        return 'scale(0.8,0.8) translate(0,0)';
      })
      .attr('d', `${lineFunction(contour)}Z`)
      .attr('stroke', isDark ? 'white' : '#AAAAAA')
      .attr('stroke-width', 0.5)
      .attr('stroke-opacity', opacityLine)
      .attr('fill-opacity', 0)
      .attr('fill', isDark ? 'white' : '#666666');
    valRatio = 10.0 / ratio;
    contour = [
      { a: valRatio },
      { b: valRatio },
      { c: valRatio },
      { d: valRatio },
      { e: valRatio },
      { a: valRatio },
      { b: valRatio },
      { c: valRatio },
      { d: valRatio },
      { e: valRatio },
    ];
    radar
      .append('path')
      .attr('transform', () => {
        return 'scale(1,1) translate(0,0)';
      })
      .attr('d', `${lineFunction(contour)}Z`)
      .attr('stroke', isDark ? 'white' : '#AAAAAA')
      .attr('stroke-width', 0.5)
      .attr('stroke-opacity', opacityLine)
      .attr('fill-opacity', 0.8)
      .attr('fill', 'white');
    valRatio = 6.0 / ratio;
    contour = [
      { a: valRatio },
      { b: valRatio },
      { c: valRatio },
      { d: valRatio },
      { e: valRatio },
      { a: valRatio },
      { b: valRatio },
      { c: valRatio },
      { d: valRatio },
      { e: valRatio },
    ];
    radar
      .append('path')
      .attr('transform', () => {
        return 'scale(1,1) translate(0,0)';
      })
      .attr('d', `${lineFunction(contour)}Z`)
      .attr('stroke', isDark ? 'white' : '#AAAAAA')
      .attr('stroke-width', 0.5)
      .attr('stroke-opacity', opacityLine)
      .attr('fill-opacity', 0)
      .attr('fill', isDark ? 'white' : '#666666');

    valRatio = 2.0 / ratio;
    contour = [
      { a: valRatio },
      { b: valRatio },
      { c: valRatio },
      { d: valRatio },
      { e: valRatio },
      { a: valRatio },
      { b: valRatio },
      { c: valRatio },
      { d: valRatio },
      { e: valRatio },
    ];
    radar
      .append('path')
      .attr('transform', () => {
        return 'scale(1,1) translate(0,0)';
      })
      .attr('d', `${lineFunction(contour)}Z`)
      .attr('stroke', isDark ? 'white' : '#AAAAAA')
      .attr('stroke-width', 0.5)
      .attr('stroke-opacity', opacityLine)
      .attr('fill-opacity', 0)
      .attr('fill', isDark ? 'white' : '#666666');

    valRatio = 10.0 / ratio;
    contour = [
      { a: valRatio },
      { b: 0 },
      { c: valRatio },
      { d: 0 },
      { e: valRatio },
      { a: 0 },
      { b: valRatio },
      { c: 0 },
      { d: valRatio },
      { e: 0 },
      { d: valRatio },
      { e: 0 },
      { d: valRatio },
      { e: 0 },
      { d: valRatio },
      { e: 0 },
    ];
    radar
      .append('path')
      .attr('transform', () => {
        return 'scale(1,1) translate(0,0)';
      })
      .attr('d', `${starFunction(contour)}Z`)
      .attr('stroke', isDark ? 'white' : '#AAAAAA')
      .attr('stroke-width', 1)
      .attr('stroke-opacity', 1)
      .attr('fill-opacity', 0.8)
      .attr('fill', isDark ? 'white' : '#CCCCCC');

    radar
      .append('path')
      .attr('transform', () => {
        return 'scale(1,1) translate(0,0)';
      })
      .attr('d', `${lineFunction(data)}Z`)
      .attr('stroke', '#1C7ED6')
      .attr('stroke-linecap', 'round')
      .attr('stroke-width', 1)
      .attr('stroke-opacity', 1)
      .attr('fill-opacity', isDark ? '0.6' : '0.3')
      .attr('fill', isDark ? 'white' : '#1C7ED6');

    radar
      .selectAll('text')
      .data(keys.slice().reverse())
      .enter()
      .append('text')
      .attr('transform', 'translate(0,0)')
      .style('text-anchor', 'middle')
      .style('dominant-baseline', 'central')
      .attr('x', (a, b) => 70 * Math.cos(rotate * (b + 1) * (Math.PI / 180)))
      .attr('y', (a, b) => 50 * Math.sin(rotate * (b + 1) * (Math.PI / 180)))
      .attr('fill', isDark ? 'white' : '#548AE2')
      .attr('font-weight', 'bold')
      .attr('font-size', 11)
      .text((t) => t);

    const res = svg.node().toReact();

    return (
      <ObjGraphsRadarContainer
        transform={this.props.mini ? 'scale(1,1)' : ''}
        height={this.props.mini ? '105px' : '150px'}
      >
        <svg>{res}</svg>
      </ObjGraphsRadarContainer>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ObjGraphsRadar);
