import produce from 'immer';
import { getSuggestedType } from '../components/object-selector/ObjectSelectorUtils';
import { parseJson } from '../utils/parseJson';

const OBJECT_SELECTOR = (state = DEFAULT_STATE, action, root) => {
  let res;

  switch (action.type) {
    //--------------------------------------------------------------------------------------------------
    case 'SET_OBJECT_SELECTOR_STATE': {
      return {
        ...state,
        objectSelectorState: { ...action.objectSelectorState },
      };
    }
    //--------------------------------------------------------------------------------------------------
    case 'CLOSE_SELECTOBJECT':
      return { ...state, isObjectSelectorOpen: false };

    case 'SET_DRAWING_ITEM_SELECTED':
      const svgPad = root.objects.objFormData.find(
        (obj) => obj.attrName.trim() === action.attrNameSelected.trim()
      );

      let svgPadObj = parseJson(svgPad.defaultValue);

      const elemSelected = svgPadObj.find(
        (obj) => obj.key === action.selectedDrawingItem
      );

      return {
        ...state,
        attrNameSelected: action.attrNameSelected,
        selectedDrawingItem: elemSelected.key,
        objectSelectorState: {
          ...action.objectSelectorState,
          mode: action.typeElement,
        },
      };

    case 'OPEN_OBJECTSELECTOR': {
      const typeParent = root.objects.objTypeId;
      const suggestedType = getSuggestedType(
        root.objects.pattern,
        typeParent,
        root.objects.objectsDefList
      );

      return {
        ...state,
        isObjectSelectorOpen: true,
        objectSelectorState: {
          ...state.objectSelectorState,
          typeSelectObject: null,
          idSelectedObject: null,
          action: action.action,
        },
        suggestedType,
      };
    }
    //--------------------------------------------------------------------------------------------------
    case 'SELECTED_OBJECT_TO_CREATE':
      res = {
        ...state,
        objectSelectorState: { typeSelectObject: action.objType },
      };
      return res;
    //--------------------------------------------------------------------------------------------------

    default:
      return state;
  }
};

//--------------------------------------------------------------------------------------------------
const DEFAULT_STATE = {
  isObjectSelectorOpen: false,
  suggestedType: null,
  objectSelectorState: {
    typeSelectObject: null,
    idSelectedObject: null,
    step: 0,
    action: 'none',
    mode: 'none',
  },
};
//--------------------------------------------------------------------------------------------------
export default OBJECT_SELECTOR;
