// svgDraggableAdapter.js
import { makeDraggable } from '../../utils/DragDropTouchTablet';
import { SvgCoordinateTransform } from './svgCoordinateTransform';

export function makeSvgDraggable(
  element,
  onStart,
  onEnd,
  onDrag,
  onClick,
  threshold,
  panPosition,
  zoomLevel
) {
  if (!element) return;

  const svgPad = document.getElementById('svgPad');

  // Track the mouse's initial position and the element's initial position
  let startMouseX = 0;
  let startMouseY = 0;
  let elementStartX = 0;
  let elementStartY = 0;

  // Start with the standard draggable
  return makeDraggable(
    element,
    (position) => {
      // Get initial mouse position in SVG coordinates
      const transformedPos = SvgCoordinateTransform.screenToSvgCoords(
        position.x,
        position.y,
        svgPad,
        panPosition,
        zoomLevel
      );

      startMouseX = transformedPos.x;
      startMouseY = transformedPos.y;

      // Store the element's starting position
      // This assumes element is the owner component knowing its position
      // You'll need to provide this through props or data attributes
      if (element.dataset && element.dataset.x !== undefined) {
        elementStartX = parseFloat(element.dataset.x);
        elementStartY = parseFloat(element.dataset.y);
      } else if (element.__draggableContext) {
        // Store context on the element for later use
        elementStartX = element.__draggableContext.startX;
        elementStartY = element.__draggableContext.startY;
      }

      // Call the original onStart with just the transformed position
      if (onStart) onStart(transformedPos);
    },
    (position) => {
      // Handle drag end
      const transformedPos = SvgCoordinateTransform.screenToSvgCoords(
        position.x,
        position.y,
        svgPad,
        panPosition,
        zoomLevel
      );

      // Calculate delta
      const deltaX = transformedPos.x - startMouseX;
      const deltaY = transformedPos.y - startMouseY;

      // Calculate new absolute position
      const newX = elementStartX + deltaX;
      const newY = elementStartY + deltaY;

      // Call original handler with the calculated new position
      if (onEnd) onEnd({ x: newX, y: newY });
    },
    (position) => {
      // Handle drag move
      const transformedPos = SvgCoordinateTransform.screenToSvgCoords(
        position.x,
        position.y,
        svgPad,
        panPosition,
        zoomLevel
      );

      // Calculate delta
      const deltaX = transformedPos.x - startMouseX;
      const deltaY = transformedPos.y - startMouseY;

      // Calculate new absolute position
      const newX = elementStartX + deltaX;
      const newY = elementStartY + deltaY;

      // Call original handler with the calculated new position
      if (onDrag) onDrag({ x: newX, y: newY });
    },
    onClick,
    threshold
  );
}
