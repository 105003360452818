import React from 'react';
import Remarkable from 'react-remarkable';
import { useDispatch, useSelector } from 'react-redux';
import {
  ModalOverlay,
  ModalContent,
  TitleMarkdown,
  OptionsList,
  OptionItem,
  OptionButton,
  CloseButton,
  Description,
} from './QuestConfirmationCSS';

const QuestConfirmation = () => {
  const dispatch = useDispatch();
  const currentInteraction = useSelector(
    (state) => state.interaction.currentInteraction
  );

  // Vérifie si l'interaction en cours est de type questConfirmation
  if (!currentInteraction || currentInteraction.type !== 'questConfirmation')
    return null;

  const { question, questionId, description } = currentInteraction.data;

  const handleOptionClick = (response) => {
    // Envoie la réponse à Redux
    dispatch({
      type: 'INTERACTION_RESPONSE',
      payload: {
        questionId,
        response,
      },
    });

    // Ferme l'interaction après la réponse
    dispatch({ type: 'INTERACTION_CLOSE' });
  };

  const handleClose = () => {
    dispatch({ type: 'INTERACTION_CLOSE' });
  };

  return (
    <ModalOverlay>
      <ModalContent>
        {/* Question en Markdown */}
        <TitleMarkdown>
          <Remarkable source={question ?? ''} />
        </TitleMarkdown>
        <Description><Remarkable source={description ?? ''} /></Description>
        <OptionsList>
          <OptionItem>
            <OptionButton onClick={() => handleOptionClick('Yes')}>
              Yes
            </OptionButton>
          </OptionItem>
          <OptionItem>
            <OptionButton onClick={() => handleOptionClick('No')}>
              No
            </OptionButton>
          </OptionItem>
        </OptionsList>
        <CloseButton onClick={handleClose}>Close</CloseButton>
      </ModalContent>
    </ModalOverlay>
  );
};

export default QuestConfirmation;
