import styled from 'styled-components';

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

export const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  width: 90%;
  text-align: center;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
`;

export const Title = styled.h2`
  margin-bottom: 20px;
  font-size: 1.5em;
  color: #333;
`;

export const OptionsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 0 20px;
`;

export const OptionItem = styled.li`
  margin: 10px 0;
`;

export const OptionButton = styled.button`
  padding: 10px 20px;
  font-size: 1em;
  color: white;
  background-color: ${(props) => (props.isSelected ? '#28a745' : '#007bff')};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${(props) => (props.isSelected ? '#218838' : '#0056b3')};
  }
`;

export const SendButton = styled.button`
  padding: 10px 20px;
  font-size: 1em;
  color: white;
  background-color: #28a745;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 15px;

  &:hover {
    background-color: #218838;
  }

  &:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
  }
`;

export const CloseButton = styled.button`
  padding: 10px 15px;
  font-size: 0.9em;
  color: #333;
  background-color: #f1f1f1;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;

  &:hover {
    background-color: #e0e0e0;
  }
`;
