import { v4 as uuidv4 } from 'uuid';
import { SVG_DIAGRAM_ACTIONS } from './types';
import ObjectApi from '../../api/ObjectApi';
import { IMAGE_SVG_ICONS_TYPE } from '../../constants/libraryIcons';

// Transform actions
export const setAttrName = (name) => ({
  type: SVG_DIAGRAM_ACTIONS.SET_ATTR_NAME,
  payload: name,
});
export const setZoomLevel = (level) => ({
  type: SVG_DIAGRAM_ACTIONS.SET_ZOOM_LEVEL,
  payload: level,
});

export const setGridVisible = (isVisible) => ({
  type: SVG_DIAGRAM_ACTIONS.SET_GRID_VISIBLE,
  payload: isVisible,
});

export const setPanMode = (isPanMode) => ({
  type: SVG_DIAGRAM_ACTIONS.SET_PAN_MODE,
  payload: isPanMode,
});

export const setPanPosition = (position) => ({
  type: SVG_DIAGRAM_ACTIONS.SET_PAN_POSITION,
  payload: position,
});

export const toggleDebugMode = (isEnabled) => ({
  type: SVG_DIAGRAM_ACTIONS.TOGGLE_DEBUG_MODE,
  payload: isEnabled,
});

export const setGridSize = (size) => ({
  type: SVG_DIAGRAM_ACTIONS.SET_GRID_SIZE,
  payload: size,
});

/**
 * Set the current action mode
 * @param {string} action - The action mode to set (mouse, move, drawing-line, etc.)
 * @param {boolean} maintainSelection - Whether to maintain the current selection
 */
export const setCurrentAction =
  (action, maintainSelection = true) =>
  (dispatch, getState) => {
    dispatch({
      type: SVG_DIAGRAM_ACTIONS.SET_CURRENT_ACTION,
      payload: action,
    });

    // If not maintaining selection, clear the selected element
    if (!maintainSelection) {
      dispatch(setSelectedElement(null));
    }

    // Exit drawing mode when switching to non-drawing actions
    if (!action.startsWith('drawing-')) {
      dispatch(setDrawingMode(false));
    }

    // Set connector mode appropriately
    if (action === 'drawing-connector') {
      dispatch(
        setConnectorMode({
          active: false,
          sourceId: null,
          targetId: null,
        })
      );
    }
  };

/**
 * Set the selected element
 * @param {string|null} elementKey - The key of the selected element, or null to deselect
 */
export const setSelectedElement = (elementKey) => ({
  type: SVG_DIAGRAM_ACTIONS.SET_SELECTED_ELEMENT,
  payload: elementKey,
});

/**
 * Set the current line drawing type
 * @param {string} lineType - The type of line to draw (line, polyline, arrow-line, etc.)
 */
export const setLineDrawingType = (lineType) => (dispatch) => {
  dispatch({
    type: SVG_DIAGRAM_ACTIONS.SET_LINE_DRAWING_TYPE,
    payload: lineType,
  });

  // Update the current action based on the line type
  const actionMap = {
    line: 'drawing-line',
    polyline: 'drawing-polyline',
    'arrow-line': 'drawing-arrow-line',
    'bidirectional-line': 'drawing-bidirectional-line',
    connector: 'drawing-connector',
  };

  if (actionMap[lineType]) {
    dispatch(setCurrentAction(actionMap[lineType]));
  }
};

/**
 * Set all elements in the diagram
 * @param {Array} elements - Array of all diagram elements
 */
export const setElements = (elements) => ({
  type: SVG_DIAGRAM_ACTIONS.SET_ELEMENTS,
  payload: elements,
});

/**
 * Add a new element to the diagram
 * @param {Object} element - The element to add
 */
export const addElement = (element) => ({
  type: SVG_DIAGRAM_ACTIONS.ADD_ELEMENT,
  payload: element,
});

/**
 * Create and add a new text element
 * @param {Object} params - Text element parameters
 * @param {string} params.text - The text content
 * @param {string} params.color - The text color
 * @param {number} params.fontWeight - The font weight
 * @param {number} params.fontSize - The font size
 * @param {string} params.backgroundColor - The background color
 * @param {number} params.x - X position
 * @param {number} params.y - Y position
 */
export const createTextElement =
  ({ text, color, fontWeight, fontSize, backgroundColor, x, y, blocSize }) =>
  (dispatch, getState) => {
    const state = getState();
    const topZIndex =
      Math.max(...state.svgDiagram.elements.map((el) => el.zIndex || 0), 0) + 1;

    const textElement = {
      key: `text_${uuidv4()}`,
      type: 'text',
      points: text,
      x: x,
      y: y,
      fontWeight,
      color,
      fontSize,
      blocSize, // Default width
      zIndex: topZIndex,
      backgroundColor,
    };

    dispatch(addElement(textElement));
    return textElement.key; // Return the key for potential selection
  };

/**
 * Create and add a line element
 * @param {string} lineType - Type of line (line, polyline, arrow-line, etc.)
 * @param {Array} points - Array of points {x, y}
 * @param {Object} options - Additional options
 */
export const createLineElement =
  (lineType, points, options = {}) =>
  (dispatch, getState) => {
    const state = getState();
    const topZIndex =
      Math.max(...state.svgDiagram.elements.map((el) => el.zIndex || 0), 0) + 1;

    const lineElement = {
      key: `${lineType}_${uuidv4()}`,
      type: lineType,
      points: points,
      tick: options.thickness || state.svgDiagram.lineThickness,
      color: options.color || state.svgDiagram.color,
      zIndex: topZIndex,
      backgroundColor: options.backgroundColor,
      ...options,
    };

    dispatch(addElement(lineElement));
    return lineElement.key; // Return the key for potential selection
  };

/**
 * Create and add a connector between two elements
 * @param {string} sourceId - Source element ID
 * @param {string} targetId - Target element ID
 * @param {Object} options - Additional options
 */
export const createConnectorElement =
  (sourceId, targetId, options = {}) =>
  (dispatch, getState) => {
    const state = getState();
    const topZIndex =
      Math.max(...state.svgDiagram.elements.map((el) => el.zIndex || 0), 0) + 1;

    // Find source and target elements to get their positions
    const sourceElement = state.svgDiagram.elements.find(
      (el) => el.key === sourceId
    );
    const targetElement = state.svgDiagram.elements.find(
      (el) => el.key === targetId
    );

    if (!sourceElement || !targetElement) {
      console.warn(
        'Could not create connector: source or target element not found'
      );
      return null;
    }

    // Calculate initial connection points (simplified - would use actual centers in real implementation)
    const sourceCenter = calculateElementCenter(
      sourceElement,
      state.svgDiagram.elements
    );
    const targetCenter = calculateElementCenter(
      targetElement,
      state.svgDiagram.elements
    );

    const connectorElement = {
      key: `connector_${uuidv4()}`,
      type: 'connector',
      sourceId,
      targetId,
      points: [sourceCenter, targetCenter],
      tick: options.thickness || state.svgDiagram.lineThickness,
      color: options.color || state.svgDiagram.color,
      zIndex: topZIndex,
      curveAmount: options.curveAmount || 20,
      text: options.text || '',
      textColor: options.textColor || options.color || state.svgDiagram.color,
      textSize: options.textSize || 14,
    };

    dispatch(addElement(connectorElement));
    return connectorElement.key;
  };

/**
 * Définir la relation parent-enfant entre deux éléments
 * @param {string} childKey - La clé de l'élément enfant
 * @param {string} parentKey - La clé de l'élément parent
 */
export const setElementParent = (childKey, parentKey) => ({
  type: SVG_DIAGRAM_ACTIONS.SET_ELEMENT_PARENT,
  payload: { childKey, parentKey },
});

/**
 * Briser la relation parent-enfant d'un élément
 * @param {string} childKey - La clé de l'élément enfant
 */
export const breakElementParent = (childKey) => ({
  type: SVG_DIAGRAM_ACTIONS.BREAK_ELEMENT_PARENT,
  payload: { childKey },
});

// Helper function for connector creation
const calculateElementCenter = (element, allElements) => {
  // Simplified implementation - would be more complex in real usage
  if (element.type === 'text') {
    return {
      x: element.x + 50, // Rough estimate
      y: element.y + (element.fontSize || 20) / 2,
    };
  }

  if (element.type === 'svg' || element.type === 'image') {
    return {
      x: element.x + element.width / 2,
      y: element.y + element.height / 2,
    };
  }

  if (
    ['polyline', 'line', 'arrow-line', 'bidirectional-line'].includes(
      element.type
    )
  ) {
    const points = element.points;
    if (points && points.length > 0) {
      // Average of all points
      return {
        x: points.reduce((sum, point) => sum + point.x, 0) / points.length,
        y: points.reduce((sum, point) => sum + point.y, 0) / points.length,
      };
    }
  }

  return {
    x: element.x || 0,
    y: element.y || 0,
  };
};

/**
 * Update an existing element
 * @param {string} elementKey - The key of the element to update
 * @param {Object} updates - The properties to update
 */
export const updateElement = (elementKey, updates) => (dispatch, getState) => {
  // Mettre à jour l'élément

  // Trouver l'élément mis à jour
  const state = getState();
  const { elements } = state.svgDiagram;

  const originalElement = elements.find(
    (element) => element.key === elementKey
  );
  let dx = 0;
  let dy = 0;
  if (
    originalElement.points &&
    [
      'line',
      'polyline',
      'arrow-line',
      'bidirectional-line',
      'connector',
    ].includes(originalElement.type) &&
    originalElement.points.length > 0
  ) {
    const firstPtAfter = updates.points[0];
    const firstPtBefore = originalElement.points?.find(
      (point) => point.key === firstPtAfter.key
    );
    if (firstPtAfter && firstPtBefore) {
      dx = firstPtAfter.x - firstPtBefore.x;
      dy = firstPtAfter.y - firstPtBefore.y;
    }
  } else {
    dx = -(originalElement.x - updates.x);
    dy = -(originalElement.y - updates.y);
  }
  dispatch({
    type: SVG_DIAGRAM_ACTIONS.UPDATE_ELEMENT,
    payload: { key: elementKey, updates },
  });

  // Si c'est une mise à jour de position OU des points
  if (updates.x !== undefined || updates.y !== undefined || updates.points) {
    if (originalElement) {
      // Trouver tous les enfants directs de cet élément
      const childElements = elements.filter(
        (element) => element.parentKey === elementKey
      );

      // Propager le déplacement aux enfants
      if (dx !== 0 || dy !== 0) {
        // Lancer la mise à jour récursive des enfants
        // updateChildrenPositions(childElements, dx, dy);
        updateChildrenPositions(childElements, dx, dy, elements, dispatch);
      }
    }
  }
};

const updateChildrenPositions = (
  children,
  parentDx,
  parentDy,
  allElements,
  dispatch
) => {
  children.forEach((childElement) => {
    let childUpdates = {};

    // Adapter la mise à jour selon le type d'élément enfant
    if (
      ['polyline', 'line', 'arrow-line', 'bidirectional-line'].includes(
        childElement.type
      )
    ) {
      // Pour les éléments basés sur des points, déplacer tous les points
      const newPoints = childElement.points.map((point) => ({
        ...point,
        x: point.x + parentDx,
        y: point.y + parentDy,
      }));
      childUpdates = { points: newPoints };
    } else {
      // Pour les autres types d'éléments, mettre à jour x et y
      childUpdates = {
        x: childElement.x + parentDx,
        y: childElement.y + parentDy,
      };
    }

    // Mettre à jour l'enfant
    dispatch({
      type: SVG_DIAGRAM_ACTIONS.UPDATE_ELEMENT,
      payload: { key: childElement.key, updates: childUpdates },
    });

    // Trouver et mettre à jour les enfants de cet enfant (petits-enfants)
    const grandchildren = allElements.filter(
      (element) => element.parentKey === childElement.key
    );
    if (grandchildren.length > 0) {
      updateChildrenPositions(
        grandchildren,
        parentDx,
        parentDy,
        allElements,
        dispatch
      );
    }
  });
};

/**
 * Supprime un élément par son ID
 * @param {string} elementKey - L'identifiant de l'élément à supprimer
 * @param {string} objId - L'ID de l'objet parent (pour la gestion des ressources)
 */
export const deleteElement = (elementKey, objId) => (dispatch, getState) => {
  // Obtenir l'état actuel
  const state = getState();
  const { elements } = state.svgDiagram;

  // Fonction récursive pour supprimer un élément et tous ses enfants
  const deleteElementAndChildren = (key) => {
    // Trouver les enfants directs
    const children = elements.filter((element) => element.parentKey === key);

    // Gérer les ressources externes si nécessaire
    const element = elements.find((item) => item.key === key);
    if (
      element?.keyImage !== IMAGE_SVG_ICONS_TYPE.ACCESS_FROM_ICON_LIB &&
      !element?.isCloneTemp
    ) {
      ObjectApi.postFile(objId, null, null, element.keyImage);
    }

    // Dispatcher l'action de suppression pour cet élément
    dispatch({
      type: SVG_DIAGRAM_ACTIONS.DELETE_ELEMENT,
      payload: key,
    });

    // Supprimer récursivement tous les enfants
    children.forEach((child) => {
      deleteElementAndChildren(child.key);
    });
  };

  // Supprimer l'élément et tous ses enfants
  deleteElementAndChildren(elementKey);
};

/**
 * Supprime l'élément actuellement sélectionné
 * @param {string} objId - L'ID de l'objet parent (pour la gestion des ressources)
 */
export const deleteSelected = (objId) => (dispatch, getState) => {
  const state = getState();
  const { selectedElementKey } = state.svgDiagram;

  if (selectedElementKey) {
    dispatch(deleteElement(selectedElementKey, objId));
  }
};

/**
 * Set the line thickness
 * @param {number} thickness - The thickness in pixels
 */
export const setLineThickness = (thickness) => (dispatch, getState) => {
  dispatch({
    type: SVG_DIAGRAM_ACTIONS.SET_LINE_THICKNESS,
    payload: thickness,
  });

  // If there's a selected element that's a line, update its thickness too
  const state = getState();
  const { selectedElementKey, elements } = state.svgDiagram;

  if (selectedElementKey) {
    const element = elements.find((el) => el.key === selectedElementKey);
    if (
      element &&
      [
        'line',
        'polyline',
        'arrow-line',
        'bidirectional-line',
        'connector',
      ].includes(element.type)
    ) {
      dispatch(updateElement(selectedElementKey, { tick: thickness }));
    }
  }
};

/**
 * Set the connector mode state
 * @param {Object} connectorState - The connector mode state
 */
export const setConnectorMode = (connectorState) => ({
  type: SVG_DIAGRAM_ACTIONS.SET_CONNECTOR_MODE,
  payload: connectorState,
});

/**
 * Set the current color
 * @param {string} color - The color value
 */
export const setColor = (color) => (dispatch, getState) => {
  dispatch({
    type: SVG_DIAGRAM_ACTIONS.SET_COLOR,
    payload: color,
  });

  // If there's a selected element, update its color too
  const state = getState();
  const { selectedElementKey, elements } = state.svgDiagram;

  if (selectedElementKey) {
    const element = elements.find((el) => el.key === selectedElementKey);
    if (
      element &&
      [
        'line',
        'polyline',
        'arrow-line',
        'bidirectional-line',
        'connector',
        'text',
      ].includes(element.type)
    ) {
      dispatch(updateElement(selectedElementKey, { color }));
    }
  }
};

/**
 * Set drawing mode
 * @param {boolean} isDrawing - Whether in drawing mode
 */
export const setDrawingMode = (isDrawing) => ({
  type: SVG_DIAGRAM_ACTIONS.SET_DRAWING_MODE,
  payload: isDrawing,
});

/**
 * Duplicate an element
 * @param {string} elementKey - The key of the element to duplicate
 */
export const duplicateElement = (elementKey) => (dispatch, getState) => {
  const state = getState();
  const { elements } = state.svgDiagram;
  const element = elements.find((el) => el.key === elementKey);

  if (!element) return null;

  // Fonction récursive pour dupliquer un élément et ses enfants
  const duplicateElementAndChildren = (
    originalKey,
    parentKeyOverride = null
  ) => {
    const originalElement = elements.find((el) => el.key === originalKey);
    if (!originalElement) return null;

    let newElement;
    const topZIndex = Math.max(...elements.map((el) => el.zIndex || 0)) + 1;

    // Dupliquer l'élément selon son type
    switch (originalElement.type) {
      case 'svg':
        newElement = {
          ...originalElement,
          key: `svg_${uuidv4()}`,
          zIndex: topZIndex,
        };
        break;

      case 'image': {
        const keyImage = `illustration_file${uuidv4()}`;
        newElement = {
          ...originalElement,
          key: `image_${uuidv4()}`,
          keyImage:
            originalElement.keyImage === 'ACCESS_FROM_ICON_LIB'
              ? originalElement.keyImage
              : keyImage,
          zIndex: topZIndex,
        };
        break;
      }

      case 'text':
        newElement = {
          ...originalElement,
          key: `text_${uuidv4()}`,
          zIndex: topZIndex,
        };
        break;

      case 'line':
      case 'arrow-line':
      case 'bidirectional-line':
      case 'polyline': {
        // Appliquer directement un décalage aux points
        const shiftedPoints = originalElement.points.map((point) => ({
          ...point,
          x: point.x + 20, // Même décalage que newElement.x
          y: point.y + 20, // Même décalage que newElement.y
          id: uuidv4(), // Générer un nouvel ID pour chaque point
        }));

        newElement = {
          ...originalElement,
          key: `${originalElement.type}_${uuidv4()}`,
          points: shiftedPoints,
          zIndex: topZIndex,
        };
        break;
      }

      default:
        return null;
    }

    // Appliquer un léger décalage à la copie
    newElement.x += 20;
    newElement.y += 20;

    // Si un parent override est fourni, l'utiliser
    if (parentKeyOverride !== null) {
      newElement.parentKey = parentKeyOverride;
    }

    // Ajouter le nouvel élément
    dispatch({
      type: SVG_DIAGRAM_ACTIONS.ADD_ELEMENT,
      payload: newElement,
    });

    // Trouver les enfants de l'élément original
    const children = elements.filter((el) => el.parentKey === originalKey);

    // Dupliquer récursivement tous les enfants
    children.forEach((child) => {
      duplicateElementAndChildren(child.key, newElement.key);
    });

    return newElement.key;
  };

  // Lancer la duplication récursive et retourner la clé du nouvel élément racine
  return duplicateElementAndChildren(elementKey);
};

/**
 * Change an element's layout position (move forward/backward)
 * @param {string} elementKey - The key of the element to change
 * @param {string} direction - The direction to move ('forward' or 'back')
 */
export const changeElementLayout = (elementKey, direction) => ({
  type: SVG_DIAGRAM_ACTIONS.CHANGE_ELEMENT_LAYOUT,
  payload: { key: elementKey, direction },
});

/**
 * Toggle snap to grid
 * @param {boolean} snapEnabled - Whether snapping should be enabled
 * @param {number} gridSize - The grid size to use
 */
export const toggleSnapToGrid =
  (snapEnabled, gridSize = 50) =>
  (dispatch) => {
    dispatch(setGridVisible(snapEnabled));
    if (snapEnabled) {
      dispatch(setGridSize(gridSize));
    }
  };

/**
 * Handle click on SVG background (for tool interaction)
 * @param {Object} position - The click position {x, y}
 */
export const handleBackgroundClick = (position) => (dispatch, getState) => {
  const state = getState();
  const {
    currentAction,
    connectorMode,
    lineThickness,
    color,
    lineDrawingType,
  } = state.svgDiagram;

  // If in connector mode and active, clear the connector state (click on empty area)
  if (currentAction === 'drawing-connector' && connectorMode.active) {
    dispatch(
      setConnectorMode({
        active: false,
        sourceId: null,
        targetId: null,
      })
    );
    return;
  }

  // If in a drawing mode, handle starting a new line
  if (
    currentAction.startsWith('drawing-') &&
    !currentAction.includes('connector')
  ) {
    const pointWithId = {
      x: position.x,
      y: position.y,
      id: uuidv4(),
    };

    // For line drawing, start a new line with a single point
    if (lineDrawingType === 'line' || lineDrawingType === 'polyline') {
      const key = dispatch(createLineElement(lineDrawingType, [pointWithId]));
      dispatch(setDrawingMode(true));
      return key;
    }

    // For arrow lines, create with two close points that will be moved later
    if (
      lineDrawingType === 'arrow-line' ||
      lineDrawingType === 'bidirectional-line'
    ) {
      const secondPoint = {
        x: position.x + 10,
        y: position.y + 10,
        id: uuidv4(),
      };

      const key = dispatch(
        createLineElement(lineDrawingType, [pointWithId, secondPoint])
      );
      dispatch(setDrawingMode(true));
      return key;
    }
  }

  // Default behavior - deselect
  dispatch(setSelectedElement(null));
};

/**
 * Handle element click (for tool interaction)
 * @param {string} elementKey - The key of the clicked element
 */
export const handleElementClick = (elementKey) => (dispatch, getState) => {
  const state = getState();
  const { currentAction, connectorMode, elements } = state.svgDiagram;

  // Skip if the element doesn't exist
  if (!elements.find((el) => el.key === elementKey)) {
    return;
  }

  // In delete mode, delete the element
  if (currentAction === 'delete') {
    dispatch(deleteElement(elementKey));
    return;
  }

  // In connector mode, handle connector creation logic
  if (currentAction === 'drawing-connector') {
    if (!connectorMode.active) {
      // First click - set source
      dispatch(
        setConnectorMode({
          active: true,
          sourceId: elementKey,
          targetId: null,
        })
      );
    } else {
      // Second click - create connector and reset
      const sourceId = connectorMode.sourceId;

      // Don't connect to self
      if (sourceId !== elementKey) {
        dispatch(createConnectorElement(sourceId, elementKey));
      }

      // Reset connector mode
      dispatch(
        setConnectorMode({
          active: false,
          sourceId: null,
          targetId: null,
        })
      );
    }
    return;
  }

  // Default: select the element
  dispatch(setSelectedElement(elementKey));
};
