import { isArray, isObject } from 'lodash';

export const parseJson = (data) => {
  try {
    return JSON.parse(data);
  } catch (error) {
    if (isArray(data)) return [];
    if (isObject(data)) return {};
    return null;
  }
};
