import styled from 'styled-components';

const HEADER_HEIGHT = 100;
export const NavigHeaderBarContainer = styled.div({
  backgroundColor: '',
  boxShadow: '1px 0px 1px #AAAAAA',
  height: HEADER_HEIGHT,
  minWidth: '100%',
  opacity: 1,
  position: 'sticky',
  userSelect: 'none',
  width: '2600px',
  zIndex: 10000,
  // top: (props) => props.scrollY,
  top: (props) => props.top,

  left: (props) => props.left,
});

const GET_HEADERBAR_STYLE = (props) => {
  return {
    backgroundColor: '',
    // boxShadow: '1px 0px 1px #CCCCCC',
    height: HEADER_HEIGHT,
    left: props.left,
    minWidth: '100%',
    opacity: 1,
    position: 'sticky',
    top: '0px',
    userSelect: 'none',
    width: '2600px',
    zIndex: 10000,
  };
};

export const IconExpandColumn = styled.div({
  width: '16px',
});
