import styled from 'styled-components';
import { devices } from '../../../constants/devices';
import { DATA_COLUMNS_ICON_OFFSET } from '../../../constants/layout';

export const PhoneContent = styled.div({
  backgroundColor: '#6560A8',
  border: '0px solid #BBBBBB',
  borderBottomRightRadius: '5px',
  borderTopRightRadius: '5px',
  color: 'white',
  fontSize: '16px',
  height: '45px',
  left: '258px',
  paddingTop: '10px',
  position: 'absolute',
  textAlign: 'center',
  textJustify: 'auto',
  top: (props) => props.top,
  verticalAlign: 'middle',
  width: '40px',
});

export const NodeForm = styled.div({
  border: '0px solid red',
  borderRadius: '3px',
  backgroundColor: 'inherit',
  height: 'auto',
});
//------------------------------------------------------------------------------

export const NodeTitle = styled.div({
  border: `3px 1px 0px 0px solid var(--ds-border-base)`,
  borderRadius: '2px',
  color: 'var(--ds-text-title)',
  cursor: 'pointer',
  fontFamily: 'proxima-nova, sans-serif',
  fontSize: (props) => (props.zoom < 80 ? 13 * (100 / props.zoom) : '13px'),
  fontStyle: 'normal',
  fontWeight: 'bold',
  minHeight: '20px',
  lineHeight: '20px',
  marginBottom: '0px',
  marginTop: '1px',
  maxHeight: '60px',
  overflow: 'hidden',
  paddingBottom: '3px',
  paddingLeft: '5px',
  textOverflow: 'ellipsis',
  textWrap: 'wrap',
  width: '190px',
  '& .titleIcon svg': {
    paddingTop: '0px',
    marginBottom: '-3px',
    marginLeft: '-5px',
    marginRight: '5px',
    icon: 'cursor',
  },
});

//------------------------------------------------------------------------

export const IconImgStyle = styled.img({
  filter: (props) =>
    props.dark ? 'opacity(0.6) invert(100%) ' : 'opacity(0.8)',
  float: 'right',
  height: '16px',
  margin: '1px',
  marginRight: '2px',
  marginTop: '6px',
  opacity: '1',
  width: '16px',
  '&:first-child': {
    width: `${DATA_COLUMNS_ICON_OFFSET}px`,
    paddingRight: '8px',
  },
});
//------------------------------------------------------------------------
export const SpanNavigContentStyle = styled.div({
  // backgroundColor: (props) =>
  //   props.zoom < 80 || !props.isNodeOnActivePanel ? '#FFFFFF' : 'inherit',
  backgroundColor: (props) => 'inherit',
  display: (props) => (props.isGrid ? 'grid' : ''),
  gridTemplate: (props) =>
    props.isGrid ? `var(--grid-template-${props.level})` : '',
  overflow: (props) => (props.isGrid ? 'hidden' : ''),
  color: '#CCCCCC',
  cursor: 'pointer',
  fontFamily: 'proxima-nova',
  fontSize: (props) => props.fontSize,
  fontStyle: 'normal',
  fontWeight: 200,
  letterSpacing: '0.5px',
  marginTop: '0px',
  opacity: (props) => (props.isNodeOnActivePanel ? 1 : 0.6),
  overFlow: 'hidden',
  padding: '10px',
  paddingBottom: 0,
  paddingTop: 3,
  textOverflow: 'ellipsis',
  // textTransform: 'capitalize',
  whiteSpace: 'nowrap',
  border: '0px solid red',
  [devices.tablet]: {
    // touchAction: (props) => (props.isDisableSwipeMobile ? 'pan-left' : 'auto'),
    // touchAction: 'pan-right',
    backgroundColor: (props) => 'inherit',
  },
});

//------------------------------------------------------------------------------

//------------------------------------------------------------------------------
export const DivBlocNavigNode = styled.div({
  backgroundColor: 'inherit',
  borderRadius: '15px',
  color: 'black',
  height: 'auto',
  left: 0,
  minHeight: '30px',
  opacity: (props) => (props.dragging ? 0 : 1),
  top: 0,
  width: (props) => props.width,
  [devices.tablet]: {
    position: 'sticky',
  },
});
export const IconActionNavigNodeSvg = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  width: 18,
  height: 18,
  opacity: '0.6',
  ':hover': {
    opacity: '1',
  },
  margin: '0px 3px',
});

export const ChildrenContent = styled.div({
  paddingLeft: '200px',
  left: '200px',
});

export const NavigTooltip = styled.div({
  bottom: '50%',
  height: '20px',
  position: 'absolute',
  left: '55px',
  top: '-52px',
  width: 'auto',
  zIndex: '29999',
  [devices.tablet]: {
    display: 'none',
  },
});

export const WapImgCollapse = styled.div({
  [devices.tablet]: {
    display: 'none',
  },
});
