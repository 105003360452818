import React from 'react';
import { connect } from 'react-redux';
import { Tooltip } from '@mantine/core';
import {
  ObjGraphsBubbleHtmlContent,
  ObjGraphsBubbleHtmlStyles,
  NavigationButton,
  NavigationContainer,
  TableContainer,
} from './ObjGraphsBubbleHtmlCSS';
import { abbreviateName } from '../../../Utils/TextUtils';
import { formatNumber } from '../../../Utils/Utils';
import { Avatar } from '../../Avatar/Avatar';

class ObjGraphsBubbleHtml extends React.Component {
  static defaultProps = {
    displayTotalsX: false,
    displayTotalsY: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0,
      columnsPerPage: 3,
      animation: null,
      direction: 'next',
    };

    this.containerRef = React.createRef();
  }

  componentDidMount() {
    this.updateColumnsPerPage();
    window.addEventListener('resize', this.updateColumnsPerPage);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateColumnsPerPage);
  }

  updateColumnsPerPage = () => {
    if (this.containerRef.current) {
      const containerWidth =
        this.containerRef.current.getBoundingClientRect().width;
      const columnWidth = 45;
      const columnsPerPage = Math.floor(containerWidth / columnWidth);
      this.setState({ columnsPerPage: Math.max(columnsPerPage, 1) });
    }
  };

  changePage = (direction) => {
    const { currentPage } = this.state;
    const totalPages = Math.ceil(
      this.props.data.length / this.state.columnsPerPage
    );
    const newPage = direction === 'next' ? currentPage + 1 : currentPage - 1;

    if (newPage >= 0 && newPage < totalPages) {
      this.setState({ animation: 'out', direction });
      setTimeout(() => {
        this.setState({
          currentPage: newPage,
          animation: 'in',
        });
      }, 300);
    }
  };

  getDisplayValue = (rawVal, index) => {
    if (this.props?.profiles[rawVal]) {
      return (
        <Avatar
          key={`av_${rawVal}${index}`}
          objId={rawVal}
          ratio={0.7}
          marginTop={7}
        />
      );
    }
    const dateTrg = new Date(Number(rawVal));
    if (!isNaN(dateTrg)) {
      return `${dateTrg.getDate()}/${dateTrg.getMonth() + 1}`;
    }
    return abbreviateName(rawVal);
  };

  calculateTotals = (matrix, xses, yses) => {
    const rowTotals = new Array(xses.length).fill(0);
    const colTotals = new Array(yses.length).fill(0);
    let hasNonNumeric = false;

    for (let i = 0; i < xses.length; i++) {
      for (let j = 0; j < yses.length; j++) {
        const val = matrix[i][j];
        if (typeof val === 'number' && !isNaN(val)) {
          rowTotals[i] += val;
          colTotals[j] += val;
        } else {
          hasNonNumeric = true;
        }
      }
    }

    return { rowTotals, colTotals, hasNumericValues: !hasNonNumeric };
  };

  render() {
    const { currentPage, columnsPerPage, animation, direction } = this.state;
    const { displayTotalsX, displayTotalsY } = this.props;
    const startIndex = currentPage * columnsPerPage;
    const endIndex = startIndex + columnsPerPage;

    const formulaCat1 = this.props.formulaCat1;
    const formulaCat2 = this.props.formulaCat2;
    const refValue = this.props.refValue;
    const totalPages = Math.ceil(
      this.props.data.length / this.state.columnsPerPage
    );

    let xses = [
      ...new Set(this.props.data.map((item) => item[formulaCat1])),
    ].sort((a, b) => {
      return typeof a === 'number' && typeof b === 'number'
        ? a - b
        : a.toString().localeCompare(b.toString());
    });

    let yses = [
      ...new Set(this.props.data.map((item) => item[formulaCat2])),
    ].sort((a, b) => {
      return typeof a === 'number' && typeof b === 'number'
        ? a - b
        : a.toString().localeCompare(b.toString());
    });

    const matrix = Array(xses.length)
      .fill()
      .map(() => Array(yses.length).fill(0));
    let maxAbsValue = 1;

    for (const item of this.props.data) {
      const xval = item[formulaCat1];
      const yval = item[formulaCat2];
      const resval = item[Object.keys(item)[0]];
      const matx = xses.indexOf(xval);
      const maty = yses.indexOf(yval);
      matrix[matx][maty] = (matrix[matx][maty] || 0) + Number(resval);
      maxAbsValue = Math.max(maxAbsValue, Math.abs(matrix[matx][maty]));
    }

    const { rowTotals, colTotals, hasNumericValues } = this.calculateTotals(
      matrix,
      xses,
      yses
    );
    const calcRefValue = refValue ?? maxAbsValue;
    const ratio = 30 / calcRefValue;

    const lines = [];

    if (displayTotalsY && hasNumericValues) {
      const totalHeader = [<td key="total-header">Totaux</td>];
      for (let j = startIndex; j < Math.min(endIndex, yses.length); j++) {
        totalHeader.push(
          <td key={`total-col-${j}`} style={ObjGraphsBubbleHtmlStyles.td}>
            <div style={ObjGraphsBubbleHtmlStyles.total}>
              {formatNumber(colTotals[j])}
            </div>
          </td>
        );
      }
      if (displayTotalsX) {
        totalHeader.push(<td key="total-header-spacer" />);
      }
      lines.push(<tr key="column-totals">{totalHeader}</tr>);
    }

    for (let i = 0; i < xses.length; i++) {
      const line = [
        <Tooltip
          key={`tt_${i}`}
          label={this.props?.profiles[xses[i]]?.name ?? xses[i]}
        >
          <td
            key={`td${i}`}
            title={xses[i]}
            style={ObjGraphsBubbleHtmlStyles.xses}
          >
            {this.getDisplayValue(xses[i], i)}
          </td>
        </Tooltip>,
      ];

      for (let j = startIndex; j < Math.min(endIndex, yses.length); j++) {
        const val = matrix[i][j];
        line.push(
          <td key={`tt_${i}${j}`} style={ObjGraphsBubbleHtmlStyles.td}>
            <div style={ObjGraphsBubbleHtmlStyles.bubbleBg}>
              {val !== 0 && (
                <ObjGraphsBubbleHtmlContent
                  lineHeight={`${
                    ratio * Math.min(calcRefValue, Math.abs(val))
                  }px`}
                  height={`${ratio * Math.min(calcRefValue, Math.abs(val))}px`}
                  width={`${ratio * Math.min(calcRefValue, Math.abs(val))}px`}
                  pal={this.props.pal}
                  aboveRef={Math.abs(val) > calcRefValue}
                  isNegative={val < 0}
                >
                  {isNaN(val) ? val : formatNumber(val)}
                </ObjGraphsBubbleHtmlContent>
              )}
            </div>
          </td>
        );
      }

      if (displayTotalsX && hasNumericValues) {
        line.push(
          <td key={`row-total-${i}`} style={ObjGraphsBubbleHtmlStyles.td}>
            <div style={ObjGraphsBubbleHtmlStyles.total}>
              {formatNumber(rowTotals[i])}
            </div>
          </td>
        );
      }

      lines.push(<tr key={`l_${i}`}>{line}</tr>);
    }

    const navigationRow = [
      <td key="td_navigContainr">
        <NavigationContainer disabled={totalPages < 2}>
          <NavigationButton
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              e.nativeEvent.stopImmediatePropagation();
              this.changePage('prev');
            }}
            onMouseUp={(e) => e.stopPropagation()}
            disabled={currentPage === 0}
          >
            &lt;
          </NavigationButton>
          <NavigationButton
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              e.nativeEvent.stopImmediatePropagation();
              this.changePage('next');
            }}
            onMouseUp={(e) => e.stopPropagation()}
            disabled={endIndex >= yses.length}
          >
            &gt;
          </NavigationButton>
        </NavigationContainer>
      </td>,
    ];

    for (let j = startIndex; j < Math.min(endIndex, yses.length); j++) {
      navigationRow.push(
        <td key={`td_${j}`} style={ObjGraphsBubbleHtmlStyles.date}>
          {this.getDisplayValue(yses[j], j)}
        </td>
      );
    }

    if (displayTotalsX && hasNumericValues) {
      navigationRow.push(<td key="nav-total-spacer" />);
    }

    lines.push(<tr key="lasttr">{navigationRow}</tr>);

    return (
      <div ref={this.containerRef} style={ObjGraphsBubbleHtmlStyles.content}>
        <div>
          <div style={ObjGraphsBubbleHtmlStyles.table}>
            <TableContainer animation={animation} direction={direction}>
              <tbody>{lines}</tbody>
            </TableContainer>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    profiles: state.objects.profiles,
  };
}

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ObjGraphsBubbleHtml);
