import React, { useCallback, useEffect, useState, memo } from 'react';
import throttle from 'lodash/throttle';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import ObjectActionCreators from '../../../actions';
import { closeObjectDetails } from '../../../reducers/layoutReducer/action';
import {
  scrollXSelector,
  zoomSelector,
} from '../../../reducers/layoutReducer/layoutSelector';
import { onResizePaneTablet } from '../../../reducers/responsiveReducer/action';
import {
  isDragTabletSelector,
  isMobileSelector,
} from '../../../reducers/responsiveReducer/responsiveSelector';
import { isTablet } from '../../../utils/detectDevices';
import {
  StyledResizePane,
  StyledResizePaneBar,
} from './NavigTreeResizePaneCSS';
import { usePanelsConfig } from '../NavigTree/usePanelsConfig';

const NavigTreeResizePane = memo(({ id, width, level, children }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // Redux selectors
  const zoom = useSelector(zoomSelector);
  const scrollX = useSelector(scrollXSelector);
  const activePanel = useSelector((state) => state.objects.activePanel);
  const isMobile = useSelector(isMobileSelector);
  const isDragTablet = useSelector(isDragTabletSelector);

  const { updatePanelConfig } = usePanelsConfig();
  // Local state
  const [dragState, setDragState] = useState({
    barMouseDown: null,
  });

  const handleMouseUp = useCallback(() => {
    document.body.style.userSelect = '';
    setDragState({ barMouseDown: null });
  }, []);

  // Throttle pour limiter les mises à jour Redux et éviter trop d'actions
  const throttledUpdatePanelConfig = useCallback(
    throttle(updatePanelConfig, 100),
    [updatePanelConfig]
  );

  const handleMouseMove = useCallback(
    (evt) => {
      if (!dragState.barMouseDown) return;
      throttledUpdatePanelConfig(level, evt.clientX);
    },
    [dragState.barMouseDown, throttledUpdatePanelConfig, scrollX]
  );

  const handleMouseDown = useCallback(() => {
    document.body.style.userSelect = 'none';
    setDragState({ barMouseDown: true });
  }, []);

  const handleOver = useCallback(() => {
    if (activePanel !== level) {
      dispatch(ObjectActionCreators.setActivePanel(level));
    }
  }, [activePanel, level, dispatch]);

  const handleClick = useCallback(() => {
    dispatch(ObjectActionCreators.selectObject(null));
    dispatch(closeObjectDetails());
  }, [dispatch]);

  // Ajouter et retirer les listeners de mouvement de souris
  useEffect(() => {
    if (dragState.barMouseDown) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
      window.addEventListener('touchmove', handleMouseMove);
      window.addEventListener('touchend', handleMouseUp);
    } else {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('touchmove', handleMouseMove);
      window.removeEventListener('touchend', handleMouseUp);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('touchmove', handleMouseMove);
      window.removeEventListener('touchend', handleMouseUp);
    };
  }, [dragState.barMouseDown, handleMouseMove, handleMouseUp]);

  return (
    <StyledResizePane
      id={`StyledResizePane--${id}`}
      isDragTablet={isDragTablet}
      zoom={zoom}
      width={width}
      onMouseOver={handleOver}
      onClick={handleClick}
      backgroundColor="var(--ds-bg-secondary)"
      column={id}
      isMobile={isMobile}
      innerWidth={window.innerWidth}
    >
      <ResizePaneBar
        level={level}
        id={id}
        onMouseDown={handleMouseDown}
        onTouchStart={handleMouseDown} // Support mobile
        onMouseUp={handleMouseUp}
        onTouchEnd={handleMouseUp} // Support mobile
      />
      {children}
    </StyledResizePane>
  );
});

const ResizePaneBar = memo(({ level, id, onMouseDown, onMouseUp }) => {
  const dispatch = useDispatch();

  const handleTouchStart = useCallback(
    (e) => {
      document.body.style.userSelect = 'none';
      dispatch(onResizePaneTablet(true));
      onMouseDown(e);
    },
    [dispatch, onMouseDown]
  );

  const handleTouchEnd = useCallback(
    (e) => {
      document.body.style.userSelect = '';
      dispatch(onResizePaneTablet(false));
      onMouseUp(e);
    },
    [dispatch, onMouseUp]
  );

  return (
    <StyledResizePaneBar
      id={id}
      level={level}
      draggable={false}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    />
  );
});

NavigTreeResizePane.displayName = 'NavigTreeResizePane';
ResizePaneBar.displayName = 'ResizePaneBar';

export default NavigTreeResizePane;
