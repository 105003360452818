import React from 'react';
import ReactDOM from 'react-dom';

const ICONSIZE = 12;

class APortal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.elBackground = document.createElement('div');
    this.elBackground.style.width = '100%';
    this.elBackground.style.position = 'absolute';
    this.elBackground.style.top = 0;
    this.elBackground.style.left = 0;
    this.elBackground.style.height = '100%';
    this.elBackground.style.backgroundColor = 'var(--ds-bg-base)';
    this.elBackground.style.opacity = '0.0';
    this.elBackground.style.zIndex = '11111111111111100';
    this.elBackground.onclick = props.onClose;
    this.el = document.createElement('div');
    this.el.style.position = 'absolute';
    this.el.style.top = 0;
    this.el.style.left = 0;
    this.el.style.height = 'auto';
    this.el.style.backgroundColor = 'var(--ds-bg-base)';
    this.el.style.border = '1px solid var(--ds-border-base)';
    this.el.style.borderRadius = '5px';
    this.el.style.zIndex = '11111111111111111';
    this.el.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.3)';
    this.el.onmouseup = (e) => e.stopPropagation();
    this.el.style.overflowY = 'auto';
  }

  setChildPosition() {
    try {
      const viewHeight = document.documentElement.clientHeight;
      const viewWidth = document.documentElement.clientWidth;
      const id = this.props.idParent;
      const elemParent = document.getElementById(id);
      const dimensions = elemParent.getBoundingClientRect();
      this.el.style.left = `${Math.min(
        dimensions.left,
        viewWidth - (this.el.clientWidth + 30)
      )}px`;

      this.el.style.top = `${Math.min(
        dimensions.top,
        viewHeight - (this.el.clientHeight + 30)
      )}px`;
    } catch (e) {}
  }

  componentDidMount() {
    try {
      document.body.appendChild(this.elBackground);
      document.body.appendChild(this.el);

      if (this.props.width) {
        this.el.style.maxWidth = `${this.props.width}px`;
      }
      if (this.props.height) {
        this.el.style.maxHeight = `${this.props.height}px`;
      }
      this.setChildPosition();
    } catch (e) {
      console.log(e);
    }
  }

  componentDidUpdate() {
    try {
      this.setChildPosition();
    } catch (e) {
      console.log(e);
    }
  }

  componentWillUnmount() {
    try {
      document.body.removeChild(this.elBackground);
      document.body.removeChild(this.el);
    } catch (e) {
      console.log(e);
    }
  }

  handleMouseUp = (e) => {
    e.stopPropagation();
  };

  render() {
    const childs = (
      <div onMouseUp={this.handleMouseUp}>{this.props.children}</div>
    );
    return ReactDOM.createPortal(childs, this.el);
  }
}
export default APortal;
