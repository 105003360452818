import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ObjectActionCreators from '../../../actions';
import { SliderContainer, SliderArrow } from './NavigHorizontalSliderCSS';

export const NavigHorizontalSlide = ({ width, level }) => {
  const dispatch = useDispatch();
  const timeline_shift = useSelector((state) => state.objects.timeline_shift);
  const timelineShift = (level, value) => {
    dispatch(ObjectActionCreators.timelineShift(level, value));
  };
  return (
    <SliderContainer width={width}>
      <SliderArrow onClick={() => timelineShift(level, -4)}>
        &lt;&lt;
      </SliderArrow>
      <SliderArrow onClick={() => timelineShift(level, -1)}> &lt; </SliderArrow>
      {/* {timeline_shift[level]} */}
      <SliderArrow onClick={() => timelineShift(level, 1)}> &gt; </SliderArrow>
      <SliderArrow onClick={() => timelineShift(level, 4)}>
        &gt;&gt;
      </SliderArrow>
    </SliderContainer>
  );
};
