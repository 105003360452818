import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce, isEmpty } from 'lodash';

import { useTranslation } from 'react-i18next';
import APortal from '../../UI/APortal/APortal.js';
import BigNumber from '../../UI/BigNumber/BigNumber';
import BigMonetary from '../../UI/BigMonetary/BigMonetary';
import ProgressBar from '../../UI/ProgressBar/ProgressBar';
import { isValidColor, lighten } from '../../Utils/colorManipulator';
import { getHost } from '../../../helper/getHost';
import MiniChartSVG from '../../UI/MiniChartSVG/MiniChartSVG';
import ObjFieldCamRec from '../../UI/Objects/ObjFieldCamRec/ObjFieldCamRec.js';
import ObjFieldDrawingPad from '../../UI/Objects/ObjFieldDrawingPad/ObjFieldDrawingPad';
import ObjFieldFormula from '../../UI/Objects/ObjFields/ObjFieldFormula';
import ObjFieldDate from '../../UI/Objects/ObjFields/ObjFieldDate.js';
import ObjFieldList from '../../UI/Objects/ObjFields/ObjFieldList.js';
import ObjectSelector from '../../object-selector/index.js';
import {
  removeTagHtml,
  formatNumber,
  formatMonetary,
  isLinkedField,
  getTypeOfField,
  isLinkedType,
  getObjectDefField,
} from '../../Utils/Utils';
import {
  DivSmallAvatarStyle,
  DivSmallTagAreaStyle,
  DivSmallTagNumberStyle,
  DivSmallTagStyle,
  FieldCamrecContent,
  FieldFile,
  FieldFileContainer,
  FieldListContent,
  HalfBlocSmallTag,
  HalfBlocSmallTagLabelStyle,
  NeutralDiv,
  PercentFieldContent,
  SpanSmallAvatarLabelStyle,
  SpanSmallAvatarValueStyle,
  SpanSmallTagAreaValueStyle,
  SpanSmallTagLabelStyle,
  SpanSmallTagValueStyle,
  TextFieldUrlContent,
} from './SmallTagsCSS.js';
import { parseJson } from '../../../utils/parseJson.js';
import ObjectActionCreators from '../../../actions.js';
import TiptapEditor from '../../UI/Objects/ObjFields/TiptapEditor';
import { Avatar } from '../../UI/Avatar/Avatar.js';
import { removeQuote } from '../../Utils/TextUtils.js';
import ReduxDrawingPadWrapper from '../../UI/Objects/ObjFieldDrawingPad/ReduxDrawingPadWrapper.js';

const SHOW_AREA_LABEL = false;
export default function SmallTag({
  field,
  ind,
  nodeColor,
  dicoNameRef,
  pal,
  onLoad,
  objectsDefList,
  zoneType,
  objId,
  node,
}) {
  let res = '';
  let data = field.value;
  const [isEditing, setIsEditing] = useState(false);
  const [editValue, setEditValue] = useState(data);
  const dicoFullRef = useSelector((state) => state.objects.dicoFullRef);
  const dispatch = useDispatch();
  let canWrite = true; // XXX todo

  const typeOfField = getTypeOfField(
    objectsDefList,
    field.type,
    field.attrName
  );
  const isLink = isLinkedField(objectsDefList, field.type, field.attrName);

  const handleBlur = (e) => {
    setEditValue(e.target.value);
    setIsEditing(false);
  };

  const handleChangeDate = (name, date) => {
    setEditValue(date);
    setIsEditing(false);
  };
  const changeValue = (newValue) => {
    setIsEditing(false);
  };

  const handlePreventInputEvent = (e) => {
    e.stopPropagation();
    // e.preventDefault(); remving default behavior would impact +/- ui on input field
  };

  const debouncedSave = debounce((objFormData) => {
    dispatch(ObjectActionCreators.saveObjectFormData(objId, [objFormData]));
  }, 500);

  useEffect(() => {
    if (editValue !== data) {
      let objFormData = {
        type: field.type,
        key: objId,
        attrName: field.attrName,
        value: editValue,
        defaultValue: editValue,
      };
      if (canWrite) {
        debouncedSave(objFormData);
      }
    }
  }, [editValue]);

  let objFormData = getObjectDefFromType(
    field.type,
    objectsDefList
  )?.objFormData;
  if (objFormData) {
    let fldObj = getFieldDef(field.attrName, objFormData);
    let label = fldObj.label;
    let value = field.value;
    if (fldObj != null) {
      if (fldObj.type === 'formula' && fldObj.formulaType === 'parentValue') {
        // pour un parentValue on va adatper les données pour coller au type visé
        value = removeQuote(value);
        fldObj = getObjectDefField(
          objectsDefList,
          fldObj.formulaObject,
          fldObj.formulaField
        );

        value = value.replace(/\\"/g, '"');
      }

      let type = fldObj.type;
      canWrite =
        node.data.canWrite && type !== 'formula' && fldObj.readOnly === 'false';
      let option = fldObj.options;
      let refValue = fldObj.refValue;
      let unit = fldObj.unit;
      let formulaType = fldObj.formulaType;
      let formulaObject = fldObj.formulaObject;
      let formulaField = fldObj.formulaField;
      let formulaCat1 = fldObj.formulaCat1;
      let formulaCat2 = fldObj.formulaCat2;
      if (isLinkedType(objectsDefList, type)) {
        value =
          typeof value === 'string'
            ? getDisplayValue(value, dicoNameRef)
            : value;
      }

      if (zoneType === 'zoneStandard') {
        switch (type) {
          case 'area':
            res =
              canWrite && isEditing ? (
                <div id={`portalArea${objId}`}>
                  <APortal
                    idParent={`portalArea${objId}`}
                    onClose={() => setIsEditing(false)}
                    width={600}
                    height={400}
                  >
                    <TiptapEditor
                      value={data}
                      onUpdate={(newValue) => setEditValue(newValue)}
                      editable={true}
                    />
                  </APortal>
                </div>
              ) : (
                <div
                  onMouseUp={(e) => e.stopPropagation()}
                  onClick={(e) => {
                    if (canWrite) setIsEditing(true);
                  }}
                >
                  {_fieldArea(ind, label, value)}
                </div>
              );
            break;
          case 'date':
            res = isEditing ? (
              <div id={`portaldate${objId}`}>
                <APortal
                  idParent={`portaldate${objId}`}
                  onClose={() => setIsEditing(false)}
                >
                  <ObjFieldDate
                    isEditing={isEditing}
                    readOnly={!canWrite}
                    handleChangeFieldValue={handleChangeDate}
                    value={data}
                    options={option}
                  />
                </APortal>
              </div>
            ) : (
              <div
                onMouseUp={(e) => e.stopPropagation()}
                onClick={(e) => {
                  if (canWrite) setIsEditing(true);
                }}
              >
                {_fieldDate(ind, label, value)}
              </div>
            );

            break;
          case 'text':
            res =
              canWrite && isEditing ? (
                <input
                  onMouseUp={handlePreventInputEvent}
                  type="text"
                  defaultValue={data}
                  onChange={(e) => setEditValue(e.target.value)}
                  onBlur={changeValue}
                  autoFocus
                  style={{ width: '100%', height: '100%', fontSize: 12 }}
                />
              ) : (
                <div
                  onMouseUp={(e) => e.stopPropagation()}
                  onClick={(e) => {
                    if (canWrite) setIsEditing(true);
                  }}
                >
                  {_fieldText(ind, label, value)}
                </div>
              );

            break;
          case 'number':
            switch (option) {
              case 'progressbar':
                res = isNaN(+data) ? (
                  <div></div>
                ) : canWrite && isEditing ? (
                  <input
                    type="number"
                    defaultValue={data}
                    onMouseUp={handlePreventInputEvent}
                    onBlur={handleBlur}
                    style={{ width: '100%', height: '100%', fontSize: 12 }}
                    autoFocus
                  />
                ) : (
                  <div
                    onMouseUp={(e) => e.stopPropagation()}
                    onClick={(e) => {
                      if (canWrite) setIsEditing(true);
                    }}
                  >
                    {_progessBarNumber(
                      ind,
                      label,
                      value,
                      pal,
                      nodeColor,
                      dicoNameRef
                    )}
                  </div>
                );

                break;
              case 'bignumber':
                break;
              case 'bigmonetary':
                break;
              default:
                res = isNaN(+data) ? (
                  <div></div>
                ) : canWrite && isEditing ? (
                  <input
                    type="number"
                    defaultValue={data}
                    onMouseUp={handlePreventInputEvent}
                    onBlur={handleBlur}
                    style={{ width: '100%', height: '100%', fontSize: 12 }}
                    autoFocus
                  />
                ) : (
                  <div
                    onMouseUp={(e) => e.stopPropagation()}
                    onClick={(e) => {
                      if (canWrite) setIsEditing(true);
                    }}
                  >
                    {_fieldNumber(ind, label, value, unit)}
                  </div>
                );
                break;
            }

            break;
          case 'camrec':
            res = _fieldCamrec(ind, label, value, pal, field);
            break;
          case 'file':
            res = _fieldFile(ind, label, value, field, onLoad);
            break;
          case 'drawing':
            res = _fieldDrawing(ind, label, value, field);

            break;
          case 'list':
            break;
          case 'formula':
            switch (option) {
              case 'bignumber':
                break;
              case 'bigMonetary':
                break;
              default:
                res = _fieldformula(
                  ind,
                  label,
                  value,
                  unit,
                  pal,
                  fldObj,
                  dicoNameRef,
                  formulaCat1,
                  formulaCat2
                );
                break;
            }
            break;
          default:
            if (isLink) {
              res = isEditing ? (
                <div id={`portallink${objId}`}>
                  <APortal
                    idParent={`portallink${objId}`}
                    onClose={() => setIsEditing(false)}
                  >
                    <ObjectSelector
                      typeSelectedObject={typeOfField}
                      onSelectValue={(value) => {
                        setEditValue(value);
                        setIsEditing(false);
                      }}
                      isDisplaySummary={false}
                      isDisplayTitle={false}
                      isDisplayConfirm={false}
                      height={400}
                    />
                  </APortal>
                </div>
              ) : (
                <div
                  onMouseUp={(e) => e.stopPropagation()}
                  onClick={(e) => {
                    if (canWrite) setIsEditing(true);
                  }}
                >
                  {field.value.includes('emplo')
                    ? _fieldAvatar(ind, label, value, field.value)
                    : _fieldDefault(ind, label, value)}
                </div>
              );
            } else {
              res = _fieldDefault(ind, label, value);
            }

            break;
        }
      } else if (zoneType === 'zoneBigNumber') {
        switch (type) {
          case 'number':
            switch (option) {
              case 'percent':
                res = isNaN(+data) ? (
                  <div></div>
                ) : canWrite && isEditing ? (
                  <input
                    type="number"
                    defaultValue={data}
                    onMouseUp={handlePreventInputEvent}
                    onBlur={handleBlur}
                    style={{ width: '100%', height: '100%', fontSize: 12 }}
                    autoFocus
                  />
                ) : (
                  <NeutralDiv
                    onMouseUp={(e) => e.stopPropagation()}
                    onClick={(e) => {
                      if (canWrite) setIsEditing(true);
                    }}
                  >
                    {_percentNumber(ind, label, value, nodeColor, unit)}
                  </NeutralDiv>
                );

                break;
              case 'bignumber':
                res = isNaN(+data) ? (
                  <div></div>
                ) : canWrite && isEditing ? (
                  <input
                    type="number"
                    defaultValue={data}
                    onMouseUp={handlePreventInputEvent}
                    onBlur={handleBlur}
                    style={{ width: '100%', height: '100%', fontSize: 12 }}
                    autoFocus
                  />
                ) : (
                  <NeutralDiv
                    onMouseUp={(e) => e.stopPropagation()}
                    onClick={(e) => {
                      if (canWrite) setIsEditing(true);
                    }}
                  >
                    {_bigNumber(
                      ind,
                      label,
                      isNaN(+value) ? '' : formatNumber(value),
                      nodeColor,
                      unit
                    )}
                  </NeutralDiv>
                );
                break;
              case 'bigmonetary':
                res = isNaN(+data) ? (
                  <div></div>
                ) : canWrite && isEditing ? (
                  <input
                    type="number"
                    defaultValue={data}
                    onMouseUp={handlePreventInputEvent}
                    onBlur={handleBlur}
                    style={{ width: '100%', height: '100%', fontSize: 12 }}
                    autoFocus
                  />
                ) : (
                  <NeutralDiv
                    onMouseUp={(e) => e.stopPropagation()}
                    onClick={(e) => {
                      if (canWrite) setIsEditing(true);
                    }}
                  >
                    {_bigNumber(
                      ind,
                      label,
                      formatMonetary(value),
                      nodeColor,
                      unit
                    )}
                  </NeutralDiv>
                );
                break;
              default:
                break;
            }

            break;
          case 'formula':
            switch (option) {
              case 'bignumber':
                res = _bigNumber(
                  ind,
                  label,
                  isNaN(+value) ? '' : formatNumber(value),
                  nodeColor,
                  unit
                );

                break;
              case 'bigmonetary':
                res = _bigMonetary(
                  ind,
                  label,
                  isNaN(+value) ? '' : formatMonetary(value),
                  nodeColor,
                  unit
                );
                break;
              default:
                break;
            }
            break;
          default:
            break;
        }
      } else if (zoneType === 'zoneBadge') {
        switch (type) {
          case 'list':
            let entry = dicoFullRef[data];

            res = isEditing ? (
              <div id={`portalliste${objId}`}>
                <APortal
                  idParent={`portalliste${objId}`}
                  onClose={() => setIsEditing(false)}
                >
                  <ObjFieldList
                    isEditing={isEditing}
                    readOnly={!canWrite}
                    handleChangeFieldValue={handleChangeDate}
                    value={data}
                    options={option}
                    unit={unit}
                    orientation="vertical"
                  />
                </APortal>
              </div>
            ) : (
              <NeutralDiv
                onMouseUp={(e) => e.stopPropagation()}
                onClick={(e) => {
                  if (canWrite) setIsEditing(true);
                }}
              >
                {_fieldList(ind, label, value, fldObj, nodeColor, unit)}
              </NeutralDiv>
            );
            break;

          default:
            break;
        }
      }
    }
  }

  return res;
}

export const getFieldDef = (fieldName, objFormData) =>
  objFormData.filter((fld) => fld.attrName === fieldName)[0];

//------------------------------------------------------------------------------
export const getDisplayValue = (value, dico) =>
  dico[value] ? dico[value].substring(0, 15) : value;
//------------------------------------------------------------------------------
export const _fieldArea = (ind, label, value) =>
  value ? (
    <AreaField key={ind + label} label={label} value={value}></AreaField>
  ) : null;
//------------------------------------------------------------------------------
export const _fieldDate = (ind, label, value) => {
  let aDate = new Date(parseInt(value));
  if (aDate.toLocaleDateString() === 'Invalid Date') {
    aDate = '-';
  } else {
    aDate = aDate.toLocaleDateString();
  }
  return <TextField key={ind + label} label={label} value={aDate}></TextField>;
};
//------------------------------------------------------------------------------
export const _fieldText = (ind, label, value) => (
  <TextField key={ind + label} label={label} value={value}></TextField>
);
//------------------------------------------------------------------------------
export const _fieldNumber = (ind, label, value = '', unit = '') => (
  <NumberField
    key={ind + label}
    label={label}
    value={value}
    unit={unit}
  ></NumberField>
);
//------------------------------------------------------------------------------
export const _bigNumber = (ind, label, value, nodeColor, unit = '') => (
  <BigNumberField
    key={ind + label}
    label={label}
    value={value}
    unit={unit}
    nodeColor={nodeColor}
  ></BigNumberField>
);
//------------------------------------------------------------------------------
export const _bigMonetary = (ind, label, value, nodeColor, unit = '') => (
  <BigMonetaryField
    key={ind + label}
    label={label}
    value={value}
    unit={unit}
    nodeColor={nodeColor}
  ></BigMonetaryField>
);
//------------------------------------------------------------------------------
export const _percentNumber = (ind, label, value, nodeColor, unit = '') => (
  <PercentField
    key={ind + label}
    label={label}
    value={value}
    unit={unit}
    nodeColor={nodeColor}
  ></PercentField>
);
//------------------------------------------------------------------------------

//------------------------------------------------------------------------------
export const _fieldCamrec = (ind, label, value, pal, field) => {
  let res = value != null && value.length > 0 && (
    <FieldCamrecContent key={ind + label}>
      <ObjFieldCamRec
        mini={true}
        readOnly={true}
        keyParam={field.keyParam}
        value={value + ind}
        pal={pal}
      />
    </FieldCamrecContent>
  );
  return res;
};
//------------------------------------------------------------------------------

export const _fieldFile = (ind, label, value, field, onLoad) => {
  return (
    <FieldFileContainer key={ind + label}>
      <FieldFile
        alt=""
        onLoad={onLoad}
        src={`https://${getHost()}/guerillaPM3/DownloadFile/download/${value}/${
          field.fileOrigName
        }`}
      />
    </FieldFileContainer>
  );
};
//------------------------------------------------------------------------------
export const _fieldDrawing = (ind, label, value, field) => {
  return (
    <div key={ind + label}>
      <ReduxDrawingPadWrapper
        readOnly={true}
        keyParam={field.keyParam}
        value={value}
      />
    </div>
  );
};
//------------------------------------------------------------------------------
export const _fieldList = (
  ind,
  label,
  value,
  fldObj,
  nodeColorOrig,
  unit = ''
) => {
  try {
    let options = parseJson(fldObj.options);
    if (options) {
      let selected = options.find(
        (elem) => elem.value?.toLowerCase() === value?.toLowerCase()
      );

      let nodeColor = nodeColorOrig ?? 'var(--ds-base)';
      let nodeColorLight =
        nodeColorOrig && isValidColor(nodeColorOrig)
          ? lighten(nodeColorOrig, 0.9)
          : 'var(--ds-bg-secondary)';
      let nodeLabel = selected?.display ?? value;
      nodeColor = selected.color ?? nodeColor;

      let res = nodeLabel ? (
        <>
          <FieldListContent
            key={ind + label}
            backgroundColor={nodeColorLight}
            border={`0px solid var(--ds-border-intense)`}
            color={'var(--ds-text-base)'}
            title={label}
          >
            {selected?.icon && (
              <div
                style={{
                  border: '1px solid red',
                  position: 'relative',
                  display: 'inline',
                  width: '16px',
                  height: '16px',
                  backgroundColor: nodeColor,
                  mask: `url('${selected?.icon ?? '/img/alert.svg'}')`,
                  maskRepeat: 'no-repeat',
                  maskSize: '16px 16px',
                  right: '5px',
                  top: '3px',
                  opacity: '0.7',
                  color: 'transparent',
                }}
              >
                .
              </div>
            )}
            {nodeLabel}
            {unit}
          </FieldListContent>
        </>
      ) : (
        <></>
      );

      return res;
    }
  } catch (error) {}
};
//------------------------------------------------------------------------------
export const _progessBarNumber = (
  ind,
  label,
  value,
  pal,
  nodeColor,
  dicoNameRef
) => (
  <ObjFieldFormula
    mini={true}
    key={ind + label}
    value={JSON.stringify([{ result: value, status: label }])}
    pal={pal}
    options={JSON.stringify([{ type: 'percent' }])}
    dicoNameRef={dicoNameRef}
  ></ObjFieldFormula>
);

export const _fieldformula = (
  ind,
  label,
  value,
  unit = '',
  pal,
  fldObj,
  dicoNameRef,
  f1,
  f2
) => {
  let res;

  if (value.startsWith('[') && value.length > 2) {
    res = (
      <ObjFieldFormula
        key={ind + label}
        mini={true}
        formulaCat1={f1}
        formulaCat2={f2}
        value={value}
        pal={pal}
        options={fldObj.options}
        refValue={fldObj.refValue}
        dicoNameRef={dicoNameRef}
        label={label}
      ></ObjFieldFormula>
    );
  } else if (!value.startsWith('[')) {
    res = (
      <TextField
        key={ind + label}
        label={label}
        value={formatNumber(value)}
        unit={unit}
      ></TextField>
    );
  }
  return res;
};
//------------------------------------------------------------------------------
export const _fieldDefault = (ind, label, value) => {
  return <TextField key={ind + label} label={label} value={value}></TextField>;
};

//------------------------------------------------------------------------------
export const _fieldAvatar = (ind, label, value, avatarId) => {
  return (
    <AvatarField
      key={ind + label}
      label={label}
      value={value}
      avatarId={avatarId}
    ></AvatarField>
  );
};

//------------------------------------------------------------------------------
export const AreaField = ({ label, value }) => {
  let valueCleaned = removeTagHtml(value);
  return (
    <DivSmallTagAreaStyle title={`${label} - ${valueCleaned}`} id="small_tag">
      {SHOW_AREA_LABEL && (
        <SpanSmallTagLabelStyle> {label} </SpanSmallTagLabelStyle>
      )}
      <SpanSmallTagAreaValueStyle
        className="MarkdownMini"
        style={{ width: SHOW_AREA_LABEL ? '130px' : '100%' }}
      >
        {valueCleaned}
      </SpanSmallTagAreaValueStyle>
    </DivSmallTagAreaStyle>
  );
};
//------------------------------------------------------------------------------
export const NumberField = ({ label, value = '', unit = '' }) => {
  return (
    <DivSmallTagNumberStyle title={label}>
      <SpanSmallTagLabelStyle> {label} </SpanSmallTagLabelStyle>

      <SpanSmallTagValueStyle>
        {' '}
        {value} {unit}
      </SpanSmallTagValueStyle>
    </DivSmallTagNumberStyle>
  );
};
const ProgressBarField = ({ label, value }) => {
  return (
    <DivSmallTagNumberStyle title={label}>
      <SpanSmallTagLabelStyle>
        {' '}
        {label} <ProgressBar width={value} />
      </SpanSmallTagLabelStyle>

      <div style={{ width: '100%' }}> </div>
    </DivSmallTagNumberStyle>
  );
};
export const BigNumberField = ({
  label,
  value,
  unit = '',
  nodeColor = 'var(--ds-base)',
}) => {
  return (
    <HalfBlocSmallTag nodeColor={nodeColor}>
      <HalfBlocSmallTagLabelStyle>{label}</HalfBlocSmallTagLabelStyle>
      <BigNumber
        value={value}
        unit={unit}
        nodeColor={nodeColor ?? 'var(--ds-base)'}
      />
    </HalfBlocSmallTag>
  );
};
export const BigMonetaryField = ({
  label,
  value,
  nodeColor = 'var(--ds-base)',
}) => {
  return (
    <HalfBlocSmallTag nodeColor={nodeColor}>
      <HalfBlocSmallTagLabelStyle>{label}</HalfBlocSmallTagLabelStyle>
      <BigMonetary value={value} nodeColor={nodeColor} />
    </HalfBlocSmallTag>
  );
};
export const PercentField = ({
  label,
  value,
  unit,
  nodeColor = 'var(--ds-base)',
}) => {
  return (
    <HalfBlocSmallTag>
      <HalfBlocSmallTagLabelStyle>{label}</HalfBlocSmallTagLabelStyle>

      <PercentFieldContent nodeColor={nodeColor}>
        <MiniChartSVG val={value} size={32} /> {formatNumber(value)}
        {unit}
      </PercentFieldContent>
    </HalfBlocSmallTag>
  );
};

//------------------------------------------------------------------------------
export const AvatarField = ({ label, value, avatarId }) => {
  const { t } = useTranslation();

  return (
    <DivSmallAvatarStyle title={label}>
      <SpanSmallAvatarLabelStyle> {label} </SpanSmallAvatarLabelStyle>
      <SpanSmallAvatarValueStyle>
        {' '}
        {value} <Avatar objId={avatarId} ratio={0.6} marginRight={'0px'} />
      </SpanSmallAvatarValueStyle>
    </DivSmallAvatarStyle>
  );
};

//------------------------------------------------------------------------------
export const TextField = ({ label, value, unit = '' }) => {
  const { t } = useTranslation();

  let isUrl =
    value?.startsWith && (value.startsWith('http') || value.startsWith('www'));
  return (
    <DivSmallTagStyle title={label}>
      <SpanSmallTagLabelStyle> {label} </SpanSmallTagLabelStyle>
      {isUrl ? (
        <SpanSmallTagValueStyle>
          <TextFieldUrlContent
            color={'var(--ds-text-base)'}
            href={value}
            target="_blank"
            rel="noopener noreferrer"
            onClick={handleLinkClick}
          >
            {simplifyUrl(value)}
          </TextFieldUrlContent>
        </SpanSmallTagValueStyle>
      ) : (
        <SpanSmallTagValueStyle>
          {' '}
          {value}
          {unit}{' '}
        </SpanSmallTagValueStyle>
      )}
    </DivSmallTagStyle>
  );
};

const handleLinkClick = (e) => {
  e.stopPropagation(); // Empêche la propagation de l'événement
  console.log('Link clicked');
};

const simplifyUrl = (url) => {
  let cleaned = url;
  if (cleaned) {
    cleaned = url.toLowerCase();
    cleaned = cleaned.replace(/^https?:\\?\/\\?\//, '');
    // Enlever www. s'il est présent
    cleaned = cleaned.replace(/^www\./, '');
  }
  return cleaned;
};

//------------------------------------------------------------------------------
export const getObjectDefFromType = (type, objectsDefList) =>
  objectsDefList.filter((odf) => odf.key === type)[0];
