import styled from 'styled-components';
import { devices } from '../../../constants/devices';

//-------------------------------------------------------------------------------------------------------
export const ModalBackgdDiv = styled.div({
  alignContent: 'center',
  backgroundColor: 'rgba(60, 60, 60, .4)',

  height: '100%',
  left: 0,
  opacity: 1,
  overflow: 'hidden',
  paddingLeft: '50%',
  paddingRight: '50%',
  position: 'fixed',
  top: 0,
  width: '100%',
  zIndex: 100000000000000,
});

//-------------------------------------------------------------------------------------------------------
export const ModalDiv = styled.div((props) => ({
  backgroundColor: 'var(--ds-bg-base)',
  borderRadius: props.isMaximized ? '0' : '5px',
  boxShadow: props.isMaximized ? 'none' : '1px 2px 2px 0px rgba(0,0,0,0.35)',
  height: props.isMaximized ? '100%' : `${props.height}px`,
  left: props.isMaximized ? '0' : '50%',
  top: props.isMaximized ? '0' : '50%',
  maxHeight: props.isMaximized ? '100%' : props.height,
  overflow: 'hidden',
  padding: props.padding || '3px',
  paddingLeft: props.isMaximized ? '0' : '-50%',
  position: 'fixed',
  transform: props.isMaximized ? 'none' : 'translate(-50%, -50%)',
  width: props.isMaximized ? '100%' : `${props.width}px`,
  transition: props.isMaximized ? 'all 0.3s ease' : 'none',
  [devices.tablet]: {
    width: '100% !important',
  },
}));

//-------------------------------------------------------------------------------------------------------
export const ModalContentDiv = styled.div({
  backgroundColor: (props) => props?.backgroundColor, // '#00000055',
  color: 'black',
  height: 'calc(100% - 40px)',
  margin: 'auto',
  overflow: 'auto',
  padding: (props) => props.padding || '5px',
  width: 'auto',
});

//-------------------------------------------------------------------------------------------------------
export const ModalTitleDiv = styled.div({
  backgroundColor: '',
  borderRadius: '7px 7px 0px 0px',
  color: (props) => props?.color || '#1C7ED6',
  fontSize: '16px',
  padding: (props) => props.padding || '10px',
  fontWeight: '600',
});

export const modalDialogStyle = {
  titleBarModal: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: 12,
    paddingTop: 6,
  },
  closeIcon: {
    width: 24,
    height: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  actionIcon: {
    width: 24,
    height: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    marginRight: 8,
  },
};
