import styled from 'styled-components';

export const NavigNodeAvailableContainer = styled.div({
  backgroundColor: 'transparent',
  border: '0px solid red',
  color: (props) => props?.color || '#333',
  height: 'calc(100vh - 30px)',
  left: (props) => `${props.left + 50}px`,
  padding: '30px',
  position: 'absolute',
  top: '140px',
  width: (props) => `${props.width}px`,
  fontSize: '14px',
});

export const NavigNodeAvailableContainerType = styled.div({
  marginTop: '30px',
  fontSize: '12px',
});

export const navigNodeAvailableStyles = {
  label: {
    margin: '5px',
  },
};
