import styled from 'styled-components';

export const CellListContainer = styled.div({
  backgroundColor: 'var(--ds-bg-secondary)',
  borderRadius: '3px',
  display: 'inline-flex',
  height: '24px',
  marginLeft: '4px',
  marginRight: '4px',
  padding: '4px',
  paddingLeft: '6px',
  verticalAlign: 'center',
  width: 'calc(100% - 4px)',
});

export const CellListIcon = styled.div(({ color, icon }) => ({
  backgroundColor: color || 'var(--ds-icon-base)',
  display: 'flex',
  height: '16px',
  WebkitMaskImage: `url('${icon ?? '/img/alert.svg'}')`,
  WebkitMaskRepeat: 'no-repeat',
  WebkitMaskSize: '16px 16px',
  position: 'relative',
  paddingLeft: '12px',
  width: '18px',
}));

export const CellListText = styled.div({
  color: 'var(--ds-text-base)',
  display: 'flex',
  fontFamily: 'proxima-nova',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '15px',
  overflow: 'hidden',
  paddingLeft: '6px',
  textOverflow: 'ellipsis',
  verticalAlign: 'center',
});
