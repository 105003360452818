// 'use strict';
import { cloneDeep } from 'lodash';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { ActionIcon } from '@mantine/core';
import { range } from 'd3';
import { Download, Edit, Eye } from 'tabler-icons-react';
import produce from 'immer';
import ObjectActionCreators from '../../../../actions';

import ObjectApi from '../../../../api/ObjectApi';
import { colorsSvgDrawing } from '../../../../constants/colors';
import { IMAGE_SVG_ICONS_TYPE } from '../../../../constants/libraryIcons';
import {
  onTogglePreviewSvgSketch,
  setPreviewSvgSketchData,
} from '../../../../reducers/layoutReducer/action';
import {
  changeIndexElement,
  generateNewPointsItemDuplicate,
} from '../../../../utils/functionSvgDrawing';
import { ObjFieldDrawingPadContainer } from './ObjFieldDrawingPadCSS';
import SvgDrawing from './SvgDrawing';
import SvgPreviewContent from './SvgPreviewContent';
import SvgPreviewDownload from './SvgPreviewDownload';
import { parseJson } from '../../../../utils/parseJson';
import { setElements } from '../../../../reducers/svgDiagramReducer/actions';
// lineDrawing ='polyline'||'line'
// action = 'delete' ||'ZoomIn'|'ZoomOut'|'move'|'mouse'|'drawing-polyline'|'drawing-line'|'drawing-arrow-line'

class ObjFieldDrawingPad extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      action: 'mouse',
      editMode: false,
      color: colorsSvgDrawing[0],
      opacity: 1,
      drawing: false,
      tick: 6,
      lines: [],
      points: [],
      svgFile: [],
      lineDrawing: 'line',
      idLineDrawing: null,
      // previewMode: false,
      exportMode: false,
      connectorMode: {
        active: false,
        sourceId: null,
        targetId: null,
      },
    };

    this.handleTouchStart = this.handleTouchStart.bind(this);
    this.handleTouchEnd = this.handleTouchEnd.bind(this);
    this.handleTouchMove = this.handleTouchMove.bind(this);
    this.handleCleanAll = this.handleCleanAll.bind(this);
    // this.handleTickLower = this.handleTickLower.bind(this);
    // this.handleTickUp = this.handleTickUp.bind(this);
    this.handleColorChange = this.handleColorChange.bind(this);
    this.handleUndo = this.handleUndo.bind(this);
    this.toggleEditMode = this.toggleEditMode.bind(this);
    this.toggleDelete = this.toggleDelete.bind(this);
    this.handleClickSVG = this.handleClickSVG.bind(this);
    this.onAddFile = this.onAddFile.bind(this);
    this.setActionDragSvg = this.setActionDragSvg.bind(this);
    this.setSvgFocus = this.setSvgFocus.bind(this);
    this.handleSaveSvgFileToDb = this.handleSaveSvgFileToDb.bind(this);
    this.onResizeSvg = this.onResizeSvg.bind(this);
    this.getTopZIndex = this.getTopZIndex.bind(this);
    this.onChangeLineDrawing = this.onChangeLineDrawing.bind(this);
    this.onChangeAction = this.onChangeAction.bind(this);
    this.onChangeTick = this.onChangeTick.bind(this);
    this.onUpdatePointsOnMove = this.onUpdatePointsOnMove.bind(this);
    this.onGeneratePoly = this.onGeneratePoly.bind(this);
    this.onDuplicateItem = this.onDuplicateItem.bind(this);
    this.onChangeLayout = this.onChangeLayout.bind(this);
    this.onLink = this.onLink.bind(this);
    this.onUpdateItemPolylineOrArrowLine =
      this.onUpdateItemPolylineOrArrowLine.bind(this);
    this.togglePreviewMode = this.togglePreviewMode.bind(this);
    this.toggleExportMode = this.toggleExportMode.bind(this);
    this.onLoadSourceFromTemplateIcon =
      this.onLoadSourceFromTemplateIcon.bind(this);
    // this.removeSvgSinglePoints = this.removeSvgSinglePoints.bind(this);
    // this.handleEnterKeyPress = this.handleEnterKeyPress.bind(this);
    this.handleConnectorSelection = this.handleConnectorSelection.bind(this);
    this.onUpdateConnectorData = this.onUpdateConnectorData.bind(this);
  }

  onLoadSourceFromTemplateIcon(dataTemp) {
    const { elements, setElements } = this.props;
    let topZIndex = 0;
    elements?.map((line) => {
      if (line?.zIndex > topZIndex) {
        topZIndex = line?.zIndex;
      }
    });
    topZIndex + 1;
    const newLines = [];
    elements?.map((line) => {
      newLines.push({
        ...line,
        // zIndex: line?.zIndex + topZIndex,
      });
    });
    const newLinesTemp = [];

    dataTemp.map((item) => {
      newLinesTemp.push({
        ...item,
        key: `clone_temp_${uuidv4()}`,
        isCloneTemp: true,
        zIndex: item?.zIndex + topZIndex,
      });
    });
    const allLineData = [...newLines, ...newLinesTemp]?.sort(function (a, b) {
      return a?.zIndex - b?.zIndex;
    });
    setElements(allLineData);
  }

  onUpdatePointsOnMove(id, newPoints) {
    const { elements, setElements } = this.props;
    const newLines = cloneDeep(elements);
    newLines.map((line) => {
      if (line.key === id) {
        line.points = newPoints;
      }
      return line;
    });
    setElements(newLines);
  }

  onChangeLayout(type) {
    const { elements, setElements } = this.props;
    const topZIndex = this.getTopZIndex();
    const newLines = changeIndexElement(
      type,
      elements,
      this.props.selectedElementKey,
      topZIndex
    );
    setElements(newLines);
  }

  onUpdateItemPolylineOrArrowLine(itemUpdate) {
    const { elements, setElements } = this.props;
    const newLinesClone = cloneDeep(elements);
    const newLines = newLinesClone.map((line) => {
      if (line.key === itemUpdate.key) {
        return itemUpdate;
      }
      return line;
    });
    setElements(newLines);
  }

  onDuplicateItem() {
    const { elements, setElements, duplicateSelected } = this.props;
    const newLines = cloneDeep(elements);
    const itemDuplicate = newLines.find(
      (line) => line.key === this.props.selectedElementKey
    );
    const type = itemDuplicate.type;
    switch (type) {
      case 'svg':
        return this.onAddFile('svg', itemDuplicate.points);

      case 'image': {
        return duplicateSelected();
      }
      case 'text':
        return duplicateSelected();
      case 'line':
      case 'arrow-line':
      case 'bidirectional-line':
      case 'polyline': {
        duplicateSelected();
      }
      default:
        break;
    }
  }

  onChangeTick(tick) {
    const { elements, setElements } = this.props;
    const { selectedElementKey } = this.props;
    let currentElementSelect = elements.find(
      (svg) => svg.key === this.props.selectedElementKey
    );
    let newData = [];
    if (selectedElementKey) {
      newData = produce(elements, (draffState) => {
        draffState.map((line) => {
          if (line?.key === selectedElementKey) {
            line.tick = tick;
          }
        });
      });
    }
    if (selectedElementKey) {
      setElements(newData);
    }
    this.setState({
      tick,
      idLineDrawing: null,
    });
  }

  onChangeAction(action, resetSvgSelect = true) {
    const { elements, setElements } = this.props;
    if (action === 'drawing-connector') {
      console.log('Setting connector mode');
      this.props.setCurrentAction(action);
      this.setState({
        idLineDrawing: null,
        connectorMode: {
          active: false,
          sourceId: null,
          targetId: null,
        },
      });
      return;
    }

    if (resetSvgSelect) {
      const newLineData = this.removeSvgSinglePoints();
      this.props.setSelectedElement(null);
      this.props.setCurrentAction(action);
      setElements(newLineData);
      return this.setState({
        idLineDrawing: null,
        connectorMode: {
          active: false,
          sourceId: null,
          targetId: null,
        },
      });
    }
  }

  onChangeLineDrawing(lineDrawing) {
    this.props.setSelectedElement(null);

    if (lineDrawing === 'connector') {
      this.props.setCurrentAction('drawing-connector');
      this.setState({
        lineDrawing,
        drawing: false,
        idLineDrawing: null,
        connectorMode: {
          active: false,
          sourceId: null,
          targetId: null,
        },
      });
    } else {
      // Original behavior for other line types
      this.setState({
        lineDrawing,
        drawing: false,
        idLineDrawing: null,
      });
    }
  }

  getTopZIndex() {
    const { elements, setElements } = this.props;
    let topZIndex = 0;
    elements?.map((line) => {
      if (line?.zIndex > topZIndex) {
        topZIndex = line?.zIndex;
      }
    });
    return topZIndex + 1;
  }

  onAddFile(type, file, keyImage, size) {
    const { elements, setElements } = this.props;
    // type  = 'svg' or'image'

    const svgBox = document.getElementById('svgPad')?.getBoundingClientRect();
    setElements([
      ...elements,
      {
        key: `${type}_${uuidv4()}`,
        type,
        points: file,
        x: size ? 0 : svgBox?.width / 2 - 50,
        y: size ? 0 : svgBox?.height / 2 - 100,
        width: size ? size?.width : 100,
        height: size ? size?.height : 100,
        zIndex: this.getTopZIndex(),
        ...(type === 'image' ? { keyImage } : {}),
      },
    ]);
  }

  onLink() {
    const { selectedElementKey } = this.props;
    const { elements, setElements } = this.props;
    let currentElementSelect = elements.find(
      (svg) => svg.key === selectedElementKey
    );

    this.props.setDrawingItemSelected(
      this.props.attrName,
      selectedElementKey,
      currentElementSelect.type
    );
    this.props.openObjectSelector('link');
  }

  onResizeSvg(action) {
    const { selectedElementKey } = this.props;
    const { elements, setElements } = this.props;
    const newLines = [...elements];
    newLines.map((svg) => {
      if (svg?.key === selectedElementKey) {
        // svg.zIndex = this.getTopZIndex();
        if (action === 'ZoomIn') {
          if (svg?.type === 'text') {
            if (svg.fontSize >= 82) return;
            svg.fontSize += 2;
            return;
          }
          svg.width += 14;
          svg.height += 14;
        } else {
          if (svg?.type === 'text') {
            if (svg.fontSize <= 16) return;
            svg.fontSize -= 2;
            return;
          }
          svg.width -= 14;
          svg.height -= 14;
        }
      }
    });
    setElements(newLines);
  }

  setSvgFocus(selectedElementKey) {
    console.log('setSvgFocus called with:', selectedElementKey);

    // When in connector mode, delegate to connector selection logic
    if (this.props.currentAction === 'drawing-connector') {
      console.log('In connector mode, delegating to handleConnectorSelection');
      this.handleConnectorSelection(selectedElementKey);
      return;
    }

    // Normal behavior
    this.props.setSelectedElement(selectedElementKey);
  }

  handleConnectorSelection(elementId) {
    const { elements, setElements } = this.props;
    console.log('handleConnectorSelection called with:', elementId);

    if (this.props.currentAction !== 'drawing-connector') {
      console.log(
        'Not in connector mode, action is:',
        this.props.currentAction
      );
      return;
    }

    // Make sure we're dealing with a valid element ID
    if (!elementId || elementId === 'svgPad') {
      console.log('Invalid element ID or clicked on background:', elementId);
      return;
    }

    // Clean the element ID (remove any prefixes like 'wrap-', 'content_', etc.)
    let cleanElementId = elementId;

    // If the ID has a prefix, extract the main element ID
    if (elementId.includes('wrap-')) {
      cleanElementId = elementId.replace('wrap-', '');
    } else if (elementId.includes('content_')) {
      cleanElementId = elementId.replace('content_', '');
    }

    console.log('Using clean element ID:', cleanElementId);

    // Check if the element exists
    const elementExists = elements.some((line) => line.key === cleanElementId);
    if (!elementExists) {
      console.log('Element does not exist in lines:', cleanElementId);
      console.log(
        'Available elements:',
        elements.map((l) => l.key)
      );
      return;
    }

    if (!this.state.connectorMode.active) {
      // First element selection (source)
      console.log('Setting source element:', cleanElementId);
      this.setState({
        connectorMode: {
          active: true,
          sourceId: cleanElementId,
          targetId: null,
        },
      });
    } else {
      // Second element selection (target)
      const sourceId = this.state.connectorMode.sourceId;
      const targetId = cleanElementId;

      console.log('Setting target element:', targetId, 'source was:', sourceId);

      if (sourceId === targetId) {
        // Cannot connect an element to itself
        console.log('Cannot connect element to itself');
        return;
      }

      // Find the source and target elements
      const sourceElement = elements.find((line) => line.key === sourceId);
      const targetElement = elements.find((line) => line.key === targetId);

      if (!sourceElement || !targetElement) {
        console.log(
          'Could not find elements:',
          !sourceElement ? 'source missing' : 'target missing'
        );
        return;
      }

      // Create connector
      const key = `connector_${uuidv4()}`;

      // Get centers of elements for initial connection points
      const sourceCenter = this.getElementCenter(sourceElement);
      const targetCenter = this.getElementCenter(targetElement);

      console.log('Creating connector between:', sourceCenter, targetCenter);

      const newLines = [
        ...elements,
        {
          key,
          type: 'connector',
          points: [sourceCenter, targetCenter],
          sourceId: sourceId,
          targetId: targetId,
          tick: this.state.tick,
          color: this.state.color,
          zIndex: this.getTopZIndex(),
          curveAmount: 20, // Default curve amount
        },
      ];
      setElements(newLines);
      this.setState({
        connectorMode: {
          active: false,
          sourceId: null,
          targetId: null,
        },
        action: 'mouse', // Reset to mouse action after creating connector
      });
    }
  }

  // Method to update connector data
  onUpdateConnectorData(connectorId, sourcePoint, targetPoint, options = {}) {
    const { elements, setElements } = this.props;
    const newLines = cloneDeep(elements);
    const connectorIndex = newLines.findIndex(
      (line) => line.key === connectorId
    );

    if (connectorIndex !== -1) {
      newLines[connectorIndex].points = [sourcePoint, targetPoint];

      if (options.text !== undefined)
        newLines[connectorIndex].text = options.text;
      if (options.textColor !== undefined)
        newLines[connectorIndex].textColor = options.textColor;
      if (options.textSize !== undefined)
        newLines[connectorIndex].textSize = options.textSize;

      setElements(newLines);
    }
  }

  // Helper method to get element center

  getElementCenter(element) {
    if (!element) return { x: 0, y: 0 };

    if (element.type === 'text') {
      // Try to get the actual rendered text dimensions
      const textContentElement = document.getElementById(
        `content_${element.key}`
      );

      if (textContentElement) {
        // Get the actual rendered dimensions
        const rect = textContentElement.getBoundingClientRect();
        const actualWidth = rect.width;

        // Use actual dimensions while staying in the element's coordinate system
        return {
          x: element.x + actualWidth / 2,
          y: element.y + (element.fontSize || 20) / 2,
        };
      }

      // Fallback if we can't get the actual element
      return {
        x: element.x + (element.width || 100) / 2,
        y: element.y + (element.fontSize || 20) / 2,
      };
    }
    if (element.type === 'svg' || element.type === 'image') {
      return {
        x: element.x + element.width / 2,
        y: element.y + element.height / 2,
      };
    }
    if (
      [
        'polyline',
        'line',
        'arrow-line',
        'bidirectional-line',
        'connector',
      ].includes(element.type)
    ) {
      // This part remains unchanged
      const points = element.points;
      if (points && points.length > 0) {
        let sumX = 0;
        let sumY = 0;

        for (let i = 0; i < points.length; i++) {
          const point = points[i];
          if (
            point &&
            typeof point.x === 'number' &&
            typeof point.y === 'number'
          ) {
            sumX += point.x;
            sumY += point.y;
          }
        }

        const validPoints = points.filter(
          (p) => p && typeof p.x === 'number' && typeof p.y === 'number'
        );
        const count = validPoints.length || 1;

        return {
          x: sumX / count,
          y: sumY / count,
        };
      }
    }

    return {
      x: element.x || 0,
      y: element.y || 0,
    };
  }

  handleTouchMove(e) {
    e.preventDefault();
    const { elements, setElements } = this.props;
    if (this.props.currentAction !== 'drawing-line') return;
    if (!this.state.drawing || this.state.lineDrawing !== 'line') return;
    const { transformClientToSVG } = this.props;
    const clientX = e.touches ? e.touches[0].clientX : e.clientX;
    const clientY = e.touches ? e.touches[0].clientY : e.clientY;
    let { x, y } = transformClientToSVG(clientX, clientY);
    this.setState({
      points: [...this.state.points, { x, y }],
      opacity: 1,
    });
  }

  toggleEditMode(e) {
    if (typeof this.props.onToggleEditMode === 'function') {
      return this.props.onToggleEditMode();
    }
    this.props.setSelectedElement(null);
    this.setState({ editMode: !this.state.editMode });
  }

  togglePreviewMode(e) {
    const { elements, setElements } = this.props;
    this.props.showPreviewSvgSketch();
    this.props.setPreviewSvgSketchData(elements);
  }

  async toggleExportMode(e) {
    this.props.setSelectedElement(null);
    this.setState({ exportMode: !this.state.exportMode });
  }

  toggleDelete(e) {
    this.props.setSelectedElement(null);
    this.props.setCurrentAction('delete');
    this.setState({
      drawing: false,
      idLineDrawing: null,
    });
  }

  handleClickSVG(e) {
    const { currentAction, deleteSelected } = this.props;
    const idTarget = e.target.id;
    if (currentAction === 'drawing-connector') {
      e.stopPropagation();
      return this.handleConnectorSelection(idTarget);
    }

    if (currentAction !== 'delete') {
      return this.setSvgFocus(idTarget);
    }

    if (currentAction === 'delete') {
      deleteSelected(idTarget);
    }
  }

  setActionDragSvg(action) {
    // if (this.props.currentAction === 'delete') return;
    this.props.setCurrentAction(action);
  }

  handleTouchEnd(e) {
    const id = `id_${uuidv4()}`;
    const { currentAction, deleteSelected } = this.props;
    const { svgPanPosition, svgZoomLevel, transformClientToSVG } = this.props;
    const { elements, setElements } = this.props;

    const clientX = e.touches ? e.touches[0].clientX : e.clientX;
    const clientY = e.touches ? e.touches[0].clientY : e.clientY;
    let { x, y } = transformClientToSVG(clientX, clientY);

    if (currentAction === 'drawing-connector') {
      // When in connector mode, we don't need to do anything in touch end
      // The handleConnectorSelection will be triggered by handleClickSVG
      return;
    }

    if (
      ['arrow-line', 'bidirectional-line'].includes(this.state.lineDrawing) &&
      this.state.drawing &&
      ['drawing-arrow-line', 'drawing-bidirectional-line'].includes(
        currentAction
      )
    ) {
      const idLineDrawing = `arrow-line_${uuidv4()}`;
      if (this.state.idLineDrawing) {
        const cloneDeepLines = cloneDeep(elements);
        const currentPolylineEdit = cloneDeepLines?.map((svg) => {
          if (svg.key === this.state.idLineDrawing) {
            svg.points.push({
              ...this.calculatePositionPolylineDrawing({
                x,
                y,
              }),
              id,
            });
            if (svg.points?.length === 2) {
              this.setState({ idLineDrawing: null });
            }
          }
          return svg;
        });
        setElements(currentPolylineEdit);
        return;
      }
      setElements([
        ...elements,
        {
          key: idLineDrawing,
          type: this.state.lineDrawing,
          points: [
            {
              ...this.calculatePositionPolylineDrawing({
                x,
                y,
              }),
              id,
            },
          ],
          tick: this.state.tick,
          color: this.state.color,
          zIndex: this.getTopZIndex(),
        },
      ]);
      this.setState({
        idLineDrawing,
      });
      return;
    }
    if (
      this.state.lineDrawing === 'polyline' &&
      this.state.drawing &&
      currentAction === 'drawing-polyline'
    ) {
      const idLineDrawing = `polyline_${uuidv4()}`;
      if (this.state.idLineDrawing) {
        const cloneDeepLines = cloneDeep(elements);

        const currentPolylineEdit = cloneDeepLines?.map((svg) => {
          if (svg.key === this.state.idLineDrawing) {
            this.calculatePositionPolylineDrawing({
              x,
              y,
            });

            svg.points.push({
              ...this.calculatePositionPolylineDrawing({
                x,
                y,
              }),
              id,
            });
          }
          return svg;
        });
        setElements(currentPolylineEdit);
        this.props.handleChangeInputDrawing(
          null,
          JSON.stringify(currentPolylineEdit),
          this.props.label
        );
        return;
      }
      setElements([
        ...elements,
        {
          key: idLineDrawing,
          type: 'polyline',
          points: [
            {
              ...this.calculatePositionPolylineDrawing({
                x,
                y,
              }),
              id,
            },
          ],
          tick: this.state.tick,
          color: this.state.color,
          zIndex: this.getTopZIndex(),
        },
      ]);
      this.setState({
        idLineDrawing,
      });
      return;
    }
    if (currentAction === 'drawing-line') {
      setElements([
        ...elements,
        {
          key: `line_${uuidv4()}`,
          opacity: this.state.opacity,
          color: this.state.color,
          tick: this.state.tick,
          points: this.state.points,
          type: 'line',
          // zIndex: this.getTopZIndex(),
        },
      ]);
      this.setState({
        drawing: false,
        points: [],
      });
    }
  }

  handleSaveSvgFileToDb(keySvg, position, isDuringMovement = false) {
    const { elements, setElements } = this.props;
    if (isDuringMovement) {
      // During movement, use the lighter tracking method
      this.trackElementPositionChange(keySvg, position);
      return;
    }

    // Original code for end of movement
    const newLines = cloneDeep(elements);
    newLines.map((svg) => {
      if (svg?.key === keySvg) {
        svg.x = position.x;
        svg.y = position.y;
      }
    });

    setElements(newLines);
  }

  updateConnectedConnectors(elementKey, lines) {
    // Find all connectors that connect to the moved element
    const connectors = lines.filter(
      (line) =>
        line.type === 'connector' &&
        (line.sourceId === elementKey || line.targetId === elementKey)
    );

    if (connectors.length === 0) return;

    // Update each connector
    connectors.forEach((connector) => {
      // Get source and target elements
      const source = lines.find((line) => line.key === connector.sourceId);
      const target = lines.find((line) => line.key === connector.targetId);

      if (source && target) {
        // Calculate new connection points
        const sourceCenter = this.getElementCenter(source);
        const targetCenter = this.getElementCenter(target);

        // Update the connector's points
        if (this.onUpdateConnectorData) {
          this.onUpdateConnectorData(connector.key, sourceCenter, targetCenter);
        }
      }
    });
  }

  handleTouchStart(e) {
    console.log('hts1');
    if (
      this.props.currentAction !== 'delete' ||
      this.props.currentAction === 'drawing-line' ||
      this.props.currentAction === 'drawing-polyline'
    ) {
      this.setState({
        drawing: true,
        polyKey: uuidv4(),
      });
      this.handleTouchMove(e);
    }
  }

  handleCleanAll(e) {
    const { elements, setElements } = this.props;
    const listIdImageDelete = elements.filter((line) => line?.type === 'image');
    const listPromiseDeleteImage = [];
    listIdImageDelete.map((image) => {
      console.log('image', image);
      if (
        image?.keyImage !== IMAGE_SVG_ICONS_TYPE.ACCESS_FROM_ICON_LIB &&
        !image?.isCloneTemp
      ) {
        listPromiseDeleteImage.push(
          ObjectApi.postFile(this.props?.objId, null, null, image?.keyImage)
        );
      }
    });
    this.props.setSelectedElement(null);
    setElements([]);
    this.setState({
      points: [],
      idLineDrawing: null,
      action: 'mouse',
    });
    this.props.handleChangeInputDrawing(
      null,
      JSON.stringify([]),
      this.props.label
    );
    try {
      Promise.all(listPromiseDeleteImage);
    } catch (error) {}
  }

  handleUndo(e) {
    const { elements, setElements } = this.props;
    const newTab = [...elements];
    newTab.pop();
    this.props.setSelectedElement(null);
    setElements(newTab);

    this.setState({
      idLineDrawing: null,
      action: 'mouse',
    });
  }

  handleColorChange(e) {
    const { selectedElementKey } = this.props;
    const { elements, setElements } = this.props;
    const indColor = e.target.id.split('index_color_')[1];
    let newData = [];
    const itemSelected = elements.find(
      (line) => line.key === selectedElementKey
    );
    console.log('itemSelected', itemSelected);
    if (
      itemSelected &&
      ['bidirectional-line', 'arrow-line', 'text', 'polyline', 'line'].includes(
        itemSelected?.type
      )
    ) {
      newData = produce(elements, (draffState) => {
        draffState.map((line) => {
          if (line?.key === selectedElementKey) {
            line.color = colorsSvgDrawing[indColor];
          }
        });
      });
    }
    if (selectedElementKey) {
      setElements(newData);
    }

    this.setState({
      color: colorsSvgDrawing[indColor],
      drawing: false,
      idLineDrawing: null,
    });
  }

  generatePoly(points = [], tick, color, opacity, key) {
    let resStr = '';
    points.map((poi) => {
      resStr += `${poi.x},${poi.y} `;
    });
    return (
      <polyline
        onClick={this.handleClickSVG}
        id={key}
        key={key}
        points={resStr}
        strokeWidth={tick}
        stroke={color}
        fill="none"
      />
    );
  }

  onGeneratePoly() {
    return this.generatePoly(
      this.state.points,
      this.state.tick,
      this.state.color,
      this.state.opacity,
      this.state.polyKey
    );
  }

  calculatePositionPolylineDrawing(position) {
    if (!this.props.isGridVisible) return position;
    if (!position) return { x: 0, y: 0 };
    const { gridSize } = this.props;
    const offsetX = ((position.x % gridSize) + gridSize) % gridSize;
    const offsetY = ((position.y % gridSize) + gridSize) % gridSize;

    // Determine whether to snap up or down based on which grid line is closer
    const snapX =
      position.x - offsetX + (offsetX > gridSize / 2 ? gridSize : 0);
    const snapY =
      position.y - offsetY + (offsetY > gridSize / 2 ? gridSize : 0);

    return { x: snapX, y: snapY };
  }

  render() {
    const { editMode, lineDrawing, action, lines, tick, idLineDrawing, color } =
      this.state;
    const { gridSize, isGridVisible } = this.props;
    const { elements, setElements } = this.props;
    this.calculatePositionPolylineDrawing();

    return (
      <ObjFieldDrawingPadContainer>
        {elements?.length > 0 && !this.props.isEditSvgLibIcon && (
          <SvgPreviewContent
            value={this.props.value}
            onClickElement={this.props.onClickElement}
          />
        )}

        {!this.props.readOnly && (
          <>
            {!this.props.isEditSvgLibIcon && (
              <div
                style={{ display: 'flex', flexDirection: 'row', columnGap: 10 }}
              >
                <ActionIcon id="btn_edit_drawing" onClick={this.toggleEditMode}>
                  <Edit />
                </ActionIcon>
                {elements.length > 0 && (
                  <>
                    <ActionIcon onClick={this.togglePreviewMode}>
                      <Eye />
                    </ActionIcon>
                    <ActionIcon onClick={this.toggleExportMode}>
                      <Download />
                    </ActionIcon>
                  </>
                )}
              </div>
            )}
            {this.props.currentAction === 'drawing-connector' && (
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  padding: '4px 8px',
                  background: 'rgba(255,0,0,0.2)',
                  color: 'white',
                  zIndex: 999,
                  fontSize: '12px',
                }}
              >
                Connector Mode{' '}
                {this.state.connectorMode.active ? 'Active' : 'Ready'}
                {this.state.connectorMode.sourceId
                  ? ` - Source: ${this.state.connectorMode.sourceId}`
                  : ''}
              </div>
            )}
            <SvgPreviewDownload
              lines={elements}
              exportMode={this.state.exportMode}
              togglePreviewMode={this.togglePreviewMode}
              toggleExportMode={this.toggleExportMode}
              onClickElement={this.props.onClickElement}
            />

            <SvgDrawing
              onLoadSourceFromTemplateIcon={this.onLoadSourceFromTemplateIcon}
              exportMode={this.state.exportMode}
              editMode={this.props?.editMode || editMode}
              onLink={this.onLink}
              toggleEditMode={this.toggleEditMode}
              lineDrawing={lineDrawing}
              onChangeAction={this.onChangeAction}
              onChangeLineDrawing={this.onChangeLineDrawing}
              action={action}
              onResizeSvg={this.onResizeSvg}
              onDeleteSvg={this.onDeleteSvg}
              onChangeTick={this.onChangeTick}
              tick={tick}
              handleTouchStart={this.handleTouchStart}
              handleTouchMove={this.handleTouchMove}
              handleTouchEnd={this.handleTouchEnd}
              setActionDragSvg={this.setActionDragSvg}
              setSvgFocus={this.setSvgFocus}
              handleSaveSvgFileToDb={this.handleSaveSvgFileToDb}
              idLineDrawing={idLineDrawing}
              onUpdatePointsOnMove={this.onUpdatePointsOnMove}
              handleCleanAll={this.handleCleanAll}
              onGeneratePoly={this.onGeneratePoly}
              handleUndo={this.handleUndo}
              toggleDelete={this.toggleDelete}
              handleColorChange={this.handleColorChange}
              color={color}
              onAddFile={this.onAddFile}
              onDuplicateItem={this.onDuplicateItem}
              onChangeLayout={this.onChangeLayout}
              onUpdateItemPolylineOrArrowLine={
                this.onUpdateItemPolylineOrArrowLine
              }
              isEditSvgLibIcon={this.props.isEditSvgLibIcon}
              updateIconSvgDrawing={this.props.updateIconSvgDrawing}
              attrName={this.props.attrName}
            />
          </>
        )}
      </ObjFieldDrawingPadContainer>
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const { setElements, setAttrName, attrName, value } = this.props;
    if (prevProps.value !== value) {
      try {
        setElements(this.sortLine(value));
      } catch (error) {
        console.log(
          '🚀 ~ file: ObjFieldDrawingPad.js:596 ~ ObjFieldDrawingPad ~ componentDidUpdate ~ error:',
          error
        );
      }
    }
  }

  removeSvgSinglePoints() {
    const { elements, setElements } = this.props;
    const newLines = produce(elements, (draffState) => {
      return draffState.filter((svg) => {
        const isLines = [
          'line',
          'polyline',
          'arrow-line',
          'bidirectional-line',
        ].includes(svg?.type);
        if (isLines) {
          if (svg?.points.length > 1) return svg;
        }
        if (!isLines) return svg;
      });
    });

    return newLines;
  }

  handleEnterKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.setState({ idLineDrawing: null });
    }
  };

  componentDidMount() {
    const { setElements, setAttrName, value, attrName } = this.props;
    document.addEventListener('keydown', this.handleEnterKeyPress);
    if (
      value !== null &&
      value !== undefined &&
      value !== 'null' &&
      value.length > 0
    ) {
      try {
        setElements(this.sortLine(value));
      } catch (error) {
        console.log(
          '🚀 ~ file: ObjFieldDrawingPad.js:616 ~ ObjFieldDrawingPad ~ componentDidMount ~ error:',
          error
        );
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleEnterKeyPress);
  }

  sortLine(valueLine) {
    try {
      const lines = valueLine ? parseJson(valueLine) : '';
      lines?.sort(function (a, b) {
        return a?.zIndex - b?.zIndex;
      });
      console.log('lines', lines);
      return lines;
    } catch (error) {
      console.log(
        '🚀 ~ file: ObjFieldDrawingPad.js:633 ~ ObjFieldDrawingPad ~ sortLine ~ error:',
        error
      );
      return [];
    }
  }

  trackElementPositionChange(elementKey, newPosition) {
    // Create a shallow copy of lines to trigger rerenders
    const { elements, setElements } = this.props;
    const updatedLines = [...elements];

    // Find the element and update its position
    const elementIndex = updatedLines.findIndex((el) => el.key === elementKey);
    if (elementIndex !== -1) {
      updatedLines[elementIndex] = {
        ...updatedLines[elementIndex],
        x: newPosition.x,
        y: newPosition.y,
      };

      // Update state to trigger rerender of connectors
      setElements(updatedLines);
    }
  }
}

// export default withTranslation()(ObjFieldDrawingPad);
const mapDispatchToProps = (dispatch) => {
  return {
    openObjectSelector: (action) =>
      dispatch(ObjectActionCreators.openObjectSelector(action)),
    setDrawingItemSelected: (
      attrNameSelected,
      selectedDrawingItem,
      typeElement
    ) =>
      dispatch(
        ObjectActionCreators.setDrawingItemSelected(
          attrNameSelected,
          selectedDrawingItem,
          typeElement
        )
      ),
    showPreviewSvgSketch: () => dispatch(onTogglePreviewSvgSketch(true)),
    setPreviewSvgSketchData: (data) => dispatch(setPreviewSvgSketchData(data)),
  };
};
const mapStateToProps = (state, ownProps) => {
  return {
    objId: state.objects.objId,
  };
};
const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ObjFieldDrawingPad));

export default ConnectedComponent;
