import React from 'react';
import { connect } from 'react-redux';
import { getProgressBar } from '../../../Utils/Utils';
import { objGraphsBarHtmlStyles } from './ObjGraphsBarHtmlCss';

//= ============================================================================
class ObjGraphsBarHtml extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const options = this.props.options;

    const sum = this.props.data.reduce(
      (sum, elem, arr) => sum + parseInt(elem.result),
      0
    );

    return (
      <div style={objGraphsBarHtmlStyles.container}>
        {this.props.data.map((elem, ind) => {
          const value =
            sum > 0 ? `${Math.round((elem.result / sum) * 100)}%` : `${0}%`;
          const key = Object.keys(elem)?.find((key) => key !== 'result');
          let colorToUse = '#228BE6';
          if (options && Array.isArray(options)) {
            colorToUse =
              options?.find((elemIn) => elemIn.display === elem[key])?.color ??
              colorToUse;
          }

          return getProgressBar(
            value,
            elem[key],
            colorToUse,
            '',
            this.props.mini ? 12 : 14
          );
        })}
      </div>
    );
  }
}

//-----------------------------------------------------------------------------------------------------------

function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ObjGraphsBarHtml);
