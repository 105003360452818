import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { splitPattern } from '../../Utils/TextUtils';
import { initializeAllScales, updateScaleRanges } from './scaleUtils';

export const useScales = () => {
  // État unifié pour les scales et leurs dépendances
  const [scalesConfig, setScalesConfig] = useState({
    bandValues: null,
    arrayClassifBy: null,
    bandScales: [],
    colorScales: [],
    version: 0,
    isValid: false,
  });

  // Selectors
  const bandValues = useSelector(
    (state) => state.CLASSIFIER_REDUCER.bandValues
  );
  const arrayClassifBy = useSelector((state) => state.objects.arrayClassifBy);
  const objectsDefList = useSelector((state) => state.objects.objectsDefList);
  const timeline_shift = useSelector((state) => state.objects.timeline_shift);
  const profiles = useSelector((state) => state.objects.profiles);
  const pattern = useSelector((state) => state.objects.pattern);
  const panels = useSelector((state) => state.objects.panels);

  // Initialisation des scales
  useEffect(() => {
    if (!pattern || !bandValues || !arrayClassifBy) return;

    const { bandScales, colorScales } = initializeAllScales(
      bandValues,
      arrayClassifBy,
      objectsDefList,
      timeline_shift,
      profiles,
      splitPattern(pattern)
    );

    setScalesConfig((prev) => ({
      bandValues,
      arrayClassifBy,
      bandScales,
      colorScales,
      version: prev.version + 1,
      isValid: bandScales.length > 0 && bandValues.length === bandScales.length,
    }));
  }, [
    bandValues,
    arrayClassifBy,
    pattern,
    objectsDefList,
    timeline_shift,
    profiles,
  ]);

  // Mise à jour des ranges
  useEffect(() => {
    if (
      !pattern ||
      !scalesConfig.bandScales.length ||
      !scalesConfig.arrayClassifBy
    )
      return;

    const updatedBandScales = updateScaleRanges(
      scalesConfig.bandScales,
      splitPattern(pattern),
      scalesConfig.arrayClassifBy,
      objectsDefList
    );

    setScalesConfig((prev) => ({
      ...prev,
      bandScales: updatedBandScales,
      version: prev.version + 1,
    }));
  }, [panels, pattern, objectsDefList]);

  return { scalesConfig };
};
