import React, { useCallback, useEffect } from 'react';
import { Group, SegmentedControl } from '@mantine/core';
import { segmentedControlStyles } from '../../SegmentedControlStylesCSS';

const ObjFieldList = ({
  handleChangeFieldValue,
  keyParam,
  attrName,
  readOnly,
  options,
  value,
  pal,
  transition,
  orientation,
  unit,
}) => {
  let arrOptions = [];
  let orientationCalculated = orientation;
  if (!orientationCalculated) {
    orientationCalculated = arrOptions.length > 7 ? 'vertical' : 'horizontal';
  }
  try {
    if (Array.isArray(options)) {
      arrOptions = options;
    } else {
      arrOptions = JSON.parse(options);
    }
  } catch (e) {
    console.error(e);
  }

  const onChangeField = useCallback(
    (value) => {
      if (readOnly) {
        return;
      }
      handleChangeFieldValue(keyParam + attrName, value);
    },
    [keyParam, attrName, value, readOnly]
  );

  return (
    <SegmentedControl
      fullWidth={arrOptions.length < 7}
      transitionDuration={transition ? 500 : 0}
      color="#3A3077"
      styles={segmentedControlStyles()} // Using styles from external file
      size="sm"
      orientation={orientationCalculated}
      value={value}
      data={arrOptions.map((elem) => ({
        label: (
          <Group>
            {elem.icon && (
              <div
                style={{
                  position: 'relative',
                  display: 'inline',
                  width: '16px',
                  height: '16px',
                  backgroundColor: elem.color ?? 'var(--ds-icon-base)',
                  mask: `url('${elem.icon ?? '/img/alert.svg'}')`,
                  maskRepeat: 'no-repeat',
                  maskSize: '16px 16px',
                  opacity: '0.7',
                  color: 'transparent',
                }}
              >
                .
              </div>
            )}
            <div>
              {elem.display}
              {unit}
            </div>
          </Group>
        ),
        value: elem.value,
      }))}
      onChange={onChangeField}
    />
  );
};
export default ObjFieldList;
