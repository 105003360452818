import { cloneDeep } from 'lodash';
import * as d3 from 'd3';

export const generatePolyline = (points) => {
  let resStr = '';
  let ind = 0;
  while (ind < points.length) {
    const x = Math.floor(points[ind++]);
    const y = Math.floor(points[ind++]);
    resStr += `${x},${y} `;
  }
  return resStr;
};

export const generatePointsPolylineWhenMovePoint = ({
  dataPoint,
  gridSize,
  isGridVisible,
}) => {
  const newDataPoints = cloneDeep(dataPoint);
  const positionSvg = isGridVisible
    ? getPositionDragSnapSvg(d3.event, gridSize)
    : d3.event;
  newDataPoints.map((item) => {
    if (item?.id === d3.event?.subject?.id) {
      item.x = positionSvg.x;
      item.y = positionSvg.y;
    }
  });
  return newDataPoints;
};

export const getPositionDragSnapSvg = (currentPosition, gridSize) => {
  const offsetX = ((currentPosition.x % gridSize) + gridSize) % gridSize;
  const offsetY = ((currentPosition.y % gridSize) + gridSize) % gridSize;

  // Determine whether to snap up or down based on which grid line is closer
  const snapX =
    currentPosition.x - offsetX + (offsetX > gridSize / 2 ? gridSize : 0);
  const snapY =
    currentPosition.y - offsetY + (offsetY > gridSize / 2 ? gridSize : 0);

  return { x: snapX, y: snapY };
};

export function isPolylineClosed(points = []) {
  // Ensure there are at least two points
  if (points?.length < 2) {
    return false;
  }

  // Get the first and last points
  const firstPoint = points[0];
  const lastPoint = points[points.length - 1];

  // Check if they are the same
  return firstPoint?.x === lastPoint?.x && firstPoint?.y === lastPoint?.y;
}

export const getPolylineBackground = (points, backgroundColor) => {
  if (isPolylineClosed(points)) {
    return backgroundColor || 'transparent';
  }
  return 'transparent';
};

export const calculatePositionSnapClosedPolyline = (
  dataPoint,
  newDataPoints
) => {
  const lastPoint = dataPoint[dataPoint.length - 1];
  const firstPoint = dataPoint[0];

  const currentPoint = d3.event?.subject;
  const entryPoint = [lastPoint.id, firstPoint.id];

  const isSnapClose = (position, movePosition) => {
    if (
      inRange(movePosition.x, position.x - 10, position.x + 10) &&
      inRange(movePosition.y, position.y - 10, position.y + 10)
    ) {
      return true;
    }
    return false;
  };
  if (entryPoint.includes(currentPoint?.id)) {
    if (firstPoint.id === currentPoint.id && isSnapClose(lastPoint, d3.event)) {
      newDataPoints[0].x = lastPoint.x;
      newDataPoints[0].y = lastPoint.y;
    }
    if (lastPoint.id === currentPoint.id && isSnapClose(firstPoint, d3.event)) {
      newDataPoints[dataPoint.length - 1].x = firstPoint.x;
      newDataPoints[dataPoint.length - 1].y = firstPoint.y;
    }
  }
};
function inRange(x, min, max) {
  return (x - min) * (x - max) <= 0;
}
