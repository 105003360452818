import React, { useState } from 'react';
import Remarkable from 'react-remarkable';
import { useDispatch, useSelector } from 'react-redux';
import { Description } from './QuestConfirmationCSS';
import {
  ModalOverlay,
  ModalContent,
  Title,
  OptionsList,
  OptionItem,
  OptionButton,
  SendButton,
  CloseButton,
} from './QuestMultipleChoiceCSS';
import AudioRecorder from '../../AudioRecorder/AudioRecorder';
import ObjectActionCreators from '../../../actions';
import { TextArea } from './QuestInputCSS'; // Import TextArea from QuestInputCSS

const QuestMultipleChoice = () => {
  const dispatch = useDispatch();
  const currentInteraction = useSelector(
    (state) => state.interaction.currentInteraction
  );

  // Guard clause: Only render for questMultChoice interactions
  if (!currentInteraction || currentInteraction.type !== 'questMultChoice')
    return null;

  const { question, options, questionId, description } =
    currentInteraction.data;

  // State for selected options
  const [selectedOptions, setSelectedOptions] = useState([]);

  // State for alternative input
  const [showAlternative, setShowAlternative] = useState(false);

  // Get response from state for text input
  const response = currentInteraction.response || '';

  // Function to toggle option selection
  const toggleOption = (option) => {
    if (showAlternative) return; // Don't allow option selection when in alternative mode

    setSelectedOptions(
      (prevSelected) =>
        prevSelected.includes(option)
          ? prevSelected.filter((item) => item !== option) // Deselect
          : [...prevSelected, option] // Select
    );
  };

  // Handle user text input for alternative response
  const handleInputChange = (event) => {
    dispatch({
      type: 'VOICE_TO_TEXT_INPUT_CLIENT_CHANGED',
      transcription: event.target.value,
    });
  };

  // Toggle between multiple choice and alternative input
  const toggleAlternativeMode = () => {
    if (showAlternative) {
      // When switching back to multiple choice, clear the text input
      dispatch({
        type: 'VOICE_TO_TEXT_INPUT_CLIENT_CHANGED',
        transcription: '',
      });
    } else {
      // When switching to alternative, clear selected options
      setSelectedOptions([]);
    }
    setShowAlternative(!showAlternative);
  };

  // Dispatch the selected options or alternative response
  const handleSend = () => {
    if (showAlternative) {
      // Send alternative response
      if (response.trim()) {
        dispatch({
          type: 'INTERACTION_RESPONSE',
          payload: {
            questionId,
            response,
            isAlternative: true,
          },
        });
        dispatch({ type: 'INTERACTION_CLOSE' });
      } else {
        alert('Please enter a response before sending.');
      }
    }
    // Send selected options
    else if (selectedOptions.length > 0) {
      dispatch({
        type: 'INTERACTION_RESPONSE',
        payload: {
          questionId,
          response: JSON.stringify(selectedOptions),
          isAlternative: false,
        },
      });
      dispatch({ type: 'INTERACTION_CLOSE' });
    } else {
      alert('Please select at least one option before sending.');
    }
  };

  // Close interaction without sending
  const handleClose = () => {
    dispatch({ type: 'INTERACTION_CLOSE' });
  };

  return (
    <ModalOverlay>
      <ModalContent>
        <Title>{question}</Title>
        <Description>
          <Remarkable source={description ?? ''} />
        </Description>

        {/* Toggle button for alternative mode */}
        <div style={{ marginBottom: '15px', textAlign: 'right' }}>
          <button
            onClick={toggleAlternativeMode}
            style={{
              padding: '5px 10px',
              background: '#f0f0f0',
              border: '1px solid #ccc',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
          >
            {showAlternative
              ? 'Switch to Multiple Choice'
              : 'Provide Alternative Answer'}
          </button>
        </div>

        {!showAlternative ? (
          /* Multiple choice options */
          <OptionsList>
            {options.map((option, index) => (
              <OptionItem key={index}>
                <OptionButton
                  isSelected={selectedOptions.includes(option)}
                  onClick={() => toggleOption(option)}
                >
                  {option}
                </OptionButton>
              </OptionItem>
            ))}
          </OptionsList>
        ) : (
          /* Alternative input with audio recorder */
          <div>
            <TextArea
              value={response}
              onChange={handleInputChange}
              placeholder="Type your alternative response here..."
            />
            <AudioRecorder
              onEndRecording={(blob) =>
                dispatch(ObjectActionCreators.voiceToTextInput(blob))
              }
            />
          </div>
        )}

        <SendButton
          onClick={handleSend}
          disabled={
            (showAlternative && !response.trim()) ||
            (!showAlternative && selectedOptions.length === 0)
          }
        >
          Send
        </SendButton>
        <CloseButton onClick={handleClose}>Close</CloseButton>
      </ModalContent>
    </ModalOverlay>
  );
};

export default QuestMultipleChoice;
