import React from 'react';
import { getProgressBar } from '../../Utils/Utils';

class ProgressBar extends React.Component {
  render() {
    return (
      <>
        {getProgressBar(this.props.width, this.props.width, '#1C7ED6', '', 12)}
      </>
    );
  }
}

export default ProgressBar;
