import React from 'react';
import { Slider, Text, Group, Button } from '@mantine/core';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Bug } from 'tabler-icons-react';
import {
  setZoomLevel,
  setPanPosition,
  toggleDebugMode,
} from '../../reducers/svgDiagramReducer/actions';
import { ZoomInIcon, ZoomOutIcon } from '../../constants/icon';
import { svgDiagramSelector } from '../../reducers/svgDiagramReducer/selectors';
import { withSvgTransform } from './withSvgTransform';
import {
  createDebugGrid,
  markContentBounds,
  createDebugInfoBlock,
  removeDebugElements,
} from './utils/svgDebugUtils';

const ZoomSliderContainer = styled.div({
  position: 'absolute',
  bottom: 15,
  right: 110,
  width: 400,

  alignItems: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  padding: '5px 10px',
  borderRadius: 4,
  boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
  zIndex: 1000,
});

const ZoomSlider = () => {
  const dispatch = useDispatch();
  const { zoomLevel, isDebugMode, panPosition } =
    useSelector(svgDiagramSelector);

  const handleZoomChange = (value) => {
    dispatch(setZoomLevel(value));
  };

  const resetZoom = () => {
    dispatch(setZoomLevel(1));
  };

  const toggleDebug = () => {
    dispatch(toggleDebugMode(!isDebugMode));
  };

  const autoFit = () => {
    // Get the SVG container
    const svgPad = document.getElementById('svgPad');
    if (!svgPad) return;

    // Find the transformed group
    const transformGroup = svgPad.querySelector('#contentDrawing');
    if (!transformGroup) {
      resetZoom();
      return;
    }

    // Get the current state values directly from props - not from another useSelector call
    const currentZoom = zoomLevel;
    const currentPan = panPosition;

    // Viewport dimensions
    const viewportWidth = svgPad.clientWidth;
    const viewportHeight = svgPad.clientHeight;

    // Remove existing debug elements if not in debug mode
    if (!isDebugMode) {
      removeDebugElements();
    }

    // Get all relevant visual elements
    const elements = Array.from(
      transformGroup.querySelectorAll(
        'rect, polyline, line, text, image, path, circle, ellipse, foreignObject, svg'
      )
    ).filter((el) => !el.closest('#debug-grid'));

    // Filter to keep only real content elements
    const contentElements = elements.filter((el) => {
      if (el.classList.contains('snap-grid-element')) {
        return false;
      }
      return (
        //   !el.classList.contains('data-html-to-image-ignore') &&
        //    !el.id?.includes('wrap-') &&
        !(el.tagName === 'circle' && el.classList.contains('vertex'))
      );
    });

    if (contentElements.length === 0) {
      console.log('No content elements found');
      return;
    }

    // Calculate global bounds of all elements
    let minX = Infinity;
    let minY = Infinity;
    let maxX = -Infinity;
    let maxY = -Infinity;

    let foundValidElement = false;

    contentElements.forEach((element) => {
      try {
        let bbox;
        console.log('element ', element);
        const textBlockBBox = getTextBlockBBox(element);
        if (textBlockBBox) {
          // C'est un bloc de texte, utiliser le bbox calculé
          bbox = textBlockBBox;
        } else if (element.getBBox) {
          console.log('element hasbbox');
          bbox = element.getBBox();
        } else if (element.getBoundingClientRect) {
          console.log('element bbox');
          const clientRect = element.getBoundingClientRect();
          const pt1 = svgPad.createSVGPoint();
          pt1.x = clientRect.left;
          pt1.y = clientRect.top;

          const pt2 = svgPad.createSVGPoint();
          pt2.x = clientRect.right;
          pt2.y = clientRect.bottom;

          const ctm = transformGroup.getCTM();
          if (ctm && ctm.inverse) {
            const inverseCTM = ctm.inverse();
            const svgPt1 = pt1.matrixTransform(inverseCTM);
            const svgPt2 = pt2.matrixTransform(inverseCTM);

            bbox = {
              x: svgPt1.x,
              y: svgPt1.y,
              width: svgPt2.x - svgPt1.x,
              height: svgPt2.y - svgPt1.y,
            };
          }
        }

        if (bbox) {
          minX = Math.min(minX, bbox.x);
          minY = Math.min(minY, bbox.y);
          maxX = Math.max(maxX, bbox.x + bbox.width);
          maxY = Math.max(maxY, bbox.y + bbox.height);
          console.log(
            'bbox',
            bbox.x,
            bbox.y,
            bbox.x + bbox.width,
            bbox.y + bbox.height
          );
          foundValidElement = true;
        }
      } catch (e) {
        console.warn('Unable to get dimensions for:', element, e);
      }
    });

    if (!foundValidElement) {
      console.log('No valid content elements found');
      return;
    }

    // Add padding
    const padding = 50;
    minX -= padding;
    minY -= padding;
    maxX += padding;
    maxY += padding;

    // Content dimensions in SVG coordinates
    const contentWidth = maxX - minX;
    const contentHeight = maxY - minY;

    // Content center
    const contentCenterX = (minX + maxX) / 2;
    const contentCenterY = (minY + maxY) / 2;

    // If debug mode is enabled, add debug elements
    if (isDebugMode) {
      // Create and add debug grid
      const debugGrid = createDebugGrid();
      if (debugGrid) {
        transformGroup.appendChild(debugGrid);

        // Mark content center and bounds
        markContentBounds(
          debugGrid,
          { minX, minY, maxX, maxY },
          { x: contentCenterX, y: contentCenterY },
          contentWidth,
          contentHeight
        );
      }
    }

    // Calculate optimal zoom
    const zoomX = viewportWidth / contentWidth;
    const zoomY = viewportHeight / contentHeight;
    const newZoom = Math.min(Math.min(zoomX, zoomY), 2);

    // Viewport center
    const viewportCenterX = viewportWidth / 2;
    const viewportCenterY = viewportHeight / 2;

    // Fixed formula for pan
    const newPanPosition = {
      x: (viewportCenterX - contentCenterX) * newZoom,
      y: (viewportCenterY - contentCenterY) * newZoom,
    };

    // Get current transformations for debug
    const currentTransform = transformGroup.getAttribute('transform') || 'none';

    // If debug mode is enabled, display info block
    if (isDebugMode) {
      createDebugInfoBlock({
        viewportSize: { width: viewportWidth, height: viewportHeight },
        contentBounds: { minX, minY, maxX, maxY },
        contentCenter: { x: contentCenterX, y: contentCenterY },
        contentSize: { width: contentWidth, height: contentHeight },
        transform: {
          currentTransform,
          currentZoom,
          currentPan,
        },
        newTransform: {
          newZoom,
          newPan: newPanPosition,
        },
      });
    }

    // Apply zoom and pan
    dispatch(setZoomLevel(newZoom));
    dispatch(setPanPosition(newPanPosition));
  };

  return (
    <ZoomSliderContainer>
      <Group spacing={10} noWrap>
        <ZoomOutIcon
          style={{ cursor: 'pointer' }}
          onClick={() => handleZoomChange(Math.max(0.5, zoomLevel - 0.1))}
        />
        <Slider
          value={zoomLevel}
          onChange={handleZoomChange}
          min={0.5}
          max={2}
          step={0.1}
          style={{ flex: 1 }}
          label={(value) => `${Math.round(value * 100)}%`}
        />
        <ZoomInIcon
          style={{ cursor: 'pointer' }}
          onClick={() => handleZoomChange(Math.min(2, zoomLevel + 0.1))}
        />
        <Text size="xs" style={{ width: 40, textAlign: 'right' }}>
          {Math.round(zoomLevel * 100)}%
        </Text>
        <Button
          compact
          size="xs"
          variant="subtle"
          onClick={resetZoom}
          style={{ marginLeft: 5 }}
        >
          Reset
        </Button>
        <Button
          compact
          size="xs"
          variant="subtle"
          onClick={autoFit}
          style={{ marginLeft: 5 }}
          title="Auto-fit zoom to display all elements"
        >
          AutoFit
        </Button>
        <Button
          compact
          size="xs"
          variant={isDebugMode ? 'filled' : 'subtle'}
          color={isDebugMode ? 'orange' : 'gray'}
          onClick={toggleDebug}
          title="Toggle debug mode"
          leftIcon={<Bug size={16} />}
        >
          Debug
        </Button>
      </Group>
    </ZoomSliderContainer>
  );
};

/**
 * Calcule la boîte englobante (bbox) pour un bloc de texte SVG avec foreignObject
 * @param {SVGElement} element - L'élément SVG contenant le foreignObject avec texte
 * @returns {Object|null} Un bbox {x, y, width, height} ou null si pas calculable
 */
export const getTextBlockBBox = (element) => {
  try {
    // Vérifier si c'est un bloc de texte (SVG contenant un foreignObject)
    if (element.tagName !== 'svg' || !element.querySelector('foreignObject')) {
      return null;
    }

    const foreignObj = element.querySelector('foreignObject');
    const span = foreignObj.querySelector('span');

    if (!foreignObj) return null;

    // Obtenir la position du SVG parent
    const svgX = parseFloat(element.getAttribute('x') || 0);
    const svgY = parseFloat(element.getAttribute('y') || 0);

    // Obtenir les dimensions du foreignObject
    const foreignWidth = parseFloat(foreignObj.getAttribute('width') || 0);
    const foreignHeight = parseFloat(foreignObj.getAttribute('height') || 0);

    // Obtenir les paddings du foreignObject depuis le style
    const foreignStyle = foreignObj.getAttribute('style') || '';

    // Extraire les valeurs de padding
    const paddingLeft = parseFloat(
      foreignStyle.match(/padding-left:\s*(\d+)px/)
        ? foreignStyle.match(/padding-left:\s*(\d+)px/)[1]
        : 0
    );

    const paddingTop = parseFloat(
      foreignStyle.match(/padding-top:\s*(\d+)px/)
        ? foreignStyle.match(/padding-top:\s*(\d+)px/)[1]
        : 0
    );

    // Estimer padding-right et padding-bottom s'ils ne sont pas explicites
    const paddingRight = parseFloat(
      foreignStyle.match(/padding-right:\s*(\d+)px/)
        ? foreignStyle.match(/padding-right:\s*(\d+)px/)[1]
        : paddingLeft
    );

    const paddingBottom = parseFloat(
      foreignStyle.match(/padding-bottom:\s*(\d+)px/)
        ? foreignStyle.match(/padding-bottom:\s*(\d+)px/)[1]
        : paddingTop
    );

    // Calculer les dimensions réelles incluant le padding
    const totalWidth = foreignWidth + paddingLeft + paddingRight;
    const totalHeight = foreignHeight + paddingTop + paddingBottom;

    // Créer un bbox qui englobe tout
    return {
      x: svgX,
      y: svgY,
      width: totalWidth,
      height: totalHeight,
    };
  } catch (error) {
    console.warn('Erreur lors du calcul du bbox pour le bloc de texte:', error);
    return null;
  }
};

export default ZoomSlider;
