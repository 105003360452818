import React, { memo, useCallback } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

import ObjectActionCreators from '../../../actions.js';
import {
  NODE_NAVIG_WIDTH,
  HEADER_HEIGHT,
  HEADER_LEVEL_OFFSET,
  DATA_COLUMNS_HEADER_OFFSET,
  DATA_COLUMNS_ICON_OFFSET,
  DATA_COLUMNS_COLOR_BAR_OFFSET,
  RESIZE_PANE_BAR_WIDTH,
} from '../../../constants/layout.js';
import { addScrollListener } from '../../../reducers/objectReducers/action.js';
import { getBandsPosition } from '../../Utils/LayoutUtils.js';
import {
  getTypeOfField,
  getOptionsForField,
  getLabel,
  getObjectDefField,
} from '../../Utils/Utils.js';
import { splitPattern } from '../../Utils/TextUtils.js';

import {
  DataColumnHeader,
  DataColumnsHeaders,
} from '../DataColumnsHeaders/DataColumnsHeaders.js';
import NavigHeader from '../NavigHeader';
import { NavigNodeAvailable } from '../NavigNodeAvailable/NavigNodeAvailable.js';

import {
  IconExpandColumn,
  NavigHeaderBarContainer,
} from './NavigHeaderBarCss.js';

function NavigHeaderBar({
  // Props supposées provenir directement du parent
  handleDrop,
  nodes,
  scalesConfig,
  dataMap,
  left,
}) {
  // ---- Récupération des states du store Redux via les Hooks ----
  const dispatch = useDispatch();

  // mapStateToProps équivalent :
  const pattern = useSelector((state) => state.objects.pattern);
  const displayMode = useSelector((state) => state.objects.displayMode);
  const arrayClassifBy = useSelector((state) => state.objects.arrayClassifBy);
  const bandValues = useSelector(
    (state) => state.CLASSIFIER_REDUCER.bandValues
  );
  const objectsDefList = useSelector((state) => state.objects.objectsDefList);
  const arraySortBy = useSelector((state) => state.objects.arraySortBy);
  const levels = useSelector((state) => state.objects.levels);
  const panels = useSelector((state) => state.objects.panels, shallowEqual);

  // Filtrage de dataColumns comme dans mapStateToProps
  const dataColumns = useSelector((state) => {
    const cols = state.objects.dataColumns;
    if (!cols) return undefined;
    return cols.map((level) =>
      level.filter((elem) =>
        getObjectDefField(objectsDefList, elem.to, elem.data)
      )
    );
  });

  // ---- mapDispatchToProps équivalent ----
  const addScrollListenerCb = useCallback(
    (callback) => dispatch(addScrollListener(callback)),
    [dispatch]
  );

  const setSortBy = useCallback(
    (sortKey, level) =>
      dispatch(ObjectActionCreators.setSortBy(sortKey, level)),
    [dispatch]
  );

  const filter_set_level = useCallback(
    (level, value) =>
      dispatch(ObjectActionCreators.filter_set_level(level, value)),
    [dispatch]
  );

  // ---- Fonctions internes équivalentes à celles de la classe ----
  const getObjectType = useCallback(
    (index) => {
      return splitPattern(pattern)[index];
    },
    [pattern]
  );

  const getNavigHeaders = useCallback(() => {
    return [...Array(levels).keys()].map((index) => {
      const nodeMode = displayMode[index];
      const isGrid = nodeMode === 'grid';

      // Récupère la configuration de la "band"
      const band = getBandsPosition(index, panels);

      // Détermine le type d'objet
      const objectType = getObjectType(index);

      // Nom du champ, valeurs de classification, etc.

      const fieldName = scalesConfig.arrayClassifBy[index];

      // Champs/Options récupérés via l’objectsDefList
      let fieldOptions = getOptionsForField(
        objectsDefList,
        objectType,
        fieldName
      );
      let defField = getObjectDefField(objectsDefList, objectType, fieldName);

      // Gestion du cas "formula parentValue"
      if (
        defField &&
        defField.type === 'formula' &&
        defField.formulaType === 'parentValue'
      ) {
        const objectTypeTrg = defField.formulaObject;
        defField = getObjectDefField(
          objectsDefList,
          defField.formulaObject,
          defField.formulaField
        );
        fieldOptions = getOptionsForField(
          objectsDefList,
          objectTypeTrg,
          defField?.attrName
        );
      }

      const fieldType = defField?.type;

      // Filtrage des data columns (si mode "grid")
      const filteredDataColumns = isGrid
        ? dataColumns?.[index]?.filter((col) =>
            objectType.toLowerCase().startsWith(col.to)
          )
        : [];

      // Rendu du header de navigation et éventuellement des colonnes
      return (
        <div key={`mk${index}`}>
          <NavigHeader
            key={`navH${index}`}
            id={`navigHeader${index}`}
            arrayClassif={scalesConfig.bandValues[index]}
            colorBand={scalesConfig.colorScales[index]}
            fieldName={fieldName}
            fieldType={fieldType}
            fieldOptions={fieldOptions}
            options={fieldOptions}
            level={index}
            objectType={objectType}
            onDrop={handleDrop}
            scalerXBand={scalesConfig.bandScales[index]}
            width={band?.width - NODE_NAVIG_WIDTH}
            x={band?.start + RESIZE_PANE_BAR_WIDTH * index}
            statsMap={dataMap}
          />
          {isGrid && (
            <DataColumnsHeaders
              height={HEADER_HEIGHT - 105}
              left={
                band.start +
                DATA_COLUMNS_COLOR_BAR_OFFSET +
                DATA_COLUMNS_ICON_OFFSET +
                DATA_COLUMNS_HEADER_OFFSET * (index - 1)
              }
              width={band.width}
              level={index}
            >
              <IconExpandColumn />
              {filteredDataColumns?.map((item, ind, arr) => (
                <DataColumnHeader
                  isLastColumn={arr.length - 1 === ind}
                  isSorted={arraySortBy[index] === item.data}
                  handleClick={() => setSortBy(item.data, index)}
                  width={item.w}
                  key={item.data}
                  panelNumber={index}
                  colNumber={ind}
                >
                  {item.label ??
                    getLabel(objectsDefList, objectType, item.data)}
                </DataColumnHeader>
              ))}
            </DataColumnsHeaders>
          )}
          {
            // Affichage du composant NavigNodeAvailable s'il n'y a pas de noeuds à ce niveau
            nodes?.filter((elem) => elem.depth - 1 === index).length === 0 && (
              <NavigNodeAvailable
                index={index}
                left={band?.start + RESIZE_PANE_BAR_WIDTH * (index - 2)}
                filter_set_level={filter_set_level}
                width={band?.width ?? 300}
              />
            )
          }
        </div>
      );
    });
  }, [
    displayMode,
    panels,
    getObjectType,
    objectsDefList,
    scalesConfig,
    handleDrop,
    dataMap,
    dataColumns,
    arraySortBy,
    setSortBy,
    filter_set_level,
  ]);

  // ---- Rendu du composant ----
  return (
    <NavigHeaderBarContainer id="NavigHeaderBar" top={0} left={left}>
      {getNavigHeaders()}
    </NavigHeaderBarContainer>
  );
}

export default memo(NavigHeaderBar);
