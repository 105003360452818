import styled from 'styled-components';

//--------------------------------------------------------------------------------------------------------
export const ButtonEditMultiple = styled.button({
  backgroundColor: '#F5F5F5',
  border: '1px solid gray',
  borderRadius: '5px',
  color: 'gray',
  height: '20px',
  marginLeft: '4px',
  marginTop: '0px',
  width: '20px',
});

export const RichContent = styled.div({
  border: `1px solid var(--ds-border-base)`,
  color: `var(--ds-text-base) !important`,
  minHeight: '60px',
  borderRadius: '3px',
  whiteSpace: 'pre',
  width: '100%',
  textWrap: 'wrap',
  padding: '0px',
});

export const StyledDropZone = styled.div({
  backgroundColor: '#534F9D22',
  border: '1px dashed #534F9D55',
  borderRadius: '5px',
  color: '#534F9D',
  display: (props) => (props.fileDragged ? '' : 'none'),
  fontSize: '22px',
  opacity: (props) => (props.fileHover ? '1' : '0.4'),
  padding: '30px',
  transition: 'opacity 0.4s',
  userSelect: 'none',
  width: '100%',
  '& * ': {
    // captureEvents:'none',
    color: 'black',
  },
});

export const MarkDownBloc = styled.div({
  backgroundColor: 'transparent',
  color: (props) => props?.color || 'black',
  cursor: 'pointer',
  fontSize: '12px',
  fontWeight: '400',
  minHeight: '25px',
  minWidth: '100%',
  overflow: 'auto',
  padding: '5',
  'h1,h2,h3': {
    color: 'black',
    margin: '0px',
  },
  h1: {
    fontSize: '18px',
  },
  h2: {
    fontSize: '16px',
  },
  h3: {
    fontSize: '14px',
  },
  p: {
    border: '0px solid red',
    padding: '0px',
    margin: '0px',
    fontSize: '12px',
  },
  li: {
    color: '#1C7ED6',
    fontSize: '14',
  },
});

export const TextArea = styled.textarea({
  backgroundColor: '#FFFFFF00',
  border: (props) => `1px solid ${props?.borderColor || 'white'} `,
  borderRadius: '5px',
  boxShadow: '0 0 3px #CCCCCC44',
  fontSize: '13px',
  fontWeight: 'normal',
  minHeight: '50px',
  height: '100px',
  outline: 'none !important',
  resize: 'vertical',
  width: '100%',
  padding: '5px',
  color: (props) => props?.color,
});

export const ObjFieldsListItem = styled.div({
  fontSize: '15px',
  fontWeight: 'bold',
});
export const ObjFieldsListOptionSelect = styled.select({
  backgroundColor: 'transparent',
  border: '0px',
  borderRadius: '2px',
  color: (props) => props.color,
  fontSize: '13px',
  height: '25px',
  paddingLeft: '0px',
  width: (props) => props.width,
});

export const ObjFieldsListOptionUsed = styled.option({
  color: (props) => props.color,
  backgroundColor: (props) => props.backgroundColor,

  fontSize: '13px',
  width: '100%',
});

export const ObjFieldsStyles = {
  smallFontSize: {
    fontSize: '12px',
    color: '#183BF3',
  },
  camrec: {
    width: '100%',
    overflow: 'hidden',
  },
  valueReadonly: {
    fontSize: '15px',
    fontWeight: 'bold',
  },
  icon: {
    borderRadius: '8px',
    maxWidth: '100%',
    maxHeight: '500px',
  },
  attrNameContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  isFilePresent: {
    display: 'grid',
    gridTemplateColumns: '85px auto ',
    gridAutoRows: 'minmax(20px, auto)',
  },
  isFilePresentContent: {
    border: '0px solid white',
    fontSize: 11,
    gridColumn: '2',
    gridRow: '1',
    overflow: 'hidden',
    padding: '5px',
    width: '95%',
  },
  isFilePresentRight: {
    gridColumn: '1',
    gridRow: '1 / 3',
    border: '0px solid white',
    paddingTop: '10px',
  },
  isFilePresentIcon: {
    borderRadius: '8px',
    maxWidth: '100%',
    maxHeight: '60px',
  },
  fileOrigName: { gridColumn: '1', gridRow: '1', border: '0px solid white' },
  fileOrigImg: { width: '24px', height: '24px', border: '0px solid green' },
  fileContainer: {
    gridColumn: '2 ',
    gridRow: '2',
    float: 'right',
  },
  isFileOrigName: {
    border: '0px solid red',
    borderRadius: '5px',
    height: '500px',
    padding: '20px',
    width: '100%',
  },
  objFieldFormulaTable: {
    border: '0px solid gray',
    fontSize: '10px',
    width: '94%',
  },
  value: {
    fontSize: '14px',
    fontWeight: '400',
  },
};

export const ObjFieldTextInput = styled.input({
  backgroundColor: (props) => props.backgroundColor,
  border: '0px',
  borderRadius: '2px',
  color: (props) => props.color,
  fontSize: '13px',
  fontWeight: 'normal',
  margin: '0px',
  padding: '2px',
  width: (props) => props.width,
});

export const ObjFieldNumberInput = styled.input({
  backgroundColor: (props) => props.backgroundColor,
  borderRadius: '2px',
  color: (props) => props.color,
  fontSize: '13px',
  fontWeight: 'normal',
  margin: '0px',
  padding: '2px',
  width: '100%',
});

export const ObjFieldLocationInput = styled.input({
  backgroundColor: (props) => props.backgroundColor,
  border: '0px',
  borderRadius: '2px',
  color: (props) => props.color,
  fontSize: '15px',
  fontWeight: 'bold',
  margin: '0px',
  padding: '2px',
  width: '100%',
});

export const ObjFieldParentAttrName = styled.input({
  display: (props) => props.display,
  fontSize: '10px',
  fontWeight: 'normal',
  margin: '10',
  marginLeft: '20px',
  border: '0px',
});
export const ObjFieldParentHandlePaste = styled.div({
  display: (props) => props.display,
  width: '50%',
  margin: '10px',
});

export const ObjFieldParentIframe = styled.iframe({
  background: '#FFFFFF',
  border: '1px solid #CCC',
  height: (props) => props.height,
  transform: (props) => props.transform,
  transformOrigin: '0% 0%',
  width: (props) => props.width,
});

export const ObjFieldParentIframePDF = styled.iframe({
  background: '#FFFFFF',
  border: '1px solid #CCC',
  height: (props) => props.height,
  transform: (props) => props.transform,
  transformOrigin: '0% 0%',
  width: (props) => props.width,
});
export const ObjFieldFormulaContent = styled.div({
  // backgroundColor: (props) => props.backgroundColor,
  border: (props) => props.border,
  borderRadius: '3px',
  color: (props) => props.color,
  marginTop: '3px',
  padding: '2px',
});

export const ObjFieldFormulaContentTd = styled.td({
  backgroundColor: (props) => props.backgroundColor,
  border: '0px solid gray',
  borderRadius: '3px',
  color: (props) => props.color,
  padding: '4px',
});

export const ObjFieldTextInput1 = styled.input({
  backgroundColor: (props) => props.backgroundColor,
  width: (props) => `${props.width} !important`,
  padding: '4px 10px 4px 10px',
  color: (props) => `${props.color} !important`,
  height: '100%',
});
