import {
  ActionIcon,
  Badge,
  Button,
  Center,
  Group,
  Modal,
  Space,
  Text,
  Transition,
  useMantineTheme,
} from '@mantine/core';
import { cloneDeep } from 'lodash';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Edit, TrashX, X } from 'tabler-icons-react';
import ObjectApi, { ipServer } from '../../api/ObjectApi';
import {
  setCurrentIconSelect,
  updateDataIcons,
} from '../../reducers/iconsLibraryReducer/action';
import {
  currentIconSelectLibrarySelector,
  iconsLibrarySelector,
} from '../../reducers/iconsLibraryReducer/iconsLibrarySelector';
import ObjFieldDrawingPad from '../UI/Objects/ObjFieldDrawingPad/ObjFieldDrawingPad';
import { ImageIcon } from './ListIcons';
import { modalStyle } from '../../constants/commonStyles';
import ReduxDrawingPadWrapper from '../UI/Objects/ObjFieldDrawingPad/ReduxDrawingPadWrapper';

const scaleY = {
  in: { opacity: 1, transform: 'scaleX(1)' },
  out: { opacity: 0, transform: 'scaleX(0)' },
  common: { transformOrigin: 'left' },
  transitionProperty: 'transform, opacity',
};
export default function CurrentIconSelect({
  updateItemIcons,
  currentSession,
  updateIconSvgDrawing,
}) {
  const theme = useMantineTheme();
  const currentIconSelectLibrary = useSelector(
    currentIconSelectLibrarySelector
  );

  const iconsLibrary = useSelector(iconsLibrarySelector);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const onToggleEditMode = () => {
    setEditMode(!editMode);
  };

  const dispatch = useDispatch();

  const onCloseModalIcon = useCallback(() => {
    dispatch(setCurrentIconSelect(null));
  }, []);

  const [isShowModalConfirmDelete, setShowModalConfirmDelete] = useState(false);
  const { t } = useTranslation();

  const onHideModalConfirmDelete = useCallback(() => {
    setShowModalConfirmDelete(false);
  }, []);

  const onDeleteIcon = useCallback(async () => {
    const newData = cloneDeep(iconsLibrary);
    newData[currentIconSelectLibrary.currentSession].categories[
      currentIconSelectLibrary.currentCategory.parentId
    ].icons[currentIconSelectLibrary.key].isDelete = true;
    onHideModalConfirmDelete();
    try {
      if (isLoadingDelete) return;
      setIsLoadingDelete(true);
      await ObjectApi.saveLibrary(JSON.stringify(newData));
      dispatch(updateDataIcons(newData));
      setIsLoadingDelete(false);
      dispatch(setCurrentIconSelect(null));
      updateItemIcons(currentIconSelectLibrary, 'delete');
    } catch (error) {
      setIsLoadingDelete(false);
    }
  }, [iconsLibrary, currentIconSelectLibrary]);
  return (
    <>
      <Transition
        mounted={!!currentIconSelectLibrary}
        transition={scaleY}
        duration={200}
        timingFunction="ease"
      >
        {(styles) => (
          <div
            style={{
              ...styles,
              top: 0,
              left: 0,
              right: 0,
              border: `1px solid ${
                theme.colorScheme === 'dark'
                  ? theme.colors.gray[7]
                  : theme.colors.gray[2]
              }`,
              width: 200,
              height: '100%',
              marginLeft: 12,
              padding: 8,
              borderRadius: 6,
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <ActionIcon onClick={onCloseModalIcon}>
                <X size={16} />
              </ActionIcon>
            </div>
            <Space h={10} />

            <Center>
              <div
                style={{
                  width: 180,
                  height: 180,
                  backgroundColor: 'white',
                  padding: 12,
                  borderRadius: 4,
                }}
              >
                <ImageIcon
                  style={{
                    // backgroundColor:
                    //   theme.colorScheme === 'light'
                    //     ? theme.colors.cool_gray[1]
                    //     : theme.colors.cool_gray[8],
                    backgroundImage: `url('${`${
                      ipServer + currentIconSelectLibrary?.icon
                    }`}')`,
                  }}
                />
              </div>
            </Center>
            <Space h={20} />
            <Group spacing="xs">
              <Text weight={500}>Name:</Text>
              <Text>{currentIconSelectLibrary?.name}</Text>
            </Group>
            <Space h={8} />

            <div>
              <Group spacing="xs">
                <Text weight={500}>Tags:</Text>
                {currentIconSelectLibrary?.tags.map((tag) => (
                  <Badge size="sm" key={tag} color="blue" variant="filled">
                    {tag}
                  </Badge>
                ))}
              </Group>
            </div>
            <Space h={24} />
            <Group>
              <Button
                style={{ width: 100, maxWidth: 100 }}
                loading={isLoadingDelete}
                size="xs"
                onClick={() => setShowModalConfirmDelete(true)}
                color="red"
                rightIcon={!isLoadingDelete && <TrashX size={16} />}
              >
                {t('delete')}
              </Button>
              {currentSession === 'svg' && (
                <Button
                  style={{ width: 100, maxWidth: 100 }}
                  loading={isLoadingDelete}
                  size="xs"
                  onClick={() => setEditMode(true)}
                  rightIcon={!isLoadingDelete && <Edit size={16} />}
                >
                  Edit
                </Button>
              )}
            </Group>
          </div>
        )}
      </Transition>
      <Modal
        zIndex={20000000009}
        opened={isShowModalConfirmDelete}
        onClose={onHideModalConfirmDelete}
        title={t('delete_item')}
        styles={modalStyle}
      >
        <Text weight={400}>{t('delete_icon_confirm')}</Text>
        <Space h={12} />
        <Group spacing="sm">
          <Button
            onClick={onDeleteIcon}
            compact
            color="red"
            rightIcon={<TrashX size={16} />}
          >
            {t('ok')}
          </Button>
          <Button onClick={onHideModalConfirmDelete} compact>
            {t('cancel')}
          </Button>
        </Group>
      </Modal>
      {editMode && (
        <ReduxDrawingPadWrapper
          editMode={editMode}
          onToggleEditMode={onToggleEditMode}
          value={JSON.stringify(currentIconSelectLibrary?.elements)}
          isEditSvgLibIcon={true}
          handleChangeInputDrawing={(_, data) => {}}
          updateIconSvgDrawing={updateIconSvgDrawing}
        />
      )}
    </>
  );
}
