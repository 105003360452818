import React from 'react';
import { useSelector } from 'react-redux';
import { isScrollHorizontalSelector } from '../../../reducers/responsiveReducer/responsiveSelector';
import { currentPaletteActiveSelector } from '../../../reducers/themeReducers/themeSelector';
import { DivNavigPanelContent } from './NavigTreeCSS';

export default function NavigPanelContent({ children, height }) {
  const isScrollHorizontal = useSelector(isScrollHorizontalSelector);
  return (
    <DivNavigPanelContent
      isScrollHorizontal={isScrollHorizontal}
      id="div_navig_panel_content_handle_scroll_x"
      height={height}
    >
      {children}
    </DivNavigPanelContent>
  );
}
