import styled from 'styled-components';
import { BrandBlackbery } from 'tabler-icons-react';

//------------------------------------------------------------------------------
export const STYLEMINIACTIONS = {
  backgroundColor: '#FFFFFF',
  border: '0px solid transparent',
  color: 'black',
  height: 'auto',
  marginLeft: '10px',
  padding: '3px',
  position: 'relative',
  width: 'auto',
  zIndex: 30000,
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  alignItems: 'center',
};
//------------------------------------------------------------------------------
export const IMGSTYLE = {
  border: '0px',
  color: 'red',
  cursor: 'pointer',
  height: '16px',
  margin: '2px',
  width: '16px',
  ':hover': {
    opacity: '0',
  },
};

export const ObjectCreatorMiniContainer = styled.div({
  ...STYLEMINIACTIONS,
  left: -15,
  top: 5,
  ' .title': {
    marginLeft: '3px',
    flex: '0 0 100%',
  },
});
