import { call, put, select, debounce } from 'redux-saga/effects';
import { OBJECTS_TYPE } from '../../reducers/objectReducers/type';
import ObjectActionCreators from '../../actions';
import ObjectApi from '../../api/ObjectApi';

const getState = (state) => state;

function* fetchTreeByPattern(action) {
  try {
    const state = yield select(getState);
    const { pattern, meta } = action;
    console.log(`insaga fetchTreeByPattern, meta:${JSON.stringify(meta)}`);
    // Appel à l'API
    const objects = yield call(
      ObjectApi.getTreeByPattern,
      pattern,
      state.QBUILDER.qb_restrictions,
      state.objects.paging_map,
      state.objects.paging_excluded,
      state.objects.mandatoryLevel,
      state.objects.arraySortBy,
      meta?.abortSignal
    );

    // Dispatch l’action de succès
    yield put({ type: OBJECTS_TYPE.RECEIVE_TREE, success: true, objects });

    // Dispatch des actions supplémentaires si nécessaire
    // Par exemple, getChildsCountByType pour chaque niveau
    const NUM_LEVEL = 5;
    for (let i = 0; i < NUM_LEVEL + 1; i++) {
      console.log('insaga calling getchidlcount');
      yield put(ObjectActionCreators.getChildsCountByType(null, i));
    }
  } catch (error) {
    // Dispatch d’une action de failure si besoin
    yield put({ type: OBJECTS_TYPE.RECEIVE_TREE, success: false });
  }
}

// Une saga unique qui gère plusieurs actions avec des delays différents
export function* watchRequests() {
  yield debounce(50, OBJECTS_TYPE.REQUEST_TREE_BY_PATTERN, fetchTreeByPattern);
}
