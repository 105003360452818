import React from 'react';
import { connect } from 'react-redux';
import Remarkable from 'react-remarkable';
import { isJSON, getFromDico, formatNumber } from '../../../Utils/Utils';
import ObjGraphsRadar from '../ObjGraphsRadar/ObjGraphsRadar';
import ObjectActionCreators from '../../../../actions';
import ObjGraphsBarHtml from '../ObjGraphsBar/ObjGraphsBarHtml';
import ObjGraphsBarHtml2D from '../ObjGraphsBar/ObjGraphsBarHtml2D';
import ObjGraphsBubbleHtml from '../ObjGraphsBubbleHtml/ObjGraphsBubbleHtml';
import {
  MarkDownBloc,
  ObjFieldFormulaContent,
  ObjFieldsStyles,
  RichContent,
} from './ObjFieldsCSS';
import {
  refreshAISynthesis,
  cleanAISynthesis,
} from '../../../../reducers/objectReducers/action';

class ObjFieldFormula extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      localInstruction: '',
    };
    this.handleRefreshFormula = this.handleRefreshFormula.bind(this);
    this.handleCleanFormula = this.handleCleanFormula.bind(this);
    this.handleChangeLocalInstruction =
      this.handleChangeLocalInstruction.bind(this);
  }

  handleRefreshFormula() {
    this.props.refreshAISynthesis(
      this.props.objId,
      this.props.attrName,
      this.state.localInstruction
    );
  }

  handleCleanFormula() {
    this.props.cleanAISynthesis(this.props.objId, this.props.attrName);
  }

  handleChangeLocalInstruction(e) {
    this.setState({ localInstruction: e.target.value });
  }

  render() {
    const formulaCat1 = this.props.formulaCat1;
    const formulaCat2 = this.props.formulaCat2;
    const formulaType = this.props.formulaType;
    const dicoNameRef = this.props.dicoNameRef;
    const refValue = this.props.refValue;
    const unit = this.props.unit;
    const mini = this.props.mini;

    if (isJSON(this.props.value) && this.props.value.startsWith('[')) {
      const myArray = JSON.parse(this.props.value);

      if (myArray != null && myArray.length > 0) {
        myArray.forEach((element) => {
          Object.keys(element).forEach((key) => {
            if (element[key].startsWith('empl')) {
            } else {
              element[key] = getFromDico(dicoNameRef, element[key], 15);
            }
          });
        });

        let isRadar = this.props.options === 'radar';
        const isBubble = this.props.options === 'bubble';

        if (isJSON(this.props.options)) {
          try {
            const objJSON = JSON.parse(this.props.options);
            if (objJSON != null && objJSON.type != null) {
              isRadar = objJSON.type === 'radar';
            }
          } catch (e) {
            console.log(e);
          }
        }
        const isDark = this.props.pal.isDark;
        return (
          <ObjFieldFormulaContent
            color={isDark ? 'white' : '#999999'}
            backgroundColor={this.props.pal.body.smallTag}
          >
            <div
              style={{
                fontSize: 11,
                fontWeight: '900',
                backgroundColor: 'var(--ds-bg-secondary)',
                padding: '3px',
                color: 'var(--ds-base)',
                borderTopRightRadius: '0px',
                borderTopLeftRadius: '5px',
                borderBottomRightRadius: '0px',
                borderBottomLeftRadius: '5px',
                marginLeft: '-3px',
                height: '25px',
                marginTop: '5px',
                marginBottom: '4px',
                padding: '5px',
                paddingLeft: '8px',
              }}
            >
              {this.props.label}
            </div>
            {isRadar && (
              <ObjGraphsRadar
                mini={this.props.mini}
                data={myArray}
                pal={this.props.pal}
              />
            )}
            {!isRadar && !isBubble && !formulaCat2 && (
              <ObjGraphsBarHtml
                formulaCat1={formulaCat1}
                options={this.props.options}
                mini={this.props.mini}
                data={myArray}
                pal={this.props.pal}
              />
            )}
            {!isRadar && !isBubble && formulaCat2 && (
              <ObjGraphsBarHtml2D
                formulaCat1={formulaCat1}
                formulaCat2={formulaCat2}
                options={this.props.options}
                mini={this.props.mini}
                data={myArray}
              />
            )}
            {isBubble && (
              <ObjGraphsBubbleHtml
                formulaCat1={formulaCat1}
                formulaCat2={formulaCat2}
                options={this.props.options}
                refValue={refValue}
                mini={this.props.mini}
                data={myArray}
                pal={this.props.pal}
                displayTotalsX={!mini}
                displayTotalsY={!mini}
              />
            )}
          </ObjFieldFormulaContent>
        );
      }
      return <></>;
    }

    return (
      <>
        {formulaType !== 'AISynthesis' && (
          <div style={ObjFieldsStyles.value}>
            {formatNumber(this.props.value)}
            {unit}
          </div>
        )}
        {formulaType === 'AISynthesis' && (
          <>
            <div style={{ display: 'flex', gap: '5px' }}>
              <button
                style={{
                  backgroundColor: 'var(--ds-bg-base)',
                  color: 'var(--ds-text-base)',
                  border: '1px solid var(--ds-border-base)',
                  borderRadius: '3px',
                }}
                onClick={this.handleRefreshFormula}
              >
                Refresh
              </button>
              <button
                style={{
                  backgroundColor: 'var(--ds-bg-base)',
                  color: 'var(--ds-text-base)',
                  border: '1px solid var(--ds-border-base)',
                  borderRadius: '3px',
                }}
                onClick={this.handleCleanFormula}
              >
                Clean
              </button>
              <input
                placeholder="Alternative Instructions"
                style={{ flex: '1' }}
                onChange={this.handleChangeLocalInstruction}
                value={this.state.localInstruction}
              />
            </div>

            <MarkDownBloc>
              <Remarkable source={this.props.value ?? ''} />
            </MarkDownBloc>
          </>
        )}
      </>
    );
  }
}
ObjFieldFormula.defaultProps = {
  mini: false,
};

//------------------------------------------------------------------------------------------------------
const mapDispatchToProps = (dispatch) => {
  return {
    refreshAISynthesis: (objId, attrName, localInstruction) =>
      dispatch(refreshAISynthesis(objId, attrName, localInstruction)),
    cleanAISynthesis: (objId, attrName) =>
      dispatch(cleanAISynthesis(objId, attrName)),
  };
};

export default connect(null, mapDispatchToProps)(ObjFieldFormula);
