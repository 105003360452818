const initialState = {
  currentInteraction: null, // Stocke le type et les données de l'interaction en cours
  responses: {}, // Stocke les réponses des interactions { questionId: response }
};

const interactionReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'INTERACTION_OPEN':
      return {
        ...state,
        currentInteraction: {
          type: action.payload.interactionType,
          data: action.payload.interactionData,
          response: '', // Initialise la réponse vide
        },
      };
    case 'INTERACTION_CLOSE':
      return {
        ...state,
        currentInteraction: null,
      };
    case 'INTERACTION_RESPONSE':
      return {
        ...state,
        responses: {
          ...state.responses,
          [action.payload.questionId]: action.payload.response,
        },
        currentInteraction: null, // Ferme l'interaction après une réponse
      };
    case 'VOICE_TO_TEXT_INPUT_DONE':
      return {
        ...state,
        currentInteraction: {
          ...state.currentInteraction,
          response: action.objects.transcription, // Met à jour la réponse
        },
      };
    case 'VOICE_TO_TEXT_INPUT_CLIENT_CHANGED':
      return {
        ...state,
        currentInteraction: {
          ...state.currentInteraction,
          response: action.transcription, // Met à jour la réponse
        },
      };
    default:
      return state;
  }
};

export default interactionReducer;
