import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const MonthCalendar = ({ handleDateChanged, attrName }) => {
  const [year, setYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const { t } = useTranslation();
  const handleYearChange = (increment) => {
    setYear(year + increment);
  };

  const handleMonthClick = (month) => {
    setSelectedMonth(month);
    const selectedDate = new Date(year, month - 1, 1);
    handleDateChanged(null, attrName, selectedDate);
  };

  const months = [
    { value: 1, name: t('calendar.january') },
    { value: 2, name: t('calendar.february') },
    { value: 3, name: t('calendar.march') },
    { value: 4, name: t('calendar.april') },
    { value: 5, name: t('calendar.may') },
    { value: 6, name: t('calendar.june') },
    { value: 7, name: t('calendar.july') },
    { value: 8, name: t('calendar.august') },
    { value: 9, name: t('calendar.september') },
    { value: 10, name: t('calendar.october') },
    { value: 11, name: t('calendar.november') },
    { value: 12, name: t('calendar.december') },
  ];

  return (
    <div>
      <div
        style={{
          height: '30px',
          lineHeight: '30px',
        }}
      >
        <button
          style={{
            width: '10%',
            height: '30px',
            padding: 'auto',
            backgroundColor: 'transparent',
            cursor: 'pointer',
            border: '0px',
          }}
          onClick={() => handleYearChange(-1)}
        >
          {'<'}
        </button>
        <div
          style={{
            width: '80%',
            height: '30px',
            display: 'inline-block',
            textAlign: 'center',
            lineHeight: '30px',
            fontWeight: 'bold',
            padding: 'auto',
          }}
        >
          {year}
        </div>
        <button
          style={{
            width: '10%',
            height: '30px',
            padding: 'auto',
            backgroundColor: 'transparent',
            cursor: 'pointer',
            border: '0px',
          }}
          onClick={() => handleYearChange(1)}
        >
          {'>'}
        </button>
      </div>
      <div>
        {months.map((month) => (
          <div
            key={month.value}
            style={{
              width: 'calc(33% - 4px)',
              padding: '5px',
              margin: '2px',
              borderRadius: '5px',
              cursor: 'pointer',
              textAlign: 'center',
              backgroundColor:
                month.value === selectedMonth ? '#406AB5' : '#EEEEEE44',
              color: month.value === selectedMonth ? 'white' : '#406AB5',
              border: '0px solid #000',
              display: 'inline-block',
              fontSize: '14px',
              fontWeight: '500',
            }}
            onClick={() => handleMonthClick(month.value)}
          >
            {month.name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MonthCalendar;
