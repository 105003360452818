import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { svgDiagramSelector } from '../../reducers/svgDiagramReducer/selectors';

import {
  ObjFieldDrawingPadImageStyles,
  ObjFieldDrawingPadSvgStyles,
} from '../UI/Objects/ObjFieldDrawingPad/ObjFieldDrawingPadCSS';
import { getHost } from '../../helper/getHost';
import { buildCursorSvgPadWithAction } from '../../utils/buildCursorSvgPadWithAction';
import { makeDraggable } from '../../utils/DragDropTouchTablet';
import { withSvgTransform } from './withSvgTransform';
import {
  useDiagramView,
  useElementOperations,
} from '../../reducers/svgDiagramReducer/hooks';

const ipServer = `https://${getHost()}`;
console.log('ipServer', ipServer);
const SvgRenderer = ({
  svg,
  setActionDragSvg,
  setSvgFocus,
  handleSaveSvgFileToDb,
  isDisableAction = false,
  prefix_key,
  onClickElement,
  idLineDrawing,
  // makeDraggable,
  // These props come from the HOC
  svgPanPosition,
  svgZoomLevel,
  transformClientToSVG,
}) => {
  const [position, setPosition] = useState({
    x: svg.x,
    y: svg.y,
  });

  useEffect(() => {
    // Mettre à jour la position locale quand svg.x ou svg.y changent
    setPosition({
      x: svg.x,
      y: svg.y,
    });
  }, [svg.x, svg.y]);

  const wrapper = useRef(null);
  const { selectedElementKey, currentAction } = useSelector(svgDiagramSelector);
  const { deleteSelected } = useElementOperations();
  const { gridSize, isGridVisible } = useDiagramView();

  const renderSvgMarkup = () => {
    return { __html: replaceWidthHeight(svg?.points) }; // Convert SVG string to HTML
  };

  const centerSvg = { width: svg.width / 2, height: svg.height / 2 };

  const handleSelectSvg = useCallback(
    (e) => {
      e.stopPropagation(); // Prevent event bubbling
      console.log(
        'SVG element clicked, current action:',
        currentAction,
        'svg key:',
        svg?.key
      );
      if (idLineDrawing) return;
      if (isDisableAction) {
        if (svg?.link && typeof onClickElement === 'function')
          return onClickElement(svg.link);
      }
      // Add specific handling for connector mode
      if (currentAction === 'drawing-connector') {
        console.log('In connector mode, selected element:', svg?.key);
        if (typeof setSvgFocus === 'function') {
          setSvgFocus(svg?.key);
        }
        return;
      }
      if (currentAction === 'delete') {
        return deleteSelected(svg?.key);
      }
      if (typeof setSvgFocus === 'function') setSvgFocus(svg?.key);
    },
    [
      svg?.key,
      svg?.link,
      selectedElementKey,
      isDisableAction,
      currentAction,
      deleteSelected,
      onClickElement,
      idLineDrawing,
    ]
  );

  const onMakeDraggable = (el) => {
    makeDraggable(
      el,
      (e) => {
        if (currentAction === 'delete') return;
        setActionDragSvg('move');
      },
      (position) => {
        const transformedPosition = transformClientToSVG(
          position.x,
          position.y
        );
        // drag end
        const svgBox = document
          .getElementById('svgPad')
          ?.getBoundingClientRect();

        handleSaveSvgFileToDb(
          svg?.key,
          calculateSnapPosition(
            transformedPosition,
            centerSvg,
            gridSize,
            isGridVisible
          )
        );
      },
      (position) => {
        // drag move
        const transformedPosition = transformClientToSVG(
          position.x,
          position.y
        );
        const newPosition = calculateSnapPosition(
          transformedPosition,
          centerSvg,
          gridSize,
          isGridVisible
        );

        // Update position state
        setPosition(newPosition);
        handleSaveSvgFileToDb(svg?.key, newPosition, true); // true flag indicates this is during movement
      },
      (e) => {},
      15
    );
  };

  useEffect(() => {
    if (isDisableAction) return;
    if (svg?.key === selectedElementKey) {
      onMakeDraggable(wrapper.current);
    }
  });

  return (
    <svg
      onClick={(e) => {
        console.log(
          'Container SVG clicked, action:',
          currentAction,
          'id:',
          svg?.key
        );

        // When in connector mode, always handle clicks on the SVG
        if (currentAction === 'drawing-connector') {
          e.stopPropagation();
          console.log('Connector mode - selecting element:', svg?.key);
          if (typeof setSvgFocus === 'function') {
            setSvgFocus(svg?.key);
          }
          return;
        }

        // For other modes, let the inner elements handle clicks
        handleSelectSvg(e);
      }}
      style={{
        cursor: buildCursorSvgPadWithAction(
          isDisableAction,
          currentAction,
          svg?.link
        ),
      }}
    >
      {svg?.type === 'svg' && (
        <ObjFieldDrawingPadSvgStyles
          link={svg?.link}
          currentAction={currentAction}
          isDisableAction={isDisableAction}
          onClick={handleSelectSvg}
          id={prefix_key ? `${prefix_key + svg?.key}` : svg?.key}
          draggable={true}
          x={isDisableAction ? svg?.x : position.x}
          y={isDisableAction ? svg?.y : position.y}
          width={svg?.width}
          height={svg?.height}
          viewBox="0 0 100 100"
          dangerouslySetInnerHTML={renderSvgMarkup()}
        />
      )}
      {svg?.type === 'image' && (
        <ObjFieldDrawingPadImageStyles
          link={svg?.link}
          currentAction={currentAction}
          isDisableAction={isDisableAction}
          onClick={handleSelectSvg}
          id={prefix_key ? `${prefix_key + svg?.key}` : svg?.key}
          draggable={true}
          x={isDisableAction ? svg?.x : position.x}
          y={isDisableAction ? svg?.y : position.y}
          width={svg?.width}
          height={svg?.height}
          viewBox="0 0 100 100"
          dangerouslySetInnerHTML={renderSvgMarkup()}
          xlinkHref={`${ipServer + svg?.points}`}
        />
      )}

      {/* Add a transparent clickable overlay when in connector mode */}
      {currentAction === 'drawing-connector' && (
        <rect
          x={position.x}
          y={position.y}
          width={svg?.width}
          height={svg?.height}
          fill="transparent"
          onClick={(e) => {
            e.stopPropagation();
            console.log('Connector overlay clicked on element:', svg?.key);
            if (typeof setSvgFocus === 'function') {
              setSvgFocus(svg?.key);
            }
          }}
          style={{ cursor: 'crosshair' }}
        />
      )}

      {svg?.key === selectedElementKey && (
        <rect
          // data-html2canvas-ignore="true"
          className="data-html-to-image-ignore"
          style={{ cursor: 'pointer' }}
          id={`wrap-${svg?.key}`}
          ref={wrapper}
          x={position.x - 5}
          y={position.y - 5}
          width={svg?.width + 10}
          height={svg?.height + 10}
          fill="transparent"
          stroke="#D8D8D8"
          strokeWidth="2"
          strokeDasharray="8 4"
        />
      )}
    </svg>
  );
};
const replaceWidthHeight = (svgStr) => {
  const updatedSvgStr = svgStr.replace(/width=".*?"/, `width="100%"`);
  return updatedSvgStr.replace(/height=".*?"/, `height="100%"`);
};

export default withSvgTransform(SvgRenderer);

const calculateSnapPosition = (
  position,
  centerSvg,
  gridSize,
  isGridVisible
) => {
  const x = position.x;
  const y = position.y;
  if (!isGridVisible) {
    return { x, y };
  }
  const xSnap = Math.round(x / gridSize) * gridSize;
  const ySnap = Math.round(y / gridSize) * gridSize;

  const positionSvg = {
    x: xSnap + 5,
    y: ySnap + 5,
  };
  return positionSvg;
};
