import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openObjectDetails } from '../../../reducers/layoutReducer/action';
import { nodeSelectedSelector } from '../../../reducers/layoutReducer/layoutSelector';
import {
  onSetDragTablet,
  onSetLevelNodeNadBandTablet,
  onSetNodeDragTablet,
  onSetOldPositionDragNodeTablet,
  onSetParentIdDragNodeTablet,
  onSetPositionDragNodeTablet,
} from '../../../reducers/responsiveReducer/action';
import {
  isMobileSelector,
  nodeDragSelector,
  nodeFocusSelector,
  positionNodeDragSelector,
} from '../../../reducers/responsiveReducer/responsiveSelector';
import { currentPaletteActiveSelector } from '../../../reducers/themeReducers/themeSelector';
import { makeDraggable } from '../../../utils/DragDropTouchTablet';
import { isTablet } from '../../../utils/detectDevices';
import {
  BorderHightLight,
  NavigNodeColorBar,
  NavigNodeContainerStyle,
} from './NavigNodeContainerCss';

const NavigNodeContainer = (props) => {
  const isNodeSelected =
    useSelector((state) => state.objects.objId) === props.node.data.key;
  const isNodeOnActivePanel = props.isNodeOnActivePanel;
  const nodeFocus = useSelector(nodeFocusSelector);
  const dispatch = useDispatch();
  const nodeDragItem = useSelector(nodeDragSelector);
  const positionNodeDrag = useSelector(positionNodeDragSelector);

  const onDragStart = () => {
    dispatch(onSetDragTablet(true));
    props.onDragStart(document.getElementById(null, props.node.data.key));

    const positionElementDefault = {
      x: props.left || 0,
      y: props.top,
    };

    dispatch(
      onSetOldPositionDragNodeTablet(
        positionElementDefault,
        `nc_${props.node.data.key}`
      )
    );
  };

  const onDragEnd = useCallback(() => {
    dispatch(onSetDragTablet(false));
    const positionElementDefault = { x: null, y: null };
    setTimeout(() => {
      onDragMove(positionElementDefault);
    }, 200);
    props.onDragEnd();
  }, []);

  const onOpenObjectDetails = useCallback(() => {
    props.onMouseUp();
    dispatch(openObjectDetails());
  }, []);

  const isOpenPerspectiveMenu = !!nodeFocus?.perspective;
  const handleOnDragOver = useCallback((e) => {
    e?.preventDefault();
  }, []);

  const isMobile = useSelector(isMobileSelector);

  const currentPaletteActive = useSelector(currentPaletteActiveSelector);

  const isMultipleNodeSelected = useSelector((state) => {
    const getNodeSelectedState = nodeSelectedSelector(
      state,
      props.node.data.key
    );
    return getNodeSelectedState();
  });

  const onDragMove = useCallback((positionElement) => {
    dispatch(onSetPositionDragNodeTablet(positionElement));
  }, []);

  useEffect(() => {
    if (props.node.data.key && isOpenPerspectiveMenu && isTablet) {
      makeDraggable(
        document.getElementById(`nc_${props.node.data.key}`),
        onDragStart,
        onDragEnd,
        onDragMove,
        onOpenObjectDetails
      );
    }
  }, [
    props.node.data.key,
    isOpenPerspectiveMenu,
    isTablet,
    onDragStart,
    onDragEnd,
    onDragMove,
    onOpenObjectDetails,
  ]);

  useEffect(() => {
    if (!nodeDragItem?.id) return;

    const nodeElement = document.getElementById(`nc_${props.node.data.key}`);

    const nodeDrag = document.getElementById(`nc_${nodeDragItem?.id}`);

    try {
      if (nodeDrag && nodeElement) {
        const { left, top, width, height } =
          nodeElement.getBoundingClientRect();
        const {
          left: leftNodeDrag,
          top: topNodeDrag,
          height: heightNodeDrag,
          width: widthNodeDrag,
        } = nodeDrag.getBoundingClientRect();

        if (
          ((left < leftNodeDrag && leftNodeDrag < left + width) ||
            (left < leftNodeDrag + widthNodeDrag / 2 &&
              leftNodeDrag + widthNodeDrag / 2 < left + width)) &&
          ((top < topNodeDrag && topNodeDrag < top + height / 2) ||
            (top < topNodeDrag + heightNodeDrag / 2 &&
              topNodeDrag + heightNodeDrag / 2 < top + height / 2))
        ) {
          nodeElement.style.borderWidth = '4px';
          dispatch(onSetParentIdDragNodeTablet(props.node.data.key));
        } else {
          nodeElement.style.borderWidth = '1px';
        }
      }
    } catch (error) {}
  }, [nodeDragItem?.id, props.node.data.key, positionNodeDrag]);

  const handleOnTouchEnd = (e) => {
    setTimeout(() => {
      dispatch(onSetNodeDragTablet(null));
    }, 150);
    props.onTouchEnd(e);
  };
  const handleOnTouchStart = (e) => {
    dispatch(
      onSetNodeDragTablet({
        id: props.node.data?.key,
        level: props.node.level,
      })
    );
    dispatch(onSetLevelNodeNadBandTablet({ nodeLevel: props.node.level }));
  };

  return (
    <NavigNodeContainerStyle
      isHaveChildNode={props.isHaveChildNode}
      className={`${!isMobile && 'NavigNode'}  step2  ${
        isTablet && 'draggable drag-handle'
      }`}
      data-id={props.node.data.key}
      draggable={props.draggable}
      dragging={props.dragging}
      height={props.height}
      highlighted={props.highlighted}
      id={`nc_${props.node.data.key}`}
      isNodeOnActivePanel={isNodeOnActivePanel}
      isNodeSelected={isNodeSelected}
      key={`nc2_${props.node.data.key}`}
      left={props.left}
      nodeColor={props.nodeColor}
      // handle drag element  here
      onDragEnd={props.onDragEnd}
      onDragOver={handleOnDragOver}
      onDragStart={props.onDragStart}
      // handle drag element  here
      onDrop={props.onDrop}
      onMouseOver={props.onMouseOver}
      onMouseUp={props.onMouseUp}
      onTouchEnd={handleOnTouchEnd}
      onTouchStart={handleOnTouchStart}
      pal={props.pal}
      top={props.top}
      width={props.width}
      zoom={props.zoom}
    >
      {isMultipleNodeSelected && (
        <BorderHightLight
          borderColor={currentPaletteActive.border.borderHightLight}
        />
      )}
      {!isMobile && <NavigNodeColorBar nodeColor={props.nodeColor} />}
      {props.children}
    </NavigNodeContainerStyle>
  );
};

//------------------------------------------------------------------------------

//------------------------------------------------------------------------------

export default memo(NavigNodeContainer);
