import React from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Title } from '@mantine/core';
import NavigBand from '../NavigBand/NavigBand';
import NavigTreeResizePane from '../NavigTreeResizePane/NavigTreeResizePane';
import { BANDS_CONFIG, NODE_NAVIG_WIDTH } from '../../../constants/layout';
import { getBandsPosition } from '../../Utils/LayoutUtils';
import { getObjectDefTrg, getObjectTypeId } from '../../Utils/Utils';
import { splitPattern } from '../../Utils/TextUtils';
import { WrapItemEmpty, ColumnsNodeContainer } from './NavigTreeCSS';
import { isMobileSelector } from '../../../reducers/responsiveReducer/responsiveSelector';


const NavigTreeColumns = ({
  // Props qui doivent rester car spécifiques à l'instance

  scalesConfig,
  dataMap,
  nodes,
  update,
  scaleUpdateCounter,
  handleDrop,
}) => {

  // Selectors Redux
  const displayMode = useSelector((state) => state.objects.displayMode);
  const objectsDefList = useSelector((state) => state.objects.objectsDefList);
  const pattern = useSelector((state) => state.objects.pattern);
  const isMobile = useSelector(isMobileSelector);
  const panels = useSelector((state) => state.objects.panels, shallowEqual);
  const { t } = useTranslation();

  const getObjectType = (index) => {
    return splitPattern(pattern)[index];
  };

  return [0, 1, 2, 3, 4].map((index) => {
    const isGrid = displayMode[index] === 'grid';
    const arrayClassif = scalesConfig.bandValues[index];
    const band = getBandsPosition(index, panels);
    const objectType = getObjectType(index);
    const fieldName = scalesConfig.arrayClassifBy[index];
    const isNextColumnEmpty =
      nodes.filter((a) => a.depth - 1 === index).length === 0;
    const defField = getObjectDefTrg(objectsDefList, objectType, fieldName);

    if (isMobile) {
      return (
        <div
          key={`mobile-column-${index}`}
          style={{
            scrollSnapAlign: 'start',
            zIndex: 10,
            touchAction: 'pan-y pan-x',
            position: 'relative',
          }}
        >
          <ColumnsNodeContainer>
            {isNextColumnEmpty && (
              <WrapItemEmpty>
                <Title
                  style={{
                    color: 'var(--ds-text-secondary)',
                    textAlign: 'center',
                  }}
                  order={6}
                >
                  {t('display.no_data')}
                </Title>
              </WrapItemEmpty>
            )}
            {update(
              nodes.filter((a) => a.depth - 1 === index),
              'default',
              index
            )}
          </ColumnsNodeContainer>
        </div>
      );
    }

    return (
      <NavigTreeResizePane
        key={`rzp${index}`}
        id={`rzp_${index}`}
        level={index}
        minWidth={BANDS_CONFIG[index].minWidth}
        objectTypeId={getObjectTypeId(objectsDefList, objectType)}
        objectTypeName={objectType}
        width={band.width}
      >
        {!isMobile && !isGrid && (
          <NavigBand
            arrayClassif={arrayClassif}
            fieldType={defField?.type}
            level={index}
            colorBandFct={scalesConfig.colorScales[index]}
            objectType={objectType}
            onDrop={handleDrop}
            scalerXBand={scalesConfig.bandScales[index]}
            statsMap={dataMap}
            type={fieldName}
            options={defField?.options}
            availableSpace={band.width - NODE_NAVIG_WIDTH}
            bandValues={scalesConfig.bandValues}
            scaleUpdateCounter={scaleUpdateCounter}
          />
        )}

        {update(
          nodes.filter((a) => a.depth - 1 === index),
          'default',
          index
        )}
      </NavigTreeResizePane>
    );
  });
};

export default NavigTreeColumns;
