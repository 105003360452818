import { Button, Group } from '@mantine/core';
import isArray from 'lodash/isArray';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { currentPaletteActiveSelector } from '../../../reducers/themeReducers/themeSelector';
import {
  NavigNodeAvailableContainer,
  NavigNodeAvailableContainerType,
  navigNodeAvailableStyles,
} from './NavigNodeAvailableCss';
import {
  isFuncAdminSelector,
  isSysAdminSelector,
} from '../../../reducers/profileReducer/profileSelector.js';

export const NavigNodeAvailable = (props) => {
  const { index, filter_set_level, width } = props;
  const childsCountByType = useSelector(
    (state) => state?.objects?.childsCountByType[index] || []
  );
  const { t } = useTranslation();
  const currentPaletteActive = useSelector(currentPaletteActiveSelector);
  const isSysAdmin = useSelector(isSysAdminSelector);

  return (
    isSysAdmin && (
      <NavigNodeAvailableContainer
        left={props.left}
        color={currentPaletteActive?.text?.secondary}
        width={width}
      >
        {t('display.no_data')}
        {isArray(childsCountByType) && childsCountByType?.length > 0 && (
          <NavigNodeAvailableContainerType>
            {t('display.available')}
          </NavigNodeAvailableContainerType>
        )}
        {isArray(childsCountByType) &&
          childsCountByType?.map((item) => {
            return (
              <Group
                direction="column"
                spacing="sm"
                key={`${item.label}__${item.count}`}
              >
                <RenderButton
                  filter_set_level={filter_set_level}
                  index={index}
                  item={item}
                />
              </Group>
            );
          })}
      </NavigNodeAvailableContainer>
    )
  );
};

const RenderButton = ({ filter_set_level, index, item }) => {
  const onClickNode = useCallback(() => {
    filter_set_level(index, item.type);
  }, []);
  return (
    <Button
      variant="subtle"
      compact
      style={navigNodeAvailableStyles.label}
      onClick={onClickNode}
    >
      {item.label} : {item.count}
    </Button>
  );
};

//-----------------------------------------------------------------------------------------------

//-----------------------------------------------------------------------------------------------
