import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import * as DataCells from '../../UI/DataCells/DataCells';
import {
  removeTagHtml,
  getOptionsForField,
  getTypeOfField,
  isLinkedField,
  getRefValuOfField,
  getUnitOfField,
  getObjectDefField,
} from '../../Utils/Utils';
import { DivCellText } from '../../UI/DataCells/DataCellsCSS';
import { CellList } from './CellList';
import ObjectActionCreators from '../../../actions';
import ObjFieldDate from '../../UI/Objects/ObjFields/ObjFieldDate';
import APortal from '../../UI/APortal/APortal';
import ObjFieldList from '../../UI/Objects/ObjFields/ObjFieldList';
import DataColumnResizer from './DataColumResizer';
import TiptapEditor from '../../UI/Objects/ObjFields/TiptapEditor';
import ObjectSelector from '../../object-selector';
import { removeQuote } from '../../Utils/TextUtils';

export default function DataCellByType({
  item,
  node,
  id,
  dicoFullRef,
  dicoNameRef,
  panelNumber,
  colNumber,
  isLastCell = false,
}) {
  let fieldName = item.data;
  let objTypeId = node.data.type;
  let data = node.data[fieldName];
  const nameref = node.data[`nameref_${fieldName}`];
  const objectsDefList = useSelector((state) => state.objects.objectsDefList);
  let typeOfField = getTypeOfField(objectsDefList, objTypeId, fieldName);
  let fldObj = getObjectDefField(objectsDefList, objTypeId, fieldName);
  if (typeOfField === 'formula') {
    if (fldObj.formulaType === 'parentValue') {
      // on simule le champ visé
      data = removeQuote(data);
      data = data.replace(/\\"/g, '"');
      objTypeId = fldObj.formulaObject;
      fieldName = fldObj.formulaField;
      typeOfField = getTypeOfField(objectsDefList, objTypeId, fieldName);
    }
  }
  let options = getOptionsForField(objectsDefList, objTypeId, fieldName);
  const isLink = isLinkedField(objectsDefList, objTypeId, fieldName);

  const refValue = getRefValuOfField(objectsDefList, objTypeId, fieldName);
  const unit = getUnitOfField(objectsDefList, objTypeId, fieldName);
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(data);
  const dispatch = useDispatch();
  const canWrite =
    node.data.canWrite &&
    typeOfField !== 'formula' &&
    fldObj.readOnly === 'false';

  const handleBlur = (e) => {
    setValue(e.target.value);
    setIsEditing(false);
  };

  const handleChangeDate = (name, date) => {
    setValue(date);
    setIsEditing(false);
  };
  const changeValue = (newValue) => {
    setIsEditing(false);
  };

  const handlePreventInputEvent = (e) => {
    e.stopPropagation();
    // e.preventDefault(); remving default behavior would impact +/- ui on input field
  };

  const debouncedSave = debounce((objFormData) => {
    dispatch(
      ObjectActionCreators.saveObjectFormData(node.data.key, [objFormData])
    );
  }, 500);

  useEffect(() => {
    if (value !== data) {
      let objFormData = {
        type: item.type,
        key: node.data.key,
        attrName: item.data,
        value: value,
        defaultValue: value,
      };
      if (canWrite) {
        debouncedSave(objFormData);
      }
    }
  }, [value]);

  const renderDateCell = (data, id) => {
    let value = '';
    try {
      const aDate = new Date(parseInt(data));
      if (!isNaN(aDate)) value = aDate.toLocaleDateString().slice(0, 10);
    } catch (e) {}

    return isEditing ? (
      <div id={`portaldate${id}`}>
        <APortal
          width={300}
          height={400}
          idParent={`portaldate${id}`}
          onClose={() => setIsEditing(false)}
        >
          <ObjFieldDate
            isEditing={isEditing}
            readOnly={!canWrite}
            handleChangeFieldValue={handleChangeDate}
            value={data}
            options={options}
          />
        </APortal>
      </div>
    ) : (
      <DivCellText
        onMouseUp={(e) => e.stopPropagation()}
        onClick={(e) => {
          if (canWrite) setIsEditing(true);
        }}
        id={id}
      >
        {value || ''}
      </DivCellText>
    );
  };

  const renderNumberCell = (data) => {
    res = isNaN(+data) ? (
      <div></div>
    ) : canWrite && isEditing ? (
      <input
        type="number"
        defaultValue={data}
        onMouseUp={handlePreventInputEvent}
        onBlur={handleBlur}
        style={{ width: '100%', height: '100%', fontSize: 12 }}
        autoFocus
      />
    ) : (
      <DataCells.DataCellNumber
        onClick={(e) => {
          if (canWrite) setIsEditing(true);
        }}
        value={data}
        refValue={isNaN(refValue) ? 1000 : refValue}
        unit={unit ?? ''}
      />
    );
    return res;
  };

  const renderYesNo = (item) => {
    if (item.operation && item.operation === 'contains') {
      return (
        <DataCells.DataCellYesNo
          color={
            data && data.indexOf(item.value) > -1 ? '#7FC97F' : 'transparent'
          }
        />
      );
    }
  };
  const renderArea = () => {
    let dispval = data;
    let isName = item.data === 'name';

    let res =
      canWrite && isEditing ? (
        <div id={`portalArea${id}`}>
          <APortal
            idParent={`portalArea${id}`}
            onClose={() => setIsEditing(false)}
            width={600}
            height={400}
          >
            <TiptapEditor
              value={data}
              onUpdate={(newValue) => setValue(newValue)}
              editable={true}
            />
          </APortal>
        </div>
      ) : (
        <DivCellText
          isName={isName}
          onMouseUp={(e) => e.stopPropagation()}
          onClick={(e) => {
            if (canWrite) setIsEditing(true);
          }}
          id={id}
        >
          {removeTagHtml(dispval) || ''}
        </DivCellText>
      );
    return res;
  };

  const renderText = () => {
    let dispval = '';
    let entry = null;
    let isName = item.data === 'name';

    if (nameref) {
      dispval = nameref;
    } else if (data) {
      dispval = `${data}`;
      if (isLink && dicoNameRef[dispval]) {
        dispval = dicoNameRef[dispval];
      }
    }

    let res =
      canWrite && isEditing ? (
        <input
          onMouseUp={handlePreventInputEvent}
          type="text"
          defaultValue={data}
          onChange={(e) => setValue(e.target.value)}
          onBlur={changeValue}
          autoFocus
          style={{ width: '100%', height: '100%', fontSize: 12 }}
        />
      ) : (
        <DivCellText
          isName={isName}
          onMouseUp={(e) => e.stopPropagation()}
          onClick={(e) => {
            if (canWrite) setIsEditing(true);
          }}
          id={id}
        >
          {removeTagHtml(dispval) || ''}
        </DivCellText>
      );
    return res;
  };

  const renderList = (data, dicoKey) => {
    let entry = dicoFullRef[dicoKey];
    let res = isEditing ? (
      <div id={`portalliste${id}`}>
        <APortal
          idParent={`portalliste${id}`}
          onClose={() => setIsEditing(false)}
        >
          <ObjFieldList
            isEditing={isEditing}
            readOnly={!canWrite}
            handleChangeFieldValue={handleChangeDate}
            value={entry.value}
            options={options}
            unit={unit ?? ''}
            orientation="vertical"
          />
        </APortal>
      </div>
    ) : (
      <CellList
        onMouseUp={(e) => e.stopPropagation()}
        onClick={(e) => {
          if (canWrite) setIsEditing(true);
        }}
        icon={entry.icon}
        value={entry.display}
        color={entry.color}
        label={'no label'}
        unit={unit ?? ''}
      />
    );
    return res;
  };

  const renderLinked = (data) => {
    let dispval = '';
    let entry = null;
    let isName = item.data === 'name';

    if (nameref) {
      dispval = nameref;
    } else if (data) {
      dispval = `${data}`;
      if (dicoNameRef[dispval]) {
        dispval = dicoNameRef[dispval];
      }
    }
    let res = isEditing ? (
      <div id={`portalliste${id}`}>
        <APortal
          idParent={`portalliste${id}`}
          onClose={() => setIsEditing(false)}
        >
          <ObjectSelector
            typeSelectedObject={typeOfField}
            isDisplaySummary={false}
            isDisplayTitle={false}
            isDisplayConfirm={false}
            onSelectValue={(value) => {
              setValue(value);
              setIsEditing(false);
            }}
          />
        </APortal>
      </div>
    ) : (
      <DivCellText
        onMouseUp={(e) => e.stopPropagation()}
        onClick={(e) => {
          if (canWrite) setIsEditing(true);
        }}
        id={id}
      >
        {removeTagHtml(dispval) || ''}
      </DivCellText>
    );
    return res;
  };

  let res = null;

  if (item.data === 'crumbtrail') {
    res = data && <DataCells.DataCellCrumbtrail value={data} />;
  } else {
    switch (item.type) {
      case 'yesno':
        res = renderYesNo(item);
        break;

      case 'date':
        res = renderDateCell(data, id);
        break;

      case 'number':
        res = renderNumberCell(data);
        break;

      case 'text':
        let dicoKey = `${item.to}-${item.data}-${data}`;
        if (typeOfField === 'list' && dicoFullRef[dicoKey]) {
          res = renderList(data, dicoKey);
        } else if (options?.includes('richtext')) {
          res = renderArea();
        } else if (isLink) {
          res = renderLinked(data);
        } else {
          res = renderText();
        }

        break;

      default:
        break;
    }
  }

  return (
    <div style={{ display: 'flex', width: '100%' }}>
      <div style={{ width: 'calc(100% - 6px)', overflow: 'hidden' }}>{res}</div>
      {!isLastCell && (
        <DataColumnResizer panelNumber={panelNumber} colNumber={colNumber} />
      )}
    </div>
  );
}
