import styled from 'styled-components';

export const NavigTreeResizePaneContainerDiv = styled.div({
  bottom: '0',
  display: 'flex',
  flexDirection: 'row',
  minWidth: '100%',
  overflow: 'hidden',
  position: 'absolute',
  top: '0',
  whiteSpace: 'nowrap',
});
