import React from 'react';
import { ObjFieldDrawingPadContent } from './ObjFieldDrawingPadCSS';
import SVGDiagramRenderer from '../../../SVGDiagramRenderer';

export default function SvgPreviewContent({ onClickElement, value }) {
  return (
    <ObjFieldDrawingPadContent>
      <svg
        id="svgRes"
        draggable={false}
        width={160}
        height={130}
        viewBox="0 0 790 640"
        preserveAspectRatio="xMidYMid meet"
      >
        <SVGDiagramRenderer
          value={value}
          mode="preview"
          onElementClick={onClickElement}
        />
      </svg>
    </ObjFieldDrawingPadContent>
  );
}
