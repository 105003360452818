import { CellListContainer, CellListIcon, CellListText } from './CellListCSS';

export const CellList = ({
  value,
  color,
  icon,
  onClick,
  onMouseUp,
  unit = '',
}) => {
  return (
    <CellListContainer onClick={onClick} onMouseUp={onMouseUp}>
      <CellListIcon color={color} icon={icon} />
      <CellListText>
        {value}
        {unit}
      </CellListText>
    </CellListContainer>
  );
};
