import styled from 'styled-components';
import { devices } from '../../../constants/devices';
import { isTablet } from '../../../utils/detectDevices';
import { RESIZE_PANE_BAR_WIDTH } from '../../../constants/layout';

// STYLES ---------------------------------------------------------------------
export const StyledResizePane = styled.div({
  // backgroundColor: (props) => (props.zoom < 70 ? '#FFFFFF' : '#F2F4F7EE'),
  backgroundColor: (props) => props?.backgroundColor,

  height: '100%',
  minWidth: (props) => `${props.minWidth}px`,
  opacity: 1,
  overflow: (props) => (props?.isDragTablet && isTablet ? 'visible' : 'hidden'),
  position: (props) =>
    props.column === 'rzp_0' && props?.isMobile ? 'static' : 'relative',
  top: '0px',
  transition:
    'left 0.2s, top 0.2s, opacity 0.3s, height 0.1s, width 0.4s, transform 1s',
  width: (props) => `${props.width + 15}px`,
  [devices.tablet]: {
    width: (props) => `${props.innerWidth / 1.5}px`,
  },
  [devices.mobileL]: {
    width: (props) => `${props.innerWidth - 35}px`,
  },
});
// STYLES ---------------------------------------------------------------------
export const StyledResizePaneBar = styled.div({
  backgroundColor: (props) => (props.level === 0 ? '' : `var(--ds-bg-base)`),
  borderLeft: (props) =>
    props.level === 0 ? '' : `1px solid var(--ds-border-base)`,
  borderRadius: '12px',
  cursor: (props) => (props.level === 0 ? '' : 'col-resize'),
  display: 'inline-block',
  height: '100%',
  left: '3px',
  marginBottom: '20px',
  marginTop: '20px',
  opacity: 0.6,
  position: 'relative',
  width: `${RESIZE_PANE_BAR_WIDTH}px`,
  ':hover': {
    opacity: 1,
    boxShadow: '-1px 1px 1px  rgba(0, 0, 0, 0.1)',
  },
});
// -----------------------------------------------------------------------------
