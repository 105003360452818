import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import ObjFieldDrawingPad from './ObjFieldDrawingPad';
import {
  setPanPosition,
  setZoomLevel,
  setSelectedElement,
  setCurrentAction,
} from '../../../../reducers/svgDiagramReducer/actions';
import {
  svgDiagramSelector,
  selectedElementKeySelector,
  currentActionSelector,
} from '../../../../reducers/svgDiagramReducer/selectors';
import { withSvgTransform } from '../../../SVGDiagramRenderer/withSvgTransform';
import {
  onTogglePreviewSvgSketch,
  setPreviewSvgSketchData,
} from '../../../../reducers/layoutReducer/action';
import ObjectActionCreators from '../../../../actions';
import {
  useDiagramView,
  useElementOperations,
} from '../../../../reducers/svgDiagramReducer/hooks';

const ReduxDrawingPadWrapper = (props) => {
  const dispatch = useDispatch();
  const { gridSize, isGridVisible, toggleGrid, setAttrName } = useDiagramView();
  const { deleteSelected, duplicateSelected, elements, setElements } =
    useElementOperations();
  const selectedElementKey = useSelector(selectedElementKeySelector);
  const currentAction = useSelector(currentActionSelector);

  // Redux dispatch functions that will be passed to ObjFieldDrawingPad
  const dispatchFunctions = {
    setPanPosition: (position) => dispatch(setPanPosition(position)),
    setZoomLevel: (level) => dispatch(setZoomLevel(level)),
    toggleGrid: (isVisible) => dispatch(toggleGrid(isVisible)),
    setSelectedElement: (key) => dispatch(setSelectedElement(key)),
    deleteSelected: (key) => dispatch(deleteSelected(key)),
    setCurrentAction: (action) => dispatch(setCurrentAction(action)),
    showPreviewSvgSketch: () => dispatch(onTogglePreviewSvgSketch(true)),
    setPreviewSvgSketchData: (data) => dispatch(setPreviewSvgSketchData(data)),
    openObjectSelector: (action) =>
      dispatch(ObjectActionCreators.openObjectSelector(action)),
    setDrawingItemSelected: (
      attrNameSelected,
      selectedDrawingItem,
      typeElement
    ) =>
      dispatch(
        ObjectActionCreators.setDrawingItemSelected(
          attrNameSelected,
          selectedDrawingItem,
          typeElement
        )
      ),
  };

  return (
    <ObjFieldDrawingPad
      {...props}
      {...dispatchFunctions}
      // Forward Redux state from mapStateToProps
      zoomLevel={props.zoomLevel}
      panPosition={props.panPosition}
      isGridVisible={isGridVisible}
      gridSize={gridSize}
      objId={props.objId}
      selectedElementKey={selectedElementKey}
      setElements={setElements}
      setAttrName={setAttrName}
      duplicateSelected={duplicateSelected}
      elements={elements}
      currentAction={currentAction}
    />
  );
};

// Map Redux state to component props
const mapStateToProps = (state) => {
  const svgDiagramState = svgDiagramSelector(state);
  return {
    zoomLevel: svgDiagramState.zoomLevel,
    panPosition: svgDiagramState.panPosition,
    isGridVisible: svgDiagramState.isGridVisible,
    objId: state.objects.objId,
  };
};

// Enhanced component with Redux connection and SVG transform capabilities
export default connect(mapStateToProps)(
  withSvgTransform(ReduxDrawingPadWrapper)
);
