/**
 * Utility functions for SVG diagram rendering, shared across all views
 */

/**
 * Get the scale factor for a specific rendering context
 * @param {string} prefixId - Prefix ID used to identify the context (preview_, export_, etc.)
 * @returns {Object} The scale factors for x and y axes
 */
export const getContextScaleFactor = (prefixId = '') => {
  // Default scale factor (editor view)
  const defaultScale = { x: 1, y: 1 };

  // No prefix means we're in the main editor
  if (!prefixId) return defaultScale;

  // For preview mode
  if (prefixId === 'preview_') {
    // Get the preview SVG
    const previewSvg = document.getElementById('svgRes');

    if (previewSvg) {
      // Get the physical dimensions
      const svgWidth = previewSvg.width.baseVal.value;
      const svgHeight = previewSvg.height.baseVal.value;

      // Get the logical dimensions from viewBox
      const viewBox = previewSvg.viewBox.baseVal;

      if (viewBox && viewBox.width && viewBox.height) {
        return {
          x: viewBox.width / svgWidth,
          y: viewBox.height / svgHeight,
        };
      }
    }

    // If we couldn't determine from the SVG, use known values
    return { x: 790 / 160, y: 640 / 130 };
  }

  // For export mode, similar logic
  if (prefixId === 'export_') {
    const exportSvg = document
      .getElementById('wrapSvgPadPreview')
      ?.querySelector('svg');

    if (exportSvg) {
      // Similar calculation to preview
      const svgWidth = exportSvg.width.baseVal.value;
      const svgHeight = exportSvg.height.baseVal.value;

      const viewBox = exportSvg.viewBox.baseVal;

      if (viewBox && viewBox.width && viewBox.height) {
        return {
          x: viewBox.width / svgWidth,
          y: viewBox.height / svgHeight,
        };
      }
    }
  }

  // Default return if we can't determine
  return defaultScale;
};

/**
 * Adjusts a rect based on the current context's scale
 * @param {Object} rect - The client bounding rect
 * @param {string} prefixId - The context prefix ID
 * @returns {Object} Adjusted rect
 */
export const adjustRectForScale = (rect, prefixId = '') => {
  if (!rect) return null;

  const scaleFactor = getContextScaleFactor(prefixId);

  return {
    width: rect.width * scaleFactor.x,
    height: rect.height * scaleFactor.y,
    x: rect.x,
    y: rect.y,
  };
};

/**
 * Calculates the center point of an element
 * @param {Object} element - The element to calculate center for
 * @param {string} prefixId - Optional ID prefix for preview/export modes
 * @param {Array} allElements - All elements in the diagram (needed for some calculations)
 * @returns {Object} The center point {x, y}
 */
export const calculateElementCenter = (
  element,
  prefixId = '',
  allElements = []
) => {
  if (!element) return { x: 0, y: 0 };

  // Get scale factor for this context
  const scaleFactor = getContextScaleFactor(prefixId);

  if (element.type === 'text') {
    // Try to get the actual rendered text dimensions
    const elementId = prefixId
      ? `content_${prefixId}${element.key}`
      : `content_${element.key}`;

    const textContentElement = document.getElementById(elementId);

    if (textContentElement) {
      // Get the actual rendered dimensions and adjust for scale
      const rect = textContentElement.getBoundingClientRect();
      const adjustedRect = adjustRectForScale(rect, prefixId);

      return {
        x: element.x + adjustedRect.width / 2,
        y: element.y + (element.fontSize || 20) / 2,
      };
    }

    // If DOM element isn't available (preview/export mode)
    // Use a consistent estimation based on element properties
    const estimatedWidth =
      element.width ||
      (element.points
        ? String(element.points).length * (element.fontSize || 20) * 0.5
        : 100);

    return {
      x: element.x + estimatedWidth / 2,
      y: element.y + (element.fontSize || 20) / 2,
    };
  }

  if (element.type === 'svg' || element.type === 'image') {
    return {
      x: element.x + element.width / 2,
      y: element.y + element.height / 2,
    };
  }

  if (
    [
      'polyline',
      'line',
      'arrow-line',
      'bidirectional-line',
      'connector',
    ].includes(element.type)
  ) {
    const points = element.points;
    if (points && points.length > 0) {
      let sumX = 0;
      let sumY = 0;
      let validPointCount = 0;

      for (let i = 0; i < points.length; i++) {
        const point = points[i];
        if (
          point &&
          typeof point.x === 'number' &&
          typeof point.y === 'number'
        ) {
          sumX += point.x;
          sumY += point.y;
          validPointCount++;
        }
      }

      if (validPointCount === 0) return { x: 0, y: 0 };

      return {
        x: sumX / validPointCount,
        y: sumY / validPointCount,
      };
    }
  }

  return {
    x: element.x || 0,
    y: element.y || 0,
  };
};

/**
 * Calculates the edge connection point for a connector
 * @param {Object} element - The element to connect to
 * @param {Object} targetPoint - The point to connect to
 * @param {string} prefixId - Optional ID prefix for preview/export modes
 * @returns {Object} The edge connection point {x, y}
 */
export const calculateEdgeConnectionPoint = (
  element,
  targetPoint,
  prefixId = ''
) => {
  // Get the center point of the element
  const center = calculateElementCenter(element, prefixId);

  // Get scale factor for this context
  const scaleFactor = getContextScaleFactor(prefixId);

  if (!targetPoint || !center) {
    return center || { x: 0, y: 0 };
  }

  if (element.type === 'text') {
    let width;
    let height;

    // Get the appropriate element ID based on mode
    const elementId = prefixId
      ? `content_${prefixId}${element.key}`
      : `content_${element.key}`;

    const textContentElement = document.getElementById(elementId);

    if (textContentElement) {
      // Get dimensions from the actual rendered element and adjust for scale
      const rect = textContentElement.getBoundingClientRect();
      const adjustedRect = adjustRectForScale(rect, prefixId);
      width = adjustedRect.width;
      height = adjustedRect.height;
    } else {
      // Make reliable estimates for text dimensions
      width =
        element.width ||
        (element.points
          ? String(element.points).length * (element.fontSize || 20) * 0.5
          : 100);
      height = (element.fontSize || 20) * 1.2;
    }

    // Add padding as the text renderer does
    width += 40;
    height += 10;

    return calculateIntersectionPoint(
      center,
      targetPoint,
      element.x,
      element.y,
      width,
      height
    );
  }

  if (element.type === 'svg' || element.type === 'image') {
    // For SVG and image elements, use the defined width and height
    return calculateIntersectionPoint(
      center,
      targetPoint,
      element.x,
      element.y,
      element.width,
      element.height
    );
  }

  if (
    ['polyline', 'line', 'arrow-line', 'bidirectional-line'].includes(
      element.type
    )
  ) {
    // For line-based elements, calculate the closest point on the line
    // This is a simplified approach - just use center for now
    return center;
  }

  // Fallback to center if we can't calculate edge intersection
  return center;
};

/**
 * Calculates the intersection point of a line from center to target with the element's bounding box
 * @param {Object} center - The center point {x, y}
 * @param {Object} target - The target point {x, y}
 * @param {number} x - The element's x position
 * @param {number} y - The element's y position
 * @param {number} width - The element's width
 * @param {number} height - The element's height
 * @returns {Object} The intersection point {x, y}
 */
export const calculateIntersectionPoint = (
  center,
  target,
  x,
  y,
  width,
  height
) => {
  // Ensure all values are numbers and valid
  if (
    !center ||
    !target ||
    typeof center.x !== 'number' ||
    typeof center.y !== 'number' ||
    typeof target.x !== 'number' ||
    typeof target.y !== 'number' ||
    typeof x !== 'number' ||
    typeof y !== 'number' ||
    typeof width !== 'number' ||
    typeof height !== 'number'
  ) {
    console.warn('Invalid inputs to calculateIntersectionPoint', {
      center,
      target,
      x,
      y,
      width,
      height,
    });
    return center || { x: 0, y: 0 };
  }

  // Direction vector from center to target
  const dx = target.x - center.x;
  const dy = target.y - center.y;

  // Check for zero direction to avoid division by zero
  if (dx === 0 && dy === 0) return center;

  // Calculate the horizontal and vertical distances to the boundaries
  const right = x + width;
  const bottom = y + height;

  // Calculate how far we can go in each direction
  // Handle case where dx or dy is zero to avoid division by zero
  const rxPos = dx !== 0 ? (right - center.x) / dx : Infinity;
  const rxNeg = dx !== 0 ? (x - center.x) / dx : Infinity;
  const ryPos = dy !== 0 ? (bottom - center.y) / dy : Infinity;
  const ryNeg = dy !== 0 ? (y - center.y) / dy : Infinity;

  // Filter out negative values, which indicate intersections in the opposite direction
  const validRs = [rxPos, rxNeg, ryPos, ryNeg].filter((r) => r > 0);

  if (validRs.length === 0) {
    // If no valid intersections, return center
    return center;
  }

  // Find the smallest positive value, which represents the closest intersection
  const r = Math.min(...validRs);

  // Calculate the intersection point
  return {
    x: center.x + r * dx,
    y: center.y + r * dy,
  };
};

/**
 * Generates a path for the connector with a curve
 * @param {Object} source - The source point {x, y}
 * @param {Object} target - The target point {x, y}
 * @param {number} curveAmount - The amount of curve (0-100)
 * @returns {string} SVG path string
 */
export const generateConnectorPath = (source, target, curveAmount = 20) => {
  // Input validation
  if (
    !source ||
    !target ||
    typeof source.x !== 'number' ||
    typeof source.y !== 'number' ||
    typeof target.x !== 'number' ||
    typeof target.y !== 'number'
  ) {
    console.warn('Invalid source or target for generateConnectorPath', {
      source,
      target,
    });
    return `M0,0 L0,0`;
  }

  // Calculate control points for a curved path
  const dx = target.x - source.x;
  const dy = target.y - source.y;
  const distance = Math.sqrt(dx * dx + dy * dy);

  // Only curve if we have enough distance
  if (distance > 50) {
    // Create a slight curve by offsetting the midpoint
    const curveOffset = Math.min(distance * 0.2, curveAmount);
    const midpoint = {
      x: (source.x + target.x) / 2,
      y: (source.y + target.y) / 2,
    };

    // Perpendicular direction for the control point
    const nx = -dy / distance;
    const ny = dx / distance;

    const controlPoint = {
      x: midpoint.x + curveOffset * nx,
      y: midpoint.y + curveOffset * ny,
    };

    return `M${source.x},${source.y} Q${controlPoint.x},${controlPoint.y} ${target.x},${target.y}`;
  }

  // For short distances, use a straight line
  return `M${source.x},${source.y} L${target.x},${target.y}`;
};

/**
 * Calculate connection points between source and target elements
 * @param {Object} lineData - The connector data
 * @param {Array} allElements - All elements in the diagram
 * @param {string} prefixId - Optional ID prefix for preview/export modes
 * @returns {Object|null} The connection points {source, target} or null if can't calculate
 */
export const calculateConnectionPoints = (
  lineData,
  allElements,
  prefixId = ''
) => {
  // Input validation
  if (
    !lineData ||
    !lineData.sourceId ||
    !lineData.targetId ||
    !Array.isArray(allElements)
  ) {
    return null;
  }

  // Find source and target elements
  const source = allElements.find((el) => el.key === lineData.sourceId);
  const target = allElements.find((el) => el.key === lineData.targetId);

  if (!source || !target) {
    console.warn(`Couldn't find source or target elements for connector`, {
      sourceId: lineData.sourceId,
      targetId: lineData.targetId,
      elements: allElements.map((el) => el.key),
    });
    return null;
  }

  try {
    // Calculate the center points of each element
    const sourceCenter = calculateElementCenter(source, prefixId, allElements);
    const targetCenter = calculateElementCenter(target, prefixId, allElements);

    // Calculate the edge connection points
    const sourcePoint = calculateEdgeConnectionPoint(
      source,
      targetCenter,
      prefixId
    );
    const targetPoint = calculateEdgeConnectionPoint(
      target,
      sourceCenter,
      prefixId
    );

    return {
      source: sourcePoint,
      target: targetPoint,
    };
  } catch (error) {
    console.error('Error calculating connection points:', error);
    return null;
  }
};
