import { useDispatch, useSelector } from 'react-redux';
import {
  setCurrentAction,
  setSelectedElement,
  setLineDrawingType,
  setLineThickness,
  setColor,
  toggleSnapToGrid,
  setPanPosition,
  setZoomLevel,
  duplicateElement,
  deleteElement,
  updateElement,
  changeElementLayout,
  createTextElement,
  createLineElement,
  createConnectorElement,
  handleElementClick,
  handleBackgroundClick,
  deleteSelected,
  setElements,
  setAttrName,
  setElementParent,
  breakElementParent,
} from './actions';
import { svgDiagramSelector, selectedElementSelector } from './selectors';

/**
 * Hook for accessing current diagram state
 * @returns {Object} Current diagram state
 */
export const useDiagramState = () => {
  return useSelector(svgDiagramSelector);
};

/**
 * Hook for accessing the selected element
 * @returns {Object|null} Selected element or null if none selected
 */
export const useSelectedElement = () => {
  return useSelector(selectedElementSelector);
};

/**
 * Hook for diagram view control (pan, zoom, grid)
 * @returns {Object} Functions to control diagram view
 */
export const useDiagramView = () => {
  const dispatch = useDispatch();
  const { zoomLevel, panPosition, isGridVisible, gridSize } =
    useSelector(svgDiagramSelector);

  return {
    zoomLevel,
    panPosition,
    isGridVisible,
    gridSize,

    // View control functions
    setAttrName: (name) => dispatch(setAttrName(name)),
    setZoom: (level) => dispatch(setZoomLevel(level)),
    setPan: (position) => dispatch(setPanPosition(position)),
    resetView: () => {
      dispatch(setZoomLevel(1));
      dispatch(setPanPosition({ x: 0, y: 0 }));
    },
    toggleGrid: (enabled, size) => dispatch(toggleSnapToGrid(enabled, size)),

    // Auto-fit function (would need to be implemented with diagram content awareness)
    autoFit: () => {
      // Implementation would calculate bounds of all elements and set appropriate zoom and pan
      console.log('Auto fit would be implemented here');
    },
  };
};

/**
 * Hook for drawing tool control
 * @returns {Object} Current tool state and control functions
 */
export const useDrawingTools = () => {
  const dispatch = useDispatch();
  const {
    currentAction,
    lineDrawingType,
    lineThickness,
    color,
    connectorMode,
  } = useSelector(svgDiagramSelector);

  return {
    // Current state
    currentTool: currentAction,
    lineType: lineDrawingType,
    thickness: lineThickness,
    color,
    connectorMode,

    // Tool selection functions
    selectTool: (tool) => dispatch(setCurrentAction(tool)),
    selectMouseTool: () => dispatch(setCurrentAction('mouse')),
    selectMoveTool: () => dispatch(setCurrentAction('move')),
    selectDeleteTool: () => dispatch(setCurrentAction('delete')),

    // Line drawing functions
    selectLineDrawingTool: (lineType) => {
      dispatch(setLineDrawingType(lineType));
      // Tool is automatically selected by the action
    },

    // Property settings
    setThickness: (thickness) => dispatch(setLineThickness(thickness)),
    setColor: (color) => dispatch(setColor(color)),
  };
};

/**
 * Hook for element manipulation
 * @returns {Object} Functions for manipulating elements
 */
export const useElementOperations = () => {
  const objId = useSelector((state) => state.objects.objId);
  const dispatch = useDispatch();
  const { elements } = useSelector(svgDiagramSelector);
  const selectedElement = useSelector(selectedElementSelector);

  return {
    // Element selection
    selectedElement,
    elements,
    selectElement: (key) => dispatch(setSelectedElement(key)),
    clearSelection: () => dispatch(setSelectedElement(null)),

    // Element operations
    duplicateSelected: () => {
      if (selectedElement) {
        dispatch(duplicateElement(selectedElement.key));
      }
    },

    deleteElement: (key) => dispatch(deleteElement(key, objId)),
    deleteSelected: () => dispatch(deleteSelected(objId)),
    setElements: (elements) => dispatch(setElements(elements)),
    updateElement: (key, updates) => dispatch(updateElement(key, updates)),
    setElementParent: (childKey, parentKey) =>
      dispatch(setElementParent(childKey, parentKey)),
    breakElementParent: (key) => dispatch(breakElementParent(key)),

    bringForward: (key) =>
      dispatch(
        changeElementLayout(
          key || (selectedElement && selectedElement.key),
          'forward'
        )
      ),
    sendBackward: (key) =>
      dispatch(
        changeElementLayout(
          key || (selectedElement && selectedElement.key),
          'back'
        )
      ),

    // Text operations
    updateTextElement: (key, textProps) => {
      const updates = {
        points: textProps.text,
        color: textProps.color,
        fontWeight: textProps.fontWeight,
        fontSize: textProps.fontSize,
        backgroundColor: textProps.backgroundColor,
        blocSize: textProps.blocSize || 100,
      };
      dispatch(updateElement(key, updates));
    },

    // Position operations
    moveElement: (key, position) => {
      dispatch(
        updateElement(key || (selectedElement && selectedElement.key), position)
      );
    },
  };
};

/**
 * Hook for creation of new elements
 * @returns {Object} Functions for creating elements
 */
export const useElementCreation = () => {
  const dispatch = useDispatch();
  const { currentAction } = useSelector(svgDiagramSelector);
  const { getDrawingCenter } = useViewport();

  return {
    // Text creation
    createText: (textProps, position) => {
      if (!position) {
        position = getDrawingCenter();
      }
      return dispatch(
        createTextElement({
          text: textProps.text,
          color: textProps.color,
          fontWeight: textProps.fontWeight || 400,
          fontSize: textProps.fontSize || 20,
          backgroundColor: textProps.backgroundColor || 'transparent',
          blocSize: textProps.blocSize || 100,
          x: position.x,
          y: position.y,
        })
      );
    },

    // Line creation
    createLine: (lineType, points, options) => {
      return dispatch(createLineElement(lineType, points, options));
    },

    // Connector creation
    createConnector: (sourceId, targetId, options) => {
      return dispatch(createConnectorElement(sourceId, targetId, options));
    },

    // Handle clicks for interactive creation
    handleElementClick: (elementKey) => {
      dispatch(handleElementClick(elementKey));
    },

    handleCanvasClick: (position) => {
      dispatch(handleBackgroundClick(position));
    },
  };
};

/**
 * Hook for connector-specific operations
 * @returns {Object} Functions and state for connector operations
 */
export const useConnectorOperations = () => {
  const dispatch = useDispatch();
  const { connectorMode, currentAction, elements } =
    useSelector(svgDiagramSelector);

  return {
    // Connector state
    isConnectorMode: currentAction === 'drawing-connector',
    connectorMode,

    // Enter connector mode
    startConnectorMode: () => {
      dispatch(setCurrentAction('drawing-connector'));
    },

    // Exit connector mode
    exitConnectorMode: () => {
      dispatch(setCurrentAction('mouse'));
    },

    // Get potential connection targets
    getValidTargets: () => {
      // Return all elements that can be connected (not connectors themselves)
      return elements.filter((el) => el.type !== 'connector');
    },

    // Update connector endpoints
    updateConnectorPoints: (connectorId, sourcePoint, targetPoint) => {
      dispatch(
        updateElement(connectorId, {
          points: [sourcePoint, targetPoint],
        })
      );
    },

    // Update connector curvature
    updateConnectorCurvature: (connectorId, curveAmount) => {
      dispatch(
        updateElement(connectorId, {
          curveAmount,
        })
      );
    },
  };
};

/**
 * Hook for viewport operations, coordinates conversion, and measurements
 * @returns {Object} Functions for viewport operations
 */
export const useViewport = () => {
  const { zoomLevel, panPosition } = useSelector(svgDiagramSelector);

  // Get the SVG pad element
  const getSvgPad = () => document.getElementById('svgPad');

  return {
    // Get the center point of the viewport in SVG coordinates
    getDrawingCenter: () => {
      const svgPad = getSvgPad();
      if (svgPad) {
        const rect = svgPad.getBoundingClientRect();
        const centerX = rect.width / 2;
        const centerY = rect.height / 2;

        // Convert from screen to SVG coordinates
        return { x: centerX, y: centerY };
      }

      // Fallback if SVG pad not found
      return { x: 300, y: 300 };
    },
    // Convert screen coordinates to SVG coordinates
  };
};
