import React, { useState } from 'react';
import Remarkable from 'react-remarkable';
import { useDispatch, useSelector } from 'react-redux';
import {
  ModalOverlay,
  ModalContent,
  Title,
  TextArea,
  SubmitButton,
  CloseButton,
} from './QuestInputCSS';
import ObjectActionCreators from '../../../actions';
import AudioRecorder from '../../AudioRecorder/AudioRecorder';
import { Description } from './QuestConfirmationCSS';

const QuestInput = () => {
  const dispatch = useDispatch();
  const currentInteraction = useSelector(
    (state) => state.interaction.currentInteraction
  );

  // Guard clause: Only render for questInput interactions
  if (!currentInteraction || currentInteraction.type !== 'questInput')
    return null;

  const { question, questionId, description } = currentInteraction.data;
  const response = currentInteraction.response;

  // Handle user text input
  const handleInputChange = (event) => {
    dispatch({
      type: 'VOICE_TO_TEXT_INPUT_CLIENT_CHANGED',
      transcription: event.target.value, // Màj de la transcription manuellement
    });
  };

  // Dispatch the response and close the interaction
  const handleSubmit = () => {
    if (response.trim()) {
      dispatch({
        type: 'INTERACTION_RESPONSE',
        payload: {
          questionId,
          response,
        },
      });
      dispatch({ type: 'INTERACTION_CLOSE' });
    } else {
      alert('Please enter a response before submitting.');
    }
  };

  const handleClose = () => {
    dispatch({ type: 'INTERACTION_CLOSE' });
  };

  return (
    <ModalOverlay>
      <ModalContent>
        <Title>{question}</Title>
        <Description>
          <Remarkable source={description ?? ''} />
        </Description>
        <TextArea
          value={response}
          onChange={handleInputChange}
          placeholder="Type your response here..."
        />
        <AudioRecorder
          onEndRecording={(blob) =>
            dispatch(ObjectActionCreators.voiceToTextInput(blob))
          }
        />
        <SubmitButton onClick={handleSubmit}>Submit</SubmitButton>
        <CloseButton onClick={handleClose}>Close</CloseButton>
      </ModalContent>
    </ModalOverlay>
  );
};

export default QuestInput;
