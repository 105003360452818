import { Group, Text, Transition } from '@mantine/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { uniqueId } from 'lodash';
import { useTranslation } from 'react-i18next';
import { DeleteIcon, DOCXIcon, XlSXIcon } from '../../../constants/icon';
import {
  isShowSelectNodeViewSelector,
  nodesSelectSelector,
} from '../../../reducers/layoutReducer/layoutSelector';
import { currentPaletteActiveSelector } from '../../../reducers/themeReducers/themeSelector';
import ButtonIcon from '../../Button/ButtonIcon';
import {
  SelectedListContainer,
  SelectedListContent,
  WrapButtonExport,
  WrapIconExport,
  WrapSelectNodes,
} from './NavigTreeCSS';
import ObjectActionCreators from '../../../actions';
import { onPushNotification } from '../../../reducers/notificationReducer/action';
import ObjectApi from '../../../api/ObjectApi';
import { refreshTreeByPattern } from '../../../reducers/objectReducers/action';

export default function SelectedList() {
  const currentPaletteActive = useSelector(currentPaletteActiveSelector);
  const nodesSelect = useSelector(nodesSelectSelector);
  const isShowSelectNodeView = useSelector(isShowSelectNodeViewSelector);
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const onExportXLSX = () => {
    const notificationId = uniqueId();

    dispatch(ObjectActionCreators.export(nodesSelect, 'xlsx', notificationId));
    dispatch(
      onPushNotification({
        title: t('app.export_file'),
        description: t('app.in_progressing'),
        disallowClose: true,
        notificationId,
      })
    );
  };
  const onExportDOCX = () => {
    const notificationId = uniqueId();
    dispatch(ObjectActionCreators.export(nodesSelect, 'word', notificationId));
    dispatch(
      onPushNotification({
        title: t('app.export_file'),
        description: t('app.in_progressing'),
        disallowClose: true,
        notificationId,
      })
    );
  };

  const onDeleteNodes = async () => {
    try {
      let promiseDeleteNodes = [];
      nodesSelect?.map(async (node) => {
        await promiseDeleteNodes.push(
          new Promise((resolve, reject) => {
            ObjectApi.deleteObject(node)
              .then((data) => resolve(data))
              .catch((err) => reject(err));
          })
        );
      });
      await Promise.all(promiseDeleteNodes);
      dispatch(refreshTreeByPattern());
    } catch (error) {}
  };

  return (
    <SelectedListContainer>
      <SelectedListContent>
        <Transition
          mounted={isShowSelectNodeView}
          transition="pop"
          duration={200}
        >
          {(styles) => (
            <div style={styles}>
              <WrapSelectNodes currentPaletteActive={currentPaletteActive}>
                <Group direction="row">
                  <Text
                    style={{
                      color: currentPaletteActive.text.secondary,
                    }}
                  >
                    {`${t('app.objects_selected')} [${nodesSelect?.length}]`}
                  </Text>
                  <Group>
                    <WrapButtonExport width={'100px'}>
                      <ButtonIcon
                        onClick={onDeleteNodes}
                        disabled={nodesSelect?.length <= 0}
                        leftIcon={
                          <WrapIconExport>
                            <DeleteIcon />
                          </WrapIconExport>
                        }
                        label={t('object.delete')}
                      />
                    </WrapButtonExport>
                    <WrapButtonExport>
                      <ButtonIcon
                        disabled={nodesSelect?.length <= 0}
                        onClick={onExportXLSX}
                        leftIcon={
                          <WrapIconExport>
                            <XlSXIcon />
                          </WrapIconExport>
                        }
                        label={t('app.export_excel')}
                      />
                    </WrapButtonExport>
                    <WrapButtonExport>
                      <ButtonIcon
                        disabled={nodesSelect?.length <= 0}
                        onClick={onExportDOCX}
                        leftIcon={
                          <WrapIconExport>
                            <DOCXIcon />
                          </WrapIconExport>
                        }
                        label={t('app.export_word')}
                      />
                    </WrapButtonExport>
                  </Group>
                </Group>
              </WrapSelectNodes>
            </div>
          )}
        </Transition>
      </SelectedListContent>
    </SelectedListContainer>
  );
}
