import React, { useState } from 'react';
import Remarkable from 'react-remarkable';
import { useDispatch, useSelector } from 'react-redux';
import { Description } from './QuestConfirmationCSS';
import {
  ModalOverlay,
  ModalContent,
  Title,
  OptionsList,
  OptionItem,
  OptionButton,
  CloseButton,
} from './QuestSingleChoiceCSS';
import { TextArea } from './QuestInputCSS'; // Import TextArea from QuestInputCSS
import AudioRecorder from '../../AudioRecorder/AudioRecorder';
import ObjectActionCreators from '../../../actions';

const QuestSingleChoice = () => {
  const dispatch = useDispatch();
  const currentInteraction = useSelector(
    (state) => state.interaction.currentInteraction
  );

  // State for alternative input mode
  const [showAlternative, setShowAlternative] = useState(false);

  if (!currentInteraction || currentInteraction.type !== 'questSingleChoice')
    return null;

  const { question, options, questionId, description } =
    currentInteraction.data;

  // Get response from state for text input
  const response = currentInteraction.response || '';

  // Handle user text input for alternative response
  const handleInputChange = (event) => {
    dispatch({
      type: 'VOICE_TO_TEXT_INPUT_CLIENT_CHANGED',
      transcription: event.target.value,
    });
  };

  // Toggle between single choice and alternative input
  const toggleAlternativeMode = () => {
    if (showAlternative) {
      // When switching back to single choice, clear the text input
      dispatch({
        type: 'VOICE_TO_TEXT_INPUT_CLIENT_CHANGED',
        transcription: '',
      });
    }
    setShowAlternative(!showAlternative);
  };

  const handleOptionClick = (response) => {
    dispatch({
      type: 'INTERACTION_RESPONSE',
      payload: {
        questionId,
        response,
        isAlternative: false,
      },
    });
    // Close the interaction modal
    dispatch({ type: 'INTERACTION_CLOSE' });
  };

  // Submit alternative response
  const handleAlternativeSubmit = () => {
    if (response.trim()) {
      dispatch({
        type: 'INTERACTION_RESPONSE',
        payload: {
          questionId,
          response,
          isAlternative: true,
        },
      });
      dispatch({ type: 'INTERACTION_CLOSE' });
    } else {
      alert('Please enter a response before submitting.');
    }
  };

  const handleClose = () => {
    dispatch({ type: 'INTERACTION_CLOSE' });
  };

  return (
    <ModalOverlay>
      <ModalContent>
        <Title>{question}</Title>
        <Description>
          <Remarkable source={description ?? ''} />
        </Description>

        {/* Toggle button for alternative mode */}
        <div style={{ marginBottom: '15px', textAlign: 'right' }}>
          <button
            onClick={toggleAlternativeMode}
            style={{
              padding: '5px 10px',
              background: '#f0f0f0',
              border: '1px solid #ccc',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
          >
            {showAlternative
              ? 'Switch to Single Choice'
              : 'Provide Alternative Answer'}
          </button>
        </div>

        {!showAlternative ? (
          /* Single choice options */
          <OptionsList>
            {options.map((option, index) => (
              <OptionItem key={index}>
                <OptionButton onClick={() => handleOptionClick(option)}>
                  {option}
                </OptionButton>
              </OptionItem>
            ))}
          </OptionsList>
        ) : (
          /* Alternative input with audio recorder */
          <div>
            <TextArea
              value={response || 'create a view on action and actionDomains and filter by priority = medium and effort in hours > 2, not bug'}
              onChange={handleInputChange}
              placeholder="Type your alternative response here..."
            />
            <AudioRecorder
              onEndRecording={(blob) =>
                dispatch(ObjectActionCreators.voiceToTextInput(blob))
              }
            />
            {/* Submit button for alternative response */}
            <div style={{ marginTop: '15px', textAlign: 'center' }}>
              <button
                onClick={handleAlternativeSubmit}
                disabled={!response.trim()}
                style={{
                  padding: '8px 16px',
                  background: response.trim() ? '#4a90e2' : '#cccccc',
                  color: 'white',
                  border: 'none',
                  borderRadius: '4px',
                  cursor: response.trim() ? 'pointer' : 'not-allowed',
                  fontWeight: 'bold',
                  marginRight: '10px',
                }}
              >
                Submit
              </button>
            </div>
          </div>
        )}

        <CloseButton onClick={handleClose}>Close</CloseButton>
      </ModalContent>
    </ModalOverlay>
  );
};

export default QuestSingleChoice;
