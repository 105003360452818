import styled from 'styled-components';

export const APortalRestrictContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  minWidth: '100px',
});

export const APortalRestrictContent = styled.div({
  marginLeft: 'calc(50% - 45px)',
  display: 'flex',
  flexDirection: 'row',
});

export const APortalRestrictList = styled.div({
  border: '0px solid red',
});

export const SVGStyledAPortalRestrict = styled.svg({
  backgroundColor: 'black',
  borderRadius: '5px',
  cursor: 'pointer',
  height: '22px',
  margin: '4px',
  padding: '5px',
  width: '22px',

  '&:hover *': {
    opacity: '1',
  },
  '& polygon': {
    opacity: '0.8',
    fill: 'white',
    stroke: 'purple',
    strokeWidth: 0,
  },
});
