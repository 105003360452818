// scaleUtils.js
import { scaleBand, scaleTime, scaleOrdinal } from 'd3';
import * as d3_chroma from 'd3-scale-chromatic';
import {
  getTimeRangeFromValues,
  getDateTicksModeFromOptions,
  getObjectDefTrg,
} from '../../Utils/Utils';

/**
 * Get the color for an employee based on their profile
 * @param {string} objId - Employee ID
 * @param {Object} profiles - Profile data
 * @returns {string} Color code
 */
export const getEmployeeColor = (objId, profiles) => {
  if (profiles && profiles[objId]) {
    return profiles[objId].profilecolor;
  }
  return '#CCCCCC';
};

/**
 * Initialize scale bands for time data
 * @param {Array} bandValues - Values to create scale for
 * @param {Object} fieldDef - Field definition
 * @param {number} timelineShift - Timeline shift value
 * @returns {Object} Scale objects for band and color
 */
export const initializeTimeScales = (bandValues, fieldDef, timelineShift) => {
  const width = 100;
  const bandValuesNoRedund = [...new Set(bandValues)];

  const timeRange = getTimeRangeFromValues(
    bandValuesNoRedund,
    getDateTicksModeFromOptions(fieldDef.options),
    timelineShift
  );

  const bandScale = scaleTime()
    .domain(timeRange)
    .nice()
    .range([0, width])
    .clamp(false);

  const colorScale = scaleTime()
    .domain(bandValuesNoRedund)
    .nice()
    .range(['#777777', '#777777']);

  return { bandScale, colorScale };
};

/**
 * Initialize scale bands for categorical data
 * @param {Array} bandValues - Values to create scale for
 * @param {Object} fieldDef - Field definition
 * @param {Object} profiles - Profile data for employee colors
 * @returns {Object} Scale objects for band and color
 */
export const initializeCategoricalScales = (bandValues, fieldDef, profiles) => {
  const width = 100;
  const bandValuesNoRedund = [...new Set(bandValues)];

  const bandScale = scaleBand()
    .domain(bandValuesNoRedund)
    .rangeRound([0, width], 0.1);

  let colorScale;
  if (fieldDef && fieldDef.type?.includes('emplo')) {
    colorScale = scaleOrdinal()
      .domain(bandValuesNoRedund)
      .range(
        bandValuesNoRedund.map((value) => getEmployeeColor(value, profiles))
      );
  } else {
    colorScale = scaleOrdinal()
      .domain(bandValuesNoRedund)
      .range(['#1971C2', ...d3_chroma.schemeDark2]);
  }

  return { bandScale, colorScale };
};

/**
 * Initialize all scale bands for the tree
 * @param {Array} bandValues - Array of band values for each level
 * @param {Array} arrayClassifBy - Classification fields
 * @param {Array} objectsDefList - Object definitions
 * @param {Object} timelineShift - Timeline shift values
 * @param {Object} profiles - Profile data
 * @returns {Object} Scale objects for bands and colors
 */
export const initializeAllScales = (
  bandValues,
  arrayClassifBy,
  objectsDefList,
  timelineShift,
  profiles,
  objectTypes
) => {
  const bandScales = new Array(5);  // Initialise avec une taille fixe
  const colorScales = new Array(5);
  console.log(bandValues);
  for (let indBand = 0; indBand < 5; indBand++) {
    const currentBandValues = bandValues[indBand];
    if (!currentBandValues) continue;

    const objectType = objectTypes[indBand];
    const fieldName = arrayClassifBy[indBand];
    console.log(`in scale objectType${objectType}`);
    console.log(`in scale fieldName${fieldName}`);

    const fieldDef = getObjectDefTrg(objectsDefList, objectType, fieldName);

    // if (!fieldDef) continue;

    let scales;
    if (fieldDef && fieldDef.type === 'date') {
      scales = initializeTimeScales(
        currentBandValues,
        fieldDef,
        timelineShift[indBand]
      );
    } else {
      scales = initializeCategoricalScales(
        currentBandValues,
        fieldDef,
        profiles
      );
    }

    bandScales[indBand] = scales.bandScale;
    colorScales[indBand] = scales.colorScale;
  }

  return { bandScales, colorScales };
};

/**
 * Update scale ranges based on band configuration
 * @param {Array} scales - Array of scale objects
 * @param {Array} objectTypes - Array of object types
 * @param {Array} arrayClassifBy - Classification fields
 * @param {Array} objectsDefList - Object definitions
 * @returns {Array} Updated scales
 */
export const updateScaleRanges = (
  scales,
  objectTypes,
  arrayClassifBy,
  objectsDefList
) => {
  const width = 100;

  return scales.map((scale, indBand) => {
    if (!scale) return scale;

    const objectType = objectTypes[indBand];
    const fieldName = arrayClassifBy[indBand];
    const fieldDef = getObjectDefTrg(objectsDefList, objectType, fieldName);

    if (!fieldDef) return scale;

    if (fieldDef.type === 'date') {
      return scale.range([0, width]).clamp(false);
    }

    return scale.rangeRound([0, width], 0.1);
  });
};
