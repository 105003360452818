// 'use strict';
import React from 'react';
import { ActionIcon, Group } from '@mantine/core';
import { Download, PlayerPlay, X } from 'tabler-icons-react';
import {
  ObjFieldCamRecContainer,
  ObjFieldCamRecFile,
  ObjFieldCamRecMini,
  ObjFieldCamRecMiniContent,
  ObjFieldCamRecMiniInput,
  ObjFieldCamRecVideoContainer,
  ObjFieldCamRecVideoContent,
} from './ObjFieldCamRecCSS';
import { getHost } from '../../../../helper/getHost';

class ObjFieldCamRec extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoaded: 0,
      status: 'stopped',
    };
    this.data = [];
    this.handleData = this.handleData.bind(this);
    this.handleStream = this.handleStream.bind(this);
    this.handleClickStop = this.handleClickStop.bind(this);
    this.handleStopRecording = this.handleStopRecording.bind(this);
    this.handleStartRecording = this.handleStartRecording.bind(this);
    this.handlePrepareRecording = this.handlePrepareRecording.bind(this);
    this.handleClickPlay = this.handleClickPlay.bind(this);
    this.prepareRecording = this.prepareRecording.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.mediaRecorder = null;
    this.player = null;
    this.onClickDownloadFile = this.onClickDownloadFile.bind(this);
  }

  handleData(e) {
    if (e.data.size > 0) {
      this.data.push(e.data);
      this.setState({ dataLoaded: this.data.length });
      this.props.handleChangeInputCamRec(
        null,
        new Blob(this.data),
        this.props.label
      );
    }
  }

  handleClickPlay(e) {
    this.player = document.getElementById('player');
    this.player.play();
    this.setState({ status: 'playing' });
  }

  handleStopRecording(e) {
    this.mediaRecorder.stop();
    this.setState({ status: 'stopped' });
  }

  handleStream(stream) {
    window.stream = stream;
    const options = { mimeType: 'video/webm' };
    this.mediaRecorder = new MediaRecorder(window.stream, options);
    this.player.srcObject = window.stream;
    this.mediaRecorder.addEventListener('dataavailable', this.handleData);
    this.mediaRecorder.addEventListener('stop', this.handleStopRecording);
  }

  handleStartRecording(e) {
    this.mediaRecorder.start();
    this.setState({ status: 'recording' });
  }

  handleClickStop(e) {
    this.player.pause();
    if (this.mediaRecorder !== null) this.mediaRecorder.stop();

    this.setState({ status: 'stopped' });
  }

  prepareRecording() {
    this.player = document.getElementById('player');
    navigator.mediaDevices
      .getUserMedia({
        video: true,
      })
      .then(this.handleStream);
    this.player.play();
  }

  handlePrepareRecording() {
    this.setState({ status: 'prepare_recording' }, this.prepareRecording);
  }

  handleRemove() {
    this.props.handleChangeInputFile(null, null, this.props.label);
  }

  onClickDownloadFile() {
    window.location = `https://${getHost()}/guerillaPM3/DownloadFile/download/${
      this.props.value
    }/${this.props.fileOrigName}`;
  }

  render() {
    const isFilePresent =
      this.props.fileOrigName !== null &&
      this.props.fileOrigName !== 'NULL' &&
      this.props.fileOrigName !== 'null';
    const isPrepareRecording = this.state.status === 'prepare_recording';
    const isRecording = this.state.status === 'recording';
    const isVideoVisible = isFilePresent || isRecording || isPrepareRecording;
    const isPlaying = this.state.status === 'playing';

    return (
      <ObjFieldCamRecContainer>
        {isVideoVisible && (
          <ObjFieldCamRecVideoContainer>
            <ObjFieldCamRecVideoContent>
              <video
                id="player"
                width="100%"
                height="auto"
                controls={isFilePresent}
                muted={!isFilePresent}
              >
                {isFilePresent && (
                  <source
                    src={`https://${getHost()}/guerillaPM3/DownloadFile/download/${
                      this.props.value
                    }/${this.props.fileOrigName}`}
                    type="video/webm"
                  />
                )}
              </video>
            </ObjFieldCamRecVideoContent>
          </ObjFieldCamRecVideoContainer>
        )}
        {!this.props.mini && (
          <ObjFieldCamRecMini>
            <ObjFieldCamRecMiniContent>
              {isFilePresent && (
                <Group>
                  {!isPlaying && (
                    <ActionIcon onClick={this.handleClickPlay}>
                      <PlayerPlay />
                    </ActionIcon>
                  )}

                  <ActionIcon onClick={this.handleRemove} aria-label="delete">
                    <X />
                  </ActionIcon>
                  <ActionIcon onClick={this.onClickDownloadFile}>
                    <Download />
                  </ActionIcon>
                </Group>
              )}
              {!isFilePresent && !isPrepareRecording && !isRecording && (
                <ActionIcon onClick={this.handlePrepareRecording}>R</ActionIcon>
              )}

              <ObjFieldCamRecMiniInput
                display={this.props.fileDragged ? 'none' : ''}
                key={`fld${this.props.keyParam}${this.props.attrName}`}
                name={this.props.keyParam + this.props.attrName}
                onChange={this.props.handleChangeInputFile}
                type="file"
                id={`fld${this.props.keyParam}${this.props.attrName}`}
                title={this.props.attrName}
                value={this.props.defaultValue}
              />
              <Group>
                {isPrepareRecording && (
                  <ActionIcon onClick={this.handleStartRecording}>R</ActionIcon>
                )}
                {(isRecording || isPlaying) && (
                  <ActionIcon onClick={this.handleClickStop}>S</ActionIcon>
                )}
              </Group>
            </ObjFieldCamRecMiniContent>
            {isFilePresent && (
              <ObjFieldCamRecFile>
                {' '}
                {this.props.fileOrigName}
              </ObjFieldCamRecFile>
            )}
          </ObjFieldCamRecMini>
        )}
      </ObjFieldCamRecContainer>
    );
  }
}

export default ObjFieldCamRec;
