import styled from 'styled-components';

export const ObjGraphsRadarContainer = styled.div({
  border: '0px solid red',
  height: (props) => props.height,
  margin: '0px',
  overflow: 'hidden',
  padding: '0px',
  transform: (props) => props.transform,
});
