import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { useSelector } from 'react-redux';
import { svgDiagramSelector } from '../../../../reducers/svgDiagramReducer/selectors';
import { useDiagramView } from '../../../../reducers/svgDiagramReducer/hooks';

export default function GridsDrawing() {
  const svgRef = useRef();
  const { zoomLevel, panPosition } = useSelector(svgDiagramSelector);
  const { gridSize } = useDiagramView();

  const [svgSize, setSvgSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const svgPad = document.getElementById('svgPad');

    if (!svgPad) return;

    // Function to update the size state
    const updateSize = () => {
      const rect = svgPad.getBoundingClientRect();
      setSvgSize({ width: rect.width, height: rect.height });
    };

    // Initialize ResizeObserver
    const resizeObserver = new ResizeObserver(updateSize);
    resizeObserver.observe(svgPad);

    // Cleanup observer on unmount
    return () => resizeObserver.disconnect();
  }, []);

  useEffect(() => {
    const svg = d3.select(svgRef.current);
    svg.selectAll('*').remove(); // Clear existing grid

    // Extended grid size to handle large panning
    const totalWidth = svgSize.width * 10;
    const totalHeight = svgSize.height * 10;

    // Apply pan adjustments
    const panX = panPosition.x;
    const panY = panPosition.y;

    // Compute the transformation origin (center-based scaling)
    const originX = svgSize.width / 2;
    const originY = svgSize.height / 2;

    // Define grid pattern
    const defs = svg.append('defs');
    const pattern = defs
      .append('pattern')
      .attr('id', 'gridPattern')
      .attr('width', gridSize) // Scale grid with zoom
      .attr('height', gridSize)
      .attr('patternUnits', 'userSpaceOnUse');

    // Define grid cell
    pattern
      .append('rect')
      .attr('width', gridSize)
      .attr('height', gridSize)
      .attr('fill', 'none')
      .attr('stroke', '#e0e0e0')
      .attr('stroke-width', 1 / zoomLevel); // Maintain line thickness

    // Create a group to apply transformations
    // Apply transformations correctly
    const gridGroup = svg
      .append('g')
      .attr(
        'transform',
        `translate(${originX}, ${originY}) scale(${zoomLevel}) translate(${
          -originX + panX / zoomLevel
        }, ${-originY + panY / zoomLevel})`
      );
    // Background rectangle using the grid pattern
    gridGroup
      .append('rect')
      .attr('x', -totalWidth / 2)
      .attr('y', -totalHeight / 2)
      .attr('width', totalWidth)
      .attr('height', totalHeight)
      .attr('fill', 'url(#gridPattern)')
      .style('pointer-events', 'none');
    svg.selectAll('*').classed('snap-grid-element', true);
  }, [gridSize, svgSize, zoomLevel, panPosition]);

  return (
    <svg
      ref={svgRef}
      style={{
        touchAction: 'none',
        pointerEvents: 'none',
        width: '100%',
        height: '100%',
        overflow: 'visible',
      }}
    ></svg>
  );
}
