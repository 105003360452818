import React from 'react';
import { ContainerDiv, BigNum } from './BigNumberCSS';

class BigNumber extends React.Component {
  render() {
    return (
      <ContainerDiv>
        <BigNum color={this.props.nodeColor}>
          {this.props.value}
          {this.props.unit}
        </BigNum>
      </ContainerDiv>
    );
  }
}

export default BigNumber;
