import { Modal, useMantineTheme } from '@mantine/core';
import { toPng } from 'html-to-image';
import React, { useEffect, useRef } from 'react';
import { filterElementToImageIgnore } from '../../../../utils/filterElementIgnore';
import { heightScreen } from '../../../../utils/windowSize';
import { modalStyle } from '../../../../constants/commonStyles';
import SVGDiagramRenderer from '../../../SVGDiagramRenderer';
import { useElementOperations } from '../../../../reducers/svgDiagramReducer/hooks';

export default function SvgPreviewDownload({
  exportMode,
  isPreViewMode,
  toggleExportMode,
  togglePreviewMode,
  onClickElement,
}) {
  const ref = useRef(null);
  const { elements } = useElementOperations();

  const onGetImage = async () => {
    try {
      toPng(ref.current, {
        cacheBust: true,
        filter: filterElementToImageIgnore,
        backgroundColor: 'white',
        quality: 1,
      })
        .then((dataUrl) => {
          const link = document.createElement('a');
          link.download = `Sketch_${(Math.random() + 1)
            .toString(36)
            .substring(7)}`;
          link.href = dataUrl;
          link.click();
          setTimeout(() => {
            toggleExportMode();
          }, 350);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      toggleExportMode();
    }
  };

  useEffect(() => {
    if (exportMode) {
      setTimeout(() => {
        onGetImage();
      }, 250);
    }
  }, [exportMode]);

  const heightContainer = isPreViewMode ? heightScreen - 200 : 700;
  let scale = isPreViewMode ? heightContainer / 700 : 1;
  if (scale < 1) scale = 1;
  const theme = useMantineTheme();

  const svgWidth = 800;
  const svgHeight = 640;

  const handleOnClickElement = (objId) => {
    if (isPreViewMode) {
      togglePreviewMode();
      onClickElement(objId);
    }
  };

  return (
    <Modal
      centered={true}
      opened={isPreViewMode || exportMode}
      onClose={isPreViewMode ? togglePreviewMode : toggleExportMode}
      title={isPreViewMode ? 'Preview sketch' : 'Download sketch'}
      styles={{
        modal: {
          width: 'auto',
          backgroundColor: modalStyle.modal.backgroundColor,
          padding: `${Math.floor(20 / scale)}px !important`,
        },
        root: {
          transform: `scale(${isPreViewMode ? scale : 1})`,
        },
        title: modalStyle.title,
        header: {
          marginBottom: Math.floor(16 / scale),
        },
        inner: {
          padding: 0,
        },
      }}
    >
      <div
        draggable={false}
        ref={ref}
        id="wrapSvgPadPreview"
        style={{
          width: svgWidth,
          height: svgHeight,
          backgroundColor: 'white',
          border: '0.5px solid #94A3B8',
          borderRadius: 4,
          overflow: 'hidden',
        }}
      >
        <svg
          draggable={false}
          width={svgWidth}
          height={svgHeight}
          viewBox={`0 0 ${svgWidth} ${svgHeight}`}
        >
          {/* Replace individual renderers with the unified component */}
          <SVGDiagramRenderer
            mode={isPreViewMode ? 'preview' : 'export'}
            onElementClick={handleOnClickElement}
          />
        </svg>
      </div>
    </Modal>
  );
}
