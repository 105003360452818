import styled from 'styled-components';
import { devices } from '../../../constants/devices';
import { isTablet } from '../../../utils/detectDevices';

export const NavigNodeColorBar = styled.div.attrs((props) => ({
  style: {
    backgroundColor: props.nodeColor,
  },
}))({
  height: 'calc(100% - 10px)',
  borderRadius: '2px',
  left: '5px',
  position: 'absolute',
  top: '5px',
  width: '3px',
});
//------------------------------------------------------------------------------

const getWidthNavigNodeContainerStyle = (props) => {
  if (props.dragging && !isTablet) return '10px';
  return props.zoom < 70 ? `${props.width * 2}px` : `${props.width}px`;
};

const getBoxShadowNavigNodeContainerStyle = (props) => {
  if (props.zoom < 70) return '0px 0px 0px';
  return props.isNodeSelected
    ? '2px 2px 3px 0px rgba(0,0,0,0.10)'
    : '1px 1px 1px 0px rgba(0,0,0,0.05)';
};

export const NavigNodeContainerStyle = styled.div.attrs((props) => ({
  style: {
    borderRadius: props.isNodeOnActivePanel ? '7px' : '50%',
    left: `${props.isNodeOnActivePanel ? props.left : 20}px`,
    top: `${props.top}px`,
    padding: '4px 0px 2px 10px',
  },
}))({
  border: (props) => (props.isHeightBorder ? 'none' : '1px solid #99999944'),
  display: (props) => (props.dragging && !isTablet ? 'none' : ''),

  opacity: (props) => (props.left < -20 ? '0' : '1'),
  pointerEvents: (props) => (props.left < -20 ? 'none' : 'auto'),
  overflow: (props) => (props.dragging ? 'hidden' : 'visible'),
  transition: isTablet
    ? 'none'
    : 'left 0.2s, top 0.5s, opacity 3.0s, height 0.9s, width 0.9s, transform 0.3s',
  width: (props) => getWidthNavigNodeContainerStyle(props),
  zIndex: 5001,
  backgroundColor: 'var(--ds-bg-base)',
  boxShadow: (props) => getBoxShadowNavigNodeContainerStyle(props),
  touchAction: (props) => (props.isHaveChildNode ? 'pax-x' : 'pan-left pan-y'),
  // transition: isTablet && 'none',
  [devices.mobileL]: {
    zIndex: 100000000,
    maxWidth: (props) => `${props.width}px`,
  },
});
//------------------------------------------------------------------------------
export const DivStickersStyle = {
  position: 'absolute',
  left: '5px',
  top: '0px',
};
//------------------------------------------------------------------------------
export const DivStickerStyle = {
  border: '1px solid #333',
  borderRadius: '0px 0px 0px 3px',
  display: 'inline-block',
  height: '4px',
  marginRight: '2px',
  width: '16px',
};
export const BorderHightLight = styled.div({
  position: 'absolute',
  width: 'calc(100% + 4px)',
  height: 'calc(100% + 4px)',
  top: -2,
  left: -2,
  borderRadius: 7,
  border: (props) => `4px solid ${props.borderColor || 'red'}`,
});
