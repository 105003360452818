// middleware/abortMiddleware.js

import { OBJECTS_TYPE } from '../reducers/objectReducers/type';

const abortMiddleware = (store) => (next) => (action) => {
  // Vérifie si l'action doit annuler d'autres actions

  abortActions(action.type);

  // Ajoute un signal AbortController aux métadonnées de l'action

  if (action.meta?.shouldAbort) {
    const controller = getAbortController(action.type);
    action.meta.abortSignal = controller.signal; // Ajoute le signal au payload
    console.log(`added signal:${action.meta.abortSignal}`);
    console.dir(action.meta.abortSignal);
  }

  return next(action);
};

export default abortMiddleware;

// abortConfig.js
export const abortConfig = {
  //   [OBJECTS_TYPE.SAVE_OBJECT]: [
  //     OBJECTS_TYPE.SAVE_OBJECT,
  //     OBJECTS_TYPE.REQUEST_TREE_BY_PATTERN,
  //   ],
  //   [OBJECTS_TYPE.DELETE_OBJECT]: [OBJECTS_TYPE.REQUEST_TREE_BY_PATTERN],
  //   [OBJECTS_TYPE.SAVE_OBJECTFORMDATA]: [
  //     OBJECTS_TYPE.SAVE_OBJECTFORMDATA,
  //     OBJECTS_TYPE.REQUEST_TREE_BY_PATTERN,
  //   ],
  [OBJECTS_TYPE.REQUEST_TREE_BY_PATTERN]: [
    OBJECTS_TYPE.REQUEST_TREE_BY_PATTERN,
  ],
  // Autres règles...
};

const abortControllers = new Map();

export const getAbortController = (actionType) => {
  if (!abortControllers.has(actionType)) {
    abortControllers.set(actionType, new AbortController());
  }
  return abortControllers.get(actionType);
};

export const abortActions = (actionType) => {
  const controllersToAbort = abortConfig[actionType] || [];
  controllersToAbort.forEach((type) => {
    const controller = abortControllers.get(type);
    if (controller) {
      console.log('in abortActions');
      controller.abort(); // Annule la requête en cours
      abortControllers.delete(type); // Supprime l'ancien contrôleur
    }
  });
};
