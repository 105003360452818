import React, { useMemo } from 'react';
import SvgRenderer from './SvgRenderer';
import SvgTextRenderer from './SvgTextRenderer';
import SVGPolylineRenderer from './SVGPolylineRenderer';
import SvgArrowLineRenderer from './SvgArrowLineRenderer';
import SmartConnectorRenderer from './SmartConnectorRenderer';
import {
  useDiagramView,
  useElementOperations,
} from '../../reducers/svgDiagramReducer/hooks';

/**
 * A unified component for rendering SVG diagrams in different modes
 * @param {Object} props - Component props
 * @param {string} props.mode - Rendering mode ('edit', 'preview', 'export')
 * @param {function} props.onElementClick - Handler for element clicks
 * @param {Object} props.editorProps - Props for edit mode (action,  etc.)
 */
const SVGDiagramRenderer = ({
  mode = 'preview',
  onElementClick,
  value = [],
  editorProps = {},
  attrName,
}) => {
  let valuePreview = [];
  try {
    valuePreview = JSON.parse(value);
  } catch (error) {
    console.log(error);
  }

  const elementOperations = useElementOperations();
  const { setAttrName } = useDiagramView();

  const elements = useMemo(() => {
    if (!(mode === 'preview')) setAttrName(attrName);
    return mode === 'preview' ? valuePreview : elementOperations.elements;
  }, [mode, valuePreview, elementOperations, attrName]);

  if (!elements || !Array.isArray(elements)) {
    console.warn(
      'SVGDiagramRenderer received invalid elements prop:',
      elements
    );
    return null; // Return null instead of trying to render
  }
  // Determine prefix based on mode
  const prefix = mode === 'edit' ? '' : `${mode}_`;

  // Determine if actions are disabled (true in preview and export modes)
  const isDisableAction = mode !== 'edit';

  return (
    <>
      {elements.map((element) => {
        if (!element || !element.type || !element.key) {
          console.warn('Invalid element in SVGDiagramRenderer:', element);
          return null;
        }

        // Common props for all renderers
        const commonProps = {
          key: element.key,
          prefix_key: prefix,
          isDisableAction,
          onClickElement: onElementClick,
        };

        // Mode-specific props
        if (mode === 'edit') {
          Object.assign(commonProps, {
            idLineDrawing: editorProps.idLineDrawing,
            setActionDragSvg: editorProps.setActionDragSvg,
            setSvgFocus: editorProps.setSvgFocus,
            handleSaveSvgFileToDb: editorProps.handleSaveSvgFileToDb,
            onUpdatePointsOnMove: editorProps.onUpdatePointsOnMove,
          });
        }

        // Render based on element type
        switch (element.type) {
          case 'svg':
          case 'image':
            return <SvgRenderer svg={element} {...commonProps} />;

          case 'text':
            return <SvgTextRenderer svg={element} {...commonProps} />;

          case 'bidirectional-line':
          case 'arrow-line':
            return <SvgArrowLineRenderer lineData={element} {...commonProps} />;

          case 'connector':
            return (
              <SmartConnectorRenderer
                lineData={element}
                allElements={elements}
                onUpdateConnectorData={editorProps.onUpdateConnectorData}
                {...commonProps}
              />
            );

          case 'polyline':
          case 'line':
            return <SVGPolylineRenderer lineData={element} {...commonProps} />;

          default:
            console.warn(`Unknown element type: ${element.type}`);
            return null;
        }
      })}{' '}
    </>
  );
};

export default SVGDiagramRenderer;
