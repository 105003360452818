import { getHost } from '../helper/getHost';
import { onPushAndAutoRemoveNotification } from '../reducers/notificationReducer/action';

// websocketMiddleware.js
export const websocketMiddleware = () => {
  let socket;
  const url = `wss://${getHost()}/guerillaPM3/ws`;

  return ({ dispatch }) =>
    (next) =>
    (action) => {
      switch (action.type) {
        // Action to initiate WebSocket connection
        case 'WEBSOCKET_CONNECT':
          try {
            socket = new WebSocket(url);
          } catch (error) {
            alert(error);
          }

          // Set up WebSocket event handlers
          socket.onopen = () => {
            dispatch({ type: 'WEBSOCKET_OPEN' });
          };

          socket.onmessage = (event) => {
            const message = JSON.parse(event.data);
            if (message.type === 'questSingleChoice') {
              dispatch({
                type: 'INTERACTION_OPEN',
                payload: {
                  interactionType: message.type,
                  interactionData: {
                    questionId: message.payload.questionId,
                    question: message.payload.question,
                    description: message.payload.description,
                    options: message.payload.options,
                  },
                },
              });
            } else if (message.type === 'questMultChoice') {
              dispatch({
                type: 'INTERACTION_OPEN',
                payload: {
                  interactionType: message.type,
                  interactionData: {
                    questionId: message.payload.questionId,
                    question: message.payload.question,
                    description: message.payload.description,
                    options: message.payload.options,
                  },
                },
              });
            } else if (message.type === 'questConfirmation') {
              dispatch({
                type: 'INTERACTION_OPEN',
                payload: {
                  interactionType: message.type,
                  interactionData: {
                    questionId: message.payload.questionId,
                    question: message.payload.question,
                    description: message.payload.description,
                    options: message.payload.options,
                  },
                },
              });

            } else if (message.type === 'questInput') {
              dispatch({
                type: 'INTERACTION_OPEN',
                payload: {
                  interactionType: message.type,
                  interactionData: {
                    questionId: message.payload.questionId,
                    question: message.payload.question,
                    description: message.payload.description,
                    options: message.payload.options,
                  },
                },
              });
            } else if (message.type === 'viewConfiguration') {
              dispatch({
                type: 'VIEW_CONFIGURATION',
                payload: message.payload,
              });
            } else if (message.type === 'viewRestriction') {
              dispatch({
                type: 'VIEW_RESTRICTION',
                payload: message.payload,
              });
            } else {
              dispatch({ type: 'SERVER_MESSAGE', payload: message });

              dispatch(
                onPushAndAutoRemoveNotification({
                  title: `Server message: ${message.type}`,
                  description: message.payload,
                  time: 15000,
                })
              );
            }
          };

          socket.onclose = () => {
            dispatch({ type: 'WEBSOCKET_CLOSE' });
            setTimeout(() => dispatch({ type: 'WEBSOCKET_CONNECT' }), 1000);
          };

          socket.onerror = (error) => {
            dispatch({ type: 'WEBSOCKET_ERROR', payload: error });
          };
          break;

        // Action to send a message via WebSocket
        case 'WEBSOCKET_SEND':
          if (socket) {
            socket.send(JSON.stringify(action.payload));
          }
          break;

        case 'INTERACTION_RESPONSE':
          if (socket && socket.readyState === WebSocket.OPEN) {
            const { questionId, response } = action.payload;

            // Message JSON attendu par le serveur
            const responseMessage = {
              type: 'question_response',
              messageId: questionId, // questionId utilisé comme messageId
              payload: {
                response: response,
              },
            };

            socket.send(JSON.stringify(responseMessage));
          } else {
            console.warn('WebSocket is not connected.');
          }
          break;

        // Pass all other actions through middleware
        default:
          return next(action);
      }
    };
};
