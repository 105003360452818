import React, { useRef, useEffect } from 'react';
import { select, arc } from 'd3';

const MiniChartSVG = ({ val, size = 16 }) => {
  const svgRef = useRef(null);

  useEffect(() => {
    const svg = select(svgRef.current);
    svg.selectAll('*').remove(); // Clear previous content

    const width = size;
    const height = size + 4;
    const radius = (size - 2) / 2;

    // Define arcs
    const baseArc = arc()
      .innerRadius(radius - 10)
      .outerRadius(radius - 5)
      .startAngle(0)
      .endAngle(2 * Math.PI);

    const progressArc = arc()
      .innerRadius(radius - 10)
      .outerRadius(radius - 5)
      .startAngle(0)
      .endAngle(Math.min((val / 100) * 2 * Math.PI, 2 * Math.PI)); // Clamp to full circle

    const overflowArc = arc()
      .innerRadius(radius - 4)
      .outerRadius(radius - 1)
      .startAngle(2 * Math.PI)
      .endAngle(((val - 100) / 100) * 2 * Math.PI + 2 * Math.PI); // Start from end of full circle

    const overflowArc2 = arc()
      .innerRadius(radius)
      .outerRadius(radius + 1)
      .startAngle(2 * Math.PI)
      .endAngle(((val - 100) / 100) * 2 * Math.PI + 2 * Math.PI); // Start from end of full circle

    // Background arc
    svg
      .append('path')
      .attr('d', baseArc())
      .attr('fill', '#0000ff16')
      .attr('transform', `translate(${width / 2}, ${height / 2})`);

    // Foreground arc (progress)
    svg
      .append('path')
      .attr('d', progressArc())
      .attr('fill', val > 100 ? 'red' : 'var(--ds-base)')
      // .attr('fill', 'var(--ds-base)')
      // .attr('stroke', 'white') // Stroke color
      // .attr('stroke-width', 1) // Stroke width
      .attr('transform', `translate(${width / 2}, ${height / 2})`);

    // Overflow arc (only if value > 100)
    if (val > 100) {
      svg
        .append('path')
        .attr('d', overflowArc())
        .attr('fill', 'red')
        .attr('fill-opacity', '0.6')
        // .attr('stroke', 'white') // Stroke color
        // .attr('stroke-width', 2) // Stroke width
        .attr('transform', `translate(${width / 2}, ${height / 2})`);

      // svg
      //   .append('path')
      //   .attr('d', overflowArc2())
      //   .attr('fill', 'red')
      //   .attr('fill-opacity', '1')
      //   .attr('transform', `translate(${width / 2}, ${height / 2})`);
    }
  }, [val, size]);

  return (
    <svg
      ref={svgRef}
      style={{
        border: '0px solid red',
        marginTop: '0px',
        height: `${size}px`,
        width: `${size}px`,
      }}
    ></svg>
  );
};

export default MiniChartSVG;
