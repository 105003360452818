// svgDiagramMiddleware.js
import { throttle } from 'lodash';
import ObjectApi from '../../api/ObjectApi';
import { SVG_DIAGRAM_ACTIONS } from './types';

// Actions qui devraient déclencher une sauvegarde
const SAVE_TRIGGER_ACTIONS = [
  SVG_DIAGRAM_ACTIONS.ADD_ELEMENT,
  SVG_DIAGRAM_ACTIONS.UPDATE_ELEMENT,
  SVG_DIAGRAM_ACTIONS.DELETE_ELEMENT,
  SVG_DIAGRAM_ACTIONS.SET_ELEMENTS,
  SVG_DIAGRAM_ACTIONS.DUPLICATE_ELEMENT,
  SVG_DIAGRAM_ACTIONS.CHANGE_ELEMENT_LAYOUT,
];

// Fonction throttled pour éviter des sauvegardes trop fréquentes
const throttledSave = throttle((objId, data) => {
  console.log('Saving SVG diagram...', objId);
  return ObjectApi.saveObjFormData(objId, data)
    .then(() => console.log('SVG diagram saved successfully'))
    .catch((error) => console.error('Error saving SVG diagram:', error));
}, 5000); // Déclencher au maximum toutes les 2 secondes

export const svgDiagramMiddleware = (store) => (next) => (action) => {
  // Laisser l'action se propager d'abord
  const result = next(action);

  // Vérifier si l'action devrait déclencher une sauvegarde
  if (SAVE_TRIGGER_ACTIONS.includes(action.type)) {
    const state = store.getState();
    const objId = state.objects.objId;
    const svgDiagramData = state.svgDiagram.elements;
    const attrName = state.svgDiagram.attrName;

    // Appeler la fonction throttled
    if (objId && attrName && svgDiagramData && svgDiagramData.length > 0) {
      const objFormData = [
        {
          attrName: attrName,
          value: JSON.stringify(svgDiagramData),
          type: 'drawing',
        },
      ];
      throttledSave(objId, objFormData);
    }
  }

  return result;
};
