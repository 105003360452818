import { NODE_OFFSET_Y } from '../../../constants/layout';

const setPosition = (
  node,
  initialPos,
  scrollTop,
  viewPortHeight,
  mapNodeSize,
  arrayNodes,
  depth,
  parent,
  dynamic = true
) => {
  let pos = initialPos + 25;

  node.ySize = mapNodeSize[node.key] || 10;
  if (node.children) {
    let length = node.children.length;
    for (let i = 0; i < length; i++) {
      const currentNode = node.children[i];
      let current = {};
      pos = setPosition(
        currentNode,
        pos,
        scrollTop,
        viewPortHeight,
        mapNodeSize,
        arrayNodes,
        depth + 1,
        current,
        dynamic
      );
      current.depth = depth + 1;
      current.data = currentNode;
      current.parent = parent;
      currentNode.wrapper = current;
      arrayNodes.push(current);
    }
  }
  let max = Math.max(initialPos + node.ySize + 15, pos);
  node.bndMin = initialPos;
  node.bndMax = max;
  node.y = initialPos + (max - initialPos) / 2 - node.ySize / 2;

  // si on a utilise plus d'espace que nos enfants on peut les recentrer sur nous
  if (max > pos) {
    let shiftChild = (max - pos) / 2;
    if (node.children) {
      let length = node.children.length;
      for (let i = 0; i < length; i++) {
        node.children[i].y += shiftChild;
        node.children[i].wrapper.y += shiftChild;
      }
    }
  } else if (node.children && node.children.length > 0 && dynamic) {
    // Si le nœud a des enfants, essayer de le centrer sur eux s'ils sont dans le view port
    // console.log(
    //   `node to recenter-sct=${scrollTop} viewportHeight:${viewPortHeight}`
    // );
    let nodeInViewPort = node.children.filter(
      (childnode) =>
        childnode.y + childnode.ySize > scrollTop &&
        childnode.y + childnode.ySize < scrollTop + viewPortHeight
    );
    if (nodeInViewPort && nodeInViewPort.length > 0) {
      // console.log('node in viewPort');
      const childrenMin = Math.min(...nodeInViewPort.map((c) => c.y));
      const childrenMax = Math.max(...nodeInViewPort.map((c) => c.y + c.ySize));
      const idealCenter = (childrenMin + childrenMax) / 2;
      const halfWidth = node.ySize / 2;
      const targetY = idealCenter - halfWidth;
      if (
        targetY < node.bndMax - node.ySize &&
        targetY > node.bndMin + node.ySize
      ) {
        node.y = targetY;
      }
    }
  }
  parent.y = node.y;
  parent.ySize = node.ySize;
  // console.log(parent);
  return max;
};

export const updateVerticalNodePositions = (
  rootNode,
  scrollTop,
  mapNodesSize,
  autoVertPos
) => {
  console.log('UPDATE NODE POSITION');
  const arrayNodes = [];
  if (rootNode) {
    // let fullNodes = treeRoot.descendants();
    // let rootElem = fullNodes.find((elem) => elem.data.key === 'root');
    let initialPos = NODE_OFFSET_Y;
    const viewPortHeight = document.documentElement.clientHeight;
    const depth = 0;
    setPosition(
      rootNode,
      initialPos,
      scrollTop,
      viewPortHeight,
      mapNodesSize,
      arrayNodes,
      depth,
      {},
      autoVertPos
    );
  }
  // console.log('arrayModes');
  // console.log(arrayNodes);
  return arrayNodes;
};
