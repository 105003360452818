import styled from 'styled-components';
import { devices } from '../../constants/devices';
import { heightScreen } from '../../utils/windowSize';

const getHeightObjectSelectorContainer = () => {
  return heightScreen > 1000 ? 800 : 600;
};

export const ObjectSelectorContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  height: (props) =>
    props.height
      ? `${props.height}px`
      : `${getHeightObjectSelectorContainer()}px`,
  justifyContent: 'start',
  padding: '4px',
  width: 'auto',
  [devices.tablet]: {
    height: 'auto',
  },
});

export const APortalContentContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  padding: '5px',
});

export const APortalContent = styled.div({
  color: (props) => props.color,
  margin: '10px',
  fontWeight: 'bold',
  // height: '30px',
  fontSize: 14,
});

//------------------------------------------------------------------------------------------------
export const ButtonStyled = styled.button({
  backgroundColor: '#1C7ED6',
  border: '0px',
  borderRadius: '6px',
  color: 'white',
  fontWeight: 'bold',
  margin: '16px',
  padding: '10px 20px',
  verticalAlign: 'bottom',
  ':disabled': {
    color: '#CCC',
    backgroundColor: '#9B9BB344',
  },
});
//------------------------------------------------------------------------------------------------
const getBackgroundColorDivLine = (props) => {
  if (props.selected) return '#D0EBFF';
  return props.suggested ? '#E7F5FF' : '#FFFFFF';
};
export const DivLine = styled.div({
  // backgroundColor: (props) => getBackgroundColorDivLine(props),
  backgroundColor: 'var(--ds-bg-secondary)',
  borderLeft: '2px solid var(--ds-border-base)',
  color: (props) => props.color || '#4A4F58',
  cursor: 'pointer',
  fontSize: '12px',
  lineHeight: '22px',
  margin: '3px',
  padding: '1px',
  paddingLeft: '7px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',

  ':hover': {
    backgroundColor: 'var(--ds-bg-base)',
    borderLeft: '2px solid var(--ds-base)',
  },
  '.tooltip': {
    visibility: 'hidden',
    borderRadius: '8px',
    padding: '8px',
    zIndex: '1',
    boxShadow: '0px 1px 3px 1px #CCCCCC',
    backgroundColor: 'red',
  },
  ':hover .tooltip': {
    backgroundColor: '#87879C',

    visibility: 'visible',
    color: 'white',
  },

  img: {
    opacity: '0.4',
  },
});

//------------------------------------------------------------------------------------------------
export const DivBlocList = styled.div({
  borderRadius: '6px',
  boxShadow: '0px 1px 3px 1px #CCCCCC',
  color: 'black',
  flexGrow: '1',
  margin: '10px',
  width: 'auto',
  h1: {
    color: '#565194',
    fontSize: '22px',
    margin: '10px',
  },
});
//------------------------------------------------------------------------------------------------

export const DivBlocWrap = styled.div({
  borderRadius: '6px',
  color: 'black',
  flexGrow: '1',
  margin: 'auto',
  overflowY: 'auto',
  padding: '6px',
  width: '100%',
  backgroundColor: (props) => props.backgroundColor,
});
//------------------------------------------------------------------------------------------------
export const DivTitleBloc = styled.div({
  // borderBottom: '2px solid #1C7ED6',
  color: (props) => props.color || 'black',
  fontSize: '17px',
  fontWeight: 'bold',
  margin: '0px',
  // marginBottom: '10px',
  padding: '6px',
  width: '99%',
});
export const DivDivider = styled.div({
  borderBottom: '2px solid #1C7ED6',
  marginTop: 12,
  marginBottom: 8,
});

//------------------------------------------------------------------------------------------------
// export const DivSummary = styled.div`
//   width: 100%;
//   height: 100px;
// color: #888;
// font-size: 12px;
//   & h2 {
//     font-size: 12px;
//     color: #777;
//   }
// `;

export const DivSummary = styled.div({
  width: '100%',
  // height: 100,
  color: '#888',
  fontSize: 12,
  [devices.tablet]: {
    height: '100%',
  },
});
