import { produce } from 'immer';
import { v4 as uuidv4 } from 'uuid';
import { SVG_DIAGRAM_ACTIONS } from './types';
import { colorsSvgDrawing } from '../../constants/colors';
import {
  changeIndexElement,
  generateNewPointsItemDuplicate,
} from '../../utils/functionSvgDrawing';

const initialState = {
  // View state
  attrName: null,
  panPosition: {
    x: 0,
    y: 0,
  },
  zoomLevel: 1,
  isGridVisible: false,
  gridSize: 50,
  isDebugMode: false,
  isPanMode: false,

  // Edit state
  currentAction: 'mouse', // mouse, move, drawing-line, drawing-polyline, delete, etc.
  selectedElementKey: null,
  lineDrawingType: 'line', // line, polyline, arrow-line, bidirectional-line, connector
  elements: [], // All SVG elements
  currentLineDrawingId: null, // ID of the line being drawn
  lineThickness: 6,
  color: colorsSvgDrawing[0], // Current color
  isDrawing: false, // User is drawing

  // Connector mode state
  connectorMode: {
    active: false,
    sourceId: null,
    targetId: null,
  },
};

const svgDiagramReducer = (state = initialState, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      // View transformations
      case SVG_DIAGRAM_ACTIONS.SET_ATTR_NAME:
        draft.attrName = action.payload;
        break;
      case SVG_DIAGRAM_ACTIONS.SET_ZOOM_LEVEL:
        draft.zoomLevel = action.payload;
        break;

      case SVG_DIAGRAM_ACTIONS.SET_GRID_VISIBLE:
        draft.isGridVisible = action.payload;
        break;

      case SVG_DIAGRAM_ACTIONS.SET_GRID_SIZE:
        draft.gridSize = action.payload;
        break;

      case SVG_DIAGRAM_ACTIONS.SET_PAN_POSITION:
        draft.panPosition = action.payload;
        break;

      case SVG_DIAGRAM_ACTIONS.SET_PAN_MODE:
        draft.isPanMode = action.payload;
        break;

      case SVG_DIAGRAM_ACTIONS.TOGGLE_DEBUG_MODE:
        draft.isDebugMode = action.payload;
        break;

      // Edit state changes
      case SVG_DIAGRAM_ACTIONS.SET_CURRENT_ACTION:
        draft.currentAction = action.payload;

        // Reset drawing state if changing to non-drawing action
        if (!action.payload.startsWith('drawing-')) {
          draft.isDrawing = false;
        }

        // Reset connector mode if not in connector drawing mode
        if (action.payload !== 'drawing-connector') {
          draft.connectorMode = {
            active: false,
            sourceId: null,
            targetId: null,
          };
        }
        break;

      case SVG_DIAGRAM_ACTIONS.SET_SELECTED_ELEMENT:
        draft.selectedElementKey = action.payload;
        break;
      case SVG_DIAGRAM_ACTIONS.SET_ELEMENT_PARENT: {
        const { childKey, parentKey } = action.payload;

        // Trouver l'index de l'élément enfant
        const childIndex = draft.elements.findIndex(
          (element) => element.key === childKey
        );

        if (childIndex !== -1) {
          // Ajouter la référence au parent
          draft.elements[childIndex].parentKey = parentKey;
        }
        break;
      }

      case SVG_DIAGRAM_ACTIONS.BREAK_ELEMENT_PARENT: {
        const { childKey } = action.payload;

        // Trouver l'index de l'élément enfant
        const childIndex = draft.elements.findIndex(
          (element) => element.key === childKey
        );

        if (childIndex !== -1) {
          // Supprimer la référence au parent
          draft.elements[childIndex].parentKey = null;
        }
        break;
      }
      case SVG_DIAGRAM_ACTIONS.SET_LINE_DRAWING_TYPE:
        draft.lineDrawingType = action.payload;
        break;

      case SVG_DIAGRAM_ACTIONS.SET_ELEMENTS:
        draft.elements = action.payload;
        break;

      case SVG_DIAGRAM_ACTIONS.ADD_ELEMENT:
        draft.elements.push(action.payload);
        break;

      case SVG_DIAGRAM_ACTIONS.UPDATE_ELEMENT: {
        const { key, updates } = action.payload;
        const elementIndex = draft.elements.findIndex(
          (element) => element.key === key
        );
        if (elementIndex !== -1) {
          Object.assign(draft.elements[elementIndex], updates);
        }
        break;
      }

      case SVG_DIAGRAM_ACTIONS.DELETE_ELEMENT: {
        const elementKey = action.payload;
        const elementIndex = draft.elements.findIndex(
          (element) => element.key === elementKey
        );

        if (elementIndex !== -1) {
          draft.elements.splice(elementIndex, 1);

          // Also clear selection if deleting selected element
          if (draft.selectedElementKey === elementKey) {
            draft.selectedElementKey = null;
          }
        }
        break;
      }

      case SVG_DIAGRAM_ACTIONS.SET_LINE_THICKNESS:
        draft.lineThickness = action.payload;
        break;

      case SVG_DIAGRAM_ACTIONS.SET_CONNECTOR_MODE:
        draft.connectorMode = action.payload;
        break;

      case SVG_DIAGRAM_ACTIONS.SET_COLOR:
        draft.color = action.payload;
        break;

      case SVG_DIAGRAM_ACTIONS.SET_DRAWING_MODE:
        draft.isDrawing = action.payload;
        break;

      case SVG_DIAGRAM_ACTIONS.DUPLICATE_ELEMENT: {
        const elementKey = action.payload;
        const element = state.elements.find((el) => el.key === elementKey);

        if (!element) break;

        let newElement;
        const topZIndex =
          Math.max(...state.elements.map((el) => el.zIndex || 0)) + 1;

        switch (element.type) {
          case 'svg':
            newElement = {
              ...element,
              key: `svg_${uuidv4()}`,
              zIndex: topZIndex,
            };
            break;

          case 'image': {
            const keyImage = `illustration_file${uuidv4()}`;
            newElement = {
              ...element,
              key: `image_${uuidv4()}`,
              keyImage:
                element.keyImage === 'ACCESS_FROM_ICON_LIB'
                  ? element.keyImage
                  : keyImage,
              zIndex: topZIndex,
            };
            break;
          }

          case 'text':
            newElement = {
              ...element,

              key: `text_${uuidv4()}`,
              zIndex: topZIndex,
            };
            break;

          case 'line':
          case 'arrow-line':
          case 'bidirectional-line':
          case 'polyline': {
            // Get the SVG pad bounding box for duplication positioning
            const svgPad = document.getElementById('svgPad');
            if (!svgPad) break;

            const svgBoundingPad = svgPad.getBoundingClientRect();
            const svgItemBounding = document
              .getElementById(element.key)
              ?.getBoundingClientRect();

            if (!svgItemBounding) break;

            newElement = {
              ...element,
              key: `${element.type}_${uuidv4()}`,
              points: generateNewPointsItemDuplicate(
                element.points,
                svgBoundingPad,
                svgItemBounding
              ),
              zIndex: topZIndex,
            };
            break;
          }

          default:
            break;
        }

        if (newElement) {
          newElement.x += 20;
          newElement.y += 20;
          draft.elements.push(newElement);
        }
        break;
      }

      case SVG_DIAGRAM_ACTIONS.CHANGE_ELEMENT_LAYOUT: {
        const { key, direction } = action.payload;

        if (!key) break;

        const topZIndex =
          Math.max(...state.elements.map((el) => el.zIndex || 0)) + 1;
        const newElements = changeIndexElement(
          direction,
          state.elements,
          key,
          topZIndex
        );

        draft.elements = newElements;
        break;
      }

      default:
        break;
    }
  });
};

export default svgDiagramReducer;
