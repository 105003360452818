// 'use strict';
import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Group, useMantineTheme } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { widthScreen } from '../../../../utils/windowSize';
import ModalDialog from '../../ModalDialog/ModalDialog';
import DropFileObjDrawingPad from './DropFileObjDrawingPad';
import ModalSelectIconDrawing from './ModalSelectIconDrawing';

import { colorsSvgDrawing } from '../../../../constants/colors';
import { renderCursorWithAction } from '../../../../utils/functionSvgDrawing';
import ActionDrawPanel from './ActionDrawPanel';
import ButtonSaveToLibrary from './ButtonSaveToLibrary';
import GridsDrawing from './GridsDrawing';
import {
  ObjFieldDrawingPadModalContent,
  ObjFieldDrawingPadModalItem,
  ObjFieldDrawingPadModalList,
  ObjFieldDrawingPadModalPalette,
} from './ObjFieldDrawingPadCSS';

import SVGDiagramRenderer from '../../../SVGDiagramRenderer';
import { svgDiagramSelector } from '../../../../reducers/svgDiagramReducer/selectors';
import {
  setPanMode,
  setPanPosition,
} from '../../../../reducers/svgDiagramReducer/actions';
import ZoomSlider from '../../../SVGDiagramRenderer/ZoomSlider';
import { SvgPanningAdapter } from '../../../SVGDiagramRenderer/svgPanningAdapter';
import { MainDrawingPanelSVG } from './SvgDrawingCSS';
import { useDiagramView } from '../../../../reducers/svgDiagramReducer/hooks';

export default function SvgDrawing({
  editMode,
  toggleEditMode,
  onLink,
  onChangeLineDrawing,
  lineDrawing,
  onChangeAction,
  onResizeSvg,
  onDeleteSvg,
  onChangeTick,
  tick,
  handleTouchStart,
  handleTouchMove,
  handleTouchEnd,
  setActionDragSvg,
  setSvgFocus,
  handleSaveSvgFileToDb,
  idLineDrawing,
  onUpdatePointsOnMove,
  handleCleanAll,
  onGeneratePoly,
  handleUndo,
  toggleDelete,
  handleColorChange,
  color,
  onAddFile,
  onDuplicateItem,
  onChangeLayout,
  onUpdateItemPolylineOrArrowLine,
  isEditSvgLibIcon,
  updateIconSvgDrawing,
  onLoadSourceFromTemplateIcon,
  onUpdateConnectorData,
  attrName,
}) {
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const dispatch = useDispatch();
  const { zoomLevel, panPosition, selectedElementKey, currentAction } =
    useSelector(svgDiagramSelector);
  const { gridSize, isGridVisible } = useDiagramView();

  const [isPanning, setIsPanning] = useState(false);
  const [panningContext, setPanningContext] = useState(null);
  const [startPanPosition, setStartPanPosition] = useState({ x: 0, y: 0 });
  const transformGroupRef = useRef(null); // Add this ref

  // Prepare editor props for the unified renderer
  const editorProps = {
    selectedElementKey,
    idLineDrawing,
    setActionDragSvg,
    setSvgFocus,
    handleSaveSvgFileToDb,
    onUpdatePointsOnMove,
    onUpdateConnectorData,
    panPosition,
    zoomLevel,
  };

  const [svgTransformGroup, setSvgTransformGroup] = useState(null);

  // Update the useEffect to store the reference reliably
  useEffect(() => {
    // Only try to get the transform group when the modal is open
    if (editMode) {
      // Small timeout to ensure DOM has rendered
      setTimeout(() => {
        const svgPad = document.getElementById('svgPad');
        if (svgPad) {
          const transformGroup = svgPad.querySelector('g');
          if (transformGroup) {
            transformGroupRef.current = transformGroup;
            console.log('Transform group found after modal open');
          }
        }
      }, 100); // Short delay to ensure DOM is ready
    } else {
      // Clean up when modal closes
      transformGroupRef.current = null;
    }
  }, [editMode]);

  const handleBackgroundMouseDown = (e) => {
    const isDrawingMode = currentAction.startsWith('drawing-');

    if (
      (e.target.id === 'svgPad' || e.target.tagName === 'svg') &&
      !isDrawingMode
    ) {
      e.preventDefault();
      e.stopPropagation();
      const clientX = e.touches ? e.touches[0].clientX : e.clientX;
      const clientY = e.touches ? e.touches[0].clientY : e.clientY;

      // Initialize panning context
      const context = SvgPanningAdapter.startPanning(
        clientX,
        clientY,
        panPosition,
        zoomLevel
      );
      setPanningContext(context);

      setIsPanning(true);
      document.body.style.cursor = 'grabbing';
    } else {
      handleTouchStart(e);
    }
  };

  const handleBackgroundMouseMove = (e) => {
    const isDrawingMode = currentAction.startsWith('drawing-');
    if (isPanning && !isDrawingMode) {
      console.log('still panning');
      const transformGroup = transformGroupRef.current;

      if (panningContext && transformGroup) {
        e.preventDefault();
        e.stopPropagation();

        const clientX = e.touches ? e.touches[0].clientX : e.clientX;
        const clientY = e.touches ? e.touches[0].clientY : e.clientY;

        // Calculate new pan position
        const newPanPosition = SvgPanningAdapter.calculateNewPanPosition(
          clientX,
          clientY,
          panningContext,
          zoomLevel
        );

        // Apply transform directly to DOM for immediate visual feedback
        transformGroup.setAttribute(
          'transform',
          `translate(${newPanPosition.x}, ${newPanPosition.y}) scale(${zoomLevel})`
        );

        dispatch(setPanPosition(newPanPosition));

        // Store pending position for mouseup
        transformGroup._pendingPosition = newPanPosition;
      } else {
      }
    } else if (isDrawingMode) {
      handleTouchMove(e);
    }
  };

  const handleBackgroundMouseUp = (e) => {
    console.log('mouse up', isPanning, transformGroupRef);
    if (isPanning) {
      e.preventDefault();
      e.stopPropagation();
      console.log('was panning');
      const transformGroup = transformGroupRef.current;
      // Update Redux state with the final position
      if (transformGroup && transformGroup._pendingPosition) {
        dispatch(setPanPosition(transformGroup._pendingPosition));
        transformGroup._pendingPosition = null;
      }

      setIsPanning(false);
      document.body.style.cursor = 'default';
    } else {
      // Use regular handler when not panning
      handleTouchEnd(e);
    }
  };

  // Add body-level handlers to catch mouse events even when cursor leaves the SVG
  useEffect(() => {
    if (isPanning) {
      document.addEventListener('mousemove', handleBackgroundMouseMove);
      document.addEventListener('mouseup', handleBackgroundMouseUp);
    }

    return () => {
      document.removeEventListener('mousemove', handleBackgroundMouseMove);
      document.removeEventListener('mouseup', handleBackgroundMouseUp);
    };
  }, [isPanning, panPosition, startPanPosition]);

  return (
    <ModalDialog
      title="Drawing Panel"
      width={widthScreen > 900 ? 900 : widthScreen - 100}
      height="700"
      show={editMode}
      onClose={toggleEditMode}
      allowMaximize={true}
      // padding
      modalPadding="0px"
      modalContentPadding="0px"
    >
      <ObjFieldDrawingPadModalContent id="pad" draggable={false}>
        <ActionDrawPanel
          color={color}
          onChangeLineDrawing={onChangeLineDrawing}
          lineDrawing={lineDrawing}
          onChangeAction={onChangeAction}
          onLink={onLink}
          onResizeSvg={onResizeSvg}
          onDeleteSvg={onDeleteSvg}
          onChangeTick={onChangeTick}
          tick={tick}
          onDuplicateItem={onDuplicateItem}
          onChangeLayout={onChangeLayout}
          onUpdateItemPolylineOrArrowLine={onUpdateItemPolylineOrArrowLine}
        />
        <div
          id="wrapSvgPad"
          style={{
            width: '100%',
            height: '100%',
            backgroundColor: 'var(--ds-bg-secondary)',
          }}
        >
          <MainDrawingPanelSVG
            id="svgPad"
            draggable={false}
            onMouseDown={handleBackgroundMouseDown}
            onTouchStart={handleBackgroundMouseDown}
            onMouseMove={handleBackgroundMouseMove}
            onTouchMove={handleBackgroundMouseMove}
            onMouseUp={handleBackgroundMouseUp}
            onTouchEnd={handleBackgroundMouseUp}
            width={'100%'}
            height={'100%'}
            style={{
              overflow: 'visible',
              position: 'relative',
              touchAction: 'none',
              border: '1px solid red',
              cursor: isPanning
                ? 'grabbing'
                : currentAction.startsWith('drawing-')
                ? renderCursorWithAction(currentAction)
                : 'grab',
            }}
            onClick={(e) => {
              // If we're in connector mode and the click is directly on the SVG pad (not on an element)
              if (currentAction === 'drawing-connector') {
                console.log('Click on SVG pad in connector mode');
                // We don't want to handle clicks on the background in connector mode
              }
            }}
          >
            {isGridVisible && <GridsDrawing />}
            <g
              transform={`translate(${panPosition.x}, ${panPosition.y}) scale(${zoomLevel})`}
              id="contentDrawing"
              style={{
                transformOrigin: 'center center',
                overflow: 'visible',
              }}
            >
              <SVGDiagramRenderer
                mode="edit"
                attrName={attrName}
                editorProps={{
                  ...editorProps,
                }}
                onElementClick={(objId) => {
                  // Handle element clicks if needed
                }}
              />

              {onGeneratePoly()}
            </g>
          </MainDrawingPanelSVG>
          <ZoomSlider />
        </div>
        <ObjFieldDrawingPadModalPalette
          id="palette"
          backgroundColor={
            theme.colorScheme === 'dark'
              ? theme.colors.cool_gray[8]
              : theme.colors.blue_gray[1]
          }
        >
          <Group direction="column" position="center" spacing="xs">
            <ButtonSaveToLibrary
              isEditSvgLibIcon={isEditSvgLibIcon}
              toggleEditMode={toggleEditMode}
              updateIconSvgDrawing={updateIconSvgDrawing}
            />
            <Button
              styles={{ root: { width: 70 } }}
              compact
              onClick={handleCleanAll}
              onTouchStart={handleCleanAll}
            >
              {t('object.clear')}
            </Button>
            <Button
              styles={{ root: { width: 70 } }}
              compact
              onClick={handleUndo}
              onTouchStart={handleUndo}
            >
              {t('object.undo')}
            </Button>
            <Button
              styles={{ root: { width: 70 } }}
              compact
              color={currentAction === 'delete' ? 'red' : 'blue'}
              onClick={toggleDelete}
              onTouchStart={toggleDelete}
            >
              {t('object.delete')}
            </Button>

            <ObjFieldDrawingPadModalList>
              {colorsSvgDrawing.map((e, index) => (
                <ObjFieldDrawingPadModalItem
                  id={`index_color_${index}`}
                  key={e}
                  onClick={handleColorChange}
                  onTouchStart={handleColorChange}
                  border={e === color ? '3px solid #1C7ED6' : '3px solid #DDD'}
                  backgroundColor={e}
                />
              ))}
            </ObjFieldDrawingPadModalList>
          </Group>
          <DropFileObjDrawingPad onAddFile={onAddFile} />

          <ModalSelectIconDrawing
            onAddFile={onAddFile}
            isEditSvgLibIcon={isEditSvgLibIcon}
            onLoadSourceFromTemplateIcon={onLoadSourceFromTemplateIcon}
          />
        </ObjFieldDrawingPadModalPalette>
      </ObjFieldDrawingPadModalContent>
    </ModalDialog>
  );
}
