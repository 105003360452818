import styled from 'styled-components';

export const SmallTagsContent = styled.div({
  backgroundColor: 'inherit',
  border: '0px solid red',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '5px',
  marginBottom: '4px',
  marginTop: '4px',
  '&:empty': {
    marginBottom: '0px',
    marginTop: '0px',
  },
});

export const FieldCamrecContent = styled.div({
  alignItems: 'center',
  backgroundColor: '#343841',
  border: '1px solid #343841',
  borderRadius: '3px',
  boxShadow: ' inset 0 0 5px #000000',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginBottom: '10px',
  marginLeft: 'auto',

  marginRight: 'auto',
  marginTop: '12px',
  minHeight: '150px',
  transform: 'scale(1,1)',
  width: '100%',
});
export const FieldFile = styled.img({
  borderRadius: '5px',
  maxWidth: '100%',
  maxHeight: '140px',
});

export const FieldListContent = styled.div({
  backgroundColor: (props) => props.backgroundColor,
  border: (props) => props.border,
  borderRadius: '5px',
  color: (props) => props.color,
  display: 'flex',
  fontSize: '11px',
  fontWeight: '600',
  height: '22px',
  lineHeight: '22px',
  padding: 'auto',
  paddingLeft: '6px',
  paddingRight: '6px',
  textAlign: 'center',
  width: 'auto',
});
export const TextFieldUrlContent = styled.a({
  border: '0px solid red',
  color: (props) => props.color,
  verticalAlign: 'middle',
  fontSize: '12px',
});
//------------------------------------------------------------------------------
export const DivSmallTagStyle = styled.div({
  borderTop: ` 1px dashed var(--ds-border-base)`,
  color: 'var(--ds-text-base)',
  display: 'block',
  float: 'left',
  fontSize: '12px',
  fontWeight: 'normal',
  marginTop: '6px',
  minWidth: '10px',
  opacity: '0.9',
  width: '100%',
  zIndex: 110,
});

//------------------------------------------------------------------------------
export const DivSmallAvatarStyle = styled.div({
  borderTop: ` 1px dashed var(--ds-border-base)`,
  color: 'var(--ds-text-base)',
  display: 'block',
  float: 'left',
  fontSize: '12px',
  fontWeight: 'normal',
  marginTop: '6px',
  minWidth: '10px',
  opacity: '0.9',
  width: '100%',
  zIndex: 110,
  paddingTop: '4px',
});

//------------------------------------------------------------------------------
export const DivSmallTagNumberStyle = styled(DivSmallTagStyle)({});
//------------------------------------------------------------------------------
export const DivSmallTagAreaStyle = styled(DivSmallTagStyle)({
  textOverflow: 'ellipsis',
  paddingBottom: '3px',
  border: '0px solid red',
});
//------------------------------------------------------------------------------
export const NeutralDiv = styled.div({
  display: 'contents',
});

//------------------------------------------------------------------------------
export const HalfBlocSmallTag = styled.div({
  backgroundColor: 'var(--ds-bg-secondary)',
  border: '0px solid red',
  borderRadius: '3px',
  fontWeight: '600',
  margin: '1px',
  padding: '0px',
  paddingLeft: '4px',
  paddingTop: '-10px',
  paddingBottom: '5px',
  width: '30%',
  overflow: 'hidden',
});
//------------------------------------------------------------------------------
export const HalfBlocSmallTagLabelStyle = styled.span({
  backgroundColor: 'var(--ds-bg-secondary)',
  borderRadius: '4px',
  border: '0px solid red',
  color: 'var(--ds-text-secondary)',
  display: 'block',
  fontFamily: 'proxima-nova',
  fontSize: '10px',
  fontStyle: 'normal',
  fontWeight: '600',
  letterSpacing: '0.5px',
  marginLeft: '-3px',
  marginTop: '5px',
  overflow: 'hidden',
  paddingLeft: '8px',
  paddingRight: '8px',
  textOverflow: 'ellipsis',
  verticalAlign: 'top',
  width: 'max-content',
  zIndex: 100,
});

//------------------------------------------------------------------------------
export const SpanSmallAvatarLabelStyle = styled.span({
  border: '0px solid blue',
  color: 'var(--ds-text-base)',
  display: 'inline-block',
  fontFamily: 'proxima-nova',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 'normal',
  letterSpacing: '0.5px',
  marginLeft: '5px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  lineHeight: '22px',
  width: '80px',
  zIndex: 100,
});

//------------------------------------------------------------------------------
export const SpanSmallAvatarValueStyle = styled.span({
  backgroundColor: '',
  border: '0px solid ',
  color: 'var(--ds-text-base)',
  display: 'inline-block',
  fontFamily: 'proxima-nova',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 'normal',
  overflow: 'hidden',
  textAlign: 'right',
  textOverflow: 'ellipsis',
  verticalAlign: 'top',
  width: 'CALC( 100% - 85px )',
  zIndex: 100,
});
//------------------------------------------------------------------------------
export const SpanSmallTagLabelStyle = styled.span({
  border: '0px solid blue',
  color: 'var(--ds-text-base)',
  display: 'inline-block',
  fontFamily: 'proxima-nova',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 'normal',
  letterSpacing: '0.5px',
  marginLeft: '5px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  verticalAlign: 'top',
  width: '80px',
  zIndex: 100,
});
//------------------------------------------------------------------------------
export const SpanSmallTagValueStyle = styled.span({
  backgroundColor: '',
  border: '0px solid ',
  color: 'var(--ds-text-base)',
  display: 'inline-block',
  fontFamily: 'proxima-nova',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 'normal',
  overflow: 'hidden',
  textAlign: 'right',
  textOverflow: 'ellipsis',
  verticalAlign: 'top',
  width: 'CALC( 100% - 85px )',
  zIndex: 100,
});

//------------------------------------------------------------------------------
export const SpanSmallTagAreaValueStyle = styled.span({
  backgroundColor: 'var(--ds-bg-secondary)',
  color: 'var(--ds-text-base)',
  display: 'block',
  fontFamily: 'proxima-nova',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 'normal',
  maxHeight: '64px',
  overflow: 'hidden',
  padding: '5px',
  paddingBottom: '10px',
  textAlign: 'left',
  textOverflow: 'ellipsis',
  verticalAlign: 'top',
  whiteSpace: 'normal',
  width: '100px',
  wordBreak: 'break-word',
  zIndex: 100,
  ':empty': {
    display: 'none',
  },
});

export const SmallTagsContainer = styled.div({
  display: 'flex',
  backgroundColor: 'inherit',
  flexDirection: 'column',
});
export const FieldFileContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
});
export const PercentFieldContent = styled.div({
  margin: '-5px',
  display: 'flex',
  justifyContent: 'left',
  textAlign: 'center',
  alignItems: 'center',
  width: '100%',
  fontFamily: 'proxima-nova',
  fontSize: '13px',
  fontWeight: '600',
  color: (props) => props.nodeColor,
});
