import {
  expToDBQuery,
  getOpsByType,
} from '../components/Utils/QueryBuilderUtils';
import { parseJson } from '../utils/parseJson';
import { OBJECTS_TYPE } from './objectReducers/type';

const DEFAULT_QB_EXPRESSION = {
  type: 'expression',
  id: '1',
  op: 'AND',
  terms: [],
};
export const DEFAULT_QB_RESTRICTIONS = [
  {
    str: '',
    strraw: { ...DEFAULT_QB_EXPRESSION },
  },
  {
    str: '',
    strraw: { ...DEFAULT_QB_EXPRESSION },
  },
  {
    str: '',
    strraw: { ...DEFAULT_QB_EXPRESSION },
  },
];
const QBUILDER = (
  state = {
    objFormDataQueryBuilder: [],
    qb_restrictions: DEFAULT_QB_RESTRICTIONS,
  },
  action,
  root
) => {
  let res;

  switch (action.type) {
    //--------------------------------------------------------------------------------------------------
    case 'RECEIVED_OBJECTFORMDATA_QUERYBUILDER':
      res = { ...state, objFormDataQueryBuilder: action.objects.objFormData };
      return res;
    //--------------------------------------------------------------------------------------------------
    case 'SET_TERM_TO_QB':
    case 'ADD_TERM_TO_QB': {
      const qb_restrictionsTmp2 = [...state.qb_restrictions];
      if (!qb_restrictionsTmp2[action.level]) {
        qb_restrictionsTmp2[action.level] = {
          str: '',
          strraw: { ...DEFAULT_QB_EXPRESSION },
        };
      }
      if (!qb_restrictionsTmp2[action.level].strraw)
        qb_restrictionsTmp2[action.level].strraw = { ...DEFAULT_QB_EXPRESSION };

      const strrawTmp = qb_restrictionsTmp2[action.level].strraw;
      if (strrawTmp.terms) {
        if (action.type === 'SET_TERM_TO_QB') strrawTmp.terms = [];
        strrawTmp.terms.push({
          type: 'term',
          id: `${new Date().getTime()}`,
          value: action.value,
          field: action.field,
          field_type: action.fieldType,
          ops: getOpsByType(action.fieldType),
          op: action.comparator,
        });
      }
      qb_restrictionsTmp2[action.level].str = expToDBQuery(strrawTmp);
      const tempRes = { ...state, qb_restrictions: qb_restrictionsTmp2 };
      return tempRes;
    }

    //--------------------------------------------------------------------------------------------------
    case OBJECTS_TYPE.SHIFT_PERSPECTIVE_DOWN: {
      const qb_restrictionsTmp = [...state.qb_restrictions];
      qb_restrictionsTmp[2] = qb_restrictionsTmp[1];
      qb_restrictionsTmp[1] = qb_restrictionsTmp[0];
      qb_restrictionsTmp[0] = {
        str: '',
        strraw: '',
      };
      const tempRes = { ...state, qb_restrictions: qb_restrictionsTmp };
      return tempRes;
    }
    //--------------------------------------------------------------------------------------------------
    case OBJECTS_TYPE.SHIFT_PERSPECTIVE_UP: {
      const qb_restrictionsTmp = [...state.qb_restrictions];
      qb_restrictionsTmp[0] = qb_restrictionsTmp[1];
      qb_restrictionsTmp[1] = qb_restrictionsTmp[2];
      qb_restrictionsTmp[2] = {
        str: '',
        strraw: '',
      };
      const tempRes = { ...state, qb_restrictions: qb_restrictionsTmp };
      return tempRes;
    }
    //--------------------------------------------------------------------------------------------------
    case 'SET_QB_RESTRICTION_BY_LEVEL':
      const qb_restrictionsTmp = [...state.qb_restrictions];
      qb_restrictionsTmp[action.level] = {
        str: '',
        strraw: '',
      };
      qb_restrictionsTmp[action.level].str = action.str;
      qb_restrictionsTmp[action.level].strraw = action.strraw;
      const tempRes = { ...state, qb_restrictions: qb_restrictionsTmp };
      return tempRes;
    //--------------------------------------------------------------------------------------------------
    case OBJECTS_TYPE.FILTER_SET_LEVEL: {
      // when filter are changed remove the restriction from qbuilder for that level
      const { level } = action;

      const qb_restrictionsTmp = [...state.qb_restrictions];
      qb_restrictionsTmp[level] = {
        str: '',
        strraw: '',
      };

      const tempRes = { ...state, qb_restrictions: qb_restrictionsTmp };

      return tempRes;
    }
    //--------------------------------------------------------------------------------------------------
    case OBJECTS_TYPE.OPEN_PERSPECTIVE:
      const { objFormData } = action;
      let qb_restrictions = null;
      try {
        qb_restrictions = parseJson(
          objFormData.filter((item) => item.attrName === 'qbrestrictions')?.[0]
            ?.defaultValue
        );
      } catch (error) {
        console.log('qbrestrictions problem');
      }
      res = {
        ...state,
        qb_restrictions: qb_restrictions || DEFAULT_QB_RESTRICTIONS,
      };
      return res;
    // VIEW RESTRICTION SEND BY AI
    case 'VIEW_RESTRICTION':
      const jsonConfig = JSON.parse(action.payload.configuration);

      res = {
        ...state,
        qb_restrictions: jsonConfig || DEFAULT_QB_RESTRICTIONS,
      };
      return res;
    default:
      return state;
  }
};

export default QBUILDER;
