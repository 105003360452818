import { useState } from 'react';
import { Group } from '@mantine/core';
import { Calendar } from '@mantine/dates';
import dayjs from 'dayjs';

function getDay(date) {
  const day = date.getDay();
  return day === 0 ? 6 : day - 1;
}

function startOfWeek(date) {
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate() - getDay(date)
  );
}

function endOfWeek(date) {
  return dayjs(
    new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() + (6 - getDay(date))
    )
  )
    .endOf('date')
    .toDate();
}

function isInWeekRange(date, value) {
  return (
    value &&
    dayjs(date).isBefore(endOfWeek(value)) &&
    dayjs(date).isAfter(startOfWeek(value) - 1)
  );
}

//-------------------------------------------------------------------------------------------------------

function WeekCalendar({ handleDateChanged, attrName }) {
  const [hovered, setHovered] = useState(null);
  const [value, setValue] = useState(null);
  const internalHandleDateChanged = (selectedDate) => {
    const firstDayOfWeek = startOfWeek(selectedDate);
    handleDateChanged(null, attrName, firstDayOfWeek);
  };

  const dayInRange = (date) => isInWeekRange(date, hovered);
  const dayFirstInRange = (date) =>
    isInWeekRange(date, hovered) && date.getDay() === 1;
  const dayLastInRange = (date) =>
    isInWeekRange(date, hovered) && date.getDay() === 6;
  return (
    <>
      <Group position="center">
        <Calendar
          firstDayOfWeek="monday"
          withCellSpacing={false}
          onChange={internalHandleDateChanged}
          onDayMouseEnter={(date) => setHovered(date)}
          onMouseLeave={() => setHovered(null)}
          isDateInRange={(date) => dayInRange(date)}
          isDateFirstInRange={(date) => dayFirstInRange(date)}
          isDateLastInRange={(date) => dayLastInRange(date)}
        />
      </Group>
    </>
  );
}
export default WeekCalendar;
