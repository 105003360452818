import { SvgCoordinateTransform } from './svgCoordinateTransform';

export const SvgPanningAdapter = {
  startPanning: (clientX, clientY, currentPanPosition, zoomLevel) => {
    return {
      startMousePosition: { x: clientX, y: clientY },
      initialPanPosition: { ...currentPanPosition },
    };
  },

  calculateNewPanPosition: (clientX, clientY, panningContext, zoomLevel) => {
    const { startMousePosition, initialPanPosition } = panningContext;

    // Calculate delta from the original starting point
    const totalDeltaX = (clientX - startMousePosition.x) / zoomLevel;
    const totalDeltaY = (clientY - startMousePosition.y) / zoomLevel;

    // Calculate new absolute position
    return {
      x: initialPanPosition.x + totalDeltaX,
      y: initialPanPosition.y + totalDeltaY,
    };
  },
};
