import { Button } from '@mantine/core';
import { toBlob } from 'html-to-image';
import { cloneDeep, isEmpty } from 'lodash';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidV4 } from 'uuid';
import ObjectApi from '../../../../api/ObjectApi';
import { updateDataIcons } from '../../../../reducers/iconsLibraryReducer/action';
import { iconsLibrarySelector } from '../../../../reducers/iconsLibraryReducer/iconsLibrarySelector';
import { onPushAndAutoRemoveNotification } from '../../../../reducers/notificationReducer/action';
import { filterElementToImageIgnore } from '../../../../utils/filterElementIgnore';
import { useElementOperations } from '../../../../reducers/svgDiagramReducer/hooks';

function generateRandomString(length) {
  const characters = '0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }
  return result;
}

function generateRandomImageName(extension = 'png') {
  const randomString = generateRandomString(5); // You can adjust the length as needed
  return `Svg drawing ${randomString}.${extension}`;
}

export default function ButtonSaveToLibrary({
  isEditSvgLibIcon,
  toggleEditMode,
  updateIconSvgDrawing,
}) {
  const iconsLibrary = useSelector(iconsLibrarySelector);
  const [isLoading, setIsLoading] = useState(false);
  const { elements } = useElementOperations();
  const dispatch = useDispatch();
  const onGetImage = async () => {
    if (isLoading) return;
    setIsLoading(true);

    const newData = cloneDeep(iconsLibrary);
    let responseBlob = null;
    let responseUrlImageUpload = null;
    const nameImageUpload = generateRandomImageName();
    try {
      let element = document.getElementById('wrapSvgPad');
      responseBlob = await toBlob(element, {
        filter: filterElementToImageIgnore,
      }).then((dataUrl) => dataUrl);
    } catch (error) {
      setIsLoading(false);
    }
    try {
      responseUrlImageUpload = await ObjectApi.saveLibraryAsset(
        responseBlob,
        nameImageUpload
      ).then((response) => response);
    } catch (error) {
      setIsLoading(false);
    }
    // update icons
    if (isEditSvgLibIcon) {
      updateIconSvgDrawing(elements, responseUrlImageUpload?.filepath);
      setIsLoading(false);
      toggleEditMode();
      return;
    }
    // add new icons

    if (isEmpty(newData?.svg)) {
      newData.svg = {};
      newData.svg.categories = {};
      newData.svg.categories.all = {
        icon: '',
        icons: {},
        id: 'facef505-145f-47f5-9cca-5d39eef2f86a',
        name: 'All',
      };
    }
    newData.svg.categories.all.icons[uuidV4()] = {
      name: nameImageUpload,
      icon: responseUrlImageUpload?.filepath,
      tags: [],
      file: responseUrlImageUpload?.filepath,
      elements,
    };
    try {
      await ObjectApi.saveLibrary(JSON.stringify(newData));
      dispatch(
        onPushAndAutoRemoveNotification({
          title: 'Drawing Panel',
          description: 'Save icons to icons library success!',
          disallowClose: false,
          isLoading: false,
        })
      );
      setIsLoading(false);

      dispatch(updateDataIcons(newData));
    } catch (error) {
      dispatch(
        onPushAndAutoRemoveNotification({
          title: 'Drawing Panel',
          description: 'Save icons to icons library failed!',
          disallowClose: false,
          isLoading: false,
        })
      );
      setIsLoading(false);
    }
  };
  return (
    <Button
      loading={isLoading}
      disabled={isLoading}
      styles={{ root: { width: 70 } }}
      compact
      onClick={onGetImage}
      onTouchStart={onGetImage}
    >
      {isEditSvgLibIcon ? 'Update' : 'Save'}
    </Button>
  );
}
