import styled from 'styled-components';

export const ObjFieldCamRecContainer = styled.div({
  border: '0px solid red',
  flexDirection: 'row',
  width: '100%',
  display: 'flex',
});

export const ObjFieldCamRecVideoContainer = styled.div({
  width: '100%',
});

export const ObjFieldCamRecVideoContent = styled.div({
  backgroundColor: 'black',
  borderRadius: '0px',
  margin: '0px',
  overflow: 'hidden',
  padding: '0px',
  width: '100%',
});

export const ObjFieldCamRecMini = styled.div({
  display: 'grid',
  gridTemplateColumns: 'auto ',
  gridAutoRows: 'minmax(2px, auto)',
});

export const ObjFieldCamRecMiniContent = styled.div({
  border: '0px solid white',
  fontSize: 11,
  gridRow: '1',
  padding: '2px',
  gridColumn: '1',
});

export const ObjFieldCamRecMiniInput = styled.input({
  border: '0px',
  display: (props) => props.display,
  fontSize: '10px',
  fontWeight: 'normal',
  margin: '10',
  marginLeft: '20px',
});

export const ObjFieldCamRecFile = styled.div({
  border: '0px solid white',
  color: '#BBB',
  fontSize: 11,
  gridColumn: '1',
  gridRow: '2',
  padding: '20px',
});
