import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';

import ObjectActionCreators from '../../../actions.js';
import {
  onSetLevelNodeNadBandTablet,
  onSetNodeNodeFocus,
  onSetShowNodeContent,
} from '../../../reducers/responsiveReducer/action.js';
import {
  idOldNodeActiveSelector,
  levelNodeAndBandActiveSelector,
  oldPositionNodeDragSelector,
} from '../../../reducers/responsiveReducer/responsiveSelector.js';
import { isTablet } from '../../../utils/detectDevices.js';
import ObjectCreatorMini from '../../UI/Objects/ObjectCreatorMini/ObjectCreatorMini.js';
import { getPreferedLinesByType } from '../../Utils/NodeUtils';
import { confirmAction } from '../../Utils/Utils.js';
import { ImgCollapse } from '../../layout/NavigTree/NavigTreeCSS';
import NavigNodeContainer from '../NavigNodeContainer/NavigNodeContainer';
import SmallTags from '../SmallTags/SmallTags.js';
import {
  ChildrenContent,
  DivBlocNavigNode,
  IconImgStyle,
  NavigTooltip,
  NodeForm,
  NodeTitle,
  SpanNavigContentStyle,
  WapImgCollapse,
} from './NavigNodeCss';
import { DoubleArrowIcon } from '../../../constants/icon.js';

const CHILD_PAGE_SIZE = 20;
//------------------------------------------------------------------------------------------------------
const IconImg = ({ icon, dark }) => {
  // temporary workaround, if the image was saved without extension  XXX
  if (icon && !icon.includes('.')) {
    icon = `img/icons/${icon}.svg`;
  } else {
    icon = `${icon}`;
  }
  return (
    <IconImgStyle
      onerror={() => {
        this.style.display = 'none';
      }}
      alt={icon}
      src={icon}
      dark={dark && icon?.includes('-inv-')}
    />
  );
};
//------------------------------------------------------------------------------------------------------
const getDisplayValue = (value, dico) =>
  dico[value] ? dico[value].substring(0, 15) : value;
//------------------------------------------------------------------------------------------------------

//------------------------------------------------------------------------------------------------------
class NavigNode extends React.Component {
  //----------------------------------------------------------------------------
  handleDelete(key) {
    this.props.deleteObject(key);
  }

  //----------------------------------------------------------------------------
  handleClickMenu(type, key) {
    this.props.openObjectMenuForObj(type, key, 'addObject');
    this.props.selectObject(key);
  }

  //----------------------------------------------------------------------------
  constructor(props) {
    super(props);
    this.hoverTimer;
    this.ref = React.createRef();
    this.lastReportedHeight = null;
    this.handleClickMenu = this.handleClickMenu.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleClickVerifyCreateBrotherWithChilds =
      this.handleClickVerifyCreateBrotherWithChilds.bind(this);
    this.toggleCollapseNode = this.toggleCollapseNode.bind(this);

    this.handleComponentLoaded = this.handleComponentLoaded.bind(this);
    this.handleOnDragEnd = this.handleOnDragEnd.bind(this);
    this.handleOnDragStart = this.handleOnDragStart.bind(this);
    this.handleOnTouchMove = this.handleOnTouchMove.bind(this);
    this.handleOnTouchEnd = this.handleOnTouchEnd.bind(this);
    this.handleOnMouseLeave = this.handleOnMouseLeave.bind(this);
    this.handleOnMouseOver = this.handleOnMouseOver.bind(this);
    this.handleOnMouseUp = this.handleOnMouseUp.bind(this);
    this.onHandleClickMenu = this.onHandleClickMenu.bind(this);
    this.handleCreateBrother = this.handleCreateBrother.bind(this);
    this.handleAddFieldRestriction = this.handleAddFieldRestriction.bind(this);
    this.handleConfirmAction = this.handleConfirmAction.bind(this);
    this.handleShiftPerspectiveDown =
      this.handleShiftPerspectiveDown.bind(this);
    this.handleShiftPerspectiveUp = this.handleShiftPerspectiveUp.bind(this);
    this.handleShowNextPage = this.handleShowNextPage.bind(this);
    this.onClickActiveActivityLog = this.onClickActiveActivityLog.bind(this);
    this.touchEventId = null;
    this.state = { draggingNode: false };
  }

  //----------------------------------------------------------------------------
  handleClickVerifyCreateBrotherWithChilds(evt) {
    this.props.getNumberChilds(this.props.node.data.key);
    setTimeout(
      () =>
        confirmAction(
          `Are you sure, you want to copy ${
            Number(this.props.currObjNbrChilds) + 1
          } records?`,
          () => this.props.createBrother(this.props.node.data.key, true)
        ),
      1000
    );
  }

  //-------------------------------------------------------------------------------------------------------
  toggleCollapseNode(event) {
    const objId = event.target.id.split('-')[1];
    if (this.props.collapsedNodes.includes(objId)) {
      this.props.expandNode(objId);
    } else {
      this.props.collapseNode(objId);
    }
  }

  handleOnDragEnd(e) {
    console.log('0_handleOnDragEnd');
    const { node } = this.props;
    this.props.onDragEnd(e, node.data.key);
    this.setState({ draggingNode: false });
  }

  handleOnDragStart(e) {
    // e unnecessary
    const { node } = this.props;

    this.props.onDragStart(e, node.data.key);
    this.setState({ draggingNode: true });
  }

  handleOnTouchMove(e) {
    console.log('2_handleOnTouchMove');
    const { node } = this.props;
    this.props.onTouchStart(e, node.data.key);
  }

  handleOnTouchEnd(e) {
    const {
      currentBandActive,
      idOldNodeActive,
      oldPositionNodeDrag,
      levelNodeAndBandActive,
    } = this.props;
    const { node } = this.props;
    this.props.onTouchEnd(e, node.data.key);
    //  touch move item currentBandActive valid
    const { bandLevel, nodeLevel } = levelNodeAndBandActive;
    if (!document.getElementById(idOldNodeActive)) return;

    if (currentBandActive && bandLevel === nodeLevel) {
      document.getElementById(
        idOldNodeActive
      ).style.left = `${oldPositionNodeDrag.x}px`;
      document.getElementById(
        idOldNodeActive
      ).style.top = `${oldPositionNodeDrag.y}px`;
      this.props.handleDrop(currentBandActive);
      this.props.onSetCurrentBandTablet(null);
    } else if (isTablet) {
      document.getElementById(
        idOldNodeActive
      ).style.left = `${oldPositionNodeDrag.x}px`;
      document.getElementById(
        idOldNodeActive
      ).style.top = `${oldPositionNodeDrag.y}px`;
    }
    this.props.setLevelNodeNadBandTablet({
      nodeLevel: null,
      bandLevel: null,
    });
  }

  handleOnMouseLeave(e) {
    clearTimeout(this.hoverTimer);
    this.props.setHoverNode('');
  }

  handleOnMouseOver(e) {
    this.hoverTimer = setTimeout(() => {
      const { node } = this.props;
      this.props.setHoverNode(node.data.key);
      this.props.getFather(node.data.key);
    }, 600);
  }

  handleOnMouseUp(e) {
    if (e.isDefaultPrevented()) return;
    if (window?.isShowSelectedNodeView) {
      const { node } = this.props;
      this.props.onSetSelectNode(node?.data);
    } else {
      const { node } = this.props;
      this.props.onMouseUp(e, node.data.key);
    }
  }

  onHandleClickMenu() {
    const { node } = this.props;
    this.handleClickMenu(node.data.type, node.data.key);
  }

  handleCreateBrother() {
    const { node } = this.props;
    this.props.createBrother(node.data.key, false);
  }

  handleAddFieldRestriction() {
    const { node } = this.props;

    this.props.addFieldRestriction(
      'key',
      node.data.key,
      node.data.type,
      'text',
      'text_is',
      node.level
    );
  }

  handleConfirmAction() {
    const { node } = this.props;
    confirmAction(
      'Are you sure, you want to delete this object?',
      this.handleDelete,
      node.data.key
    );
  }

  handleShiftPerspectiveDown() {
    const { node } = this.props;
    this.props.shiftPerspectiveDown(node.data.key);
  }

  handleShiftPerspectiveUp() {
    const { node } = this.props;
    this.props.shiftPerspectiveUp(node.data.key);
  }

  handleShowNextPage() {
    const { node } = this.props;
    this.props.showNextPage(node.data.key);
  }

  onClickActiveActivityLog() {
    const { node } = this.props;
    this.props.openActivityFromVertical(false);
    this.props.onActiveActivityLog(node.data.key);
  }

  getFieldsToDisplay(node, dataLines, objectsDefList) {
    let arrayFields = [];
    let level = node.depth - 1;
    try {
      let fieldsToDisplay = dataLines[level];
      if (!fieldsToDisplay || fieldsToDisplay.length == 0) {
        fieldsToDisplay = getPreferedLinesByType(
          node.data.type,
          objectsDefList
        );
      }

      arrayFields = fieldsToDisplay
        ?.filter(
          (val) => node.data[val.data] !== undefined && node.data.type == val.to
        )
        .map((val) => {
          return {
            label: val.data,
            attrName: val.data,
            fileOrigName: node.data.fileOrigName,
            type: val.to,
            value: node.data[val.data],
          };
        });
    } catch (e) {
      console.log(e);
    }
    return arrayFields;
  }

  //----------------------------------------------------------------------------
  render() {
    const {
      children,
      canWrite,
      canDuplicateType,
      dragging,
      zoom,
      highlighted,
      height,
      collapsedNodes,
      dicoNameRef,
      dataLines,
      left,
      top,
      nodeColor,
      width,
      node,
      objectsDefList,
      isGrid,
      pal,
      isNodeHover,
      isNodeOnActivePanel,
      isDark,
      isDragTablet,
      openObjectDetails,
      onDrop,
      draggingItem,
    } = this.props;
    const level = node.depth - 1;

    const nodeIcon = this.getNodeIcon(node);
    const isNodeCollapsed = collapsedNodes.includes(node.data.key);
    const collapseButtonPos = width - 2;
    // const nameToDisp = getDisplayValue(node.data.name, dicoNameRef);
    const nameToDisp = node.data.name;
    let arrayFields = this.getFieldsToDisplay(node, dataLines, objectsDefList);

    return (
      <NavigNodeContainer
        isHaveChildNode={node?.data?.nbChild > 0}
        id="NavigNodeContainer"
        draggable={canWrite}
        zoom={zoom}
        dragging={dragging}
        draggingItem={draggingItem}
        height={height}
        highlighted={highlighted}
        key={`nc${node.data.key}`}
        left={left}
        node={node}
        nodeColor={nodeColor}
        onDragEnd={canWrite ? this.handleOnDragEnd : null}
        onDragStart={canWrite ? this.handleOnDragStart : null}
        onTouchMove={canWrite ? this.handleOnTouchMove : null}
        onTouchEnd={canWrite ? this.handleOnTouchEnd : null}
        onDrop={onDrop}
        onMouseLeave={this.handleOnMouseLeave}
        onMouseOver={this.handleOnMouseOver}
        onMouseUp={this.handleOnMouseUp}
        pal={pal}
        top={top}
        width={width}
        isNodeOnActivePanel={isNodeOnActivePanel}
        isGrid={isGrid}
      >
        <div id={node.data.key} style={{ backgroundColor: 'inherit' }}>
          {isNodeHover &&
            !window?.isShowSelectedNodeView &&
            !this.state.draggingNode && (
              <NavigTooltip className="NavigTooltip">
                <ObjectCreatorMini
                  objId={node.data.key}
                  canWrite={canWrite}
                  canDuplicateType={canDuplicateType}
                  level={level}
                  onClickActiveActivityLog={this.onClickActiveActivityLog}
                  onHandleClickMenu={this.onHandleClickMenu}
                  handleCreateBrother={this.handleCreateBrother}
                  handleClickVerifyCreateBrotherWithChilds={
                    this.handleClickVerifyCreateBrotherWithChilds
                  }
                  handleAddFieldRestriction={this.handleAddFieldRestriction}
                  handleConfirmAction={this.handleConfirmAction}
                  handleShiftPerspectiveDown={this.handleShiftPerspectiveDown}
                  handleShiftPerspectiveUp={this.handleShiftPerspectiveUp}
                />
              </NavigTooltip>
            )}
          <DivBlocNavigNode
            id="DivBlocNavigNode"
            ref={this.ref}
            isNodeHover={isNodeHover}
            height={height - 1}
            width={width}
          >
            {zoom >= 80 && (
              <>
                <IconImg dark={isDark} icon={nodeIcon} />
                {node.data.nbrDocs > 0 && (
                  <IconImg
                    dark={isDark}
                    icon={
                      node.data.icon !== 'null'
                        ? node.data.icon
                        : '011-inv-ui-clip'
                    }
                  />
                )}
              </>
            )}

            <NodeContent
              node={node}
              isGrid={isGrid}
              level={level}
              id="NodeContent_div"
              nameToDisp={nameToDisp}
              zoom={zoom}
              isNodeOnActivePanel={isNodeOnActivePanel}
              pal={pal}
              nodeColor={nodeColor}
              val={node.key}
              onClick={(e) => {
                if (e.target.tagName === 'A') {
                  // Ne pas exécuter le gestionnaire du panneau si l'élément cliqué est un lien
                  return;
                }
                console.log('openObjectDetails__>>>>>>>', isDragTablet);
                if (window.isShowSelectedNodeView || isDragTablet) return;
                openObjectDetails();
              }}
            >
              {objectsDefList && !isGrid && (
                <SmallTags
                  onLoad={this.handleComponentLoaded}
                  arrayFields={arrayFields}
                  nodeColor={'var(--ds-base)'}
                  objectsDefList={objectsDefList}
                  pal={pal}
                  dicoNameRef={dicoNameRef}
                  objId={node.data.key}
                  node={node}
                />
              )}
              <>{isGrid && children}</>
            </NodeContent>

            {node?.data?.nbChild > 0 && (
              <ImgCollapse
                left={collapseButtonPos}
                top={node.ySize / 2}
                alt="expandCollapse"
                id={`node-${node.data.key}`}
                onClick={this.toggleCollapseNode}
                collapse={isNodeCollapsed}
                title={`${node.data.nbChild} items`}
              >
                {isNodeCollapsed ? '+' : '-'}
              </ImgCollapse>
            )}

            {node?.data?.children?.length > CHILD_PAGE_SIZE && (
              <WapImgCollapse id="wap_img_collapse">
                <ImgCollapse
                  up
                  left={collapseButtonPos + 22}
                  top={node.ySize / 2}
                  alt="showMore"
                  id={`node-${node.data.key}`}
                  onClick={this.handleShowNextPage}
                  title={`${node.data.nbChild} items`}
                >
                  {'<'}
                </ImgCollapse>
                <ImgCollapse
                  left={collapseButtonPos + 40}
                  top={node.ySize / 2}
                  alt="showMore"
                  id={`node-${node.data.key}`}
                  onClick={this.handleShowNextPage}
                  title={`${node.data.nbChild} items`}
                >
                  {'>'}
                </ImgCollapse>
              </WapImgCollapse>
            )}
          </DivBlocNavigNode>
        </div>
      </NavigNodeContainer>
    );
  }

  //-------------------------------------------------------------------------------------------------------
  handleComponentLoaded(evt) {
    const current = this.ref.current;
    if (!current || !current.offsetHeight) return;

    const newHeight = current.offsetHeight;

    // Ne mettre à jour que si la taille a changé ET est différente de la dernière rapportée
    if (
      newHeight !== this.lastReportedHeight &&
      this.props.node.ySize !== newHeight
    ) {
      this.lastReportedHeight = newHeight;
      this.props.updateNodeHeight(this.props.node?.data.key, newHeight);
    }
  }

  //------------------------------------------------------------------------------
  componentDidUpdate(prevProps, prevState) {
    const { key } = this.props.node.data;
    this.handleComponentLoaded();
  }

  //------------------------------------------------------------------------------
  getNodeIcon(node) {
    let resIcon = null;
    if (node) {
      const type = node.data.type;
      const objDef = this.props.objectsDefList.find((odf) => odf.key === type);
      if (objDef) resIcon = objDef.icon;
    }
    return resIcon && resIcon !== 'null' ? resIcon : 'assignment-black-18dp';
  }
}
//------------------------------------------------------------------------------
// PURE COMPONENT
//------------------------------------------------------------------------------
const NodeContent = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const setNodeNodeFocus = () => {
    console.log('on_touch_start', props.node.level);
    dispatch(ObjectActionCreators.setActivePanel(props.node.level));
    dispatch(onSetNodeNodeFocus(props.node.level, props.node));
  };
  const isDisableSwipeMobile = props.node?.data?.nbChild === 0;

  return (
    <SpanNavigContentStyle
      id="SpanNavigContentStyle"
      isDisableSwipeMobile={isDisableSwipeMobile}
      zoom={props.zoom}
      isGrid={props.isGrid}
      level={props.level}
      fontSize={props.fontSize}
      color={props.pal.colTxtTitle}
      onMouseDown={props.onMouseUp}
      onClick={handleClickNodeContent}
      isNodeOnActivePanel={props.isNodeOnActivePanel}
      onMouseUp={props.onClick}
      val={props.val}
      onTouchStart={() => {
        setNodeNodeFocus();
      }}
      onTouchEnd={() => {}}
    >
      {props.isGrid ? (
        <>{props.children}</>
      ) : (
        <>
          <NodeTitle zoom={props.zoom}>
            <span className="titleIcon">
              <DoubleArrowIcon />
            </span>
            {props?.nameToDisp || t('app.unnamed')}
          </NodeTitle>
          <NodeForm>{props.children}</NodeForm>
        </>
      )}
    </SpanNavigContentStyle>
  );
};

const handleClickNodeContent = (e) => {
  e.stopPropagation();
};
//------------------------------------------------------------------------------

function mapStateToProps(state) {
  return {
    oldPositionNodeDrag: oldPositionNodeDragSelector(state),
    idOldNodeActive: idOldNodeActiveSelector(state),
    levelNodeAndBandActive: levelNodeAndBandActiveSelector(state),
  };
}

//------------------------------------------------------------------------------------------------
const mapDispatchToProps = (dispatch) => {
  return {
    setShowNodeContent: (status) => dispatch(onSetShowNodeContent(status)),
    setLevelNodeNadBandTablet: ({ bandLevel, nodeLevel }) =>
      dispatch(onSetLevelNodeNadBandTablet({ bandLevel, nodeLevel })),
  };
};

export default memo(connect(mapStateToProps, mapDispatchToProps)(NavigNode));
