import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { objectsDefListSelector } from '../../../reducers/objectReducers/objectSelector';
import { getObjectDefField } from '../../Utils/Utils';
import DataCellByType from './onGetDataCellByType';

function DataColumns({ node }) {
  const objectsDefList = useSelector(objectsDefListSelector);
  const dataColumns = useSelector((state) => state.objects.dataColumns)?.map(
    (level) =>
      level.filter((elem) =>
        getObjectDefField(objectsDefList, elem.to, elem.data)
      )
  ); // we filter to keep only elements in the definitionList (obsolete or not granted)
  const dicoFullRef = useSelector((state) => state.objects.dicoFullRef);
  const dicoNameRef = useSelector((state) => state.objects.dicoNameRef);
  return (
    <>
      {dataColumns &&
        dataColumns[node.depth - 1]
          ?.filter((a) => node.data.type.toLowerCase().startsWith(a.to))
          .map((item, ind, arr) => {
            const isLastCell = ind === arr.length - 1;

            const result = (
              <DataCellByType
                key={`${node?.data?.key}${ind}`}
                item={item}
                node={node}
                id={`${node?.data?.key}${ind}`}
                dicoFullRef={dicoFullRef}
                dicoNameRef={dicoNameRef}
                panelNumber={node.depth - 1}
                colNumber={ind}
                isLastCell={isLastCell}
              />
            );

            return result;
          })}
    </>
  );
}

export default memo(DataColumns);
