import styled from 'styled-components';
//---------------------------------------------------
export const DataColumnsHeadersStyle = styled.div({
  bottom: '0px',
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'row',
  fontFamily: 'proxima-nova',
  fontSize: 12,
  fontWeight: 500,
  height: (props) => `${props.height}px`,
  left: (props) => `${props.left + 3}px`,
  letterSpacing: '0.5px',
  opacity: '0.6',
  overflow: 'hidden',
  position: 'absolute',
  textTransform: 'capitalize',
  width: (props) => `${props.width}px`,
  zIndex: (props) => 60000 + 1000 * props.level,
  backgroundColor: 'var(--ds-bg-base)',
  display: 'grid',
  gridTemplate: (props) => `var(--grid-template-${props.level})`,
});
//---------------------------------------------------
export const DataColumnHeaderStyle = styled.div({
  // borderLeft: `1px solid var(--ds-border-base)`,

  color: 'var(--ds-text-base)',
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  padding: '0px',
  paddingBottom: '0px',
  width: '100%',
  ':hover': {
    fontWeight: 'bold',
  },

  ':first-of-type': {
    border: '0px',
  },
});
//---------------------------------------------------
export const DataColumnHeaderStyleContent = styled.div({
  width: 'calc(100% - 6px)',
  overflow: 'hidden',
  padding: '10px',
  paddingTop: '22px',
  textAlign: 'left',
  textOverflow: 'ellipsis',
  width: '100%',
  ':after': {
    content: (props) => (props.isSorted ? '"\\2193"' : '""'),
    position: 'relative',
    left: '5px',
    marginLeft: '0px',
  },
});
