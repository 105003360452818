import { memo } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ObjectActionCreators from '../../../actions';
import { remoteControllerShowToggle } from '../../../reducers/layoutReducer/action';
import { currentPaletteActiveSelector } from '../../../reducers/themeReducers/themeSelector';
import NavigHeader from './NavigHeader';

const mapStateToProps = (state, ownProps) => {
  return {
    activeBand: state.objects.activeBand,
    arrayParamClassif: state.objects.arrayParamClassif,
    arrayStatBy: state.objects.arraySortBy,
    dicoNameRef: state.objects.dicoNameRef,
    displayMode: state.objects.displayMode[ownProps.level],
    dragged_over: state.objects.dragged_over,
    objectsDefList: state.objects.objectsDefList,
    nbMandatoryLevel: isNaN(state.objects.mandatoryLevel)
      ? 0
      : Number(state.objects.mandatoryLevel),
    pal: currentPaletteActiveSelector(state),
    qb_restrictions: state.QBUILDER.qb_restrictions,
    restrictions: state.objects.restrictions,
    sortByKey: state.objects.arraySortBy[ownProps.level],
    type: state.objects.arrayClassifBy[ownProps.level],
    timeline_shift: state.objects.timeline_shift,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addFieldRestriction: (
    field,
    value,
    objectType,
    fieldType,
    comparator,
    level
  ) =>
    dispatch(
      ObjectActionCreators.addFieldRestriction(
        field,
        value,
        objectType,
        fieldType,
        comparator,
        level
      )
    ),
  timelineShift: (level, shift) =>
    dispatch(ObjectActionCreators.timelineShift(level, shift)),
  toggleDisplayMode: (level) =>
    dispatch(ObjectActionCreators.toggleDisplayMode(level)),
  toggleMandatoryLevel: (level) =>
    dispatch(ObjectActionCreators.toggleMandatoryLevel(level)),
  setActivePanel: (level) =>
    dispatch(ObjectActionCreators.setActivePanel(level)),
  setSortBy: (param, level) =>
    dispatch(ObjectActionCreators.setSortBy(param, level)),
  setStatBy: (param, level) =>
    dispatch(ObjectActionCreators.setStatBy(param, level)),
  expandNodes: (level) => dispatch(ObjectActionCreators.expandNodes(level)),
  collapseNodes: (level) => dispatch(ObjectActionCreators.collapseNodes(level)),

  remoteControllerShowToggle: (x, y) =>
    dispatch(remoteControllerShowToggle(x, y)),
  setQBRestrictionByLevel: (level, object, str, strraw) =>
    dispatch(
      ObjectActionCreators.setQBRestrictionByLevel(level, object, str, strraw)
    ),
  selectObject: (objId) => dispatch(ObjectActionCreators.selectObject(objId)),
  openObjectMenu: (action) =>
    dispatch(ObjectActionCreators.openObjectMenu(action)),
  setObjectToCreate: (type) =>
    dispatch(ObjectActionCreators.setObjectToCreate(type)),
});

export default memo(
  withTranslation()(
    connect(mapStateToProps, mapDispatchToProps, null)(NavigHeader)
  )
);
