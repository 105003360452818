import { useCallback, useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { svgDiagramSelector } from '../../reducers/svgDiagramReducer/selectors';
import { getPositionDragSnapSvg } from '../../utils/generatePolyline';
import { ObjFieldDrawingSVGText } from '../UI/Objects/ObjFieldDrawingPad/ObjFieldDrawingPadCSS';
import { withSvgTransform } from './withSvgTransform';
import {
  useDiagramView,
  useElementOperations,
} from '../../reducers/svgDiagramReducer/hooks';

const SvgTextRenderer = ({
  svg,
  setActionDragSvg,
  setSvgFocus,
  handleSaveSvgFileToDb,
  isDisableAction = false,
  prefix_key,
  onClickElement,
  makeDraggable,
  svgZoomLevel,
}) => {
  const { selectedElementKey, currentAction } = useSelector(svgDiagramSelector);
  const { gridSize, isGridVisible } = useDiagramView();
  const { deleteSelected, updateTextElement } = useElementOperations();

  const idNode = prefix_key ? `${prefix_key + svg?.key}` : svg?.key;

  const [boundElement, setBoundElement] = useState({
    width: svg?.blocSize,
    height: 20,
  });
  const [isEditing, setIsEditing] = useState(false);
  const [editedText, setEditedText] = useState(svg?.points || '');
  const textareaRef = useRef(null);
  const previousKeyRef = useRef(svg?.key);

  const wrapperRef = useRef(null);
  const wrapperId = `wrap-${svg?.key}`;

  const WrapWidthElement = boundElement?.width + 40 || '100%';
  const WrapHeightElement = boundElement?.height + 10 || '100%';

  // Handle saving edited text
  const handleSaveText = useCallback(() => {
    if (!isEditing) return;
    updateTextElement(svg?.key, {
      text: editedText,
      color: svg.color,
      fontWeight: svg.fontWeight,
      fontSize: svg.fontSize,
      backgroundColor: svg.backgroundColor,
      blocSize: svg.blocSize,
    }); // true indicates it's an edit

    setIsEditing(false);
  }, [isEditing, editedText, svg, updateTextElement]);

  // Added useEffect to handle automatic saving when switching text blocks
  useEffect(() => {
    if (svg?.key !== previousKeyRef.current && isEditing) {
      // We've switched to a different text element while editing
      handleSaveText();
    }

    // Update the ref for next comparison
    previousKeyRef.current = svg?.key;
  }, [svg?.key, selectedElementKey, handleSaveText]);

  // Double-click to edit text
  const handleTextEdit = useCallback(() => {
    console.log(
      'statuss editing handleTextEdit',
      isEditing,
      svg?.key === selectedElementKey
    );
    // Allow editing when the element is selected, regardless of action (mouse or move)
    if (
      (currentAction !== 'mouse' && currentAction !== 'move') ||
      svg?.key !== selectedElementKey
    )
      return;

    // If currently editing another text block, save it first
    if (isEditing) {
      handleSaveText();
    }

    // Set to edit mode for this block
    setIsEditing(true);
    setEditedText(svg?.points || '');

    // Wait a tick for the textarea to render, then focus
    console.log('Entering edit mode for text:', svg?.key);
    setTimeout(() => {
      if (textareaRef.current) {
        textareaRef.current.focus();
        console.log('Focused textarea');
      } else {
        console.log('Textarea ref not available');
      }
    }, 100);
  }, [
    currentAction,
    isEditing,
    svg?.points,
    svg?.key,
    selectedElementKey,
    handleSaveText,
  ]);

  useEffect(() => {
    // Mettre à jour la position locale quand svg.x ou svg.y changent
    setPosition({
      x: svg.x,
      y: svg.y,
    });
  }, [svg.x, svg.y]);

  // Handle cancelling edit
  const handleCancelEdit = useCallback(() => {
    setIsEditing(false);
    setEditedText(svg?.points || '');
  }, [svg?.points]);

  // Handle key events for textarea
  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        handleSaveText();
      } else if (e.key === 'Escape') {
        handleCancelEdit();
      }
    },
    [handleSaveText, handleCancelEdit]
  );

  const [position, setPosition] = useState({
    x: svg.x,
    y: svg.y,
  });

  useEffect(() => {
    if (svg?.key) {
      const element = document.getElementById(`content_${svg?.key}`);
      if (element) {
        const rect = element.getBoundingClientRect();
        setBoundElement({
          width: svg?.blocSize, // / (svgZoomLevel || 1),
          height: rect.height / (svgZoomLevel || 1),
        });
      }
    }
  }, [svg?.key, svg?.fontSize, svg?.points, svg?.blocSize, svgZoomLevel]);

  // Handle text selection
  const handleSelectSvg = useCallback(
    (e) => {
      if (e) e.stopPropagation();

      // If already in editing mode, don't interfere with the text editing
      if (isEditing && svg?.key === selectedElementKey) {
        return;
      }

      if (isDisableAction) {
        if (svg.link && typeof onClickElement === 'function') {
          return onClickElement(svg.link);
        }
        return;
      }

      if (currentAction === 'delete') {
        return deleteSelected(svg?.key);
      }

      if (currentAction === 'drawing-connector') {
        if (typeof setSvgFocus === 'function') {
          setSvgFocus(svg?.key);
        }
        return;
      }

      if (typeof setSvgFocus === 'function') {
        setSvgFocus(svg?.key);
      }

      // If it's a double click, initiate editing - regardless of current action state
      if (e && e.detail === 2) {
        console.log('Double-click detected, entering edit mode');
        // Force text editing even if we're in move mode
        setTimeout(() => handleTextEdit(), 0);
      }
    },
    [
      svg?.key,
      svg?.link,
      isDisableAction,
      currentAction,
      setSvgFocus,
      deleteSelected,
      onClickElement,
      handleTextEdit,
    ]
  );

  const onMakeDraggable = useCallback(
    (id) => {
      const element = wrapperRef.current || document.getElementById(id);
      if (!element) {
        return;
      }

      // Don't make draggable if we're editing
      if (isEditing) {
        return;
      }

      makeDraggable(
        element,
        (newPosition) => {
          if (currentAction === 'delete') return;
          console.log('start move');
          setActionDragSvg('move');
          // Exit editing mode if we start dragging
          if (isEditing) {
            handleSaveText();
          }
        },
        (newPosition) => {
          // End of drag - save the position
          const positionSvg = isGridVisible
            ? getPositionDragSnapSvg(newPosition, gridSize)
            : newPosition;
          handleSaveSvgFileToDb(svg?.key, positionSvg);
        },
        (newPosition) => {
          // During drag - update position
          const positionSvg = isGridVisible
            ? getPositionDragSnapSvg(newPosition, gridSize)
            : newPosition;
          setPosition(positionSvg);
          handleSaveSvgFileToDb(svg?.key, positionSvg, true);
        },
        () => {},
        15
      );
    },
    [
      currentAction,
      isEditing,
      handleSaveText,
      isGridVisible,
      gridSize,
      svg?.key,
      setActionDragSvg,
      handleSaveSvgFileToDb,
    ]
  );

  useEffect(() => {
    if (svg?.key === selectedElementKey && !isEditing) {
      onMakeDraggable(wrapperId);
    }
  }, [svg?.key, selectedElementKey, isEditing, onMakeDraggable]);

  const isTransparent =
    svg?.backgroundColor === 'transparent' ||
    svg?.backgroundColor === 'rgba(255, 255, 255, 0)';

  return (
    <svg
      x={isDisableAction ? svg?.x : position.x}
      y={isDisableAction ? svg?.y : position.y}
      onClick={handleSelectSvg}
    >
      {svg?.backgroundColor && !isTransparent && !prefix_key && (
        <rect
          x="2"
          y="2"
          width={WrapWidthElement}
          height={WrapHeightElement}
          fill={svg?.backgroundColor}
        />
      )}

      {/* Add a transparent clickable overlay when in connector mode */}
      {currentAction === 'drawing-connector' && (
        <rect
          x="0"
          y="0"
          width={WrapWidthElement + 10}
          height={WrapHeightElement + 10}
          fill="transparent"
          onClick={(e) => {
            e.stopPropagation();
            if (typeof setSvgFocus === 'function') {
              setSvgFocus(svg?.key);
            }
          }}
          style={{ cursor: 'crosshair' }}
        />
      )}

      <foreignObject
        width={WrapWidthElement}
        height={WrapHeightElement}
        style={{
          padding: 10,
        }}
      >
        {isEditing && svg?.key === selectedElementKey ? (
          <div style={{ width: '100%', height: '100%' }}>
            <textarea
              ref={textareaRef}
              value={editedText}
              onChange={(e) => setEditedText(e.target.value)}
              onKeyDown={handleKeyDown}
              onBlur={handleSaveText}
              style={{
                overflow: 'visible',
                width: '100%',
                height: '100%',
                minHeight: '30px',
                minWeight: '140px',
                resize: 'none',
                border: '1px solid #1C7ED6',
                borderRadius: '4px',
                padding: '5px',
                fontSize: `${svg?.fontSize}px`,
                fontWeight: svg?.fontWeight,
                color: svg?.color,
                backgroundColor: isTransparent
                  ? 'transparent'
                  : svg?.backgroundColor,
                outline: 'none',
                fontFamily: 'inherit',
              }}
            />
          </div>
        ) : (
          <ObjFieldDrawingSVGText
            link={svg?.link}
            currentAction={currentAction}
            isDisableAction={isDisableAction}
            onClick={handleSelectSvg}
            id={`content_${idNode}`}
            draggable={false}
            fontSize={svg?.fontSize}
            fontWeight={svg?.fontWeight}
            color={svg?.color}
            backgroundColor={
              prefix_key && !isTransparent ? svg?.backgroundColor : ''
            }
          >
            {svg?.points}
          </ObjFieldDrawingSVGText>
        )}
      </foreignObject>

      {svg?.key === selectedElementKey && !isEditing && (
        <rect
          className="data-html-to-image-ignore"
          ref={wrapperRef}
          style={{
            cursor: 'pointer',
            border: '1px solid red',
            pointerEvents: 'all',
          }}
          id={wrapperId}
          x="2"
          y="2"
          width={WrapWidthElement}
          height={WrapHeightElement}
          fill="transparent"
          stroke="#D8D8D8"
          strokeWidth="2"
          strokeDasharray="8 4"
          pointerEvents="all"
        />
      )}
    </svg>
  );
};

export default withSvgTransform(SvgTextRenderer);
