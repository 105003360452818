import { useEffect, useState } from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import { StarterKit } from '@tiptap/starter-kit';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import Heading from '@tiptap/extension-heading';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';
import ListItem from '@tiptap/extension-list-item';
import TiptapMenuBar from './TiptapMenuBar';
import { StyledEditorContainer, StyledEditorContent } from './TiptapEditorCSS';
import TextAlign from './TiptapTextAlignExtension';

const TiptapEditor = ({ value, onUpdate, editable }) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const editor = useEditor({
    extensions: [
      StarterKit,
      Bold,
      Italic,
      Heading,
      BulletList,
      OrderedList,
      ListItem,
      TextAlign.configure({ types: ['heading', 'paragraph'] }),
    ],
    content: value ?? '',
    onUpdate: ({ editor }) => {
      onUpdate && onUpdate(editor.getHTML());
    },
    autofocus: true,
    editable,
  });

  useEffect(() => {
    if (editor && value !== editor.getHTML()) {
      editor.commands.setContent(value);
    }
  }, [editor, editable]); // we don't run effect each time the value change

  return (
    <StyledEditorContainer isFullScreen={isFullScreen}>
      <TiptapMenuBar
        editor={editor}
        toggleFullScreen={() => setIsFullScreen(!isFullScreen)}
        isFullScreen={isFullScreen}
      />
      <StyledEditorContent isFullScreen={isFullScreen} editor={editor} />
    </StyledEditorContainer>
  );
};

export default TiptapEditor;
