import styled, { keyframes, css } from 'styled-components';

export const ObjGraphsBubbleHtmlStyles = {
  xses: {
    fontSize: '10px',
    color: '#AAA',
    fontWeight: '600',
    backgroundColor: '5489E211',
    borderRadius: '5px',
  },
  td: {
    borderTop: '1px dashed var(--ds-border-base)',
    borderRight: '1px dashed var(--ds-border-base)',

    padding: 'auto',
    textAlign: 'center',
    verticalAlign: 'middle',
    height: '30px',
    lineHeight: '30px',
  },

  bubbleBg: {
    height: '28px',
    minWidth: '28px',
    padding: 'auto',
    textAlign: 'center',
    borderRadius: '50%',
    height: '30px',
    width: '30px',
    verticalAlign: 'middle',
    lineHeight: '30px',
    padding: 'auto',
    display: 'grid',
    placeItems: 'center',
  },

  date: {
    color: '#000000',
    fontSize: '10px',
    fontWeight: '400',
    textAlign: 'center',
    padding: 'auto',
    borderRadius: '3px',
    backgroundColor: '#f8f8f8',
    height: '20px',
  },
  table: {
    position: 'relative',
    overflowX: 'hidden',
    backgroundColor: 'tranparent',
  },
  content: {
    marginTop: '0px',
    marginBottom: '0px',
  },

  total: {
    padding: '4px',
    textAlign: 'center',
    fontWeight: 'bold',
    color: 'var(--ds-base)',
    fontSize: '0.9em',
  },
};
export const ObjGraphsBubbleHtmlContent = styled.div({
  backgroundColor: (props) =>
    props.isNegative
      ? 'var(--ds-bg-secondary)'
      : props.aboveRef
      ? 'pink'
      : 'var(--ds-bg-secondary)',

  borderRadius: '50%',
  color: 'var(--ds-text-base)',
  fontSize: '10px',
  fontWeight: '600',
  height: (props) => props.height,
  lineHeight: (props) => props.lineHeight,
  marginLeft: (props) => props.marginLeft,
  marginTop: (props) => props.marginTop,
  position: 'absolute',
  textAlign: 'center',
  minWidth: (props) => props.width,
  transition: 'width 0.5s, height 0.5s',
});

export const NavigationContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between', // Aligne les boutons de manière espacée
  alignItems: 'center',
  marginTop: '2px',
  display: (props) => (props.disabled ? 'none' : ''),
});

export const NavigationButton = styled.button((props) => ({
  fontSize: '12px', // Taille du symbole
  color: props.disabled ? '#eeeeee00' : '#000', // Gris si désactivé, noir sinon
  backgroundColor: props.disabled ? '#EFEFEF' : 'var(--ds-bg-secondary)',
  borderRadius: '6px',
  border: 'none', // Pas de bordure
  cursor: 'pointer',
  transition: 'color 0.3s ease', // Transition pour le changement de couleur
  '&:hover': {
    color: props.disabled ? '#cccccc' : '#007bff', // Bleu au survol si actif
  },
}));

// Animation pour glisser vers la gauche (sortie pour 'next')
const slideOutLeft = keyframes`
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(-100%);
    opacity: 0;
  }
`;

// Animation pour glisser depuis la droite (entrée pour 'next')
const slideInRight = keyframes`
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

// Animation pour glisser vers la droite (sortie pour 'prev')
const slideOutRight = keyframes`
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
`;

// Animation pour glisser depuis la gauche (entrée pour 'prev')
const slideInLeft = keyframes`
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

// Conteneur animé
export const TableContainer = styled.div`
  position: relative;
  animation: ${(props) =>
    props.animation === 'out' && props.direction === 'next'
      ? css`
          ${slideOutLeft} 0.3s ease
        `
      : props.animation === 'out' && props.direction === 'prev'
      ? css`
          ${slideOutRight} 0.3s ease
        `
      : props.animation === 'in' && props.direction === 'next'
      ? css`
          ${slideInRight} 0.3s ease
        `
      : css`
          ${slideInLeft} 0.3s ease
        `};
`;
