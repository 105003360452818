import React from 'react';

import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import BigNumber from '../../UI/BigNumber/BigNumber';
import BigMonetary from '../../UI/BigMonetary/BigMonetary';
import ProgressBar from '../../UI/ProgressBar/ProgressBar';
import { lighten } from '../../Utils/colorManipulator';

import { getHost } from '../../../helper/getHost';
import MiniChartSVG from '../../UI/MiniChartSVG/MiniChartSVG';
import ObjFieldCamRec from '../../UI/Objects/ObjFieldCamRec/ObjFieldCamRec.js';
import ObjFieldDrawingPad from '../../UI/Objects/ObjFieldDrawingPad/ObjFieldDrawingPad';
import ObjFieldFormula from '../../UI/Objects/ObjFields/ObjFieldFormula';
import { removeTagHtml, formatNumber, formatMonetary } from '../../Utils/Utils';
import {
  DivSmallTagAreaStyle,
  DivSmallTagNumberStyle,
  DivSmallTagStyle,
  FieldCamrecContent,
  FieldFile,
  FieldFileContainer,
  FieldListContent,
  HalfBlocSmallTag,
  HalfBlocSmallTagLabelStyle,
  PercentFieldContent,
  SmallTagsContainer,
  SmallTagsContent,
  SpanSmallTagAreaValueStyle,
  SpanSmallTagLabelStyle,
  SpanSmallTagValueStyle,
  TextFieldUrlContent,
} from './SmallTagsCSS.js';
import { parseJson } from '../../../utils/parseJson.js';
import SmallTag from './SmallTag.js';

//------------------------------------------------------------------------------

let slotBadge = [];
let slotBigNumber = [];

const SmallTags = ({
  arrayFields,
  pal,
  nodeColor,
  objectsDefList,
  dicoNameRef,
  onLoad,
  objId,
  node,
}) => {
  slotBadge = [];
  slotBigNumber = [];

  return (
    <SmallTagsContainer>
      {arrayFields?.map((field, ind) => {
        return (
          <SmallTag
            key={ind}
            ind={ind}
            field={field}
            nodeColor={nodeColor}
            zoneType={'zoneStandard'}
            dicoNameRef={dicoNameRef}
            onLoad={onLoad}
            pal={pal}
            objectsDefList={objectsDefList}
            objId={objId}
            node={node}
          />
        );
      })}

      <SmallTagsContent>
        {arrayFields?.map((field, ind) => {
          return (
            <SmallTag
              key={ind}
              ind={ind}
              field={field}
              nodeColor={nodeColor}
              zoneType={'zoneBigNumber'}
              dicoNameRef={dicoNameRef}
              onLoad={onLoad}
              pal={pal}
              objectsDefList={objectsDefList}
              objId={objId}
              node={node}
            />
          );
        })}
      </SmallTagsContent>
      <SmallTagsContent>
        {arrayFields?.map((field, ind) => {
          return (
            <SmallTag
              key={ind}
              ind={ind}
              field={field}
              nodeColor={nodeColor}
              zoneType={'zoneBadge'}
              dicoNameRef={dicoNameRef}
              onLoad={onLoad}
              pal={pal}
              objectsDefList={objectsDefList}
              objId={objId}
              node={node}
            />
          );
        })}
      </SmallTagsContent>
    </SmallTagsContainer>
  );
};

export default SmallTags;
