import React from 'react';
import { useSelector } from 'react-redux';
import { svgDiagramSelector } from '../../reducers/svgDiagramReducer/selectors';
import { makeSvgDraggable } from './svgDraggableAdapter';

// withSvgTransform.js
export function withSvgTransform(WrappedComponent) {
  return function WithSvgTransform(props) {
    const { panPosition, zoomLevel } = useSelector(svgDiagramSelector);

    const transformClientToSVG = (clientX, clientY) => {
      let x = clientX;
      let y = clientY;
      const svgPad = document.getElementById('svgPad');
      const svgPoint = svgPad.createSVGPoint();
      svgPoint.x = clientX;
      svgPoint.y = clientY;
      const transformGroup = svgPad.querySelector('g');
      if (transformGroup) {
        const CTM = transformGroup.getScreenCTM();
        if (CTM) {
          const inverseCTM = CTM.inverse();
          const transformedPoint = svgPoint.matrixTransform(inverseCTM);
          x = transformedPoint.x;
          y = transformedPoint.y;
        }
      }
      return { x, y };
    };

    const makeDraggable = (
      element,
      onStart,
      onEnd,
      onDrag,
      onClick,
      threshold
    ) => {
      // Store the element's position for the draggable context
      if (element && props.svg) {
        element.__draggableContext = {
          startX: props.svg.x,
          startY: props.svg.y,
        };
      }

      return makeSvgDraggable(
        element,
        onStart,
        onEnd,
        onDrag,
        onClick,
        threshold,
        panPosition,
        zoomLevel
      );
    };

    return (
      <WrappedComponent
        {...props}
        makeDraggable={makeDraggable}
        svgPanPosition={panPosition}
        svgZoomLevel={zoomLevel}
        transformClientToSVG={transformClientToSVG}
      />
    );
  };
}
