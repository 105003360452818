import { NODE_NAVIG_WIDTH, NODE_OFFSET_X } from '../../../constants/layout';
import { getBandsPosition } from '../../Utils/LayoutUtils';

export const updateNodeHorizontalPositions = (
  nodes = [],
  scalesConfig,
  bandsConfig
) => {
  return nodes.map((node) => {
    const trgPanel = node.depth - 1;
    const band = getBandsPosition(trgPanel, bandsConfig);
    const bandWidth = band.width;
    const bandStart = band.start;
    const bandEnd = band.end;
    const availableSpace = bandWidth - NODE_NAVIG_WIDTH;

    // Get the scaler and field name for this level
    const scaler = scalesConfig.bandScales[trgPanel];
    const paramClassifNode = scalesConfig.arrayClassifBy[trgPanel];
    const fieldValue = node.data[paramClassifNode];

    const hasDefaultPosition =
      !fieldValue ||
      paramClassifNode === 'default' ||
      trgPanel < 0 ||
      typeof scaler !== 'function';

    node.level = trgPanel;
    node.x = bandStart;
    node.xmax = bandEnd;

    try {
      let relativePos = 0; // used for block position
      let absolutePos = 0; // used for arrows to block

      if (!hasDefaultPosition) {
        relativePos = scaler(fieldValue) ?? 0;
        absolutePos = bandStart + relativePos * (availableSpace / 100);
      }

      if (hasDefaultPosition || isNaN(relativePos)) {
        relativePos = 0;
        absolutePos = bandStart + NODE_OFFSET_X;
      }
      absolutePos = Math.max(0, absolutePos);

      node.x = absolutePos; // used for arrow position
      node.offsetX = relativePos; // used for block position

      return node;
    } catch (error) {
      return node;
    }
  });
};