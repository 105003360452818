import ObjectActionCreators from '../../actions';
import ObjectApi from '../../api/ObjectApi';
import store from '../../store/configureStore';
import { prepareParamsCurrentPerspective } from '../../utils/commonFunctionObjectsReducer';
import { calculateZoomObjFormData } from '../../utils/layoutCommon';
import { AUTH_TYPE } from '../authReducer/type';
import { setZoom } from '../layoutReducer/action';
import { zoomSelector } from '../layoutReducer/layoutSelector';
import { OBJECTS_TYPE } from './type';
import { joinPattern } from '../../components/Utils/TextUtils';

const NUM_LEVEL = 5;

export const getPerspectives = () => {
  return (dispatch, getState) => {
    const isLoadingPerspectivesSelector =
      getState()?.objects?.isLoadingPerspectives;
    dispatch({ type: 'GET_PERSPECTIVES' });
    ObjectApi.getPerspectives()
      .then((objects) => {
        if (!isLoadingPerspectivesSelector) {
          dispatch({
            type: OBJECTS_TYPE.SET_LOADING_PERSPECTIVE,
            value: true,
          });
        }

        dispatch({
          type: OBJECTS_TYPE.RECEIVED_PERSPECTIVES,
          success: true,
          objects,
        });
      })
      .catch((error) =>
        dispatch({ type: OBJECTS_TYPE.RECEIVED_PERSPECTIVES, success: false })
      )
      .finally(() => {
        dispatch({
          type: OBJECTS_TYPE.SET_LOADING_PERSPECTIVE,
          value: false,
        });
      });
  };
};

export const getUserRole = () => {
  return (dispatch) => {
    dispatch({ type: 'REQUEST_USERROLE' });
    ObjectApi.getUserRole().then(
      (objects) =>
        dispatch({
          type: AUTH_TYPE.RECEIVED_USERROLE,
          success: true,
          objects,
        }),
      (error) => dispatch({ type: AUTH_TYPE.RECEIVED_USERROLE, success: false })
    );
  };
};

export const getUserData = () => {
  return (dispatch) => {
    dispatch({ type: 'REQUEST_USERDATA' });
    ObjectApi.getUserData().then(
      (objects) => {
        dispatch({
          type: 'RECEIVED_USERDATA',
          success: true,
          objects,
        });
        if (!window.location.pathname.includes('MOD_')) {
          const state = store.getState();
          let homepage = state?.profile?.homepage;
          if (homepage != null && homepage.length > 0) {
            dispatch(openPerspectiveById(homepage));
          }
        }
      },

      (error) => dispatch({ type: 'RECEIVED_USERDATA', success: false })
    );
  };
};

export const getUserPrivs = () => {
  return (dispatch) => {
    dispatch({ type: 'REQUEST_USERPRIVS' });
    ObjectApi.getUserPrivs().then(
      (objects) =>
        dispatch({
          type: AUTH_TYPE.RECEIVED_USERPRIVS,
          success: true,
          objects,
        }),
      (error) =>
        dispatch({ type: AUTH_TYPE.RECEIVED_USERPRIVS, success: false })
    );
  };
};
export const setLoadingCrumbTrail = (status) => {
  return (dispatch) => {
    dispatch({ type: OBJECTS_TYPE.SET_LOADING_CRUMB_TRAIT, status });
  };
};

export const resetCrumbTrail = () => {
  return (dispatch) => {
    dispatch({ type: OBJECTS_TYPE.RESET_CRUMB_TRAIL });
  };
};

export const openActivityFromVertical = (status) => {
  return (dispatch) => {
    dispatch({ type: OBJECTS_TYPE.OPEN_ACTIVITY_FROM_VERTICAL, status });
  };
};

export const setNodeDraggedOver = (objId) => {
  return (dispatch) => {
    dispatch({ type: OBJECTS_TYPE.SET_DRAGGED_OVER, objId });
  };
};

export const addScrollListener = (callback) => {
  return (dispatch) => {
    dispatch({ type: OBJECTS_TYPE.ADD_SCROLLLISTENER, callback });
  };
};

export const startDraggingNodes = (key) => {
  return (dispatch) => {
    let promise = ObjectApi.selectObject(key).then(
      (objects) =>
        dispatch({
          type: OBJECTS_TYPE.RECEIVED_DRAGGED_OBJECT,
          success: true,
          objects,
          key,
        }),
      (error) =>
        dispatch({
          type: OBJECTS_TYPE.RECEIVED_DRAGGED_OBJECT,
          success: false,
        })
    );
    dispatch({ type: OBJECTS_TYPE.STARTED_DRAGGING_NODE, key, promise });
  };
};

export const setNodesPosition = (nodes) => {
  return (dispatch) => {
    dispatch({ type: OBJECTS_TYPE.SET_NODES_POSITION, nodes });
  };
};

export const showNextPage = (objId) => {
  return (dispatch) => {
    dispatch({ type: OBJECTS_TYPE.SET_PAGING, objId });
    dispatch(refreshTreeByPattern());
  };
};

export const setCurrentConfiguration = (value) => {
  return (dispatch) => {
    dispatch({ type: OBJECTS_TYPE.SEC_CURRENT_CONFIG, value });
  };
};

export const toggleAdditionalColumn = (objectType, column) => {
  return (dispatch) => {
    dispatch({
      type: OBJECTS_TYPE.TOGGLE_ADDITIONAL_COLUMN,
      objectType,
      column,
    });
  };
};

export const toggleAdditionalLine = (objectType, line) => {
  return (dispatch) => {
    dispatch({ type: OBJECTS_TYPE.TOGGLE_ADDITIONAL_LINE, objectType, line });
    dispatch({ type: 'TOGGLED_ADDITIONAL_LINE' });
  };
};

export const resetAdditionalLines = (objectType) => {
  return (dispatch) => {
    dispatch({ type: OBJECTS_TYPE.RESET_ADDITIONAL_LINES, objectType });
    dispatch({ type: 'TOGGLED_ADDITIONAL_LINE' });
  };
};

export const resetAdditionalColumns = (objectType) => {
  return (dispatch) => {
    dispatch({ type: OBJECTS_TYPE.RESET_ADDITIONAL_COLUMNS, objectType });
  };
};

export const fetchObjects = (objId) => {
  return (dispatch) => {
    ObjectApi.fetchObjects(objId).then(
      (objects) =>
        dispatch({
          type: OBJECTS_TYPE.RECEIVE_OBJECTS,
          success: true,
          objects,
          objId,
        }),
      (error) =>
        dispatch({ type: OBJECTS_TYPE.RECEIVE_OBJECTS, success: false })
    );
  };
};

export const saveCurrentPerspective = (perspId) => {
  return (dispatch) => {
    const state = store.getState();
    const params = prepareParamsCurrentPerspective(state);
    let paramPerspId = perspId;
    if (!paramPerspId) {
      paramPerspId = state.objects.perspectiveId;
    }
    ObjectApi.saveObject(paramPerspId, params).then((objects) => {
      ObjectApi.selectObject(paramPerspId).then(
        (objects) => {
          dispatch({
            type: OBJECTS_TYPE.SELECTED_OBJECT,
            success: true,
            objects,
          });
        },
        (error) =>
          dispatch({ type: OBJECTS_TYPE.SELECTED_OBJECT, success: false })
      );
    });
  };
};

export const refreshAISynthesis = (objId, fieldName, localInstruction) => {
  return (dispatch) => {
    ObjectApi.refreshAISynthesis(objId, fieldName, localInstruction).then(
      (objects) => {
        dispatch(ObjectActionCreators.selectObject(objId));
        dispatch(refreshTreeByPattern());
      },
      (error) =>
        dispatch({ type: OBJECTS_TYPE.REFRESHED_AISYNTHESIS, success: false })
    );
  };
};

export const getAIAsk = () => {
  return (dispatch) => {
    ObjectApi.getAIAsk().then(
      (objects) => {},
      (error) => dispatch({ type: OBJECTS_TYPE.GOT_AI_ASK, success: false })
    );
  };
};

export const cleanAISynthesis = (objId, fieldName) => {
  return (dispatch) => {
    ObjectApi.cleanAISynthesis(objId, fieldName).then(
      (objects) => {
        dispatch(ObjectActionCreators.selectObject(objId));
      },
      (error) =>
        dispatch({ type: OBJECTS_TYPE.REFRESHED_AISYNTHESIS, success: false })
    );
  };
};

export const refreshTreeByPattern = () => {
  const state = store.getState();
  return getTreeByPattern(
    state.objects.pattern,
    state.QBUILDER.qb_restrictions,
    state.objects.paging_map,
    state.objects.paging_excluded,
    state.objects.mandatoryLevel
  );
};

export const openPerspectiveById = (objId) => {
  return (dispatch) => {
    dispatch({ type: 'OPEN_PERSPECTIVE_BY_ID', objId });
    ObjectApi.selectObject(objId).then(
      (objects) => {
        dispatch({
          type: OBJECTS_TYPE.SELECTED_OBJECT,
          success: true,
          objects,
        });
        dispatch(openPerspective(objects.objFormData));
      },
      (error) => dispatch({ type: 'OPENED_PERSPECTIVE_BY_ID', success: false })
    );
  };
};
export const openPerspective = (objFormData) => {
  return (dispatch, getState) => {
    const oldZoom = zoomSelector(getState());
    const newZoom = calculateZoomObjFormData(objFormData);
    if (oldZoom !== newZoom) {
      dispatch(setZoom(newZoom));
    }
    dispatch({ type: OBJECTS_TYPE.OPEN_PERSPECTIVE, objFormData });
  };
};

export const shiftPerspectiveDown = (objId) => {
  console.log('shiftPerspectiveDown');

  return (dispatch) => {
    const father = store
      .getState()
      .objects.fathers.find((elem) => elem.objId === objId);
    if (father) {
      const { fatherId } = father;
      const level = 0;
      dispatch({ type: OBJECTS_TYPE.SHIFT_PERSPECTIVE_DOWN, objId });
      dispatch({
        type: 'SET_TERM_TO_QB',
        level,
        comparator: 'text_is',
        fieldType: 'text',
        field: 'key',
        value: fatherId,
      });
    }
  };
};

export const shiftPerspectiveUp = (objId) => {
  console.log('shiftPerspectiveUp');
  return (dispatch) => {
    const level = 0;
    dispatch({ type: OBJECTS_TYPE.SHIFT_PERSPECTIVE_UP, objId });
    dispatch({
      type: 'SET_TERM_TO_QB',
      level,
      comparator: 'text_is',
      fieldType: 'text',
      field: 'key',
      value: objId,
    });
  };
};

export const search = (term) => {
  return (dispatch) => {
    dispatch({ type: 'SEARCH_TERM', term });
    ObjectApi.search(term).then(
      (objects) =>
        dispatch({
          type: OBJECTS_TYPE.RECEIVE_SEARCH_RESULT,
          success: true,
          objects,
        }),
      (error) =>
        dispatch({ type: OBJECTS_TYPE.RECEIVE_SEARCH_RESULT, success: false })
    );
  };
};

export const getTreeByPattern = (pattern) => {
  return (dispatch, getState) => {
    let meta = { shouldAbort: true };
    dispatch({
      type: OBJECTS_TYPE.REQUEST_TREE_BY_PATTERN,
      pattern,
      meta,
    });
    return;
    const state = getState();
    ObjectApi.getTreeByPattern(
      pattern,
      state.QBUILDER.qb_restrictions,
      state.objects.paging_map,
      state.objects.paging_excluded,
      state.objects.mandatoryLevel,
      state.objects.arraySortBy,
      meta?.abortSignal
    ).then(
      (objects) => {
        dispatch({ type: OBJECTS_TYPE.RECEIVE_TREE, success: true, objects });
        for (let i = 0; i < NUM_LEVEL + 1; i++) {
          dispatch(ObjectActionCreators.getChildsCountByType(null, i));
        }
      },
      (error) => {
        // dispatch({ type: OBJECTS_TYPE.RECEIVE_TREE, success: false });
      }
    );
  };
};

export const setOpenModule = (moduleId) => {
  return (dispatch) => {
    dispatch({ type: OBJECTS_TYPE.SET_OPEN_MODULE, moduleId });
  };
};

export const getObjectsByTypeNoRestrictions = (type) => {
  return (dispatch) => {
    dispatch({ type: 'GET_OBJECTS_BY_TYPE' });

    ObjectApi.getTreeByPattern(
      joinPattern([type, 'none', 'none']),
      null,
      null,
      null
    ).then(
      (objects) => {
        dispatch({
          type: OBJECTS_TYPE.RECEIVED_OBJECTS,
          success: true,
          objects,
        });
      },
      (error) =>
        dispatch({ type: OBJECTS_TYPE.RECEIVED_OBJECTS, success: false })
    );
  };
};

export const getProfiles = () => {
  return (dispatch) => {
    dispatch({ type: 'GET_PROFILES' });

    ObjectApi.getTreeByPattern(
      joinPattern(['employee', 'none', 'none']),
      null,
      null,
      null
    ).then(
      (objects) => {
        dispatch({
          type: OBJECTS_TYPE.RECEIVED_PROFILES,
          success: true,
          objects,
        });
      },
      (error) =>
        dispatch({ type: OBJECTS_TYPE.RECEIVED_PROFILES, success: false })
    );
  };
};

export const setArrayParamClassif = (
  arrayParamClassif,
  arrayParamClassifDetails
) => {
  return (dispatch) => {
    dispatch({
      type: OBJECTS_TYPE.SET_ARRAY_PARAM_CLASSIF,
      arrayParamClassif,
      arrayParamClassifDetails,
    });
  };
};

export const openObjectMenuForObj = (type, key) => {
  return (dispatch) => {
    dispatch({
      type: 'OPEN_OBJECTMENU_FOR_OBJ',
      ptype: type,
      pkey: key,
      action: 'addObject',
    });
  };
};

export const setCue = (value) => {
  return (dispatch) => {
    dispatch({ type: OBJECTS_TYPE.SET_CUE, value });
  };
};

export const setLoadingPerspective = (value) => {
  return (dispatch) => {
    dispatch({ type: OBJECTS_TYPE.SET_LOADING_PERSPECTIVE, value });
  };
};

export const setLoadingReceiveTree = (level, value) => {
  return (dispatch) => {
    dispatch({ type: OBJECTS_TYPE.SET_LOADING_RECEIVE_TREE, value, level });
  };
};
