import styled from 'styled-components';

export const StyledButton = styled.button({
  color: 'white',
  backgroundColor: 'var(--ds-base)',
  margin: '3px',
  padding: '3px',
  paddingTop: '5px',
  borderRadius: '50%',
  border: '0px',
  width: '26px',
});

export const StyledMenuBar = styled.div({
  backgroundColor: 'var(--ds-bg-base)',
  padding: (props) => (props.isFullScreen ? '10px' : '0px'),
  borderBottom: (props) => (props.isFullScreen ? '3px solid #ddd' : ''),
  position: (props) => (props.isFullScreen ? 'sticky' : 'relative'),
  top: 0,
  zIndex: 1000,
  width: '100%',
  maxWidth: '1024px',
});
