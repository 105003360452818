import * as d3 from 'd3';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { svgDiagramSelector } from '../../reducers/svgDiagramReducer/selectors';
import { buildCursorSvgPadWithAction } from '../../utils/buildCursorSvgPadWithAction';
import {
  calculateConnectionPoints,
  generateConnectorPath,
} from './svgDiagramUtils';
import { DRAW_POINT_COLOR, DRAW_POINT_TICK } from '../../constants/drawing';
import { useDiagramView, useElementOperations } from '../../reducers/svgDiagramReducer/hooks';

/**
 * SmartConnectorRenderer - Renders a connector between two elements
 *
 * @param {Object} lineData - The connector data
 * @param {Array} allElements - All elements in the diagram
 * @param {string} idLineDrawing - ID of the current line being drawn
 * @param {Function} setSvgFocus - Function to set focus on a SVG element
 * @param {boolean} isDisableAction - Whether actions are disabled
 * @param {Function} setActionDragSvg - Function to set the drag action
 * @param {Function} onUpdatePointsOnMove - Function to update points on move
 * @param {string} prefix_key - Prefix for the key (for preview/export modes)
 * @param {Function} onUpdateConnectorData - Function to update connector data
 */
const SmartConnectorRenderer = ({
  lineData,
  allElements,
  setSvgFocus,
  isDisableAction,
  prefix_key,
  onUpdateConnectorData,
}) => {
  const { selectedElementKey, currentAction } = useSelector(svgDiagramSelector);
  const { deleteSelected } = useElementOperations();
  const svgRef = useRef();
  const svgVertexRef = useRef(null);
  const [positionSvg, setPositionSvg] = useState(null);
  const [dataPoint, setDataPoint] = useState([]);
  const [boundingBox, setBoundingBox] = useState({ width: 0, height: 0 });

  // Set initial points from lineData
  useEffect(() => {
    if (lineData?.points && lineData.points.length >= 2) {
      setDataPoint(lineData.points);
    }
  }, [lineData?.points]);

  // Create a unique ID for this connector
  const idSvg = prefix_key ? `${prefix_key}${lineData?.key}` : lineData?.key;

  // Handle selecting this connector
  const handleSelectSvg = (e) => {
    if (e) e.stopPropagation();

    if (isDisableAction) return;

    if (currentAction === 'delete') {
      return deleteSelected(lineData?.key);
    }

    if (typeof setSvgFocus === 'function') {
      setSvgFocus(lineData?.key);
    }
  };

  // Function to draw the connector with markers and path
  const drawConnector = () => {
    const svg = d3.select(svgRef.current);
    svg.selectAll('*').remove();

    // Define marker for the arrow with prefixed ID to avoid conflicts
    const markerId = `arrow-${prefix_key || ''}${lineData.key}`;

    // Create arrow marker
    svg
      .append('defs')
      .append('marker')
      .attr('id', markerId)
      .attr('viewBox', '0 -5 10 10')
      .attr('refX', 2)
      .attr('refY', 0)
      .attr('markerWidth', 3)
      .attr('markerHeight', 3)
      .attr('orient', 'auto')
      .append('path')
      .attr('d', 'M0,-5L10,0L0,5')
      .style('fill', lineData?.color);

    // Calculate connection points between source and target elements
    const connectionPoints = calculateConnectionPoints(
      lineData,
      allElements,
      prefix_key
    );

    if (
      connectionPoints &&
      connectionPoints.source &&
      connectionPoints.target
    ) {
      // Draw the connector path
      const path = svg
        .append('path')
        .attr(
          'd',
          generateConnectorPath(
            connectionPoints.source,
            connectionPoints.target,
            lineData.curveAmount || 20
          )
        )
        .attr('stroke', lineData?.color)
        .attr('stroke-width', lineData?.tick)
        .attr('fill', 'none')
        .attr('marker-end', `url(#${markerId})`)
        .on('click', handleSelectSvg);

        if (lineData.text) {
          // Create a text element
          const pathNode = path.node();
          if (pathNode) {
            // Get the path length
            const pathLength = pathNode.getTotalLength();
            // Calculate the midpoint position
            const midPoint = pathNode.getPointAtLength(pathLength / 2);
            
            // Add a text element at the midpoint
            svg.append('text')
              .attr('x', midPoint.x)
              .attr('y', midPoint.y)
              .attr('dy', -10) // Offset from the line
              .attr('text-anchor', 'middle')
              .attr('fill', lineData.textColor || lineData.color)
              .attr('font-size', lineData.textSize || 14)
              .attr('pointer-events', 'none') // Prevent text from blocking clicks
              .text(lineData.text)
              .on('click', handleSelectSvg);
              
            // Optionally add a background rectangle for better readability
            if (lineData.text.length > 0) {
              const textNode = svg.select('text').node();
              if (textNode) {
                const textBox = textNode.getBBox();
                svg.insert('rect', 'text')
                  .attr('x', textBox.x - 8)
                  .attr('y', textBox.y - 3)
                  .attr('width', textBox.width + 16)
                  .attr('height', textBox.height + 6)
                  .attr('rx', 5)          // Rayon de l'arrondi horizontal
                  .attr('ry', 5)     
                  .attr('fill', 'white')
                  .attr('fill-opacity', 0.8)
                  .attr('pointer-events', 'none');
              }
            }
          }
        }

      // Store the connection points
      setDataPoint([connectionPoints.source, connectionPoints.target]);

      // Calculate bounding box for selection indicator
      const pathNode = path.node();
      if (pathNode) {
        const bbox = pathNode.getBBox();
        setBoundingBox({
          width: bbox.width,
          height: bbox.height,
          x: bbox.x,
          y: bbox.y,
        });

        // Set position for selection box
        setPositionSvg({
          x: bbox.x,
          y: bbox.y,
        });
      }

      // Show vertex points for editing if selected
      if (lineData?.key === selectedElementKey) {
        showVertexPoints([connectionPoints.source, connectionPoints.target]);
      }
    } else if (dataPoint && dataPoint.length >= 2) {
      // Fallback to direct points if connection points can't be calculated
      const line = svg
        .append('line')
        .attr('x1', dataPoint[0].x)
        .attr('y1', dataPoint[0].y)
        .attr('x2', dataPoint[1].x)
        .attr('y2', dataPoint[1].y)
        .attr('stroke', lineData?.color)
        .attr('stroke-width', lineData?.tick)
        .attr('marker-end', `url(#${markerId})`)
        .on('click', handleSelectSvg);

      // Calculate bounding box for simple line
      setBoundingBox({
        width: Math.abs(dataPoint[1].x - dataPoint[0].x),
        height: Math.abs(dataPoint[1].y - dataPoint[0].y),
        x: Math.min(dataPoint[0].x, dataPoint[1].x),
        y: Math.min(dataPoint[0].y, dataPoint[1].y),
      });

      // Set position for selection box
      setPositionSvg({
        x: Math.min(dataPoint[0].x, dataPoint[1].x),
        y: Math.min(dataPoint[0].y, dataPoint[1].y),
      });
    }
  };

  // Show vertex points for selected connector
  const showVertexPoints = (points) => {
    if (!points || points.length < 2) return;

    d3.select(svgVertexRef.current).selectAll('*').remove();

    d3.select(svgVertexRef.current)
      .selectAll('.vertex')
      .data(points)
      .enter()
      .append('circle')
      .attr('cx', (d) => d.x)
      .attr('cy', (d) => d.y)
      .attr('r', DRAW_POINT_TICK)
      .attr('fill', DRAW_POINT_COLOR)
      .attr('class', 'vertex');
  };

  // Draw connector when data changes
  useEffect(() => {
    drawConnector();
  }, [lineData, selectedElementKey, allElements]);

  // Update connector when source or target elements change position
  useEffect(() => {
    if (lineData.sourceId && lineData.targetId) {
      const connectionPoints = calculateConnectionPoints(
        lineData,
        allElements,
        prefix_key
      );

      if (
        connectionPoints &&
        connectionPoints.source &&
        connectionPoints.target
      ) {
        // Update dataPoint state
        setDataPoint([connectionPoints.source, connectionPoints.target]);

        // Redraw the connector
        drawConnector();

        // Update connector data in parent component
        if (typeof onUpdateConnectorData === 'function') {
          onUpdateConnectorData(
            lineData.key,
            connectionPoints.source,
            connectionPoints.target
          );
        }
      }
    }
  }, [allElements, lineData]);

  return (
    <>
      {/* Main connector SVG */}
      <svg
        style={{
          cursor: buildCursorSvgPadWithAction(
            isDisableAction,
            currentAction,
            lineData?.link
          ),
        }}
        ref={svgRef}
        id={idSvg}
      ></svg>

      {/* Selection indicator */}
      {selectedElementKey === lineData?.key &&
        positionSvg &&
        boundingBox.width > 0 && (
          <rect
            className="data-html-to-image-ignore"
            style={{ cursor: 'pointer' }}
            x={positionSvg.x - 10}
            y={positionSvg.y - 10}
            width={boundingBox.width + 20}
            height={boundingBox.height + 20}
            fill="transparent"
            stroke="#D8D8D8"
            strokeWidth="4"
            strokeDasharray="8 4"
            onClick={handleSelectSvg}
          />
        )}

      {/* Vertex points container */}
      <svg ref={svgVertexRef} />
    </>
  );
};

export default SmartConnectorRenderer;
