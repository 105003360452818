import { connect } from 'react-redux';
import ObjectActionCreators from '../../actions';
import { userPrivSelector } from '../../reducers/authReducer/authSelector';
import { openObjectDetails } from '../../reducers/layoutReducer/action';
import { getObjectsByTypeNoRestrictions } from '../../reducers/objectReducers/action';
import ObjectSelector from './ObjectSelector';

//------------------------------------------------------------------------------------------------
const mapStateToProps = (state, ownProps) => {
  return {
    objectSelectorState: state.OBJECT_SELECTOR.objectSelectorState,
    availableTypes: state.objects.objectsDefList,
    suggestedType: state.OBJECT_CREATOR.suggestedType,
    objectsByType: state.objects.objectsReceived,
    objId: state.objects.objId,
    userPriv: userPrivSelector(state),
    dicoNameRef: state.objects.dicoNameRef,
    pattern: state.objects.pattern,
    perspectiveId: state.objects.perspectiveId,
  };
};
//------------------------------------------------------------------------------------------------
const mapDispatchToProps = (dispatch) => ({
  linkObject: (objId) => dispatch(ObjectActionCreators.linkObject(objId)),
  linkField: (fieldName) => dispatch(ObjectActionCreators.linkField(fieldName)),
  setObjectSelectorState: (objectSelectorState) =>
    dispatch(ObjectActionCreators.setObjectSelectorState(objectSelectorState)),
  getObjectsByTypeNoRestrictions: (type) =>
    dispatch(getObjectsByTypeNoRestrictions(type)),
  getObjDefList: () => dispatch(ObjectActionCreators.getObjDefList()),
  createFromObjectDefinitionInNodeOnLayers: (
    objId,
    idParent,
    perspectiveId,
    layersRead,
    layersWrite
  ) =>
    dispatch(
      ObjectActionCreators.createFromObjectDefinitionInNodeOnLayers(
        objId,
        idParent,
        perspectiveId,
        layersRead,
        layersWrite
      )
    ),
  openObjectDetails: () => dispatch(openObjectDetails()),
  closeSelectObject: () => dispatch(ObjectActionCreators.closeSelectObject()),
});
export default connect(mapStateToProps, mapDispatchToProps)(ObjectSelector);
