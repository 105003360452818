export const OBJECTS_TYPE = {
  INIT_DONE: 'INIT_DONE',
  SET_PAGING: 'SET_PAGING',
  GET_STATE: 'GET_STATE',
  RECEIVED_STATE: 'RECEIVED_STATE',
  SET_NODES_POSITION: 'SET_NODES_POSITION',
  GOT_NBR_CHILD: 'GOT_NBR_CHILD',
  ADD_SCROLLLISTENER: 'ADD_SCROLLLISTENER',
  REMOVE_SCROLLLISTENER: 'REMOVE_SCROLLLISTENER',
  LOG_LOGIN: 'LOG_LOGIN',
  LOG_SIGNIN: 'LOG_SIGNIN',
  UPDATED_PROFILE: 'UPDATED_PROFILE',
  CONFIGURE_SELECT_TAB: 'CONFIGURE_SELECT_TAB',

  GOT_WHOS_LOGGEDSERVER: 'GOT_WHOS_LOGGEDSERVER',
  CHECKED_TOKEN: 'CHECKED_TOKEN',
  CHECKED_LOGIN: 'CHECKED_LOGIN',
  SET_ID_TOKEN: 'SET_ID_TOKEN',
  SET_ACTIVE_PANEL: 'SET_ACTIVE_PANEL',
  SET_ACTIVE_BAND: 'SET_ACTIVE_BAND',
  UPDATE_DATA_COLUMN_SIZE: 'UPDATE_DATA_COLUMN_SIZE',
  SET_SORT_BY: 'SET_SORT_BY',
  TOGGLE_DISPLAY_MODE: 'TOGGLE_DISPLAY_MODE',
  TOGGLE_MANDATORY_LEVEL: 'TOGGLE_MANDATORY_LEVEL',
  SET_DISPLAY_MODE: 'SET_DISPLAY_MODE',
  SET_STAT_BY: 'SET_STAT_BY',
  SET_ARRAY_PARAM_CLASSIF: 'SET_ARRAY_PARAM_CLASSIF',
  SET_QB_RESTRICTION_BY_LEVEL: 'SET_QB_RESTRICTION_BY_LEVEL',
  TOGGLE_PALETTE: 'TOGGLE_PALETTE',
  SET_ZOOM: 'SET_ZOOM',
  // SET_ZOOM_MAP: 'SET_ZOOM_MAP',
  SET_CUE: 'SET_CUE',
  COLLAPSE_NODE: 'COLLAPSE_NODE',
  COLLAPSE_NODES: 'COLLAPSE_NODES',
  EXPAND_NODE: 'EXPAND_NODE',
  EXPAND_NODES: 'EXPAND_NODES',
  SEC_CURRENT_CONFIG: 'SEC_CURRENT_CONFIG',
  TIMELINE_SHIFT: 'TIMELINE_DOWNDAY',
  TOGGLE_ADDITIONAL_COLUMN: 'TOGGLE_ADDITIONAL_COLUMN',
  TOGGLE_ADDITIONAL_LINE: 'TOGGLE_ADDITIONAL_LINE',
  RESET_ADDITIONAL_LINES: 'RESET_ADDITIONAL_LINES',
  RESET_ADDITIONAL_COLUMNS: 'RESET_ADDITIONAL_COLUMNS',
  CHECK_LOGIN: 'CHECK_LOGIN',
  SET_HOVER_NODE: 'SET_HOVER_NODE',
  CLEAR_FIELD_RESTRICTION: 'CLEAR_FIELD_RESTRICTION',
  ADD_FIELD_RESTRICTION: 'ADD_FIELD_RESTRICTION',
  CREATED_FROM_OBJ_DEF: 'CREATED_FROM_OBJ_DEF',
  POST_CREATED_FROM_OBJ_DEF: 'POST_CREATED_FROM_OBJ_DEF',
  CREATED_PERSP_FROM_OBJ_DEF: 'CREATED_PERSP_FROM_OBJ_DEF',
  POST_CREATED_PERSP_FROM_OBJ_DEF: 'POST_CREATED_PERSP_FROM_OBJ_DEF',
  DELETE_OBJECT: 'DELETE_OBJECT',
  UNSTACK_OBJECT: 'UNSTACK_OBJECT',
  LINK_OBJECT: 'LINK_OBJECT',
  LINK_FIELD: 'LINK_FIELD',
  STACK_OBJECT: 'STACK_OBJECT',
  SELECT_OBJECT: 'SELECT_OBJECT',
  SELECTED_OBJECT: 'SELECTED_OBJECT',
  CLEAN_OBJECT: 'CLEAN_OBJECT',
  SAVE_OBJECTFORMDATA: 'SAVE_OBJECTFORMDATA',
  ADDED_NODE_TO_LAYER: 'ADDED_NODE_TO_LAYER',
  SELECTED_DEF_OBJECT: 'SELECTED_DEF_OBJECT',
  SAVE_CURRENT_PERSPECTIVE: 'SAVE_CURRENT_PERSPECTIVE',
  SET_OPEN_MODULE: 'SET_OPEN_MODULE',
  SET_PANELS: 'SET_PANELS',
  GOT_FATHER: 'GOT_FATHER',
  SHIFT_PERSPECTIVE_DOWN: 'SHIFT_PERSPECTIVE_DOWN',
  SHIFT_PERSPECTIVE_UP: 'SHIFT_PERSPECTIVE_UP',
  OPEN_PERSPECTIVE: 'OPEN_PERSPECTIVE',
  RECEIVED_OBJECTS: 'RECEIVED_OBJECTS',
  RECEIVED_PROFILES: 'RECEIVED_PROFILES',
  // RECEIVED_USERPRIVS: 'RECEIVED_USERPRIVS',
  REFRESHED_OBJECT: 'REFRESHED_OBJECT',
  REFRESHED_AISYNTHESIS: 'REFRESHED_AISYNTHESIS',
  GOT_AI_ASK: 'GOT_AI_ASK',
  REQUEST_TREE_BY_PATTERN: 'REQUEST_TREE_BY_PATTERN',
  STARTED_DRAGGING_NODE: 'STARTED_DRAGGING_NODE',
  RECEIVED_DRAGGED_OBJECT: 'RECEIVED_DRAGGED_OBJECT',
  SET_DRAGGED_OVER: 'SET_DRAGGED_OVER',
  SET_DRAGGED_OBJECT: 'SET_DRAGGED_OBJECT',
  SET_SELECTED_OBJECT: 'SET_SELECTED_OBJECT',
  SET_GLOBAL_VALUE: 'SET_GLOBAL_VALUE',
  SET_CLASSIFX: 'SET_CLASSIFX',
  SET_CLASSIFY: 'SET_CLASSIFY',
  SELECT_CONTEXT: 'SELECT_CONTEXT',
  UP_CONTEXT: 'UP_CONTEXT',
  DOWN_CONTEXT: 'DOWN_CONTEXT',
  REQUEST_OBJECTS: 'REQUEST_OBJECTS',
  RECEIVE_EVENTS: 'RECEIVE_EVENTS',
  RECEIVE_TREE: 'RECEIVE_TREE',
  RECEIVED_PERSPECTIVES: 'RECEIVED_PERSPECTIVES',
  RECEIVE_SEARCH_RESULT: 'RECEIVE_SEARCH_RESULT',
  RECEIVE_LAYERS: 'RECEIVE_LAYERS',
  FILTER_SET_LEVEL: 'FILTER_SET_LEVEL',
  RECEIVED_CRUMBTRAIL: 'RECEIVED_CRUMBTRAIL',
  RECEIVED_SOCIAL: 'RECEIVED_SOCIAL',
  RECEIVE_OBJECTS: 'RECEIVE_OBJECTS',
  SAVE_OBJECT_DEFINITION: 'SAVE_OBJECT_DEFINITION',
  SET_STATUS_OBJECT_DEFINITION: 'SET_STATUS_OBJECT_DEFINITION',
  RECEIVED_CHILDS_COUNT_BY_TYPE: 'RECEIVED_CHILDS_COUNT_BY_TYPE',
  RECEIVED_OBJECTS_DEF_LIST: 'RECEIVED_OBJECTS_DEF_LIST',
  SAVE_OBJECT: 'SAVE_OBJECT',
  RESET_ACTIVE_PANEL: 'RESET_ACTIVE_PANEL',
  SET_LOADING_CRUMB_TRAIT: 'SET_LOADING_CRUMB_TRAIT',
  RESET_CRUMB_TRAIL: 'RESET_CRUMB_TRAIL',
  RESET_CRUMB_TRAIL: 'RESET_CRUMB_TRAIL',
  OPEN_ACTIVITY_FROM_VERTICAL: 'OPEN_ACTIVITY_FROM_VERTICAL',
  SET_LOADING_STATUS: 'SET_LOADING_STATUS',
  SET_LOADING_PERSPECTIVE: 'SET_LOADING_PERSPECTIVE',
  SET_LOADING_RECEIVE_TREE: 'SET_LOADING_RECEIVE_TREE',
  SET_OBJ_TYPE_ID_CONFIG_OBJECT: 'SET_OBJ_TYPE_ID_CONFIG_OBJECT',
};
