import { connect } from 'react-redux';
import ObjectActionCreators from '../../../actions';
import {
  draggedOnNode,
  onActiveActivityLog,
  onSetSelectNode,
  openObjectDetails,
} from '../../../reducers/layoutReducer/action';
import { zoomSelector } from '../../../reducers/layoutReducer/layoutSelector';
import {
  openActivityFromVertical,
  openObjectMenuForObj,
  refreshTreeByPattern,
  shiftPerspectiveDown,
  shiftPerspectiveUp,
} from '../../../reducers/objectReducers/action';
import { currObjNbrChildsSelector } from '../../../reducers/objectReducers/objectSelector.js';
import { onSetCurrentBandTablet } from '../../../reducers/responsiveReducer/action';
import {
  currentBandActiveSelector,
  isDragTabletSelector,
  positionNodeDragSelector,
} from '../../../reducers/responsiveReducer/responsiveSelector';
import {
  currentPaletteActiveSelector,
  isDarkModeSelector,
} from '../../../reducers/themeReducers/themeSelector';
import NavigNode from './NavigNode';

function mapStateToProps(state, ownProps) {
  return {
    collapsedNodes: state.objects.collapsedNodes,
    currObjNbrChilds: currObjNbrChildsSelector(state),
    dataLines: state.objects.dataLines,
    dicoNameRef: state.objects.dicoNameRef,
    isNodeHover: state.objects.hoverNode === ownProps.node.data.key,
    isDark: isDarkModeSelector(state),
    layers: state.objects.layers,
    node0: state.objects.node0,
    nodes: state.objects.nodes,
    objectsDefList: state.objects.objectsDefList,
    pal: currentPaletteActiveSelector(state),
    zoom: zoomSelector(state),
    isDragTablet: isDragTabletSelector(state),
    currentBandActive: currentBandActiveSelector(state),
    positionNodeDrag: positionNodeDragSelector(state),
  };
}
//------------------------------------------------------------------------------------------------------
const mapDispatchToProps = (dispatch) => {
  return {
    addFieldRestriction: (
      fieldName,
      fieldValue,
      objectType,
      fieldType,
      comparator,
      level
    ) =>
      dispatch(
        ObjectActionCreators.addFieldRestriction(
          fieldName,
          fieldValue,
          objectType,
          fieldType,
          comparator,
          level
        )
      ),
    selectObject: (objId) => dispatch(ObjectActionCreators.selectObject(objId)),
    collapseNode: (objId) => dispatch(ObjectActionCreators.collapseNode(objId)),
    createBrother: (objId, withChilds = false, nbCopy = 1) =>
      dispatch(ObjectActionCreators.createBrother(objId, withChilds, nbCopy)),
    suggestModel: (objId) => dispatch(ObjectActionCreators.suggestModel(objId)),
    suggestWorkflow: (objId) =>
      dispatch(ObjectActionCreators.suggestWorkflow(objId)),
    implementModel: (objId) =>
      dispatch(ObjectActionCreators.implementModel(objId)),
    shiftPerspectiveUp: (objId) => dispatch(shiftPerspectiveUp(objId)),
    shiftPerspectiveDown: (objId) => dispatch(shiftPerspectiveDown(objId)),
    expandNode: (objId) => dispatch(ObjectActionCreators.expandNode(objId)),
    getNumberChilds: (objId) =>
      dispatch(ObjectActionCreators.getNumberChilds(objId)),
    openObjectDetails: () => {
      dispatch(openObjectDetails());
    },
    openObjectMenuForObj: (type, key, action) =>
      dispatch(openObjectMenuForObj(type, key, action)),
    setHoverNode: (objId) => dispatch(ObjectActionCreators.setHoverNode(objId)),
    getFather: (objId) => dispatch(ObjectActionCreators.getFather(objId)),
    showNextPage: (objId) => dispatch(ObjectActionCreators.showNextPage(objId)),
    deleteObject: (objId) => dispatch(ObjectActionCreators.deleteObject(objId)),
    refreshTreeByPattern: () => dispatch(refreshTreeByPattern()),
    onActiveActivityLog: (objId) => dispatch(onActiveActivityLog(objId)),
    openActivityFromVertical: (status) =>
      dispatch(openActivityFromVertical(status)),
    onSetSelectNode: (nodeId) => dispatch(onSetSelectNode(nodeId)),
    onSetCurrentBandTablet: (status) =>
      dispatch(onSetCurrentBandTablet(status)),
    draggedOnNode: (nodeId, dragged_node_key) =>
      dispatch(draggedOnNode(nodeId, dragged_node_key)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(NavigNode);
