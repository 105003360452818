// nodeBuilderUtils.js
import { isEmpty } from 'lodash';
import NavigNode from '../../node/NavigNode';
import { getBandsPosition } from '../../Utils/LayoutUtils';

import {
  calculateLeftVal,
  calculateNodeLeftContainer,
} from '../../../utils/nodeLayout';
import { NODE_NAVIG_WIDTH } from '../../../constants/layout';
import { IconExpandColumn } from '../NavigHeaderBar/NavigHeaderBarCss';
import { DoubleArrowIcon } from '../../../constants/icon';
import DataColumns from '../DataColumns/DataColumns';
import { rgbStringToHex } from '../../Utils/Utils';

/**
 * Calcule les statistiques pour un nœud individuel
 * @param {Object} node - Nœud à analyser
 * @param {string} paramClassifNode - Paramètre de classification
 * @param {Map} dataMap - Map des statistiques
 * @param {Array} arrayStatBy - Statistiques à calculer
 */
const calculateNodeStats = (node, paramClassifNode, dataMap, arrayStatBy) => {
  if (!node || !paramClassifNode || !dataMap || !arrayStatBy) return;

  let mainEntry =
    paramClassifNode === 'default' ? 'default' : node.data[paramClassifNode];
  mainEntry += `_${node.level}`;

  if (!dataMap.get(mainEntry)) {
    dataMap.set(mainEntry, new Map());
  }

  const subMap = dataMap.get(mainEntry);
  const currentCount = subMap.get('Count') || 0;
  subMap.set('Count', currentCount + 1);

  const fieldStat = arrayStatBy[node.depth - 1];
  if (node.data[fieldStat]) {
    const currentSum = subMap.get('Sum') || 0;
    subMap.set('Sum', currentSum + node.data[fieldStat]);
    const average =
      Math.round((100 * subMap.get('Sum')) / (currentCount + 1)) / 100;
    subMap.set('Average', average);
  }
};

/**
 * Calcule les attributs visuels d'un nœud (couleur, etc.)
 * @param {Object} node - Nœud à traiter
 * @param {Function} colorScale - Échelle de couleurs à appliquer
 * @param {string} paramClassifNode - Paramètre de classification
 * @returns {Object} Attributs visuels du nœud
 */
const computeNodeVisuals = (node, colorScale, paramClassifNode) => {
  let nodeColor =
    typeof colorScale === 'function'
      ? colorScale(node.data[paramClassifNode]) ?? 'var(--ds-base)'
      : 'var(--ds-base)';
  return {
    nodeColor: rgbStringToHex(nodeColor),
  };
};

/**
 * Calcule les dimensions et la position d'un nœud
 * @param {Object} params - Paramètres de calcul
 * @returns {Object} Position et dimensions du nœud
 */
const computeNodeGeometry = ({
  node,
  isGrid,
  currBand,
  indBand,
  displayMode,
  isMobile,
  widthScreen,
}) => {
  const nodeWidth = isGrid ? currBand.width - 80 : NODE_NAVIG_WIDTH;
  const leftVal = calculateLeftVal({ currBand, indBand, node });

  return {
    width: isMobile ? widthScreen - 70 : nodeWidth,
    left: calculateNodeLeftContainer({
      displayMode,
      leftVal,
      level: node.level,
      isMobile,
    }),
    top: node.y,
    height: node.ySize,
  };
};

/**
 * Crée les composants de nœuds pour le rendu
 * @param {Object} params - Paramètres pour la création des nœuds
 * @returns {Array} Composants de nœuds rendus
 */
export const buildNodes = ({
  nodes,
  dataMap,
  myNodeColorScale,
  displayMode,
  isMobile,
  bands_config,
  arrayClassifBy,
  eventHandlers,
  draggedItem,
  updateNodeHeight,
  handleDrop,
}) => {
  if (!nodes) return [];

  const widthScreen =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

  return nodes.map((node, index) => {
    const { canWrite, canDuplicateType } = node.data;
    const paramClassifNode = arrayClassifBy[node.depth - 1];

    // Calcule les statistiques et les attributs visuels
    calculateNodeStats(node, paramClassifNode, dataMap, arrayClassifBy);
    const visuals = computeNodeVisuals(
      node,
      myNodeColorScale[node.depth - 1],
      paramClassifNode
    );
    node.data.nodeColor = visuals.nodeColor;

    // Calcule la géométrie du nœud
    const currBand = getBandsPosition(node.depth - 1, bands_config);
    const isGrid = displayMode[node.depth - 1] === 'grid' && !isMobile;
    const geometry = computeNodeGeometry({
      node,
      isGrid,
      currBand,
      indBand: node.depth - 1,
      displayMode,
      isMobile,
      widthScreen,
    });

    // Vérifie si la colonne suivante a du contenu
    const isNextColumnEmpty = isEmpty(
      nodes.filter((a) => a.depth - 1 === node.depth - 1)
    );

    return (
      <NavigNode
        key={node.data.key}
        canWrite={canWrite}
        canDuplicateType={canDuplicateType}
        dragging={draggedItem === node.data.key}
        draggingItem={draggedItem}
        height={geometry.height}
        isGrid={isGrid}
        left={geometry.left}
        node={node}
        nodeColor={visuals.nodeColor}
        nodeLeft={0}
        top={geometry.top}
        width={geometry.width}
        updateNodeHeight={updateNodeHeight}
        isHadContend={isNextColumnEmpty}
        handleDrop={handleDrop}
        isNodeOnActivePanel={true}
        onTouchStart={eventHandlers.handleTouchStart}
        onTouchEnd={eventHandlers.handleTouchEnd}
        onDragEnd={eventHandlers.handleDragEnd}
        onDragStart={eventHandlers.handleDragStart}
        onDrop={eventHandlers.handleOnDrop}
        onMouseUp={eventHandlers.handleMouseUp}
        onMouseOver={eventHandlers.handleMouseOver}
      >
        <IconExpandColumn>
          <DoubleArrowIcon fill={'var(--ds-icon-secondary)'} />
        </IconExpandColumn>
        <DataColumns node={node} />
      </NavigNode>
    );
  });
};
