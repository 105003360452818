// statsUtils.js

/**
 * Generate statistics for a node based on classification parameters
 * @param {Object} node - The node to generate statistics for
 * @param {string} paramClassifNode - Classification parameter (e.g., 'default', 'right')
 * @param {number} depth - Node depth in the tree
 * @param {Map} dataMap - Map to store the statistics
 * @param {Array} arrayStatBy - Array of statistical fields to compute
 * @returns {Map} Updated statistics map
 */
export const generateNodeStats = (
  node,
  paramClassifNode,
  depth,
  dataMap,
  arrayStatBy
) => {
  // Generate main entry key
  let mainEntry;
  if (paramClassifNode === 'default') {
    mainEntry = 'default';
  } else {
    mainEntry = node.data[paramClassifNode];
  }
  mainEntry += `_${node.level}`;

  // Initialize or get the submap for this entry
  if (!dataMap.get(mainEntry)) {
    dataMap.set(mainEntry, new Map());
  }
  const subMap = dataMap.get(mainEntry);

  // Update count
  const currentCount = subMap.get('Count') || 0;
  subMap.set('Count', currentCount + 1);

  // Update sum and average if statistical field exists
  const fieldStat = arrayStatBy[depth - 1];
  if (node.data[fieldStat]) {
    const currentSum = Number(subMap.get('Sum') || 0);
    const newSum = currentSum + Number(node.data[fieldStat]);
    subMap.set('Sum', newSum);

    // Calculate and update average
    const average = Math.round((100 * newSum) / (currentCount + 1)) / 100;
    subMap.set('Average', average);
  }

  return dataMap;
};

/**
 * Initialize a new statistics map
 * @returns {Map} Empty statistics map
 */
export const initializeStatsMap = () => {
  return new Map();
};
